import React from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetDropdownOptions = () => {
  const { t } = useTranslation()

  const locationAdditionsChoices = [
    {
      id: 'premium_assortment',
      name: t('placeme.demo.sales_prediction_model.location_additions.premium_assortment'),
      checked: false,
      listItemName: <span>{t('placeme.demo.sales_prediction_model.location_additions.premium_assortment')}</span>,
    },
    {
      id: 'self_service_checkouts',
      name: t('placeme.demo.sales_prediction_model.location_additions.self_service_checkouts'),
      checked: false,
      listItemName: <span>{t('placeme.demo.sales_prediction_model.location_additions.self_service_checkouts')}</span>,
    },
    {
      id: 'pylon',
      name: t('placeme.demo.sales_prediction_model.location_additions.pylon'),
      checked: false,
      listItemName: <span>{t('placeme.demo.sales_prediction_model.location_additions.pylon')}</span>,
    },
  ]

  const options = [
    {
      value: true,
      label: t('generic.yes'),
    },
    {
      value: false,
      label: t('generic.no'),
    },
  ]

  const locationTypeOptions = [{
    value: 'high_street',
    label: t('placeme.demo.sales_prediction_model.dropdown.option.high_street'),
  },
  {
    value: 'local',
    label: t('placeme.demo.sales_prediction_model.dropdown.option.local'),
  },
  {
    value: 'retail_park',
    label: t('placeme.demo.sales_prediction_model.dropdown.option.retail_park'),
  }]

  const sizeOptions = [{
    value: 'small',
    label: t('placeme.demo.sales_prediction_model.criteria.future_shopping_mall_size.small'),
  },
  {
    value: 'medium',
    label: t('placeme.demo.sales_prediction_model.criteria.future_shopping_mall_size.medium'),
  },
  {
    value: 'big',
    label: t('placeme.demo.sales_prediction_model.criteria.future_shopping_mall_size.big'),
  }]

  return {
    locationAdditionsChoices,
    options,
    locationTypeOptions,
    sizeOptions,
  }
}

