import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { MapVisualizationModal, WarningModal } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { CoinsButton, PopupWithOverlay, TextButton } from '@dataplace.ai/ui-components/atoms'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { bigBenCategoryId } from '@dataplace.ai/constants'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { getRangeTranslation } from '@dataplace.ai/functions/utils'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import TileErrorBoundary from '@dataplace.ai/features/components/TileErrorBoundary'
import { TileHeader } from '../TileHeader'
import { ITile } from '../../../slice/@types/ITile'
import { ReactComponent as InfoIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/largeIcons/info.svg'
import { RootState } from '../../../../../redux/store'
import {
  deleteTileAction,
  fetchTileDataAction,
  saveChosenRangeAction,
  saveNewRangeAction,
  saveTileData,
} from '../../../slice/analysisSlice'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
// import { TileNotes } from '../TileNotes'
import { tilesWithDoublePropertiesChoose } from './constant/TilesWithDoublePropertiesChoose'
import { tilesWithoutComparedLocation } from '../AddAnotherLocationModal/constants'
import { PlacemeSelect } from '../PlacemeSelect/PlacemeSelect'

const Wrapper = styled.div<{ fullWidth: boolean, $isPrinting: boolean }>(({
  theme, fullWidth, $isPrinting,
}) => {
  const {
    palette, shadows, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    box-shadow: ${shadows.tiny.boxShadow};
    border-left: 0.25rem solid ${palette.product.location.main};
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.main};
    width: ${fullWidth ? '200%' : '74%'};
    height: min-content;

    ${$isPrinting && css`
      width: 100%;
      height: 100%;
      border: none;
      box-shadow: none;
      border-radius: none;
    `}
  `
})

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding: 1.25rem;
  box-sizing: border-box;
`

const InfoWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    margin-bottom: -1.25rem;
    padding: 1.25rem 1.25rem 0 1.25rem;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > svg {
      margin-right: 1rem;
    }

    > span {
      white-space: pre-wrap;
    }
  `
})

interface StyledInfoIconProps {
  $maxWidth: string
  $maxHeigth: string
}

const StyledInfoIcon = styled(InfoIcon)<StyledInfoIconProps>(({
  $maxWidth,
  $maxHeigth,
}) => css`
  max-width: ${$maxWidth};
  max-height: ${$maxHeigth};
`)

export interface IOddTileProps {
  tile: ITile
  sectionTile: ISectionTile
  userId: string,
  staticImage?: string
}

export const OddTile = ({
  tile,
  sectionTile,
  userId,
  staticImage,
}: IOddTileProps): JSX.Element => {
  // constants
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isPrinting } = useCheckIfPrinting()
  const authContext = useContext(AuthContext)
  const {
    values, creditsAmount, showHints, canBeSave, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, currentSubscriptionData, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)

  const {
    component: Component, canChooseRange, haveToAccept, maxRanges,
  } = tile

  const tileId = useMemo(() => values?.find(c => c.id === sectionTile.id)?.tiles.find(t => t.id === tile.id)?.id,
    [JSON.stringify(values)])

  const dropdownOptions: ResourceWithId[] = [
    {
      id: 'freestander',
      content: <>{t('placeme.mcd.competition_traffic.dropdown.option.freestander')}</>,
      range: {
        id: `${tileId}-freestander`,
        value: 10,
        type: 'car',
      },
    },
    {
      id: 'in_store',
      content: <>{t('placeme.mcd.competition_traffic.dropdown.option.instore')}</>,
      range: {
        id: `${tileId}-5-instore`,
        value: 10,
        type: 'car',
      },
    },
    {
      id: 'mall',
      content: <>{t('placeme.mcd.competition_traffic.dropdown.option.mall')}</>,
      range: {
        id: `${tileId}-mall`,
        value: 10,
        type: 'car',
      },
    },
    {
      id: 'mop',
      content: <>{t('placeme.mcd.competition_traffic.dropdown.option.mop')}</>,
      range: {
        id: `${tileId}-mop`,
        value: 20,
        type: 'car',
      },
    },
  ]

  // states
  const [isModalOpen, toggle] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [accepted, setAccepted] = useState(false)
  const [token, setToken] = useState('')
  const [selectedPointType, setSelectedPointType] = useState<ResourceWithId>(dropdownOptions[0])

  // functions
  const fetchChosenRange = (category: string, thisTile: string) => (
    values?.find((value) =>
      value.id === category)?.tiles?.find((tile) => tile.id === thisTile)?.chosenRange
  )

  const [chosenRange, setChosenRange] = useState(
    fetchChosenRange(sectionTile.id, tile.id),
  )

  const handleModal = () => {
    toggle(!isModalOpen)
  }

  const handleDeleteRange = () => {
    setAccepted(false)
    dispatch(saveChosenRangeAction(sectionTile.id, tile.id, undefined))
    dispatch(saveTileData(sectionTile.id, tile.id, undefined))
    setChosenRange(undefined)
    toggle(!isModalOpen)
  }

  const handleDeleteTile = () => dispatch(deleteTileAction(token, sectionTile.id, tile.id, comparedAnalyseId || analyseId || ''))

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const handleSave = () => {
    if (creditsAmount) {
      setAccepted(true)
    }
  }

  const data = useMemo(() => values?.find(c => c.id === sectionTile.id)?.tiles.find(t => t.id === tile.id)?.data,
    [JSON.stringify(values)])

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (
      chosenRange
      && canBeSave
      && !tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0])
      && token.length
      && !data
      && (sectionTile?.id !== bigBenCategoryId || ['traffic_visualisation_big_ben', 'old_traffic_visualisation_big_ben'].includes(tile.id.split('-')[0]))
      && (tile?.chosenRange?.type === 'custom' || !comparedLocation?.generatedFromNow || tilesWithoutComparedLocation.includes(tile?.id?.split('-')[0]))
    ) {
      dispatch(fetchTileDataAction(
        token,
        sectionTile.id,
        tile.id,
        currentSubscriptionData?.value?.subscriptionId || null,
        selectedPointType.id,
      ))
    }
  }, [chosenRange, canBeSave, token, currentSubscriptionData?.value?.subscriptionId])

  useEffect(() => {
    setChosenRange(fetchChosenRange(sectionTile.id, tile.id))
  }, [values])

  useEffect(() => {
    if (token && !tile?.chosenRange?.catchmentId && accepted) {
      dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', sectionTile?.id, tile?.id, selectedPointType.range!))
    }
  }, [token, accepted])

  return (
    <Wrapper
      $isPrinting={isPrinting}
      className='main-tile-wrapper pdf-tile'
      fullWidth={!showHints}
    >
      <>
        <PopupWithOverlay
          onClose={() => toggle(false)}
          open={isModalOpen}
        >
          <WarningModal
            agreementButtonText={t('generic.delete')}
            cancelButtonText={t('generic.cancel')}
            description={(
              <>
                {t('tile.delete.warning')}
                <br />
                {`${t('tile.delete.save_report')}.`}
              </>
            )}
            handleAgreement={handleDeleteRange}
            handleCancel={handleModal}
            heading={t('tile.delete.heading')}
            redWarning={t('tile.delete.red_warning')}
          />
        </PopupWithOverlay>
        <TileHeader
          dataLoaded={!!data?.value}
          handleMap={handleMapOpen}
          range={chosenRange}
          sectionTile={sectionTile}
          tile={tile}
          toggleChangeRangeModal={handleModal}
        />
        {/* <TileNotes
          dataLoaded={!!data?.value}
          notes={tile.notes}
          sectionTile={sectionTile}
          tile={tile}
          token={token}
        /> */}

        {haveToAccept && !accepted && !data?.value && (
          <>
            {tile.acceptDescription && (
              <InfoWrapper>
                <StyledInfoIcon
                  $maxHeigth='20px'
                  $maxWidth='20px'
                />
                <span>
                  {t(tile.acceptDescription)}
                  {tile.showRangeDescription
                    ? (
                      <>
                        <br />
                        {t('placeme.mcd_competition_traffic.accept_description_1')}
                        {' '}
                        {getRangeTranslation(selectedPointType.range).toLowerCase()}
                        .
                      </>
                    )
                    : null}
                </span>
              </InfoWrapper>
            )}
            <PlacemeSelect
              name='point_type_dropdown'
              onChange={(_, value) => setSelectedPointType(value)}
              options={dropdownOptions}
              padding='2rem 0 0 4.3rem'
              placeholder={t('placeme.mcd.competition_traffic.dropdown.placeholder')}
              selected={selectedPointType}
              width='45%'
            />
            <ButtonsWrapper>
              <TextButton onClick={handleDeleteTile}>
                <p>{t('generic.cancel')}</p>
              </TextButton>
              <CoinsButton
                isExtraPaid={tile.isExtraPaid}
                isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
                margin='0 0 0 1.82rem'
                onClick={handleSave}
                text={tile.isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
                tileId={tile?.id ?? ''}
                value={checkComparedCoinsValue(comparedLocation?.generatedFromNow, !!comparedLocation?.location, !tilesWithoutComparedLocation.includes(tile?.id?.split('-')[0]))}
              />
            </ButtonsWrapper>
          </>
        )}

        {isMapDisplayed && (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            layers={(chosenRange?.geoJSON?.coordinates || tile?.chosenRange?.geoJSON?.coordinates)
              ? [{
                id: chosenRange?.catchmentId || tile?.chosenRange?.catchmentId || '',
                layer: {
                  data: {
                    coordinates: (chosenRange?.geoJSON?.coordinates || tile?.chosenRange?.geoJSON?.coordinates) as IGeojson['data']['coordinates'],
                    type: 'Polygon',
                    properties: {},
                  },
                  options: {
                    type: 'geojson',
                    id: 'some_overlay_id',
                    style: {
                      color: '#0000a2',
                      fillColor: '#0000a2',
                      weight: 0,
                      fillOpacity: 0.3,
                    },
                  },
                },
              }]
              : undefined}
            location={value}
            mapId={`range-map-${chosenRange?.catchmentId || tile?.chosenRange?.catchmentId}`}
            setIsDisplay={setIsMapDisplayed}
          />
        )}
        <TileErrorBoundary name={tileId!}>
          <>
            {/* explanation of display conditions */}
            {/* 1. tiles in which we have to choose a range */}
            {/* 2. tiles where we cannot select a range but do not need to confirm (select something else) */}
            {/* 3. tiles where we have to confirm */}
            {((chosenRange || (!canChooseRange && !haveToAccept) || (haveToAccept && accepted)) || data?.value || tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0]))
              && (typeof Component === 'function'
                ? (
                  <Component
                    accepted={accepted}
                    catchmentId={chosenRange?.catchmentId}
                    data={data}
                    isExtraPaid={tile.isExtraPaid}
                    maxRanges={maxRanges}
                    setAccepted={setAccepted}
                    staticImage={staticImage}
                    tileId={tileId}
                    userId={userId}
                  />
                )
                : (
                  Component
                ))}
          </>
        </TileErrorBoundary>
      </>
    </Wrapper>
  )
}
