import { VolumeCheckboxFilterProps } from '../VolumeCheckboxFilter'

interface FilterDataProps {
  data: VolumeCheckboxFilterProps['data']
  selectedElements: string | string[]
  elements: {
    below?: number
    from?: number
    to?: number
    above?: number
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useGetFilteredData() {
  function getFilteredData({
    elements, selectedElements, data,
  }: FilterDataProps) {
    if (!elements) return []
    const newData: string[] = []
    if (elements.below && selectedElements.includes(elements.below.toString())) {
      const volumeBelow = data.filter(investment => Number(investment.volume) < elements.below!)
        .map(investment => investment.volume.toString())
      newData.push(...volumeBelow)
    }
    if (elements.to && elements.from && selectedElements.includes(elements.to.toString())) {
      const volumeFromTo = data.filter(investment => (
        Number(investment.volume) >= (elements.from! - 1)
          && Number(investment.volume) <= (elements.to! + 1)))
        .map(investment => investment.volume.toString())
      newData.push(...volumeFromTo)
    }
    if (elements.above && selectedElements.includes(elements.above.toString())) {
      const volumeAbove = data.filter(investment => Number(investment.volume) > elements.above!)
        .map(investment => investment.volume.toString())
      newData.push(...volumeAbove)
    }
    return newData
  }

  return {
    getFilteredData,
  }
}

export default useGetFilteredData
