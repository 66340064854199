import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { Button } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { useTranslation } from 'react-i18next'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { getCookieWithoutJSON, setCookieWithoutJSON } from '@dataplace.ai/functions/utils'
import { ReactComponent as CloseIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/add.svg'
import { INewFeatureModal } from './@types/INewFeatureModal'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2.5rem 2.5rem 10px 2.5rem;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08), 0px 0px 24px rgba(95, 82, 73, 0.08);
  `
})

const TopWrapper = styled.div`
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`
const Heading = styled.h2(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 1rem 0;
    color: ${palette.black};
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};

    >svg{
        position: absolute;
        left: 0px;
        top: 25px
    }
    `
})
const Description = styled.h2(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      margin: 2rem 0;
      max-width: 600px;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      text-align: justify;
      `
})

const BottomWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0;
`

export const NewFeatureModal = ({
  token, userInfo,
}:INewFeatureModal):JSX.Element => {
  const [open, setOpen] = useState(false)
  const [currentCounter, setCurrentCounter] = useState(0)
  const closeModal = () => setOpen(false)

  const saveCounter = useCallback(async () => {
    const body = {
      featureModalCounter: currentCounter + 1,
    }
    const axiosInstance = await getAxios()
    await axiosInstance.patch(`${ENDPOINTS.USER_FEATURE_MODAL_COUNTER_PATCH}`, body)
  }, [token, currentCounter])

  const onOk = async () => {
    saveCounter()
    setCookieWithoutJSON('isNewFeatureModalClosed', 'true')
    closeModal()
  }

  useEffect(() => {
    if (userInfo) {
      setCurrentCounter(userInfo?.featureModalCounter)
      setOpen(userInfo?.featureModalCounter < 2 && !(getCookieWithoutJSON('isNewFeatureModalClosed') === 'true'))
    }
  }, [userInfo])

  const { t } = useTranslation()
  return (
    <div>
      <Popup
        closeOnDocumentClick={false}
        modal
        onClose={closeModal}
        open={open}
        overlayStyle={defaultTheme.newFeatureModalOverlay}
      >
        <Wrapper>
          <TopWrapper>
            <CloseIcon
              height='18'
              onClick={onOk}
              style={{
                transform:'rotate(45deg)',
                cursor: 'pointer',
              }}
              width='18'
            />
          </TopWrapper>
          <Heading>
            {/* <ArrowIcon /> */}
            {t('placeme.new_feature_modal.header')}
          </Heading>
          <Description
            dangerouslySetInnerHTML={{
              __html: t('placeme.new_feature_modal.description'),
            }}
          />
          <img
            alt='what is new'
            src={t('placeme.whats_new.image')}
            style={{
              maxHeight: `${t('placeme.whats_new.max_height')}`.includes('px') ? `${t('placeme.whats_new.max_height')}` : '250px',
            }}
          />
          <BottomWrapper>
            <Button
              margin='0 0 0 20px'
              minWidth='180px'
              onClick={onOk}
            >
              {t('generic.cool')}
            </Button>
          </BottomWrapper>
        </Wrapper>
      </Popup>
    </div>
  )
}
