import styled, { css } from 'styled-components'

export const CheckboxesOutsideWrapper = styled.div(({ theme: { palette } }) => css`
  position: relative;
  max-height: 15rem;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-top: 1px solid ${palette.gray.light};
  border-bottom: 1px solid ${palette.gray.light};
`)

export const CheckboxesWrapper = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > div {
    padding: 0.5rem 0;
  }

  > div:last-of-type {
    margin-bottom: 1rem;
  }

  label {
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    cursor: pointer;
  }
`)
