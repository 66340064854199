// tiles id from which you can make pdf
export const tilesPdfList = [
  // [population]
  'people',
  'demography',
  'purchasing_power',
  'municipality_population',
  'spending',
  'location_type',
  'overlapping_ranges',
  // [market]
  'cannibalization',
  'pois',
  // [access logistics]
  'transport',
  'access_range',
  // [potential]
  'gravity_model',
  'potential_model',
  'potential_within_chain',
  // [surroundings]
  'buildings',
  'investments',
  'employees_in_range',
  // [traffic]
  'visiting_hours',
  'traffic_visualisation',
  'customer_origin',
  // [visualization]
  'traffic_visualisation_map',
  // 'cities_population', na ten czas wyłączamy
  'satellite',
  // [big ben]
  'look_alike_big_ben',
  'segmentation_big_ben',
  'population_big_ben',
  'scoring_model_big_ben',
  'traffic_big_ben',
  'old_traffic_visualisation_big_ben',
  'pois_big_ben',
  'mcd_competition_traffic',
  'mcd_store_chain_impact',
  'mcd_potential_model',
  'anw_competition',
  // [demo]
  'demo_scoring_prediction',
  'demo_sales_prediction_model',
  'demo_potential_within_chain',
  'demo_store_chain_impact',
]
