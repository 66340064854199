import { getAxios } from 'libs/shared/functions/src/utils'

export interface IResponseDataObject {
  loading: boolean
  error: unknown,
  value: unknown,
}

export const getResponseDataObject = async (
  endpoint: string,
  body: unknown,
): Promise<IResponseDataObject> => {
  const axiosInstance = await getAxios({
    errCallbackFn: (e) => ({
      loading: false,
      error: e,
      value: null,
    }),
  })
  const response = await axiosInstance.post(endpoint, body)
  return {
    loading: false,
    error: '',
    value: response?.data,
  }
}
