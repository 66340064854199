import { PieChart } from '@dataplace.ai/ui-components/atoms'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { generateColors, snakeToCamel } from '@dataplace.ai/functions/utils'
import { CategoryKeys, DistributionKeys } from '../@types/IBuildingsTileData'
import { useGetBuildingsTexts } from '../hooks/useGetBuildingsTexts'
import { categoriesColors } from '../utils/constants'

const PieChartSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
`

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LegendDesc = styled.p(({
  theme: {
    typography,
    palette,
  },
}) => css`
  color: ${palette.dark.normal};
  font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
  font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
  line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
  letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
  text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
`)

const SubTitle = styled.h2(({ theme: { palette } }) => css`
  color: ${palette.black};
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`)

interface LineProps {
  color: string
}

const Line = styled.span<LineProps>(({ color }) => css`
  min-width: 40px;
  height: 6px;
  background-color: ${color};
  margin-right: 0.5rem;
`)

const LegendElement = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const StyledStrong = styled.strong`
  &::first-letter {
    text-transform: lowercase
  }
`

interface PieChartSectionProps {
  buildingsTypes: number[]
  labels: CategoryKeys[]
  type: DistributionKeys
  mostCommonType: CategoryKeys
}

const PieChartSection: React.FC<PieChartSectionProps> = ({
  buildingsTypes,
  labels,
  type,
  mostCommonType,
}) => {
  const {
    categories,
    buildingsTexts,
  } = useGetBuildingsTexts()
  const translatedLabels = useMemo(() => labels.map(label => categories[label]), [labels, categories])

  const baseColor = categoriesColors[type] || defaultTheme.palette.grey
  const colors = useMemo(() => generateColors(buildingsTypes, baseColor), [buildingsTypes])
  const filteredBuildingsTypes = buildingsTypes.filter(value => value !== 0)

  const camelCaseCommonType = snakeToCamel(mostCommonType)

  return (
    <PieChartSectionWrapper>
      <LeftSide>
        <Description>
          <SubTitle>
            {buildingsTexts.buildnigsDataList.buildingsType[type]?.detailsChartText}
            <StyledStrong>
              {categories[camelCaseCommonType as CategoryKeys]}
            </StyledStrong>
          </SubTitle>
        </Description>
        <LegendWrapper>
          {translatedLabels.map((label, index) => (
            <LegendElement key={label}>
              <Line color={colors[index]} />
              <LegendDesc>{label}</LegendDesc>
            </LegendElement>
          ))}
        </LegendWrapper>
      </LeftSide>
      <PieChart
        customColors={colors}
        data={filteredBuildingsTypes}
        innerLabels
        labels={translatedLabels}
        valueType='percentages'
        width={300}
      />
    </PieChartSectionWrapper>
  )
}

export default PieChartSection
