import styled, { css } from 'styled-components'

export const Wrapper = styled.div(({
  theme: {
    palette,
    corners,
  },
}) => css`
  max-height: 95vh;
  min-width: 33rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${palette.light.white};
  border: 1px solid ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
  color: ${palette.black};

  @media print {
    max-height: none;
  }
`)

export const Header = styled.div(({ theme: { palette } }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${palette.light.darkest};
  padding: 1rem 1.5rem;
  gap: 1rem;
`)

export const HeaderTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`

export const HeaderText = styled.div(({ theme: { typography } }) => css`
  font-size: ${typography.big.pt_18_semibold.fontSize};
  font-weight: ${typography.big.pt_18_semibold.fontWeight};
  line-height: ${typography.big.pt_18_semibold.lineHeight};
`)

export const Content = styled.div`
  overflow-y: scroll;
`

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem 0.5rem;
`

export const ContentHeaderText = styled.div<{padding?: string}>(({
  theme: { typography }, padding,
}) => css`
  font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
  font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
  line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
  letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
  text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
  padding: ${padding || '0'};
`)

export const AddressList = styled.ul(({ theme: { palette } }) => css`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 2rem;
  list-style: none;

    li:nth-child(2n) {
      background-color: ${palette.light.white};
    }
`)

interface AddressTextProps {
  $isAnalysedAddress?: boolean
  $boldText?: boolean
  $shouldDisplay?: boolean
}

export const AddressListItem = styled.li<AddressTextProps>(({
  $isAnalysedAddress,
  $shouldDisplay = true,
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  background-color: ${palette.light.main};
  font-size: ${typography.small.pt_13_semibold.fontSize};
  line-height: ${typography.small.pt_13_semibold.lineHeight};
  ${$isAnalysedAddress && `color: ${palette.results.green.light};`};
  ${!$shouldDisplay && css`display: none;`};
`)

export const AddressText = styled.span(({ theme: { typography } }) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${typography.small.pt_13_semibold.fontWeight};
  width: 60%;
`)

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`
