import React, { ReactNode, useMemo, useState } from 'react'
import Popup from 'reactjs-popup'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import styled, { css } from 'styled-components'
import { CloseIcon } from '@dataplace.ai/ui-components/atoms'
import { downloadReportPDF } from '@dataplace.ai/functions/utils'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PdfInfoImage } from '@dataplace.ai/assets/lib/images/pdfInfoView.svg'
import { ReactComponent as ErrorImage } from '@dataplace.ai/assets/lib/icons/error.svg'
import { captureException } from '@sentry/react'

const TriggerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ModalWrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 2.5rem 1.5rem;
    width: 30rem;
  `
})

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const StyledErrorImage = styled(ErrorImage)`
  width: 10rem;
  height: 10rem;
`

const Text = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
  `
})

type DownloadStatus = 'success' | 'error' | undefined

interface DownloadPDFModalProps {
  trigger: JSX.Element | ReactNode
  reportId: string
  address: string
  comparedReportId?: string
}
export const DownloadPDFModal = ({
  trigger, reportId, address, comparedReportId,
}: DownloadPDFModalProps): JSX.Element => {
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>()
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  const onOpen = async () => {
    setIsOpen(true)
    try {
      await downloadReportPDF({
        reportId,
        address,
        comparedReportId,
      })
      setDownloadStatus('success')
    } catch (error) {
      captureException(error)
      setDownloadStatus('error')
    }
  }

  const onClose = () => {
    setIsOpen(false)
    setDownloadStatus(undefined)
  }

  const downloadStateInfo = useMemo(() => (downloadStatus === undefined
    ? (
      <>
        <Text>{t('placeme.pdf_generation.downloading')}</Text>
        <ImageWrapper>
          <object
            data='assets/loaders/placeme/small.svg'
            height='160'
            type='image/svg+xml'
            width='160'
          >
            svg-animation
          </object>
        </ImageWrapper>
      </>
    )
    : (
      <>
        <Text>{t('placeme.pdf_generation.download_complete')}</Text>
        <ImageWrapper>
          <PdfInfoImage />
        </ImageWrapper>
      </>
    )), [downloadStatus, t])

  return (
    <Popup
      closeOnDocumentClick={false}
      modal
      onOpen={onOpen}
      open={isOpen}
      overlayStyle={defaultTheme.overlay}
      trigger={<TriggerWrapper>{trigger}</TriggerWrapper>}
    >
      <ModalWrapper>
        <TopWrapper>
          <CloseIcon onClick={onClose} />
        </TopWrapper>
        {downloadStatus !== 'error'
          ? downloadStateInfo
          : (
            <>
              <Text>{t('placeme.pdf_generation.download_failed')}</Text>
              <ImageWrapper>
                <StyledErrorImage />
              </ImageWrapper>
            </>
          )}
      </ModalWrapper>
    </Popup>
  )
}

DownloadPDFModal.defaultProps = {
  comparedReportId: undefined,
}
