import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const Wrapper = styled.div(({ theme: { palette } }) => css`
  display: flex;
  padding: 0.5rem;
  background-color: ${palette.light.white};
`)

export const NoInvestments = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      {t('placeme.investments.no_planned_investments')}
    </Wrapper>
  )
}
