import { useEffect, useState } from 'react'
import { IRadiusTimeSelectorProps } from '../@types/IRadiusTimeSelector'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useManageTimeRanges = (
  initialRanges: IRadiusTimeSelectorProps['timeRanges'] | undefined,
  handleValueLabelClick: ((label: string, compared: boolean) => void) | undefined,
  compared: boolean,
) => {
  const [rangesWithActive, setRangesWithActive] = useState<IRadiusTimeSelectorProps['timeRanges']>([])

  // Initializing ranges with inactive values
  useEffect(() => {
    if (!initialRanges) return

    setRangesWithActive(initialRanges.map(range => ({
      ...range,
      active: false,
    })))
  }, [initialRanges])

  // Monitor the active scope and call handleValueLabelClick
  useEffect(() => {
    if (!handleValueLabelClick || !rangesWithActive) return

    const activeTimeRange = rangesWithActive.find(range => range?.active === true)
    if (!activeTimeRange) return

    handleValueLabelClick(activeTimeRange.label, compared)
  }, [rangesWithActive, handleValueLabelClick, compared])

  return [rangesWithActive, setRangesWithActive] as const
}
