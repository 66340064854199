import React from 'react'
import { DownloadAnalyseModal } from '@placeme/components/molecules/DownloadAnalyseModal/DownloadAnalyseModal'
import useFeatureFlags from '@dataplace.ai/functions/hooks/useFeatureFlags'
import DownloadExcelButton, { DownloadExcelButtonProps } from './DownloadExcelButton'
import DownloadPdfButton, { DownloadPdfButtonProps } from './DownloadPdfButton'
import DownloadAnalyse from './DownloadAnalyse'

type DownloadButtonTypes = DownloadExcelButtonProps & DownloadPdfButtonProps

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DownloadButtons({
  hasExcelAccess = false, isTrial, downloadDisabled, reportId, isExcelLoading, setIsExcelLoading, comparedReportId,
}: DownloadButtonTypes) {
  const { PDFv2 } = useFeatureFlags()

  return (
    <>
      {PDFv2
        ? (
          <DownloadAnalyse
            comparedReportId={comparedReportId}
            downloadDisabled={downloadDisabled}
            isTrial={isTrial}
            reportId={reportId}
          />
        )
        : (
          <DownloadAnalyseModal
            analyseId={reportId}
            trigger={(
              <DownloadPdfButton
                downloadDisabled={downloadDisabled}
                isTrial={isTrial}
              />
            )}
          />
        )}
      <DownloadExcelButton
        comparedReportId={comparedReportId}
        downloadDisabled={downloadDisabled}
        hasExcelAccess={hasExcelAccess}
        isExcelLoading={isExcelLoading}
        isTrial={isTrial}
        reportId={reportId}
        setIsExcelLoading={setIsExcelLoading}
      />
    </>
  )
}

export default DownloadButtons
