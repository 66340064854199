export type McdRadarLabels = {key: string, value: string}[];

export const radarChartLabels: McdRadarLabels = [
  {
    key: 'above60Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.above_60_buff_1000',
  },
  {
    key: 'above60Buff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.above_60_buff_250',
  },
  {
    key: 'adults1939Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.adults_1939_buff_1000',
  },
  {
    key: 'adults1939Buff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.adults_1939_buff_250',
  },
  {
    key: 'adults4059Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.adults_4059_buff_1000',
  },
  {
    key: 'adults4059Buff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.adults_4059_buff_250',
  },
  {
    key: 'buildingsBuff25000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.buildings_buff_25000',
  },
  {
    key: 'ctdAvgDailyBuff50BurgerKing',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_Burger_King',
  },
  {
    key: 'ctdAvgDailyBuff50Kfc',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_KFC',
  },
  {
    key: 'ctdAvgDailyBuff50MaxBurger',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_Max_Burger',
  },
  {
    key: 'ctdAvgDailyBuff50PizzaHut',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_Pizza_Hut',
  },
  {
    key: 'ctdAvgDailyBuff50Subway',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_avg_daily_buff50_Subway',
  },
  {
    key: 'ctdSumMonthlyBuff100',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_sum_monthly_buff100',
  },
  {
    key: 'ctdSumMonthlyBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_sum_monthly_buff250',
  },
  {
    key: 'ctdSumMonthlyBuff50Kfc',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_sum_monthly_buff50_KFC',
  },
  {
    key: 'ctdSumMonthlyRoads',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.ctd_sum_monthly_roads',
  },
  {
    key: 'distanceBurgerKing',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_Burger_King',
  },
  {
    key: 'distanceKfc',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_KFC',
  },
  {
    key: 'distanceMaxBurger',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_Max_Burger',
  },
  {
    key: 'distancePizzaHut',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_Pizza_Hut',
  },
  {
    key: 'distanceSubway',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_Subway',
  },
  {
    key: 'distanceClosest1McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_closest1_mcd_type',
  },
  {
    key: 'distanceClosest2McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_closest2_mcd_type',
  },
  {
    key: 'distanceClosest3McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_closest3_mcd_type',
  },
  {
    key: 'distanceClosest4McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.distance_closest4_mcd_type',
  },
  {
    key: 'driveThruPresence',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.drive_thru_presence',
  },
  {
    key: 'industryBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.industry_buff_1000',
  },
  {
    key: 'industryBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.industry_buff_250',
  },
  {
    key: 'industryBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.industry_buff_50',
  },
  {
    key: 'insidePlayland',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.inside_playland',
  },
  {
    key: 'mccafeCounter',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.mccafe_counter',
  },
  {
    key: 'medicalBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.medical_buff_1000',
  },
  {
    key: 'medicalBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.medical_buff_250',
  },
  {
    key: 'medicalBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.medical_buff_50',
  },
  {
    key: 'officesBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.offices_buff_1000',
  },
  {
    key: 'officesBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.offices_buff_250',
  },
  {
    key: 'officesBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.offices_buff_50',
  },
  {
    key: 'otherBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.other_buff_1000',
  },
  {
    key: 'otherBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.other_buff_250',
  },
  {
    key: 'otherBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.other_buff_50',
  },
  {
    key: 'outsidePlayland',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.outside_playland',
  },
  {
    key: 'popBuff25000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pop_buff_25000',
  },
  {
    key: 'ppQ1Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q1_buff_1000',
  },
  {
    key: 'ppQ2Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q2_buff_1000',
  },
  {
    key: 'ppQ3Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q3_buff_1000',
  },
  {
    key: 'ppQ4Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q4_buff_1000',
  },
  {
    key: 'ppQ5Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pp_q5_buff_1000',
  },
  {
    key: 'pylon',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.pylon',
  },
  {
    key: 'religionBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.religion_buff_1000',
  },
  {
    key: 'religionBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.religion_buff_250',
  },
  {
    key: 'religionBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.religion_buff_50',
  },
  {
    key: 'residentialBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.residential_buff_1000',
  },
  {
    key: 'residentialBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.residential_buff_250',
  },
  {
    key: 'residentialBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.residential_buff_50',
  },
  {
    key: 'scienceCultureArtBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.science_culture_art_buff_1000',
  },
  {
    key: 'scienceCultureArtBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.science_culture_art_buff_250',
  },
  {
    key: 'scienceCultureArtBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.science_culture_art_buff_50',
  },
  {
    key: 'servicesBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.services_buff_1000',
  },
  {
    key: 'servicesBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.services_buff_250',
  },
  {
    key: 'servicesBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.services_buff_50',
  },
  {
    key: 'sportBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.sport_buff_1000',
  },
  {
    key: 'sportBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.sport_buff_250',
  },
  {
    key: 'sportBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.sport_buff_50',
  },
  {
    key: 'storageBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.storage_buff_1000',
  },
  {
    key: 'storageBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.storage_buff_250',
  },
  {
    key: 'storageBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.storage_buff_50',
  },
  {
    key: 'tourismBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.tourism_buff_1000',
  },
  {
    key: 'tourismBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.tourism_buff_250',
  },
  {
    key: 'tourismBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.tourism_buff_50',
  },
  {
    key: 'trafficClosest1McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_closest1_mcd_type',
  },
  {
    key: 'trafficClosest2McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_closest2_mcd_type',
  },
  {
    key: 'trafficClosest3McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_closest3_mcd_type',
  },
  {
    key: 'trafficClosest4McdType',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.traffic_closest4_mcd_type',
  },
  {
    key: 'transportBuff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.transport_buff_1000',
  },
  {
    key: 'transportBuff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.transport_buff_250',
  },
  {
    key: 'transportBuff50',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.transport_buff_50',
  },
  {
    key: 'under18Buff1000',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.under_18_buff_1000',
  },
  {
    key: 'under18Buff250',
    value: 'placeme.mcd_potential_model_tile.radar_chart.label.under_18_buff_250',
  },
]
