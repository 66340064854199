import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { find } from 'lodash'
import { Bean } from '@dataplace.ai/ui-components/molecules'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { TableSpan } from '../../PotentialWithinChainResult/PotentialWithinChainResult.styles'
import { PotentialModel } from '../../@types/IPotentialWithinChain'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGenerateTableData = (potentialModel: PotentialModel, locationType?: ResourceWithId) => {
  const { t } = useTranslation()
  const data = locationType

  const flattenedData = useMemo(() => (
    locationType?.formsElements?.map(category => category.elements).flat(2)
  ), [locationType])

  const getTitle = (fieldKey: string) => {
    const foundObject = find(flattenedData, {
      fieldId: fieldKey,
    })
    return foundObject ? foundObject.title : ''
  }

  const generateTootltipText = (subCategoryKey: string, deviation: number) => {
    const upperLower = deviation > 0 ? t('generic.fem.upper') : t('generic.fem.lower')
    const translationKey1 = `placeme.potential_within_chain.${subCategoryKey}.bean_tooltip_1`

    return `${t(translationKey1)} ${deviation}% ${upperLower} ${t('placeme.potential_within_chain.bean_tooltip_2')}`
  }
  const behavioralElements = data?.formsElements?.find(metric => metric.category === 'behavioral')?.elements.map(metricId => metricId.fieldId)
  const formatCategoryValue = (subCategoryKey: string, subCategoryValue: number) => (behavioralElements?.includes(subCategoryKey) ? `${subCategoryValue}%` : subCategoryValue)

  return Object.entries(potentialModel.parameters).flatMap(([categoryKey, category]) =>
    Object.entries(category).map(([subCategoryKey, subCategory]) => {
      const title = getTitle(subCategoryKey)
      const tooltipText = generateTootltipText(subCategoryKey, subCategory.mean.deviation)

      return [(
        <React.Fragment key={`${categoryKey}-${subCategoryKey}`}>
          <TableSpan
            $bold
            $left
          >
            {title}
          </TableSpan>
          <TableSpan $bold>{subCategory.scoreShare}</TableSpan>
          <TableSpan $bold>{formatCategoryValue(subCategoryKey, subCategory.value)}</TableSpan>
          <TableSpan>
            <Bean
              tooltipMaxWidth='272px'
              tooltipText={tooltipText}
              value={subCategory.mean.deviation}
            />
          </TableSpan>
          <TableSpan>{formatCategoryValue(subCategoryKey, subCategory.mean.value)}</TableSpan>
          <TableSpan>
            {subCategory.rank}
            /
            {`${potentialModel.ranking.places.lowest}`}
          </TableSpan>
        </React.Fragment>
      )]
    }))
}
