/* eslint-disable react/require-default-props */
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const StyledContainer = styled.div<{compared: boolean}>(({
  theme: { palette }, compared,
}) => css`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  gap: 0.5rem;
  color: ${palette.black};

  ${compared && css`border-top: 1px solid ${palette.light.darker};`}
`)

const StyledParagraph = styled.p(({ theme: { typography } }) => css`
  font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
  font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
  line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
  letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
  text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
`)

const StyledAddress = styled.h3(({ theme: { typography } }) => css`
  font-size: ${typography.big.pt_18_semibold.fontSize};
  font-weight: ${typography.big.pt_18_semibold.fontWeight};
  line-height: ${typography.big.pt_18_semibold.lineHeight};
`)

interface NewComparedLocationHeaderProps {
  address?: string
  compared?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function NewComparedLocationHeader({
  address, compared = false,
}: NewComparedLocationHeaderProps) {
  const { t } = useTranslation()

  return (
    <StyledContainer compared>
      <StyledParagraph>
        {compared
          ? t('placeme.municipality_population.compared_location.header_2')
          : t('placeme.municipality_population.compared_location.header_1')}
      </StyledParagraph>
      <StyledAddress>{address}</StyledAddress>
    </StyledContainer>
  )
}

export default NewComparedLocationHeader
