import { useTranslation } from 'react-i18next'
import { categoriesColors } from '../utils/constants'

interface BuildingType {
  color: string
  label: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useBuildingTypeMappings = () => {
  const { t } = useTranslation()

  const buildingTypeMappings: Record<string, BuildingType> = {
    residental: {
      color: categoriesColors.residental,
      label: t('placeme.buildings.types.residential'),
    },
    commercialAndServices: {
      color: categoriesColors.commercialAndServices,
      label: t('placeme.buildings.types.commercial_buildings'),
    },
    public: {
      color: categoriesColors.public,
      label: t('placeme.buildings.types.public_buildings'),
    },
    other: {
      color: categoriesColors.other,
      label: t('placeme.buildings.types.others'),
    },
  }

  return {
    buildingTypeMappings,
  }
}

export default useBuildingTypeMappings
