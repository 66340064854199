import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { Checkbox } from '@dataplace.ai/ui-components/atoms'
import { ICheckboxButtonData } from './@types/ICheckboxButtonData'

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`

const ButtonText = styled.p(({
  theme: {
    palette,
    typography,
  },
}) => css`
  color: ${palette.black};
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};
`)

const ColorIndicator = styled.div<{ $color: string }>(({ $color }) => css`
  width: 1rem;
  height: 1rem;
  background-color: ${$color};
  border-radius: 3px;
`)

function CheckboxButton({
  onClick,
  checked,
  buttonText,
  indicatorColor,
}: ICheckboxButtonData): ReactElement {
  return (
    <ButtonWrapper onClick={onClick}>
      <Checkbox
        checked={checked}
        readOnly
      />
      <ButtonText>{buttonText}</ButtonText>
      {indicatorColor ? <ColorIndicator $color={indicatorColor} /> : null}
    </ButtonWrapper>
  )
}

export default CheckboxButton
