import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { ITileData } from '../../../../slice/@types/ITileData'
import { Settings } from './Settings'
import { useGetDropdownOptions } from './McdPotentialModel/McdPotentialModelData/hooks/formData/useGetDropdownOptions'

const tableTemplate = '3fr 1fr 1fr 1fr 2fr 2fr'

interface ICompetitionSettings {
  locationType: string,
}
interface ICompetitionTraffic {
  locations: {
    address: string,
    brand: string,
    dailyTraffic: number,
    distance: number,
  }[]
  averageDailyTraffic: number,
  averageDailyStoreTypeTraffic: number,
}
interface ICompetitionTileData extends ITileData {
  value: {
    competitionTraffic: ICompetitionTraffic
    settings: ICompetitionSettings
  }
}

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const Section = styled.div(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
    margin-bottom: 30px;

    >ul {
      width:100%;
      list-style-type: none;
      
      >li{
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
        padding: 10px 0 10px 10px;
        width:100%;
        border-radius: 6px;
      }

      >li:nth-child(2n){
          background-color: ${palette.light.main};
        }
    }

    > h4 {
      font-size: ${typography.main.pt_15_medium_upper.fontSize};
      font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
      line-height: ${typography.main.pt_15_medium_upper.lineHeight};
      letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
      text-transform: ${typography.main.pt_15_medium_upper.textTransform};
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > h5 {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }

    > p {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      margin-bottom: 15px;

      > span {
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};
        font-family: 'Inter',sans-serif;
        display: inline;
        margin-left: 0.25rem;
      }
    }

    .p15{
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-bottom: 15px;
    }

    > span {
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      margin-bottom: 10px;

      > strong {
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
        display: block;
        margin-top: 10px;
      }
    }
  `
})

export const CompetitionTile: React.FC<{
  data: ICompetitionTileData}> = ({ data }) => {
  const { t } = useTranslation()
  const { competitionLocationTypeOptions } = useGetDropdownOptions()
  const settingList = useMemo(() => (
    data
      ? [{
        label: t('placeme.mcd_potential_model_tile.criteria.location_type'),
        value: competitionLocationTypeOptions.find(option => option.value === data.value.settings.locationType)?.label ?? '',
        visible: true,
      }]
      : []
  ), [data])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <Settings selectedSettings={settingList} />
        <Section>
          <h4>{t('placeme.competition_and_surrounding.section_title_1')}</h4>
          <p>
            {t('placeme.competition_and_surrounding.competition.line_1')}
            {': '}
            <span>{data?.value?.competitionTraffic?.locations?.length}</span>
          </p>
          <p>
            {t('placeme.competition_and_surrounding.averageDailyTraffic.line_1')}
            {': '}
            <span>{formatNumber(data?.value?.competitionTraffic?.averageDailyTraffic)}</span>
          </p>

          <p>
            {t('placeme.competition_and_surrounding.averageDailyStoreTypeTraffic.line_1')}
            {': '}
            <span>{formatNumber(data?.value?.competitionTraffic?.averageDailyStoreTypeTraffic)}</span>
          </p>
        </Section>
        <Table
          content={data.value.competitionTraffic.locations.map(comp => [
            <span key={`${comp.address}_addr`}>{comp.address}</span>,
            <span key={`${comp.address}_brand`}>{comp.brand}</span>,
            <span key={`${comp.address}_dist`}>{comp.distance}</span>,
            <span key={`${comp.address}_traff`}>{formatNumber(comp.dailyTraffic)}</span>,
            <span key={`${comp.address}_location_diff`}>
              {(((comp.dailyTraffic - data.value.competitionTraffic.averageDailyTraffic) * 100)
              / data.value.competitionTraffic.averageDailyTraffic).toFixed(2)}
              %
            </span>,
            <span key={`${comp.address}_store_type_diff`}>
              {(((comp.dailyTraffic - data.value.competitionTraffic.averageDailyStoreTypeTraffic) * 100)
              / data.value.competitionTraffic.averageDailyStoreTypeTraffic).toFixed(2)}
              %
            </span>,
          ])}
          headerTemplate={tableTemplate}
          labels={[
            t('placeme.mcd.competition_traffic.table.headers.address'),
            t('placeme.mcd.competition_traffic.table.headers.brand'),
            t('placeme.mcd.competition_traffic.table.headers.distance'),
            t('placeme.mcd.competition_traffic.table.headers.traffic'),
            t('placeme.mcd.competition_traffic.table.headers.location_difference'),
            t('placeme.mcd.competition_traffic.table.headers.store_type_difference'),
          ]}
          rowTemplate={tableTemplate}
        />
      </Wrapper>
    )
}
