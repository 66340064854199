import { useContext } from 'react'

import { FeatureFlagsContext, IFeatureFlagsContext } from '@dataplace.ai/features/contexts/FeatureFlagsContext'

function useFeatureFlagsContext(): IFeatureFlagsContext {
  const context = useContext(FeatureFlagsContext)

  if (!context) throw new Error('useFeatureFlagsContext must be used within a FeatureFlagsContextProvider')

  return context
}

export default useFeatureFlagsContext
