/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import Popup from 'reactjs-popup'
import { CloseIcon, Button, TextButton, LinkWithIconEdit, LinkWithIconCheckOnMap, Checkbox, Tooltip, CoinsButton } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features/components/AuthContext'
import { getAxios, getWorkspaceIdFromLocalStorage } from '@dataplace.ai/functions/utils'
import Geocoder from 'apps/placeme/src/services/Geocoder/Geocoder'
import { ILocation, IRange } from '@dataplace.ai/types'
import { GeosuggestForm, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { checkCountry } from 'apps/placeme/src/functions/checkCountry'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { ReactComponent as Wand } from '../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/wand.svg'
import { ReactComponent as HelpCircle } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { IAddAnotherLocationModalProps } from './@types/IAddAnotherLocationModalProps'
import { ReactComponent as Credits } from '../../../../../../../../libs/shared/assets/src/lib/icons/placeme/credits.svg'
import { ITile } from '../../../slice/@types/ITile'
import { compareLocationCatchmentAndDataAction, saveComparedLocation, saveComparisonState } from '../../../slice/analysisSlice'
import { creditsNumber, getBodyPropertiesForDoublePropertiesTile } from './functions'
import { ICombinedSectionTile } from '../../../slice/@types/ISectionTile'
import { DataNotAvailableModal, IDataNotAvailableModalState } from '../DataNotAvailableModal'
import { tilesWithoutComparedLocation } from './constants'

const { v4: uuidv4 } = require('uuid')

export interface IDownloadAnalyseModalProps {
  trigger: JSX.Element
  analyseId: string
}

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem 2.5rem 1.5rem;
    width: 700px;

    form {
      margin: 0;
    }

    >span{
        margin: 10px 0 2px;
        color: ${palette.dark.normal};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }

    .upper{
        margin: 2rem 0 1rem;
        color: ${palette.black};
        font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }
  `
})

const LocationsButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
`
const LocationButton = styled.button(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px 5px 7px;
    background: #FFFFFF;
    border: 1px solid #E1E2E3;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0 9px 10px 0;
    cursor: pointer;

    :hover{
        box-shadow:inset 0px 0px 0px 1px #E1E2E3;
    }

    >p{
        color: ${palette.dark.normal};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }
    :last-of-type{
        border: 1px solid #234596;
        :hover{
            box-shadow:inset 0px 0px 0px 1px #234596;
         }
        >p{
            color: ${palette.blue};
        }
    }
`
})

const TopWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1.25rem;

    h3 {
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }
  `
})

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`

const HeaderAndChangeWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;

    >h5{
        color: ${palette.dark.normal};
        font-size: ${typography.main.pt_15_medium_upper.fontSize};
        font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
        line-height: ${typography.main.pt_15_medium_upper.lineHeight};
        letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
        text-transform: ${typography.main.pt_15_medium_upper.textTransform};
        margin-right: 18px;
    }
    `
})

const AddressWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      display: flex;
      align-items: center;
  
      >p{
          color: ${palette.dark.normal};
          font-size: ${typography.big.pt_18_semibold.fontSize};
          font-weight: ${typography.big.pt_18_semibold.fontWeight};
          line-height: ${typography.big.pt_18_semibold.lineHeight};
          margin-right: 18px;
      }
      `
})

const CheckboxWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
        display: flex;
        flex-direction: column;
        margin-bottom: 13px;
        width: fit-content;
      >div  {
            display: flex;
            align-items: center;
           
            >p{
                margin-left: 10px;
                color: ${palette.black};
                font-size: ${typography.main.pt_15_regular.fontSize};
                font-weight: ${typography.main.pt_15_regular.fontWeight};
                line-height: ${typography.main.pt_15_regular.lineHeight};
            }
        }
        >span{
            color: ${palette.dark.normal};
            margin-left: 2rem;
            font-size: ${typography.tiny.pt_12_regular.fontSize};
            font-weight: ${typography.tiny.pt_12_regular.fontWeight};
            line-height: ${typography.tiny.pt_12_regular.lineHeight};
        }

        .coins-container {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 3px 7px;
        border-radius: 1rem;
        background: #C6EAE1;
        color: ${palette.blue};
        min-width: 45px;

        svg {
          margin-right: 7px;
          path {
            fill: ${palette.blue};
          }
        }
      }
    ` })

const BottomWrapper = styled.div`
        width: 100%;
        margin: 2rem 0 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `

const BottomLeftWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
        width: 40%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        >p{
            color: ${palette.dark.normal};
            font-size: ${typography.tiny.pt_12_regular.fontSize};
            font-weight: ${typography.tiny.pt_12_regular.fontWeight};
            line-height: ${typography.tiny.pt_12_regular.lineHeight};
        }
            
        `
})

const BottomRightWrapper = styled.div`
        display: flex;
        align-items: center;

        >button{
            :first-of-type{
                margin-right: 20px;
            }
        }
    `

const StyledHelpCircle = styled(HelpCircle)(({ theme }) => {
  const { palette } = theme
  return css`
      cursor: pointer;
      min-width: 1rem;
      width: 1rem;
      margin-bottom: -0.2rem;
      position: relative;
      left: -30px;
  
      > path {
        fill: ${palette.blue};
      }`
})

const AnimatedCoins = styled.div(({ theme }) => {
  const { palette } = theme
  return css`

    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 7px;
    border-radius: 1rem;
    background: #C6EAE1;
    color: ${palette.blue};
    min-width: 45px;

    svg {
      margin-right: 7px;
      path {
        fill: ${palette.blue};
      }
    }

    >p{
      margin-right: 4px;
    }

  `
})

const GeosuggestWrapper = styled.div`
  width: 65%;
  margin-top: 8px;
`
const Error = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
  margin-top: 8px;
  color: ${palette.results.red.light};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
`
})

const CustomCatchmentInfo = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.results.red.light};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

export const AddAnotherLocationModal = ({
  trigger, setCompareLocationStatusLabel,
}
: IAddAnotherLocationModalProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [open, setOpen] = useState(false)
  const [isMapOpen, setIsMapOpen] = useState(false)
  const [currentlyLocalizations, setCurrentlyLocalizations] = useState<ILocation[]>([])
  const [localizationsLoading, setLocalizationsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [chosenAddress, setChosenAddress] = useState<ILocation>()
  const [chooseAnotherAddress, setChooseAnotherAddress] = useState(false)
  const [alreadyGenerated, setAlreadyGenerated] = useState(false)
  const [generatedFromNow, setGeneratedFromNow] = useState(true)
  const [tilesWithDataAndNonDrawingRange, setTilesWithDataAndNonDrawingRange] = useState<ITile[]>([])
  const [tilesWithDrawingRange, setTilesWithDrawingRange] = useState<ITile[]>([])
  const [modalState, setModalState] = useState<IDataNotAvailableModalState | null>(null)
  const [error, setError] = useState('')

  const {
    values, tiles,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, analyseId, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)

  // we need ref to get current state in event listener
  const authContext = useContext(AuthContext)
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }

  // functions
  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const fetchTilesWithDataAndNonDrawingRage = () => {
    const tiles: ITile[] = []
    values?.forEach(section => section?.tiles.filter(tile => (tile?.data?.value && tile?.chosenRange?.type !== 'custom' && !tilesWithoutComparedLocation.includes(tile?.id?.split('-')[0]))).forEach(tile => tiles.push(tile)))
    setTilesWithDataAndNonDrawingRange(tiles)
    setAlreadyGenerated(!!tiles?.length)
  }

  const fetchTilesWithDrawingRange = () => {
    const tiles: ITile[] = []
    values?.forEach(section => section?.tiles.filter(tile => (tile?.data?.value && tile?.chosenRange?.type === 'custom')).forEach(tile => tiles.push(tile)))
    setTilesWithDrawingRange(tiles)
  }

  const handleAddChosenAddress = useCallback(async (geo: ILocation) => {
    let isInCountry = true
    try {
      if (value?.country) {
        isInCountry = await checkCountry({
          placeId: geo?.placeId,
          address: geo?.address,
          lat: geo?.lat,
          lng: geo?.lng,
        }, [value?.country], false)
      }
    }
    finally {
      if (geo?.lat?.toString() !== value?.lat?.toString()
      && geo?.lng?.toString() !== value?.lng?.toString()
      && geo?.address?.toLowerCase() !== value?.address?.toLowerCase()
      && isInCountry) {
        setError('')
        setChosenAddress(geo)
      } else if (isInCountry) {
        setError('placeme.the_same_address_as_analysed')
      } else {
        setError('placeme.compared_location.compared_address_not_in_country')
      }
    }
  }, [value])

  const fetchCurrentlyUsedAddresses = useCallback(async () => {
    let saveData
    const axiosInstance = await getAxios({
      errCallbackFn: (e) => {
        saveData = {
          loading: false,
          error: e,
          value: null,
        }
      },
    })
    const response = await axiosInstance.get(
      `/user/${userId}/catchments?workspace_id=${getWorkspaceIdFromLocalStorage()}&filter=coordinates`,
    )
    if (!response) return
    saveData = {
      loading: false,
      error: '',
      value: response.data,
    }
    if (!saveData?.value?.length) return
    const localizations : ILocation[] = []
    saveData?.value?.forEach((loc: { lat: number; lng: number }) => {
      if (typeof loc?.lat === 'number' && typeof loc?.lng === 'number') {
        const geocoder = new Geocoder()
        let address = ''
        geocoder.reverseGeocode(loc?.lat, loc?.lng).then(async (result) => {
          // eslint-disable-next-line camelcase
          const res = result as { formatted_address: string }[]
          if (result && res.length > 0) {
            address = res[0].formatted_address
            let isInCountry = true
            try {
              if (value?.country) {
                isInCountry = await checkCountry({
                  placeId: address + loc?.lat + loc?.lng,
                  address,
                  lat: loc?.lat,
                  lng: loc?.lng,
                }, [value?.country], false)
              }
            }
            finally {
              if (isInCountry) {
                localizations.push({
                  placeId: address + loc?.lat + loc?.lng,
                  address,
                  lat: loc?.lat,
                  lng: loc?.lng,
                })
              }
            }
          }
        })
      }
    })
    setCurrentlyLocalizations(localizations)
    setLocalizationsLoading(false)
  }, [token, userId, getWorkspaceIdFromLocalStorage(), value])

  const handleChosenAddress = (location: ILocation) => {
    setChosenAddress(location)
  }

  const getWhiteTiles = () : string[] => {
    const whiteTiles: string[] = []
    tiles?.forEach(cat => cat?.tiles?.forEach(tile => (tile?.plan?.id === 'white'
      ? whiteTiles.push(tile?.id)
      : null)))
    return whiteTiles
  }

  const handleSave = async () => {
    if (analyseId && token && chosenAddress) {
      if (chosenAddress?.address) {
        // logic for checking country
        const countryCodes : string[] = []
        const availableCountryCodes : string[] = []
        const placemeTiles = JSON.parse(window?.localStorage?.getItem('onlyTiles') || '') as ICombinedSectionTile[]
        if (placemeTiles) {
          placemeTiles.forEach(category => category.tiles.forEach(tile => {
            tile.countries.forEach(country =>
              (!countryCodes.includes(country) ? countryCodes.push(country) : null))
            tile.availableCountries.forEach(country =>
              (!availableCountryCodes.includes(country) ? availableCountryCodes.push(country) : null))
          }))
        }
        const plCountryCheckFlag = await checkCountry(chosenAddress, countryCodes, false)
        if (plCountryCheckFlag) {
          // [start] main functions - what is going to happen when country is correct
          dispatch(saveComparedLocation({
            location: chosenAddress,
            alreadyGenerated,
            generatedFromNow,
          }))
          let comparedAnalyseId: unknown
          try {
            // post project - with compared location - create compared analyse
            if (alreadyGenerated && tilesWithDataAndNonDrawingRange?.length) {
              comparedAnalyseId = await dispatch(createNewAnalyse(analyseId, chosenAddress))
            }
            // fetch catchment and data for each already generated tiles
          }
          finally {
            try {
              if (alreadyGenerated && tilesWithDataAndNonDrawingRange?.length && comparedAnalyseId) {
                await tilesWithDataAndNonDrawingRange?.forEach(tile => {
                  dispatch(compareLocationCatchmentAndDataAction(
                    token,
                    tile?.chosenRange?.catchmentId || '',
                    tile?.chosenRange as IRange,
                    tile.section,
                    tile.id,
                    currentSubscriptionData?.value?.subscriptionId || '',
                    chosenAddress,
                    getBodyPropertiesForDoublePropertiesTile(tile.id, tilesWithDataAndNonDrawingRange),
                  ))
                })
              }
            // eslint-disable-next-line no-empty
            } finally {

            }
          }
          window.localStorage.setItem('comparedLocation', JSON.stringify({
            alreadyGenerated,
            generatedFromNow,
            location: chosenAddress,
          }))

          setOpen(false)
          // [end] main functions
        } else {
          // when country is not available - modal settings
          const countryIsAvailable = await checkCountry(chosenAddress, availableCountryCodes, false)
          let newModalState
          if (countryIsAvailable) {
            newModalState = {
              heading: t('placeme.data_unavailable_in_current_plan.heading'),
              text: t('placeme.data_unavailable_in_current_plan.text'),
            }
          } else {
            newModalState = {
              heading: t('placeme.no_data_available.title'),
              text: t('placeme.no_data_available.description'),
            }
          }
          setModalState(newModalState)
          setCompareLocationStatusLabel(false)
          dispatch(saveComparisonState([]))
        }
      }
    }
  }

  const saveClick = () => {
    if (analyseId && token && chosenAddress) {
      if (alreadyGenerated && tilesWithDataAndNonDrawingRange?.length) {
        setCompareLocationStatusLabel(true)
        dispatch(saveComparisonState(tilesWithDataAndNonDrawingRange?.map(item => ({
          loading: true,
          error:'',
          tile: item.id,
        }))))
      }
      handleSave()
    }
  }

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (authContext.userData?.user?.uid) setUserId(authContext.userData?.user?.uid)
  }, [authContext])

  useEffect(() => {
    if (token && userId && getWorkspaceIdFromLocalStorage()) setLocalizationsLoading(true)
  }, [token, userId, getWorkspaceIdFromLocalStorage()])

  useEffect(() => {
    if (localizationsLoading) {
      fetchCurrentlyUsedAddresses()
    }
  }, [localizationsLoading])

  useEffect(() => {
    if (values?.length && open) {
      fetchTilesWithDataAndNonDrawingRage()
      fetchTilesWithDrawingRange()
    }
  }, [values, open])

  useEffect(() => {
    if (open && currentlyLocalizations) {
      if (currentlyLocalizations?.find(item =>
        item?.address?.toLowerCase() === value?.address?.toLowerCase()
        || (Number(item?.lng?.toFixed(11)) === Number(Number(value?.lng)?.toFixed(11)
         && Number(item?.lat?.toFixed(11)) === Number(Number(value?.lat)?.toFixed(11)))))) {
        setCurrentlyLocalizations(currentlyLocalizations?.filter(item =>
          item?.address?.toLowerCase() !== value?.address?.toLowerCase()
          && !(Number(item?.lng?.toFixed(11)) === Number(Number(value?.lng)?.toFixed(11))
           && Number(item?.lat?.toFixed(11)) === Number(Number(value?.lat)?.toFixed(11)))))
      }
    }
  }, [open, currentlyLocalizations])

  return (
    <>
      {isMapOpen && (
        <MapVisualizationModal
          comparedLocation={chosenAddress}
          isDisplayed={isMapOpen}
          location={value}
          mapId={`only-location-map-${uuidv4()}`}
          setIsDisplay={setIsMapOpen}
        />
      )}
      <DataNotAvailableModal
        setModalState={setModalState}
        state={modalState}
      />
      <Popup
        closeOnDocumentClick={false}
        modal
        onClose={() => {
          setOpen(false)
        }}
        onOpen={openModal}
        open={open}
        overlayStyle={defaultTheme.overlay}
        trigger={trigger}
      >
        <Wrapper>
          <TopWrapper>
            <h3>{t('placeme.compared_location.header')}</h3>
            <StyledCloseIcon onClick={closeModal} />
          </TopWrapper>
          <HeaderAndChangeWrapper>
            <h5>{t('placeme.compared_location.sec_header')}</h5>
            {chosenAddress && (
              <LinkWithIconEdit
                label='generic.change'
                onClick={() => setChosenAddress(undefined)}
              />
            )}
            {(!chosenAddress && chooseAnotherAddress) && (
              <LinkWithIconEdit
                label='placeme.compared_location.change_for_last'
                onClick={() => {
                  setChosenAddress(undefined)
                  setChooseAnotherAddress(false)
                }}
              />
            )}
          </HeaderAndChangeWrapper>
          {/* step 1 */}
          {(!chooseAnotherAddress && !chosenAddress) && (
            <>
              {currentlyLocalizations?.length !== 0 ? <span>{t('placeme.compared_location.last_analysed')}</span> : <span>{t('placeme.compared_location.no_currently_used')}</span>}
              <LocationsButtonsWrapper>
                { currentlyLocalizations?.map(location => (
                  <LocationButton
                    key={location?.placeId}
                    onClick={() => handleChosenAddress(location)}
                  >
                    <p>{location?.address}</p>
                  </LocationButton>
                ))}
                <LocationButton
                  onClick={() =>
                    setChooseAnotherAddress(true)}
                >
                  <Wand />
                  <p>{currentlyLocalizations?.length !== 0 ? t('placeme.compared_location.choose_another_location') : t('placeme.compared_location.choose_location')}</p>
                </LocationButton>
              </LocationsButtonsWrapper>
            </>
          )}
          {/* step 2 */}
          {(chosenAddress || chooseAnotherAddress) && (
            <>
              <AddressWrapper>
                {(chooseAnotherAddress && !chosenAddress)
               && (
                 <GeosuggestWrapper>
                   <GeosuggestForm
                     buttonText='+'
                     handleSubmit={handleAddChosenAddress}
                     instantAdd
                   />
                   {error && <Error>{t(error)}</Error>}
                 </GeosuggestWrapper>
               )}
                <p>{chosenAddress?.address}</p>
                {chosenAddress && (
                  <LinkWithIconCheckOnMap
                    onClick={() => setIsMapOpen(!isMapOpen)}
                    style={(chooseAnotherAddress && !chosenAddress)
                      ? {
                        position: 'relative',
                        right: '36px',
                      }
                      : {}}
                  />
                )}
              </AddressWrapper>
              <span className='upper'>{t('placeme.compared_location.compared_data_which')}</span>
              { !!tilesWithDataAndNonDrawingRange?.length && (
                <CheckboxWrapper onClick={() => setAlreadyGenerated(!alreadyGenerated)}>
                  <div>
                    <Checkbox
                      checked={alreadyGenerated}
                    />
                    <p>{t('placeme.compared_location.data_already_generated')}</p>

                    {!(currentSubscriptionData?.value?.planExact?.includes('unlimited')
                    || currentSubscriptionData?.value?.plan === 'white')
                    && (
                      <AnimatedCoins>
                        <Credits />
                        <p>{creditsNumber(tilesWithDataAndNonDrawingRange, getWhiteTiles())}</p>
                      </AnimatedCoins>
                    )}

                  </div>
                </CheckboxWrapper>
              )}
              <CheckboxWrapper onClick={() => setGeneratedFromNow(!generatedFromNow)}>
                <div>
                  <Checkbox
                    checked={generatedFromNow}
                  />
                  <p>{t('placeme.compared_location.data_generated_from_now')}</p>
                </div>
                <span>{t('placeme.compared_location.data_generated_from_now.desc')}</span>
              </CheckboxWrapper>
              {!!tilesWithDrawingRange?.length
                && <CustomCatchmentInfo>{t('placeme.compared_location.custom_catchment_info')}</CustomCatchmentInfo>}
              <BottomWrapper>
                <BottomLeftWrapper>
                  <p>{t('placeme.compared_location.info_bottom')}</p>
                  <Tooltip
                    content={t('placeme.compared_location.info_bottom.tooltip.content')}
                    header={t('placeme.compared_location.info_bottom.tooltip.header')}
                    maxWidth='450px'
                    position='bottom center'
                  >
                    <StyledHelpCircle />
                  </Tooltip>
                </BottomLeftWrapper>
                <BottomRightWrapper>
                  <TextButton onClick={closeModal}><p>{t('generic.cancel')}</p></TextButton>
                  {tilesWithDataAndNonDrawingRange?.length && alreadyGenerated
                    && !(currentSubscriptionData?.value?.planExact?.includes('unlimited')
                    || currentSubscriptionData?.value?.plan === 'white')
                    ? (
                      <CoinsButton
                        disabled={!(generatedFromNow || alreadyGenerated)}
                        margin='0 0 0 1.82rem'
                        onClick={saveClick}
                        text={t('placeme.compared_location.add_to_compare')}
                        value={creditsNumber(tilesWithDataAndNonDrawingRange, getWhiteTiles())}
                      />
                    )
                    : (
                      <Button
                        disabled={!(generatedFromNow || alreadyGenerated)}
                        onClick={saveClick}
                      >
                        {t('placeme.compared_location.add_to_compare')}
                      </Button>
                    )}
                </BottomRightWrapper>
              </BottomWrapper>
            </>
          )}
        </Wrapper>

      </Popup>
    </>
  ) }

