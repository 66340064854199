export const USER_TYPES = {
  admin: 'admin',
  user: 'user',
  owner: 'owner',
} as const

export const LOCATION_STATUSES = {
  accepted: 'accepted',
  inConstruction: 'in_construction',
  opened: 'opened',
  potential: 'potential',
  rejected: 'rejected',
} as const

export const featureAccessIds = {
  kanban: 'kanban',
  excelReport: 'excelReport',
} as const

export type FeatureAccessId = (typeof featureAccessIds)[keyof typeof featureAccessIds]
