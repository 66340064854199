import { IRemoteConfigTile } from 'apps/placeme/src/features/Analyse/slice/@types/IRemoteConfigTiles'
import { RootState } from 'apps/placeme/src/redux/store'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function useHasWhitespotAccess(): {hasWhitespotAccess?: IRemoteConfigTile} {
  const { categoriesRC } = useSelector((state: RootState) => state.analysis)
  const hasWhitespotAccess = useMemo(() => categoriesRC?.find(cat => cat.id === 'whitespot')?.tiles.find(tile => tile.id === 'whitespot'), [categoriesRC])

  return {
    hasWhitespotAccess,
  }
}
