import React from 'react'
import styled, { css } from 'styled-components'

const BadgeSpan = styled.span(({
  theme: {
    typography, palette,
  },
}) => css`
  background-color: ${palette.product.marketing.main};
  color: ${palette.light.white} !important;
  padding: 2px 6px;
  text-align: center;
  border-radius: 5px;
  font-weight: ${typography.small.pt_13_medium.fontWeight};
  font-size: ${typography.small.pt_13_medium.fontSize};
  line-height: ${typography.small.pt_13_medium.lineHeight};
`)

export const Badge = ({ label }: {label: string}): JSX.Element => (
  <BadgeSpan>{label}</BadgeSpan>
)
