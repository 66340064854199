import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Popup from 'reactjs-popup'
import { ButtonProduct, CloseIcon } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { AuthContext } from '@dataplace.ai/features'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { createFlashMessage, getAxios } from '@dataplace.ai/functions/utils'

const Wrapper = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme

  return css`
    width: 100%;
    margin: 1.5rem 0;
    >p{
        color: ${palette.dark.normal};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};

        >button{
        border: none;
        background: none;
        cursor: pointer;
        color: ${palette.dark.main};
        font-size: ${typography.tiny.pt_12_medium.fontSize};
        font-weight: ${typography.tiny.pt_12_medium.fontWeight};
        line-height: ${typography.tiny.pt_12_medium.lineHeight};
      }
    }
  
`
})

const PopupWrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
    min-width: 500px;
  `
})

const Header = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    > span {
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }

    > button {
      cursor: pointer;
      border: none;
      outline: none;
      background: none;
    }
  `
})

const Content = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
  display: flex;
  flex-direction: column;

  > span:first-child {
    margin-bottom: 0.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
  }

    > textarea {
      min-height: 10rem;
      outline: none;
      font-family: ${typography.primary.fontFamily};
      border: 1px solid ${palette.dark.lightest};
      border-radius: ${corners.default.borderRadius};
      padding: 1rem;
    }

    > span:last-child {
      margin-left: auto;
      margin-top: 0.25rem;
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium.fontSize};
      font-weight: ${typography.tiny.pt_12_medium.fontWeight};
      line-height: ${typography.tiny.pt_12_medium.lineHeight};
    }
`
})

const Footer = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
  display: flex;
  flex-direction: column;

  > span:first-child {
    margin-bottom: 0.5rem;
    width: 55%;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  }

    > div {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;

      > button {
        margin-left: 0.5rem;
      }

      > button:first-child {
        margin-left: 0;
        background-color: transparent;
        color: ${palette.blue}
      }
    }
`
})

const ColoredNumber = styled.span<{color: string}>(({
  theme, color,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
      color: ${color === 'red' ? palette.results.red.light : palette.results.green.light};
      font-size: ${typography.tiny.pt_12_medium.fontSize};
      font-weight: ${typography.tiny.pt_12_medium.fontWeight};
      line-height: ${typography.tiny.pt_12_medium.lineHeight};
  `
})

interface IReportOutOfDatePoiSection{
  catchmentId: string
}

export const ReportOutOfDatePoiSection = ({ catchmentId }: IReportOutOfDatePoiSection): JSX.Element => {
  const { t } = useTranslation()
  // token
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  // state
  const [open, setOpen] = useState(false)
  const [text, setText] = useState<string>('')

  // functions
  const closeModal = () => setOpen(false)

  const handleSave = useCallback(async () => {
    if (catchmentId && token && text?.length) {
      const body = {
        catchmentId,
        note: text,
      }

      const axiosInstance = await getAxios()
      const response = await axiosInstance.post(ENDPOINTS.REPORT_SUGGESTION || '', body)
      if (response) {
        createFlashMessage({
          message: 'status.success.report_suggest',
        })
      }
      closeModal()
    }
  }, [token, catchmentId, text])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
  }

  // hooks
  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  return (
    <Wrapper>
      <p>
        {t('placeme.report_outdated.desc')}
        {' '}
        <Popup
          modal
          onClose={closeModal}
          onOpen={() => setOpen(true)}
          open={open}
          overlayStyle={defaultTheme.overlay}
          trigger={(
            <button type='button'>
              {t('placeme.report_outdated.trigger_button')}
              .
            </button>
          )}
        >

          <PopupWrapper>
            <Header>
              <span>
                {t('placeme.report_outdated.header')}
              </span>
              <button
                onClick={closeModal}
                type='button'
              >
                <CloseIcon />
              </button>
            </Header>
            <Content>
              <span>{t('placeme.note_popup.subtitle')}</span>
              <textarea
                maxLength={500}
                onChange={handleChange}
                value={text}
              />
              <span>
                <ColoredNumber color={text.length > 0 ? 'green' : 'red'}>{text.length}</ColoredNumber>
                /500
              </span>
            </Content>
            <Footer>
              <span>{t('placeme.report_outdated.additional_info')}</span>
              <div>
                <ButtonProduct
                  onClick={closeModal}
                  small
                >
                  {t('generic.cancel')}
                </ButtonProduct>
                <ButtonProduct
                  disabled={!(text.length > 0)}
                  onClick={handleSave}
                  small
                >
                  {t('generic.save')}
                </ButtonProduct>
              </div>
            </Footer>
          </PopupWrapper>
        </Popup>
      </p>
    </Wrapper>
  )
}
