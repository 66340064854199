import { AuthContext } from '@dataplace.ai/features'
import { firebaseDatabase } from '@dataplace.ai/services'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { Signal, useSignal } from '@preact/signals-react'
import { UserReportsSettings } from './hooks/useTileSettings'

export interface UserSettings {
  reports?: UserReportsSettings
}
export interface IUserSettingsContext {
  userSettings: Signal<UserSettings>
  updateUserSettings(newSettings: UserSettings): void
}

export const UserSettingsContext = createContext<IUserSettingsContext | null>(null)

export function UserSettingsProvider({ children }: {children: ReactNode}): JSX.Element {
  const authContext = useContext(AuthContext)
  const userSettings = useSignal<UserSettings>({})

  const settingsRef = useMemo(() => {
    const userId = authContext.userData.user?.uid
    if (!userId) return null

    return firebaseDatabase.ref(`users/${userId}/settings`)
  }, [authContext.userData.user])

  const updateUserSettings = useCallback(async (newSettings: UserSettings) => {
    if (!settingsRef) return
    const payload = {
      ...userSettings.value,
      ...newSettings,
    }
    await settingsRef.update(payload)
  }, [settingsRef, userSettings.value])

  useEffect(() => {
    if (!settingsRef) return
    settingsRef.on('value', (snapshot) => {
      const data = snapshot.val()
      if (data) {
        userSettings.value = data
      }
    })

    return () => settingsRef.off()
  }, [settingsRef])

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings,
        updateUserSettings,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}
