import { useEffect } from 'react'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { FeatureFlagsObject } from '@dataplace.ai/features/contexts/FeatureFlagsContext'
import { useSignal } from '@preact/signals-react'
import useFeatureFlagsContext from './useFeatureFlagsContext'
import { getAxios } from '../utils'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useFeatureFlags() {
  const {
    featureFlags, setFeatureFlags,
  } = useFeatureFlagsContext()
  const requesting = useSignal(false)

  const PDFv2 = featureFlags?.pdfV2

  useEffect(() => {
    if (featureFlags !== undefined || requesting.value) return
    const getFeatureFlags = async () => {
      requesting.value = true
      const axiosInstance = await getAxios()
      const { data } = await axiosInstance.get<FeatureFlagsObject>(ENDPOINTS.FEATURE_FLAGS)

      setFeatureFlags(data)
      requesting.value = false
    }
    getFeatureFlags()
  }, [featureFlags])

  return {
    PDFv2,
  }
}

export default useFeatureFlags
