/* eslint-disable no-await-in-loop */
/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { BackButton,
  // Switch,
  TextButton, Button, NoPrint } from '@dataplace.ai/ui-components/atoms'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { RootState } from 'apps/placeme/src/redux/store'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addMonths, addWeeks } from 'date-fns'
import { resetLocationState } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { AuthContext } from '@dataplace.ai/features/components/AuthContext'
import { getHigherPricingPlan } from '@dataplace.ai/functions/utils'
import { placemePlans, emails } from '@dataplace.ai/constants'
import { redirectToAccountPricelist } from '@dataplace.ai/functions/utils/navigate'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import useRedirectBasedOnProjectState from 'apps/placeme/src/customHooks/useRedirectBasedOnProjectState'
import { ReactComponent as SpinnerIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/spinner.svg'
import { checkCountry } from '../../../../../functions'
import {
  ExitFromAnalysePopup,
  SidebarList,
} from '../../molecules'
import {
  SearchBar,
  // UseTemplate,
} from '../../atoms'
import 'react-perfect-scrollbar/dist/css/styles.css'

import { resetAnalysisState, saveComparedLocation } from '../../../slice/analysisSlice'
import { ICombinedSectionTile } from '../../../slice/@types/ISectionTile'
import { ICombinedTile } from '../../../slice/@types/ITile'
import { exitFromAnalyseAction } from '../../../functions/exitFromAnalyse'

interface IWrapper {
  isOpen: boolean
}

const Wrapper = styled.div<IWrapper>(({
  theme, isOpen,
}) => {
  const { palette } = theme
  return css`
    display: flex;
    width: 320px;
    flex-direction: column;
    padding: 1.5rem 0 0 1rem;
    background-color: ${palette.light.main};
    border-right: 1px solid ${palette.light.darker};
    position: fixed;
    box-sizing: border-box;
    transition: left 0.3s;
    left: 0;
    height: 100%;
    z-index: 501;

    @media (max-width: 1200px){
      width: 250px;
      padding: 1.25rem 1rem 0;
    }

    ${!isOpen
    && css`
      align-items: end;
      left: -256px;
      padding-left: 0;
      padding-right: 0;
      @media (max-width: 1200px){
        padding-left: 0;
        padding-right: 0;
        left: -186px;
    }
    `}
  `
})

const StickyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    height: 100vh;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;  /* Chrome, Safari and Opera */
    }

    -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  `

interface IToggler {
  isOpen: boolean
}

const Toggler = styled.div<IToggler>(
  ({ isOpen }) => css`
    display: flex;
    position: sticky;
    width: 1.5rem;
    height: 1.5rem;
    right: 0;
    top: 60px;
    z-index: 1002;
    background-color: #dddddd;
    border-radius: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
      background-color: #999999;

      > img {
        filter: invert(100%);
      }
    }

    > img {
      width: 50%;
      transition: transform 0.3s;
      transform: rotate(-90deg);
    }

    ${isOpen
    && css`
      > img {
        transform: rotate(90deg);
      }
    `}
  `,
)

const TogglerRail = styled.span(
  () => css`
    position: absolute;
    width: 0;
    height: 90%;
    right: 0;
  `,
)

const Divider = styled.span(({ theme }) => {
  const { palette } = theme
  return css`
    width: 2rem;
    margin: 1.5rem 1rem 1rem;
    border-bottom: 0.125rem solid ${palette.light.darker};
  `
})

const UpsellingWrapper = styled.div(({
  theme: {
    palette, corners,
  },
}) => css`
  background-color: ${palette.product.location.light};
  border-radius: ${corners.default.borderRadius};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 1rem;
  color: ${palette.black};
  margin-right: 0.8rem;
`)

const UpsellingHeaderSection = styled.div(({ theme: { typography } }) => css`
  display: flex;
  flex-direction: row;
  span {
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
  }
`)

const UpsellingSection = styled.div(({ theme: { typography } }) => css`
  span {
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-Weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  }
`)

const HideUpsellingButton = styled(TextButton)(({
  theme: {
    palette, typography,
  },
}) => css`
  height: min-content;
  color: ${palette.blue};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
  font-size: ${typography.tiny.pt_12_semibold.fontSize};
  font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
  margin-left: 5px;
`)

const UpgradeButton = styled(Button)(({
  theme: {
    corners, palette,
  },
}) => css`
  padding: .4rem .8rem;
  border-radius: ${corners.default.borderRadius};
  border: 1px solid ${palette.blue};
  color: ${palette.blue};
  background: ${palette.light.white};
  cursor: pointer;

  :active {
    background: ${palette.light.darker};
  }
`)

const StyledPerfectScrollbar = styled(PerfectScrollbar)`
  padding-bottom: 5rem;
  padding-right: 0.7rem;
`

// const ShowTilesWrapper = styled.div(({
//   theme: {
//     palette, typography,
//   },
// }) => css`
//   display: flex;
//   align-items: center;
//   margin-top: 20px;

//   p {
//     color: ${palette.black};
//     font-size: ${typography.tiny.pt_12_medium.fontSize};
//     font-weight: ${typography.tiny.pt_12_medium.fontWeight};
//     line-height: ${typography.tiny.pt_12_medium.lineHeight};
//     margin-left: 5px;
//   }
// `)

const SpinnerWrapper = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
  margin: 1rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  >span{
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  }
` })

const Spinner = styled(SpinnerIcon)`
  width: 1.8rem;
  height: 1.8rem;
  opacity: .5;
  margin-right:.5rem;
 

  animation: rotation 0.8s infinite linear;
  @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
  }
`

interface ISidebarProps {
  isOpen: boolean
  toggle: React.Dispatch<React.SetStateAction<boolean>>
}

export const AnalysePageSidebar = ({
  isOpen, toggle,
}: ISidebarProps): JSX.Element => {
  // constants
  const redirectBasedOnProjectState = useRedirectBasedOnProjectState()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const {
    canBeSave, values, analysisDbInfo, tiles, synchronousTilesLoading, isEdit, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, userType, currentWorkspaceData, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)

  const higherPricingPlan = currentSubscriptionData?.value?.planExact ? getHigherPricingPlan(currentSubscriptionData?.value?.planExact) : ''

  // states
  const [data, setData] = useState(tiles)
  const [tilesLoading, setTilesLoading] = useState(true)
  const [showingUpselling, setShowingUpselling] = useState(false)
  const [showTiles] = useState(true)

  // we need ref to get current state in event listener
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }

  // functions
  const handleCategoryClick = (categoryId: string) => {
    const newData: ICombinedSectionTile[] = []

    data.forEach(item => (item.id === categoryId
      ? newData.push({
        ...item,
        isCategoryOpen: !item.isCategoryOpen,
      })
      : newData.push(item)))

    setData(newData)
  }

  const handleAllCategories = (shouldBeOpen: boolean) => {
    setData(data.map(item => ({
      ...item,
      isCategoryOpen: shouldBeOpen,
    })))
  }

  const handleShowOnlyChosen = (dataSet: ICombinedSectionTile[]) => {
    const newData: ICombinedSectionTile[] = []
    dataSet.forEach(item =>
      (values.some(val => val.id === item.id)
        ? newData.push({
          ...item,
          isCategoryOpen: true,
        })
        : newData.push({
          ...item,
          isCategoryOpen: false,
        })))
    setData(newData)
  }

  const handleCompareLocation = (shouldBeCompare: boolean) => {
    if (shouldBeCompare) {
      localStorage.setItem('isEditCompare', 'true')
      dispatch(saveComparedLocation({
        ...comparedLocation,
        generatedFromNow: true,
      }))
    } else {
      localStorage.setItem('isEditCompare', 'false')
    }
  }

  const checkIfAnyTileSave = () => {
    let isAnyTileSave = false
    if (values) {
      values.forEach(cat => {
        cat.tiles.forEach(tile => {
          if (tile.data?.value) {
            isAnyTileSave = true
          }
          return isAnyTileSave
        })
      })
    }
    return isAnyTileSave
  }

  const hideUpselling = () => {
    if (currentSubscriptionData?.value?.planExact === placemePlans.gold) {
      localStorage.setItem('analyseUpsellingHiddenToDate', JSON.stringify(addMonths(new Date(), 3)))
    } else {
      localStorage.setItem('analyseUpsellingHiddenToDate', JSON.stringify(addWeeks(new Date(), 2)))
    }
    setShowingUpselling(false)
  }

  const getTilesInCountry = async () => {
    if (value && value.country) {
      const asyncFilter = async (
        arr: ICombinedTile[], predicate: { (i: ICombinedTile): Promise<boolean>; (i: ICombinedTile): Promise<boolean> },
      ) => {
        const res = await Promise.all(arr.map(predicate))
        return arr.filter((_, index) => res[index])
      }
      const newTiles = []

      for (const tile of tiles) {
        const isTileInCategory = await asyncFilter(tile.tiles, async (i:ICombinedTile) => {
          const res = await checkCountry(value, i.countries, false, value.country)
          return res
        })
        if (isTileInCategory.length) {
          newTiles.push({
            ...tile,
            tiles: await asyncFilter(tile.tiles, async (i:ICombinedTile) => {
              const res = await checkCountry(value, i.countries, false, value.country)
              return res
            }),
          })
        }
      }
      const res = await newTiles
      return res
    }
    return tiles
  }

  // hooks
  useEffect(() => {
    // fetches user token id from authContext
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (userType !== 'user') {
      const upsellingHiddenToDateJSON = localStorage.getItem('analyseUpsellingHiddenToDate')
      if (upsellingHiddenToDateJSON) {
        setShowingUpselling(new Date(JSON.parse(upsellingHiddenToDateJSON)) <= new Date())
      } else {
        setShowingUpselling(true)
      }
    } else {
      setShowingUpselling(false)
    }
  }, [])

  useEffect(() => {
    handleShowOnlyChosen(data)
  }, [])

  useEffect(() => {
    if (data && value && value.country && !currentSubscriptionData?.loading
      && (tilesLoading || currentSubscriptionData?.value?.credits === 0)) {
      getTilesInCountry().then(res => { setData(res)
        handleShowOnlyChosen(res) })
      setTimeout(() => {
        setTilesLoading(false)
        // handleShowOnlyChosen(res)
      }, 4000)
    }
  }, [value, currentSubscriptionData])

  return (
    <NoPrint>
      <Wrapper
        isOpen={isOpen}
      >
        {checkIfAnyTileSave() && canBeSave && !isEdit
          ? (
            <ExitFromAnalysePopup
              handleDelete={() => {
                const isProjectSaved = analysisDbInfo?.visible
                exitFromAnalyseAction(dispatch, isEdit)
                redirectBasedOnProjectState(isProjectSaved)
              }}
              trigger={(
                <BackButton
                  isEdit={isEdit}
                  isOpen={isOpen}
                />
              )}
            />
          )
          : (
            <BackButton
              handleClick={() => {
                const isProjectSaved = analysisDbInfo?.visible
                exitFromAnalyseAction(dispatch, isEdit)
                redirectBasedOnProjectState(isProjectSaved)
              }}
              isEdit={isEdit}
              isOpen={isOpen}
            />
          )}
        {isOpen && (
          <>
            <SearchBar
              dataFiltered={data}
              handleAllCategories={handleAllCategories}
              handleShowOnlyChosen={handleShowOnlyChosen}
            />
          </>
        )}
        <StickyWrapper>
          <StyledPerfectScrollbar>
            {!isOpen && <Divider />}
            { tilesLoading
              ? <Loader />
              : (
                <>
                  <SidebarList
                    data={data}
                    handleCategoryOpen={handleCategoryClick}
                    handleCompareLocation={handleCompareLocation}
                    isOpen={isOpen}
                    showDisabledByPricingPlan={
                      (currentSubscriptionData?.value?.planExact?.includes('gold')
                      )
                        ? false
                        : (currentWorkspaceData?.value?.extendedPermissions
                          ? showTiles
                          : (currentSubscriptionData?.value?.plan === 'trial' || currentSubscriptionData?.value?.plan === 'white'))
                    }
                  />
                  {synchronousTilesLoading
                    && (
                      <SpinnerWrapper>
                        <Spinner />
                        <span>{t('generic.tiles_loading')}</span>
                      </SpinnerWrapper>
                    ) }
                </>
              )}
            {(isOpen && showingUpselling && higherPricingPlan && canBeSave
            && currentSubscriptionData?.value?.planExact !== 'gold_unlimited') && (
              <UpsellingWrapper>
                <UpsellingHeaderSection>
                  <span>{t(`placeme.analyse.upselling_header.${higherPricingPlan}`)}</span>
                  <HideUpsellingButton onClick={hideUpselling}>{t('generic.hide')}</HideUpsellingButton>
                </UpsellingHeaderSection>
                <UpsellingSection>
                  <span>
                    {t(`placeme.analyse.upselling_content.${higherPricingPlan}`)}
                  </span>
                </UpsellingSection>
                {(currentSubscriptionData?.value?.plan === 'white' || currentSubscriptionData?.value?.plan === 'trial')
                  ? (
                // if there is any tile with date loaded -> shows modal "analyse isn't saved"
                    checkIfAnyTileSave()
                      ? (
                        <ExitFromAnalysePopup
                          contentAcceptButton={t('placeme.upselling.go_further')}
                          contentText={t('placeme.analyse_not_safe.upselling.content.modal')}
                          handleDelete={() => {
                            dispatch(resetAnalysisState())
                            dispatch(resetLocationState())
                            setTimeout(() => redirectToAccountPricelist(), 200)
                          }}
                          trigger={(
                            <UpgradeButton>
                              {t(`generic.upgrade_plan_to.${higherPricingPlan || ''}`)}
                            </UpgradeButton>
                          )}
                        />
                      )
                      : (
                        <UpgradeButton
                          onClick={() => redirectToAccountPricelist()}
                          onKeyPress={() => redirectToAccountPricelist()}
                        >
                          {t(`generic.upgrade_plan_to.${higherPricingPlan || ''}`)}
                        </UpgradeButton>
                      )
                  )
                  : (
                    <UpgradeButton
                      href={`mailto:${emails.sales_pl}`}
                    >
                      {t('generic.contact_us')}
                    </UpgradeButton>
                  )}
              </UpsellingWrapper>
            )}

          </StyledPerfectScrollbar>
        </StickyWrapper>
        <TogglerRail>
          <Toggler
            isOpen={isOpen}
            onClick={() => toggle(!isOpen)}
          >
            <img
              alt={isOpen ? 'Close' : 'Open'}
              src='assets/icons/arrows/arrDown.svg'
            />
          </Toggler>
        </TogglerRail>
      </Wrapper>
    </NoPrint>
  )
}

