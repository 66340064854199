import React, { ReactElement, useCallback, useMemo, useRef } from 'react'
import { PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import Ranking, { RankingProps } from './components/Ranking'

interface RankingModalProps extends RankingProps {
  isOpen: boolean
}

function RankingModal({
  rankingList,
  currentPosition,
  isOpen,
  setIsOpen,
  headerText,
  contentKeyText,
  contentValueText,
  trimFloat = false,
  additionalColumn = false,
}: RankingModalProps): ReactElement {
  const analysedAddressRef = useRef<HTMLLIElement>(null)
  const { isPrinting } = useCheckIfPrinting()

  const scrollToAnalysedAddress = useCallback(() => {
    analysedAddressRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [])

  const rankingComponent = useMemo(() => (
    <Ranking
      ref={analysedAddressRef}
      additionalColumn={additionalColumn}
      contentKeyText={contentKeyText}
      contentValueText={contentValueText}
      currentPosition={currentPosition}
      headerText={headerText}
      rankingList={rankingList}
      setIsOpen={setIsOpen}
      trimFloat={trimFloat}
    />
  ), [analysedAddressRef, additionalColumn, contentKeyText, contentValueText,
    currentPosition, headerText, rankingList, setIsOpen, trimFloat,
  ])

  return (
    isPrinting
      ? rankingComponent
      : (
        <PopupWithOverlay
          closeOnDocumentClick={false}
          onClose={() => setIsOpen(false)}
          onOpen={scrollToAnalysedAddress}
          open={isOpen}
        >
          {rankingComponent}
        </PopupWithOverlay>
      )
  )
}

export default RankingModal
