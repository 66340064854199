import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as ArrowDown } from 'libs/shared/assets/src/lib/icons/arrows/arrDown.svg'
import { ReactComponent as ArrowUp } from 'libs/shared/assets/src/lib/icons/arrows/arrUp.svg'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { CategoryDetailsType, DistributionKeys } from '../@types/IBuildingsTileData'
import { useGetBuildingsTexts } from '../hooks/useGetBuildingsTexts'
import PieAndTable from './PieAndTable'

const CategorySection = styled.div(({ theme: { palette } }) => css`
  border-bottom: 1px solid  ${palette.gray.light};
  margin-bottom: 1rem;
`)

const Title = styled.h1(({ theme: { palette } }) => css`
  color: ${palette.black};
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 1rem;
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`)

const TitleWrapper = styled.div(({ theme: { palette } }) => css`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color:  ${palette.light.main};
  }
`)

interface CategoryDetailsProps {
  category: CategoryDetailsType
  comparedCategory?: CategoryDetailsType
  categoryName: DistributionKeys
  isExpanded: boolean
  onExpand: () => void
}

const CategoryDetails: React.FC<CategoryDetailsProps> = ({
  category, comparedCategory, categoryName, isExpanded, onExpand,
}) => {
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  const { dominantTypeInfo } = useGetBuildingsTexts({
    dominantType: categoryName,
  })

  return (
    <CategorySection>
      <div>
        <TitleWrapper onClick={onExpand}>
          {dominantTypeInfo?.icon}
          <Title>
            {dominantTypeInfo?.text}
            {isExpanded ? <ArrowUp /> : <ArrowDown />}
          </Title>
        </TitleWrapper>
        {isExpanded
          ? (
            <>
              <PieAndTable
                category={category}
                categoryName={categoryName}
              />
              {!!comparedLocation?.location?.address !== false && comparedCategory
                ? (
                  <PieAndTable
                    category={comparedCategory}
                    categoryName={categoryName}
                    showComparedAddress
                  />
                )
                : null}
            </>
          )
          : null}
      </div>
    </CategorySection>
  )
}

export default CategoryDetails
