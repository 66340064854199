import { Tooltip } from '@dataplace.ai/ui-components/atoms'
import React from 'react'
import styled, { css } from 'styled-components'

interface BubbleChartProps {
  valuesArray: number[]
  colorsArray: string[]
  sortedLabels: string[]
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  position: relative;
`

interface CircleProps {
  width: number
  height: number
  color: string
}

const Circle = styled.div<CircleProps>(({
  width,
  height,
  color,
}) => css`
    width: ${width}px;
    height: ${height}px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: ${color};
`)

const Value = styled.span(({ theme: { palette } }) => css`
  color: ${palette.light.white};
  font-size: 1rem;
  font-weight: 600;
`)

const BubbleChart: React.FC<BubbleChartProps> = ({
  valuesArray,
  colorsArray,
  sortedLabels,
}) => {
  const minDimension = 50

  return (
    <GridContainer>
      {valuesArray.map((value, index) => (
        <Tooltip
          key={`${value}-${sortedLabels[index]}`}
          content=''
          header={sortedLabels[index]}
          position='top center'
        >
          <Circle
            color={colorsArray[index + 1]}
            height={minDimension + (value * 2)}
            width={minDimension + (value * 2)}
          >
            <Value>{`${value}%`}</Value>
          </Circle>
        </Tooltip>
      ))}
    </GridContainer>
  )
}

export default BubbleChart
