import React, { useState, useRef, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { IDropdownCaretProps } from '@dataplace.ai/ui-components/atoms'
import { useComponentVisible } from '@dataplace.ai/functions/hooks'
import {
  mediaMax as media,
} from '@dataplace.ai/ui-components/themes'
import { IMainDropdownProps } from './@types/IMainDropdown.props'
import { ReactComponent as ArrowDown } from '../../../../assets/src/lib/icons/arrows/arrDown.svg'

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 500;
`

const DropdownContent = styled.div<IDropdownCaretProps>(({
  active, theme, height, width,
}) => {
  const {
    palette, typography, shadows,
  } = theme

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${active ? '10px' : '0'};
    background-color: white;
    height: ${active ? 'auto' : 0};
    width: ${width || 'max-content'};
    overflow: hidden;
    margin-top: 0.5rem;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: height 0.3s;
    border-radius: 6px;
    position: absolute;
    transform: ${height && `translate(0px, ${height}px)`};
    box-shadow: ${shadows.hover.boxShadow};
    z-index: 999;

    p {
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      padding: 10px;
      border-radius: 6px;
      width: calc(100% - 20px);

      :hover {
        background-color: ${palette.results.green.opaque};
      }
    }

    button {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_medium.fontSize};
      font-weight: ${typography.small.pt_13_medium.fontWeight};
      line-height: ${typography.small.pt_13_medium.lineHeight};
      padding: 10px;
      border-radius: 6px;
      width: 100%;
      background-color: white;
      border: none;
      text-align: start;
      cursor: pointer;

      :hover {
        background-color: ${palette.results.green.opaque};
      }
    }
  `
})

export const DropdownHeader = styled.div<IDropdownCaretProps>(({
  active, theme, transparent, noBorder, width, $minWidth,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${transparent ? 'transparent' : 'white'};
    border-radius: 6px;
    border: 1px solid ${noBorder ? 'none' : (active ? palette.product.location.main : '#E1E2E3')};
    padding: 5px 15px;
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};
    width: ${width || null};
    ${$minWidth && css`min-width: ${$minWidth};`}

    ${media.desktop_medium`
    font-size: 11px;
  `}

    div {
      margin: 0 1rem 0 0;
    }

    p {
      font-size: ${typography.small.pt_13_medium.fontSize};
      font-weight: ${typography.small.pt_13_medium.fontWeight};
      line-height: ${typography.small.pt_13_medium.lineHeight};
      color: ${(active && !transparent) ? 'white' : palette.black};
      span {
        margin-left: 5px;
        font-size: ${typography.small.pt_13_semibold.fontSize};
        font-weight: ${typography.small.pt_13_semibold.fontWeight};
        line-height: ${typography.small.pt_13_semibold.lineHeight};
        color: ${active ? 'white' : palette.blue};
      }
    }

    span {
      margin-left: 5px;
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      color: ${active ? 'white' : palette.blue};
    }

    .arrow {
      margin-left: 0.5rem;
      z-index: 0;
      path {
        stroke: ${palette.blue};
        z-index: 0;
      }
      transform: ${active ? 'rotate(180deg)' : 'rotate(0deg)'};
      min-width: 0.45rem;
      max-width: 0.45rem;
      z-index: 0;
    }
  `
})

export const MainDropdown: React.FC<IMainDropdownProps> = ({
  header,
  content,
  style,
  transparent,
  noArrow,
  noBorder,
  className,
  width,
  $minWidth,
}) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)

  const toggleDropdown = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  useEffect(() => {
    if (headerRef.current) {
      setHeight(headerRef.current.offsetHeight)
    }
  }, [])

  const contentWidth = useMemo(() => (width
    ? (`${parseFloat(width) + 0.7}rem`)
    : null
  ), [width])

  return (
    <div className={className}>
      <DropdownWrapper
        ref={ref}
        style={style}
      >
        <DropdownHeader
          ref={headerRef}
          $minWidth={$minWidth}
          active={isComponentVisible}
          noBorder={noBorder}
          onClick={toggleDropdown}
          transparent={transparent}
          width={width}
        >
          {header}
          {!noArrow && (
            <ArrowDown
              className='arrow'
              height='6'
              width='10'
            />
          )}
        </DropdownHeader>
        <DropdownContent
          active={isComponentVisible}
          height={height}
          onClick={toggleDropdown}
          width={contentWidth}
        >
          {content}
        </DropdownContent>
      </DropdownWrapper>
    </div>
  )
}
