import React, { forwardRef } from 'react'
import Popup from 'reactjs-popup'
import { PopupActions, PopupProps } from 'reactjs-popup/dist/types'
import { theme } from '@dataplace.ai/ui-components/themes'

export const PopupWithOverlay: React.FC<PopupProps & React.RefAttributes<PopupActions>> = forwardRef(({
  children, ...restProps
}) => (
  <Popup
    {...restProps}
    overlayStyle={theme().overlay}
  >
    {children}
  </Popup>
))

PopupWithOverlay.defaultProps = {
  modal: true,
}
