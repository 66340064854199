import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const LITERAL_TRUE = 'true'
const PRINTING = 'printing'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useCheckIfPrinting() {
  const [searchParams] = useSearchParams()
  const isPrinting = useMemo(() => searchParams.get(PRINTING) === LITERAL_TRUE, [searchParams])

  return {
    isPrinting,
  }
}

export default useCheckIfPrinting
