import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITextFilter } from '../TextFilter'
import { ISortableTableHeaderProps, ITableFilter } from '../../@types'
import { FilterSection } from '../FilterSection'
import { CheckboxesOutsideWrapper, CheckboxesWrapper } from '../CheckboxFilter/CheckboxFilter.styles'
import { Checkbox } from '../CheckboxFilter/components/Checkbox'
import useGetFilteredData from './hooks/useGetFilteredData'

export interface VolumeCheckboxFilterProps {
  data: Record<string, string | number>[]
  filter: ITextFilter
  setFilterValue(filter: ITableFilter): React.Dispatch<React.SetStateAction<ITableFilter>> | void
  header: ISortableTableHeaderProps
  clearable?: boolean
  checkboxes?: boolean
  name: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function VolumeCheckboxFilter({
  data, filter, setFilterValue, header, clearable, checkboxes, name,
}: VolumeCheckboxFilterProps) {
  const { t } = useTranslation()
  const [selectedElements, setSelectedElements] = useState<string[] | string>(filter[header.name]?.rule || [])
  const [checkboxSwitch, setCheckboxSwtich] = useState(false)
  const { getFilteredData } = useGetFilteredData()

  // divide difference between lowest and highest volume by 3 (step), to get three
  // equal filtering options in range of investments volumes:
  // 1) below lowest volume + step
  // 2) between lowest volume + step and highest volume - step
  // 3) above  highest volume - step
  const elements = useMemo(() => {
    const sortedVolumes = [...data].sort((a, b) => Number(a.volume) - Number(b.volume))
    const lowestVolume = Number(sortedVolumes[0].volume)
    const highestVolume = Number(sortedVolumes[sortedVolumes.length - 1].volume)
    const volumeDifference = highestVolume - lowestVolume
    if (volumeDifference === 0) return {}
    const step = Math.floor(volumeDifference / 3)
    return {
      below: lowestVolume + step,
      from: lowestVolume + step + 1,
      to: highestVolume - step - 1,
      above: highestVolume - step,
    }
  }, [data])

  const clearCheckboxFilter = useCallback(() => {
    const newFilter = {
      ...filter,
    }
    delete newFilter[header.name]
    setSelectedElements([])
    setFilterValue(newFilter)
  }, [])

  const isElementChecked = useCallback((element?: number) => {
    if (!element) return false
    return selectedElements.includes(element.toString())
  }, [selectedElements])

  const areElementsChecked = useMemo(() => ({
    below: isElementChecked(elements.below),
    fromTo: isElementChecked(elements.to),
    above: isElementChecked(elements.above),
  }), [isElementChecked, elements, selectedElements])

  const filteredData = useMemo(() => getFilteredData({
    elements,
    selectedElements,
    data,
  }), [elements, selectedElements, data])

  const filterData = useCallback(() => {
    const newFilter = {
      ...filter,
    }
    if (!selectedElements.length) {
      delete newFilter[header.name]
    } else {
      newFilter[header.name] = {
        type: name,
        rule: filteredData,
      }
    }
    setFilterValue(newFilter)
  }, [selectedElements, data, elements])

  const selectElement = useCallback((checked: boolean, element?: number) => {
    if (!element) return
    const stringElement = element.toString()
    if (checked) {
      setSelectedElements([...selectedElements, stringElement])
    } else {
      setSelectedElements([...selectedElements].filter(selectedElement => selectedElement !== stringElement))
    }
    setCheckboxSwtich(!checkboxSwitch)
  }, [selectedElements, setSelectedElements, filterData])

  useEffect(() => filterData(), [filterData, checkboxSwitch])

  return (
    data.length > 3 && checkboxes && Object.keys(elements).length
      ? (
        <FilterSection
          clear={clearCheckboxFilter}
          clearable={clearable}
          header={t(`generic.${header.label}`)}
        >
          <CheckboxesOutsideWrapper>
            <CheckboxesWrapper>
              <Checkbox
                checked={areElementsChecked.below}
                label={(
                  <label htmlFor={`${elements.below}`}>
                    {`${t('placeme.purchasing_power.below')} ${elements.below} m3`}
                  </label>
                )}
                onChange={(e) => selectElement(e.target.checked, elements.below)}
              />
              <Checkbox
                checked={areElementsChecked.fromTo}
                id={elements.to?.toString()}
                label={(
                  <label htmlFor={`${elements.to}`}>
                    {`${elements.from! - 1} m3 - ${elements.to! + 1} m3`}
                  </label>
                )}
                onChange={(e) => selectElement(e.target.checked, elements.to)}
              />
              <Checkbox
                checked={areElementsChecked.above}
                id={elements.above?.toString()}
                label={(
                  <label htmlFor={`${elements.above}`}>
                    {`${t('placeme.purchasing_power.above')} ${elements.above} m3`}
                  </label>
                )}
                onChange={(e) => selectElement(e.target.checked, elements.above)}
              />
            </CheckboxesWrapper>
          </CheckboxesOutsideWrapper>
        </FilterSection>
      )
      : null
  )
}

export default VolumeCheckboxFilter
