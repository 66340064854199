/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AddIcon, CheckMarkIcon } from '@dataplace.ai/ui-components/atoms'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { IAnalyseInfoModalSelectorProps } from './@types/IAnalyseInfoModalSelector.props'
import { addTileAction, deleteTileAction } from '../../../slice/analysisSlice'
import { RootState } from '../../../../../redux/store'

const Wrapper = styled.div(() => css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`)

const TileWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin: 0.125rem 0 0.125rem 2rem;
    padding: 1.75rem 0;
    display: flex;
    width: 65%;
    flex-direction: column;
    position: relative;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};

    > span {
      margin: 0.25rem 0;
    }

    > span:first-child {
      margin-top: 0;
    }

    > span:last-child {
      margin-bottom: 0;
    }

    :first-of-type:before {
      display: none;
    }

    :before {
      content: '';
      width: 100px;
      height: 1px;
      background-color: ${palette.light.darker};
      position: absolute;
      top: 0;
    }
  `
})

const SelectorWrapper = styled.div<{active: boolean}>(({
  theme, active,
}) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
    width: 35%;
    border-left: 4px solid ${palette.light.darker};

    ${active && css`
      border-left: 4px solid ${palette.results.green.dark};
    `}
  `
})

const Selector = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    align-items: center;
    margin: 1.75rem 1.75rem 0;
    height: 2.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      flex-grow: 1;
    }
  `
})

const RemoveSpan = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin: 0.25rem 0 0.25rem 1.75rem;
    display: flex;
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};

    > span:first-child {
      color: ${palette.light.darkest};
      margin-right: 0.25rem;
    }

    > span:last-child {
      color: ${palette.blue};
      cursor: pointer;
    }
  `
})

export const AnalyseInfoModalSelector = ({
  tile, section,
}: IAnalyseInfoModalSelectorProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { values } = useSelector((state: RootState) => state.analysis)
  const {
    analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const authContext = useContext(AuthContext)

  // we need ref to get current state in event listener
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }

  const isActive = useMemo(() => !!values
    .find(value => value.id === section.id)?.tiles?.find(value => value.id === tile.id), [values])

  const handleDelete = () => {
    dispatch(deleteTileAction(token, section.id, tile.id, comparedAnalyseId || analyseId || ''))
  }

  const handleAdd = () => {
    dispatch(addTileAction(token, section, tile))
  }

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  return (
    <Wrapper>
      <TileWrapper>
        {tile.helpers.map(help => <span key={help}>{t(help)}</span>)}
      </TileWrapper>
      <SelectorWrapper active={isActive}>
        <Selector>
          <span>{t(`placeme.tile.${tile.id}`)}</span>
          {!isActive && <AddIcon onClick={handleAdd} />}
          {isActive && <CheckMarkIcon />}
        </Selector>
        <RemoveSpan>
          <span>{t('placeme.analyse_info_modal.added_to_analyse')}</span>
          <span onClick={handleDelete}>{t('placeme.analyse_info_modal.back')}</span>
        </RemoveSpan>
      </SelectorWrapper>
    </Wrapper>
  )
}
