import { useCallback } from 'react'
import { LOCATION_STATUSES } from 'apps/placeme/src/constants/constants'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { getAxios } from '../utils'

type LocationStatus = (typeof LOCATION_STATUSES)[keyof typeof LOCATION_STATUSES]

type LocationsData = Record<LocationStatus, Location[]>

interface Location {
  address: string
  lat: number
  lng: number
  id: string
  status: string
  createdAt: string
  siteId?: string
}

interface SaveLocationProps {
  address: string
  lat: number
  lng: number
  status?: LocationStatus
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSaveLocation() {
  const saveLocation = useCallback(async ({
    address,
    lat,
    lng,
    status = LOCATION_STATUSES.potential,
  }: SaveLocationProps) => {
    const axiosInstance = await getAxios()
    const locations = [{
      status,
      address,
      lat,
      lng,
    }]
    const formData = new FormData()
    formData.append('locations', JSON.stringify(locations))
    const data: LocationsData = await (await axiosInstance.post(`${ENDPOINTS.KANBAN_LOCATIONS}`, formData)).data
    return data
  }, [getAxios])

  return {
    saveLocation,
  }
}
