import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TitleFormSectionSubTitle, HorizontalSegmentedChart, HorizontalSegmentedElement } from '@dataplace.ai/ui-components/atoms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { CategoryTitle, ColorfulShape, Description, ImportanceSelector, ImportanceSelectorElement, ImportanceSelectorWrapper, ImportanceWrapper, Percentages, PercentagesValue, RestoreDefaultBtn, StyledRefreshIcon, StyledSymbol, StyledWarningIcon, SubCatDescription, SubCatTitle, SumLabel, SumWrapper, TextWrapper, ValidationInfo } from './PotentialWithinChainForm.styles'
import { FormFields } from '../@types/IPotentialWithinChain'

interface IPercentagesForm {
  invalidSum: boolean
  setFormValue: (key: string, value: string) => void
  setInitialState: () => void
  percentagesSum: number
  value: ResourceWithId
  formState: FormFields
}

export const PercentagesForm: React.FC<IPercentagesForm> = ({
  invalidSum,
  setFormValue,
  setInitialState,
  percentagesSum,
  value,
  formState,
}) => {
  const { t } = useTranslation()
  const [horizontalSegment, setHorizontalSegment] = useState<HorizontalSegmentedElement[]>([])

  const setColor = (catColor: string[], index: number) => {
    if (index > catColor.length - 1) {
      return catColor[catColor.length - 1]
    }
    return catColor[index]
  }

  useEffect(() => {
    const segmentData = value?.formsElements?.flatMap(form => form.elements.map((element, index) => ({
      fieldColor: form.catColor[index],
      value: formState?.[element.fieldId as keyof FormFields] ?? 0,
    })))

    setHorizontalSegment(segmentData ?? [])
  }, [value, formState])

  return (
    <>
      <TitleFormSectionSubTitle>
        {t('placeme.potential_within_chain.percent_form.title')}
      </TitleFormSectionSubTitle>
      <ImportanceWrapper>
        <Description>
          {t('placeme.potential_within_chain.percent_form.description')}
        </Description>
        <HorizontalSegmentedChart
          data={horizontalSegment}
          invalidData={invalidSum}
          message={t('placeme.potential_within_chain.horizontal_chart.message')}
        />
        {value?.formsElements?.map((form) => (
          <ImportanceSelectorWrapper key={form.category}>
            <CategoryTitle>{form.title}</CategoryTitle>
            {form.elements.map((element, index) => (
              <ImportanceSelectorElement key={element.fieldId}>
                <TextWrapper>
                  <SubCatTitle>
                    <ColorfulShape $catColor={setColor(form.catColor, index)} />
                    {element.title}
                  </SubCatTitle>
                  <SubCatDescription>{element.description}</SubCatDescription>
                </TextWrapper>
                <ImportanceSelector
                  $invalidColor={invalidSum}
                  onChange={(e) => setFormValue(element.fieldId, e.target.value)}
                  type='number'
                  value={formState?.[element.fieldId as keyof FormFields]?.toString() ?? ''}
                />
                <StyledSymbol $valueLength={formState?.[element.fieldId as keyof FormFields]?.toString().length ?? 1}>
                  %
                </StyledSymbol>
              </ImportanceSelectorElement>
            ))}
          </ImportanceSelectorWrapper>
        ))}
        <SumWrapper>
          <RestoreDefaultBtn onClick={setInitialState}>
            <StyledRefreshIcon
              height={16}
              width={16}
            />
            {t('placeme.potential_within_chain.percent_form.back_initial_value')}
          </RestoreDefaultBtn>
          <Percentages>
            <SumLabel>
              {t('generic.sum')}
              :
            </SumLabel>
            <PercentagesValue $invalidColor={invalidSum}>{`${percentagesSum}%`}</PercentagesValue>
          </Percentages>
        </SumWrapper>
        {invalidSum
          ? (
            <ValidationInfo>
              <StyledWarningIcon />
              {t('placeme.potential_within_chain.percent_form.valid_info')}
            </ValidationInfo>
          )
          : null}
      </ImportanceWrapper>
    </>
  )
}
