import { useTranslation } from 'react-i18next'

interface GetDoughnutDataProps {
  locationType: {
    residential: number,
    transit: number
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetDoughnutData = ({ locationType }: GetDoughnutDataProps) => {
  const { t } = useTranslation()
  const elementData = {
    title: t('placeme.location_type.section_title_1'),
    subtitle: t('placeme.location_type.form_span_1'),
    firstSegment: {
      span: t('placeme.location_type.residential'),
      value: locationType.residential,
    },
    secondSegment: {
      span: t('placeme.location_type.transit'),
      value: locationType.transit,
    },
  }

  return {
    elementData,
  }
}
