import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { getCookieWithoutJSON } from '@dataplace.ai/functions/utils'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  // use backend config
  .use(HttpApi)
  .init({
    debug: false,
    fallbackLng: 'pl',
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    lng: getCookieWithoutJSON('language') || undefined,
    backend:
      {
        loadPath: '/assets/locales/{{lng}}.json',
        allowMultiLoading: true,
        parse: data => JSON.parse(data),
        requestOptions: {
          method: 'GET',
        },
        reloadInterval: false,
      },
  }, () => {})

export const I18nProvider = i18n
