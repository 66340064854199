import React from 'react'
import styled, { css } from 'styled-components'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

type PieWrapperProps = {
  width?: number
}

const PieWrapper = styled.div<PieWrapperProps>`
  ${({ width }) => css`
    width: ${width}px;
    height: ${width}px;
  `}
`

const backgroundColors = [
  'rgba(153, 102, 255, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(14, 1, 4, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
]

export interface IPieChartProps {
  data: number[],
  labels: string[],
  width?: number,
  valueType?: string,
  innerLabels?: boolean
  customColors?: string[]
}

export function PieChart({
  data, labels, width, valueType, innerLabels, customColors,
}: IPieChartProps):JSX.Element {
  return (
    <PieWrapper width={width}>
      <Pie
        data={{
          labels,
          datasets: [
            {
              label: '',
              data,
              backgroundColor: customColors || backgroundColors,
              borderColor: 'white',
              borderWidth: 0,
            },
          ],
        }}
        options={{
          plugins:{
            datalabels: {
              display: innerLabels || false,
              color: 'white',
              font: {
                size: '16',
                weight: 'bold',
              },
              formatter(value: number) {
                switch (valueType) {
                  case 'percentages':
                    return `${value}%`
                  default:
                    return value
                }
              },
            },
          },

          maintainAspectRatio: false,
          legend: {
            display: false,
          },
        }}
        plugins={[ChartDataLabels]}
      />
    </PieWrapper>
  )
}

export default PieChart
