import { useTranslation } from 'react-i18next'
import { IChoiceObject } from '@dataplace.ai/ui-components/organisms'
import { FormFieldsDemo, InitialFormStateDemo } from '../../../DemoSalesPredictionModel'
import { useGetDropdownOptions } from './useGetDropdownOptions'

interface FormElementDemo {
  formStateElement: string | number | boolean | null
  inputKey: FormFieldsDemo
  options: {
    value: string | number | boolean
    label?: string
  }[]
  title: string
}

interface FormElementMultiDemo {
  inputKey: FormFieldsDemo
  options: IChoiceObject[]
  title: string
}

export interface FormDataObjectDemo {
  formElementData: { [key: string]: FormElementDemo }
  multipleChoiceData: { [key: string]: FormElementMultiDemo }
  additionalFormElementData?: { [key: string]: FormElementDemo } | null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormFieldsData = (formState: InitialFormStateDemo, additionalFields: boolean) => {
  const { t } = useTranslation()
  const {
    locationTypeOptions,
    options,
    sizeOptions,
    locationAdditionsChoices,
  } = useGetDropdownOptions()

  const formElementData: { [key: string]: FormElementDemo } = {
    locationType: {
      formStateElement: formState.locationType,
      inputKey: FormFieldsDemo.locationType,
      options: locationTypeOptions,
      title: t('placeme.demo.sales_prediction_model.criteria.choose_location_type'),
    },
    plannedShoppingMall: {
      formStateElement: formState.plannedShoppingMall,
      inputKey: FormFieldsDemo.plannedShoppingMall,
      options,
      title: t('placeme.demo.sales_prediction_model.criteria.if_future_planned_shopping_mall'),
    },
  }

  const multipleChoiceData: {[key: string]: FormElementMultiDemo} = {
    multipleChoices: {
      inputKey: FormFieldsDemo.locationAdditions,
      options: locationAdditionsChoices,
      title: t('placeme.demo.sales_prediction_model.criteria._choose_location_additions'),
    },
  }

  const additionalFormElementData: {[key: string]: FormElementDemo} = {
    plannedShoppingMallSize: {
      formStateElement: formState.plannedShoppingMallSize,
      inputKey: FormFieldsDemo.plannedShoppingMallSize,
      options: sizeOptions,
      title: t('placeme.demo.sales_prediction_model.criteria.what_future_shopping_mall_size'),
    },
    plannedShoppingMallFoodCourtExists: {
      formStateElement: formState.plannedShoppingMallFoodCourtExists,
      inputKey: FormFieldsDemo.plannedShoppingMallFoodCourtExists,
      options,
      title: t('placeme.demo.sales_prediction_model.criteria.if_food_court_exists'),
    },
  }

  return {
    formElementData,
    multipleChoiceData,
    additionalFormElementData: additionalFields ? additionalFormElementData : null,
  }
}
