import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MainDropdown } from '@dataplace.ai/ui-components/molecules'
import styled, { css } from 'styled-components'
import { FormFieldValue } from '@placeme/components/organisms/Tiles/Mcd/McdPotentialModel/McdPotentialModel'

const StyledWrapper = styled.div<{ width?: string }>(({ width }) => css`
  width: ${width};
`)

const InputWrapper = styled.div(({
  theme: {
    palette,
    typography,
  },
}) => css`
    color: ${palette.dark.normal};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
    display: grid;
    gap: 0.5rem;
    margin-top: 1.75rem;
    :first-of-type {
        margin-right: 1rem;
    }
`)

const DropdownWrapper = styled.div<{ maxWidth?: string }>(
  ({ maxWidth }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: ${maxWidth || null};
    
    > * {
        cursor: pointer;
    }
`,
)

const DropdownWrapperBox = styled.div(({ theme: { typography } }) => css`
  padding: 3px 12px;
  width: 100%;
  justify-content: center;
  display: flex;
  text-align: center;
  :hover {
    font-weight: ${typography.main.pt_15_medium.fontWeight};
  }
`)

const StyledSpan = styled.span`
  height: 3rem;
  display: grid;
  align-content: flex-end;
`
interface InitialFormState<T = FormFieldValue> {
  [key: string]: T
}

interface IFormElement<T extends keyof Partial<InitialFormState>> {
  title: string
  inputKey: string
  options: Array<{ value: string | boolean | number; label?: string }>
  formStateElement: Partial<InitialFormState>[T]
  setFormValue: (key: string, value: FormFieldValue) => void
  dropdownWidth?: string
}

export const FormElement: React.FC<IFormElement<keyof InitialFormState>> = ({
  title,
  inputKey,
  options,
  formStateElement,
  setFormValue,
  dropdownWidth,
}) => {
  const { t } = useTranslation()
  const headerText = useMemo(() => {
    const foundOption = options.find((option) => option.value === formStateElement)
    return (foundOption?.label ?? foundOption?.value.toString()) || t('generic.choose')
  }, [options, formStateElement, t])

  return (
    <StyledWrapper>
      <InputWrapper>
        <StyledSpan>{title}</StyledSpan>
        <MainDropdown
          content={(
            <DropdownWrapper maxWidth='11rem'>
              {options.map((option) => (
                <DropdownWrapperBox
                  key={option.value.toString()}
                  aria-hidden='true'
                  onClick={() => setFormValue(inputKey, option.value)}
                >
                  {option.label || option.value.toString()}
                </DropdownWrapperBox>
              ))}
            </DropdownWrapper>
          )}
          header={headerText}
          width={dropdownWidth ?? '4rem'}
        />
      </InputWrapper>
    </StyledWrapper>
  )
}
