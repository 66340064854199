
import { combineReducers } from 'redux'
import chooseLocationSlice from '../features/ChooseLocationReport/chooseLocationSlice'
import analysisSlice from '../features/Analyse/slice/analysisSlice'
import analysesAndReportsSlice from '../features/AnalyseAndReports/slice/analysesAndReportsSlice'

const appReducer = combineReducers({
  location: chooseLocationSlice,
  analysis: analysisSlice,
  analysesAndReports: analysesAndReportsSlice,
})

export default appReducer
