import { useTranslation } from 'react-i18next'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { TrafficIntensity } from 'apps/placeme/src/features/Analyse/utils/constants'
import { ISegmentationData } from './SegmentationTile'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetGaugeData = (data?: ISegmentationData) => {
  const { t } = useTranslation()
  let gaugeValue
  let legendColor
  let locationTranslation
  switch (data?.value.segmentation.traffic.location) {
    case TrafficIntensity.LOW:
      gaugeValue = 12.5
      legendColor = defaultTheme.palette.results.red.lightest
      locationTranslation = t('placeme.segmentation_tile.low_traffic')
      break
    case TrafficIntensity.MEDIUM:
      gaugeValue = 50
      legendColor = defaultTheme.palette.results.red.light
      locationTranslation = t('placeme.segmentation_tile.medium_traffic')
      break
    case TrafficIntensity.HIGH:
      gaugeValue = 87.5
      legendColor = defaultTheme.palette.results.red.dark
      locationTranslation = t('placeme.segmentation_tile.high_traffic')
      break
    default:
      gaugeValue = 0
      legendColor = defaultTheme.palette.results.red.lightest
      locationTranslation = t('placeme.segmentation_tile.low_traffic')
  }

  return {
    gaugeValue,
    legendColor,
    locationTranslation,
  }
}
