/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Tooltip } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as HelpIcon } from '../../../../assets/src/lib/icons/dataplace/help-circle.svg'
import { TooltipWrapper } from '../Bean'

interface WrapperProps {
  $width?: string
  $color?: string
}

const Wrapper = styled.div<WrapperProps>(({
  theme: { palette }, $width, $color,
}) => css`
  display: flex;
  width: ${$width || 'auto'};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 7px;
  gap: 0.375rem;
  background: ${$color ?? palette.results.blue.opaque};
  border-radius: 2.5rem;
`)

const StyledBeanText = styled.p<{$color?: string}>(({
  theme: { palette }, $color,
}) => css`
  margin: 0;
  color: ${$color ?? palette.results.blue.light};
`)

const StyledHelpIcon = styled(HelpIcon)<{$color?: string}>(({
  theme: { palette }, $color,
}) => css`
  width: 1rem;
  height: 1rem;
  path {
    fill:${$color ?? palette.results.blue.light};
  }
`)

interface InfoBeanProps {
  children?: ReactNode
  $beanColor?: string
  $textColor?: string
  width?: string
  tooltipText?: string
  tooltipMaxWidth?: string
  style?: React.CSSProperties
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function InfoBean({
  children, $beanColor, $textColor, width, tooltipText, tooltipMaxWidth, style,
}: InfoBeanProps) {
  return (
    <Tooltip
      content={<TooltipWrapper $maxWidth={tooltipMaxWidth}>{tooltipText}</TooltipWrapper>}
      position='bottom right'
    >
      <Wrapper
        $color={$beanColor}
        $width={width}
        style={style}
      >
        {children
          ? <StyledBeanText $color={$textColor}>{children}</StyledBeanText>
          : null}
        <StyledHelpIcon $color={$textColor} />
      </Wrapper>
    </Tooltip>
  )
}
