import React from 'react'
import styled, { css } from 'styled-components'
import { NoPrint } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as SpinnerIcon } from '../../../../assets/src/lib/icons/spinner.svg'
import { ReactComponent as Success } from '../../../../assets/src/lib/icons/successStatus.svg'
import { ReactComponent as Error } from '../../../../assets/src/lib/icons/errorStatus.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/src/lib/icons/close.svg'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
        display: flex;
        align-items: center;
        background-color: ${palette.black};
        padding: 10px 15px 10px 20px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08), 0px 0px 24px rgba(95, 82, 73, 0.08);
        border-radius: 6px;
        position: fixed;
        left: 2rem;
        bottom: 2rem;
        z-index: 600;

        >svg{
          margin-right: 1rem;
        }

        .close{
          width: 16px;
          height: 16px;
          margin:0;
          > path {
            stroke: white;
          }
          position: relative;
          bottom: 5px;
          left: 5px;
          cursor: pointer;
        }
    `
})

const TextWrapper = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
        display: flex;
        flex-direction: column;
        color: white;

        >p{
            font-size: ${typography.tiny.pt_12_semibold.fontSize};
            font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
            line-height: ${typography.tiny.pt_12_semibold.lineHeight};
        }

        >span{
            font-size: ${typography.tiny.pt_12_regular.fontSize};
            font-weight: ${typography.tiny.pt_12_regular.fontWeight};
            line-height: ${typography.tiny.pt_12_regular.lineHeight};
        }


       

    `
})

const Spinner = styled(SpinnerIcon)`
  width: 1.3rem;
  height: 1.3rem;
  opacity: .5;

  animation: rotation 0.8s infinite linear;
  @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
  }
`

interface IStatusInfoLabel {
  loading: boolean
  handleClose: ()=> void
  loadingParagraph: string
  loadingSpan: string
  successParagraph: string
  successSpan: string
  errorParagraph: string
  errorSpan: string
  errorSpan2: string
  error: boolean
}

export const StatusInfoLabel = ({
  loading, handleClose, loadingParagraph, loadingSpan, successParagraph, successSpan, errorParagraph, errorSpan, error,
  errorSpan2,
}: IStatusInfoLabel): JSX.Element => (
  <NoPrint>
    <Wrapper>
      {loading
        ? <Spinner />
        : (!error ? <Success /> : <Error />)}
      <TextWrapper>
        <p>{loading ? loadingParagraph : (!error ? successParagraph : errorParagraph)}</p>
        <span>{loading ? loadingSpan : (!error ? successSpan : errorSpan)}</span>
        <span>{loading ? '' : (!error ? '' : errorSpan2)}</span>
      </TextWrapper>
      <CloseIcon
        className='close'
        onClick={handleClose}
      />
    </Wrapper>
  </NoPrint>
)
