import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { GaugeChart, Loader, TileFormColumn, TileFormRow, TileFormRowWithData, TileFormSection, TileFormSpan } from 'libs/shared/ui-components/src/atoms'
import { ITileData } from 'apps/placeme/src/features/Analyse/slice/@types/ITileData'
import { useFetchTileData } from 'apps/placeme/src/customHooks/useFetchTileData'
import { bigBenCategoryId } from 'libs/shared/constants/src/lib/bigBen'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { TileSectionIds } from 'libs/shared/constants/src/lib/sectionIds'
import { TrafficIntensity } from 'apps/placeme/src/features/Analyse/utils/constants'
import { useGetGaugeData } from './useGetGaugeData'
import { DoughnutElement } from './resultElement/DoughnutElement'

const StyledSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.big.pt_22_semibold.fontSize} !important;
  ` })
const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  ` })

export interface ISegmentationData extends ITileData {
  value: {
    segmentation: {
      segments: {
        premium: number,
        standard: number
      },
      traffic: {
        location: string,
        segments: {
          local: number,
          transit: number
        }
      }
    }
  }
}

export const SegmentationTile: React.FC<{ data: ISegmentationData, tileId: string}> = ({
  tileId, data,
}) => {
  const { t } = useTranslation()
  const { values } = useSelector((state: RootState) => state.analysis)
  const catchmentId = useMemo(() =>
    getTileInfo(tileId, TileSectionIds.BIGBEN, values)?.chosenRange?.catchmentId, [tileId, values])

  const [_data, setData] = useState(data as ISegmentationData)
  const {
    gaugeValue, legendColor, locationTranslation,
  } = useGetGaugeData(_data)
  const canBeRequest = useMemo(() => !!catchmentId, [catchmentId])

  const { fetchData } = useFetchTileData(
    data,
    ENDPOINTS.SEGMENTATION_BIG_BEN_TILE,
    tileId,
    bigBenCategoryId,
    {
      catchmentId,
    },
  )

  useEffect(() => {
    if (!data?.value && canBeRequest) {
      fetchData()
      setData(data)
    }
  }, [catchmentId, !data?.value, canBeRequest])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <TileFormSection>
          <DoughnutElement
            data={data}
            element='segment'
          />
        </TileFormSection>
        <TileFormSection>
          <DoughnutElement
            data={data}
            element='traffic'
          />
          <TileFormRow
            gap={data.value.segmentation.traffic.location === TrafficIntensity.HIGH ? '1rem' : '3rem'}
            padding={data.value.segmentation.traffic.location === TrafficIntensity.MEDIUM ? '3rem 0 0 0' : '0'}
            width='100%'
            wrap='nowrap'
          >
            <TileFormColumn
              align='start'
              width='35%'
            >
              <TileFormRowWithData legend={legendColor}>
                <span>{t('placeme.segmentation_tile.location')}</span>
                <StyledSpan>{locationTranslation}</StyledSpan>
              </TileFormRowWithData>
            </TileFormColumn>
            <TileFormColumn width='65%'>
              <GaugeChart
                displayValue={locationTranslation}
                height={150}
                monolitColor
                value={gaugeValue}
              />
              <TileFormRow width='50%'>
                <TileFormColumn
                  justify='center'
                  padding='2rem'
                  textAlign='right'
                  width='33%'
                >
                  <TileFormSpan>{t('generic.low')}</TileFormSpan>
                </TileFormColumn>
                <TileFormColumn
                  justify='center'
                  padding='2rem'
                  textAlign='right'
                  width='33%'
                >
                  <TileFormSpan>{t('generic.medium')}</TileFormSpan>
                </TileFormColumn>
                <TileFormColumn
                  justify='center'
                  padding='2rem'
                  textAlign='right'
                  width='33%'
                >
                  <TileFormSpan>{t('generic.high')}</TileFormSpan>
                </TileFormColumn>
              </TileFormRow>
            </TileFormColumn>
          </TileFormRow>
        </TileFormSection>
      </Wrapper>
    )
}
