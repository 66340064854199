import React, { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react'

export type FeatureFlagsObject = {
  pdfV2: boolean
} | undefined
export interface IFeatureFlagsContext {
  featureFlags: FeatureFlagsObject
  setFeatureFlags: Dispatch<SetStateAction<FeatureFlagsObject>>
}

export const FeatureFlagsContext = createContext<IFeatureFlagsContext | null>(null)

interface ProgressProviderProps {
  children: ReactNode
}

export function FeatureFlagsContextProvider({ children }: ProgressProviderProps): JSX.Element {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsObject>()

  const contextValue = useMemo(() => ({
    featureFlags,
    setFeatureFlags,
  }), [featureFlags])

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
