import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@dataplace.ai/ui-components/atoms'
import { Description, ImageWrapper, BottomWrapper } from './DownloadModal.styles'

export type DownloadStatus = 'success' | 'error' | null;

interface PdfState {
  loading: boolean;
  status: DownloadStatus;
}

interface SavingState {
  loading: boolean;
  error?: string | null;
  value: unknown;
}

interface PdfInfoViewProps {
  pdfState: PdfState;
  savingState: SavingState;
  canBeSave: boolean;
  isEdit: boolean;
  handleUnderstand: () => void;
}

const PdfInfoView: React.FC<PdfInfoViewProps> = ({
  pdfState,
  savingState,
  canBeSave,
  isEdit,
  handleUnderstand,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Description>
        {pdfState?.status === 'error'
          ? t('placeme.pdf_generation_infoview1_error')
          : t('placeme.new_pdf_generation_infoview1')}
      </Description>
      <Description>
        {canBeSave && !isEdit && savingState?.value && !savingState?.error
          ? t('placeme.save_infoview1')
          : t('placeme.save_infoview1_error')}
      </Description>
      <ImageWrapper>
        <img
          alt='pdf info view'
          src='assets/images/pdfInfoView.svg'
        />
      </ImageWrapper>
      <BottomWrapper>
        <Button
          margin='0 0 0 20px'
          onClick={handleUnderstand}
          type='button'
        >
          {t('generic.understand')}
        </Button>
      </BottomWrapper>
    </>
  )
}

export default PdfInfoView
