import { KeyOfSetting, NonUndefinedReportSettings, RequiredNonUndefined, TileSettingsValue, useTileSettings, UseTileSettingsParams } from 'apps/placeme/src/contexts/UserSettings/hooks/useTileSettings'
import { useCallback, useRef } from 'react'
import { PopupActions } from 'reactjs-popup/dist/types'
import { Signal } from '@preact/signals-react'
import { useTableFilter, UseTableFilterProps, UseTableFilterReturnValues } from './useTableFilter'
import { ITableFilter } from '../../../@types'

interface UsePersistingTableFilterReturnValue<TileType extends keyof NonUndefinedReportSettings> {
  tileSettings: Signal<TileSettingsValue<TileType>>
  updateTileSettings: (tileSettings: TileSettingsValue<TileType>) => void
  tableFilterProps: Omit<UseTableFilterReturnValues, 'applyFiltersAndClose' | 'deleteActiveFilter'>
  popupRef: UseTableFilterProps['popupRef']
  applyFiltersAndClose(tileSettings: TileSettingsValue<TileType>): void
  deleteActiveFilter: (
    settingsKey: FilterFields<RequiredNonUndefined<TileSettingsValue<TileType>>, ITableFilter>,
    name: string,
  ) => void
}
type FilterFields<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T]

interface UsePersistingTableFilter<TileType extends keyof NonUndefinedReportSettings> extends UseTileSettingsParams<TileType>, Omit<UseTableFilterProps, 'popupRef'> {}
export const usePersistingTableFilter = <TileType extends keyof NonUndefinedReportSettings>({
  tileType,
  tileId,
  reportId,
  headers,
  setData,
  data,
}: UsePersistingTableFilter<TileType>): UsePersistingTableFilterReturnValue<TileType> => {
  const {
    updateTileSettings, tileSettings, deleteTileSettingKey,
  } = useTileSettings({
    tileType,
    tileId,
    reportId,
  })

  const popupRef = useRef<PopupActions>(null)
  const {
    applyFiltersAndClose: _applyFiltersAndClose,
    ...tableFilterProps
  } = useTableFilter({
    headers,
    setData,
    data,
    popupRef,
  })

  const applyFiltersAndClose = useCallback((tileSettings: TileSettingsValue<TileType>) => {
    _applyFiltersAndClose()
    updateTileSettings(tileSettings)
  }, [_applyFiltersAndClose, updateTileSettings])

  const deleteActiveFilter = useCallback((
    settingsKey: FilterFields<RequiredNonUndefined<TileSettingsValue<TileType>>, ITableFilter>,
    name: string,
  ) => {
    deleteTileSettingKey(settingsKey, name as KeyOfSetting<TileType>)
    tableFilterProps.deleteActiveFilter(name)
  }, [tileSettings.value, tableFilterProps.deleteActiveFilter, updateTileSettings])

  return {
    tileSettings,
    updateTileSettings,
    applyFiltersAndClose,
    tableFilterProps,
    popupRef,
    deleteActiveFilter,
  }
}
