/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'

const LoaderWrapper = styled.div`
  margin: 0px 15px;
  @keyframes load-spin {
    to {
      transform: rotate(1turn);
    }
  }
`

interface MiniLoaderProps {
  $size?: string
}

const Spinner = styled.div<MiniLoaderProps>(({ $size }) => css`
  width: ${$size ?? '1.8rem'};
  height: ${$size ?? '1.8rem'};
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #362FB7);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  animation: load-spin 1s infinite linear;
`)

export const MiniLoader = ({ $size }: MiniLoaderProps): JSX.Element => (
  <LoaderWrapper>
    <Spinner
      $size={$size}
      title='mini loader'
    />
  </LoaderWrapper>
)
