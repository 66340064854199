import { IChildrenProps } from '@dataplace.ai/types'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import React from 'react'

export const NoPrint = ({ children }: IChildrenProps): JSX.Element | null => {
  const { isPrinting } = useCheckIfPrinting()
  return isPrinting
    ? null
    : <>{children}</>
}
