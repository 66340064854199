import styled, { css } from 'styled-components'
import { ManagePotentialModelParams } from '../../../../molecules/ManagePotentialModelParams'

export const StyledManagePotentialModelParams = styled(ManagePotentialModelParams)`
  margin-top: 1.75rem;
`

export const DropdownOptionDiv = styled.div`
  display: flex;
  cursor: pointer;
`

export const Wrapper = styled.div<{ background: 'light' | 'dark' }>(({
  theme: {
    palette,
    typography,
  },
  background,
}) => css`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  background-color: ${background === 'light' ? palette.light.white : palette.light.main};
  > p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    text-transform: uppercase;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_semibold.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
  }
`)

export const Title = styled.h4(({
  theme: {
    palette,
    typography,
  },
}) => css`
  font-weight: 500;
  font-size: ${typography.main.pt_15_semibold.fontSize};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
  letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
  color: ${palette.black};
`)

export const ChartTitle = styled.h5(({
  theme: {
    palette,
    typography,
  },
}) => css`
  font-weight: 500;
  font-size: ${typography.main.pt_15_semibold_upper.fontSize};
  line-height: ${typography.main.pt_15_semibold_upper.lineHeight};
  letter-spacing: ${typography.main.pt_15_semibold_upper.letterSpacing};
  text-transform: ${typography.main.pt_15_semibold_upper.textTransform};
  color: ${palette.black};
`)

export const TypeRangeWrapper = styled.div(({
  theme: {
    palette,
    typography,
    corners,
  },
}) => css`
  display: flex;
  flex-direction: column;
  background: ${palette.light.white};
  border: 1.5px dashed ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
  padding: 1rem;
  color: ${palette.black};
  margin-top: 1.5rem;
  
  > p {
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  }
`)

export const DropdownWrapper = styled.div(({
  theme: {
    palette,
    typography,
  },
}) => css`
  color: ${palette.dark.normal};
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_regular.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
  margin-top: 1.75rem;

  :first-of-type {
    margin-right: 1rem;
  }
`)

export const Estimation = styled.div(({
  theme: {
    palette,
    typography,
  },
}) => css`
  padding-bottom: 1.875rem;
  margin: 1.875rem 0;
  border-bottom: 1px solid ${palette.light.darkest};

  > p {
    font-size: ${typography.main.pt_15_semibold.fontSize};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
    color: ${palette.black};

    > strong {
      font-size: ${typography.big.pt_28_semibold.fontSize};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
      margin-left: 0.5rem;
    }
  }

  > span {
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  }
`)

export const Section = styled.div(({
  theme: {
    palette,
    corners,
  },
}) => css`
  display: flex;
  flex-direction: column;
  color: ${palette.black};
  background-color: ${palette.light.white};
  border: 1px solid ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
  gap: 0.5rem;
  padding: 1.25rem;
  margin-bottom: 1.875rem;
`)

export const SectionDescription = styled.p(({ theme: { typography } }) => css`
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
`)

export const TagButtonWrapper = styled.div(() => css`
  width: 100%;
  display: flex;
  justify-content: flex-end; 
  margin-top: 6.25rem;
`)
