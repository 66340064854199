import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from '@dataplace.ai/features'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { deleteTileAction, saveNewRangeAction } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { Loader } from '@dataplace.ai/ui-components/atoms'
import { useFetchTileData } from 'apps/placeme/src/customHooks/useFetchTileData'
import { AnwPotentialModelForm } from '../AnwPotentialModelForm/AnwPotentialModelForm'
import { DataType, FormFieldValue } from '../@types/IAnwPotentialModelTileData'
import { AnwPotentialModelResult } from '../AnwPotentialResult/AnwPotentialModelResult'
import { InitialFormState } from '../AnwPotentialModel/AnwPotentialModel'
import { SettingsListElement } from '../../../Mcd/Settings'
import { PotentialLabels } from '../AnwPotentialModel/hooks/resultData/data'
import { FormDataObject } from '../AnwPotentialModel/hooks/formData/useFormFieldsData'
import { InitialFormStateDemo } from '../DemoScoringPrediction/DemoScoringPrediction'
import { FormDataObjectDemo } from '../DemoScoringPrediction/hooks/formData/useFormFieldsData'

type FormStateType = InitialFormState | InitialFormStateDemo

interface IAnwPotentialModelTile {
  data: DataType
  tileId: string
  body: unknown
  categoryId: string
  endpoint: string
  areAllFieldsFilled: boolean
  formState: FormStateType
  settingList: SettingsListElement[]
  labels: PotentialLabels
  formDataObject: FormDataObject | FormDataObjectDemo
  setFormValue: (key: string, value: FormFieldValue) => void
}

export const AnwPotentialModelTile: React.FC<IAnwPotentialModelTile> = ({
  data,
  endpoint,
  tileId,
  body,
  categoryId,
  areAllFieldsFilled,
  settingList,
  labels,
  formDataObject,
  setFormValue,
}) => {
  const authContext = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const {
    analyseId, value: { address } = {},
  } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)

  const [accepted, setAccepted] = useState<boolean>(false)
  const [canBeRequest, setCanBeRequest] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')

  const rangeValue = 250

  const { fetchData } = useFetchTileData(
    data,
    endpoint,
    tileId,
    categoryId,
    body,
    setCanBeRequest,
  )

  const handleSave = () => {
    setCanBeRequest(true)
    fetchData()
  }

  const handleDeleteTile = useCallback(() => {
    dispatch(deleteTileAction(token, categoryId, tileId, analyseId || ''))
  }, [dispatch, token, categoryId, tileId, analyseId])

  const tile = useMemo(() =>
    values?.find(category => category.id === categoryId)?.tiles.find(tile => tile.id === tileId),
  [values])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (tile?.data?.value || !token || !areAllFieldsFilled || tile?.chosenRange?.catchmentId) return

    dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', categoryId, tileId, {
      id: `${tileId}-${rangeValue}-line`,
      value: rangeValue,
      type: 'line',
    }))
  }, [token, canBeRequest])

  useEffect(() => {
    if (!data?.value && canBeRequest) {
      handleSave()
    }
  }, [!data?.value, tile?.chosenRange?.catchmentId, canBeRequest])

  if (canBeRequest && (data?.loading || !data?.value)) return <Loader />

  return (
    (!accepted && !canBeRequest) && !data?.value
      ? (
        <AnwPotentialModelForm
          accepted={accepted}
          areAllFieldsFilled={areAllFieldsFilled}
          categoryId={categoryId}
          formDataObject={formDataObject}
          handleDeleteTile={handleDeleteTile}
          handleSubmit={handleSave}
          setFormValue={setFormValue}
        />
      )
      : (
        <AnwPotentialModelResult
          address={address}
          categoryId={categoryId}
          data={data}
          labels={labels}
          setAccepted={setAccepted}
          settingList={settingList}
          tileId={tileId}
        />
      )
  )
}
