import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { demoId } from '@dataplace.ai/constants'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { FormFieldValue, IDemoPotentialModelTileData } from '../@types/IAnwPotentialModelTileData'
import { AnwPotentialModelTile } from '../AnwPotentialTile/AnwPotentialModelTile'
import { labels } from './hooks/resultData/data'
import { useDemoSelectedSettings } from './hooks/resultData/useDemoSelectedSettings.tsx'
import { useFormFieldsData } from './hooks/formData/useFormFieldsData'

export enum FormFieldsDemo {
  selfCheckoutsNumber = 'selfCheckoutsNumber',
  salesArea = 'salesArea',
  shopType = 'shopType',
  availablePremiumStock = 'availablePremiumStock',
  pylon = 'pylon',
  openSaturdays = 'openSaturdays',
  pickupPoint = 'pickupPoint',
  expressway = 'expressway',
  competitiveStore = 'competitiveStore',
  restaurant = 'restaurant',
  discountStore = 'discountStore',
}

export interface InitialFormStateDemo {
  selfCheckoutsNumber: number | null;
  salesArea: string | null;
  shopType: string | null;
  availablePremiumStock: string | null;
  pylon: boolean | null;
  openSaturdays: boolean | null;
  pickupPoint: boolean | null;
  expressway: boolean;
  competitiveStore: boolean;
  restaurant: boolean;
  discountStore: boolean;
  [key: string]: number | string | boolean | null;
}

const initialStateDemo = {
  selfCheckoutsNumber: null,
  salesArea: null,
  shopType: null,
  availablePremiumStock: null,
  pylon: null,
  openSaturdays: null,
  pickupPoint: null,
  expressway: false,
  competitiveStore: false,
  restaurant: false,
  discountStore: false,
}

const requiredFieldsDemo = [
  FormFieldsDemo.selfCheckoutsNumber,
  FormFieldsDemo.salesArea,
  FormFieldsDemo.shopType,
  FormFieldsDemo.availablePremiumStock,
  FormFieldsDemo.pylon,
  FormFieldsDemo.openSaturdays,
  FormFieldsDemo.pickupPoint,
]

export const DemoScoringPrediction: React.FC<{
  data: IDemoPotentialModelTileData,
  tileId: string,
}> = ({
  data, tileId,
}) => {
  const { values } = useSelector((state: RootState) => state.analysis)
  const { value: { address } = {} } = useSelector((state: RootState) => state.location)

  const [formState, setFormState] = useState<InitialFormStateDemo>(initialStateDemo)

  const areAllFieldsFilled = useMemo(() =>
    requiredFieldsDemo.every(key => formState[key] !== null), [formState])

  const settingList = useDemoSelectedSettings(data)
  const formDataObject = useFormFieldsData(formState, areAllFieldsFilled)

  const setFormValue = useCallback((key: string, value: FormFieldValue) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }, [setFormState])

  const catchmentId = useMemo(() => values.find(cat => cat.id === demoId)?.tiles.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId, [values])

  const body = useMemo(() => ({
    catchmentId,
    address,
    selfCheckoutsNumber: formState.selfCheckoutsNumber,
    salesArea: formState.salesArea,
    shopType: formState.shopType,
    availablePremiumStock: formState.availablePremiumStock,
    pylon: formState.pylon,
    openSaturdays: formState.openSaturdays,
    pickupPoint: formState.pickupPoint,
    plannedAdditions: {
      expressway: formState.expressway,
      competitiveStore: formState.competitiveStore,
      restaurant: formState.restaurant,
      discountStore: formState.discountStore,
    },
  }), [formState, catchmentId, address])

  return (
    <AnwPotentialModelTile
      areAllFieldsFilled={areAllFieldsFilled}
      body={body}
      categoryId={demoId}
      data={data}
      endpoint={ENDPOINTS.DEMO_SCORING_PREDICTION}
      formDataObject={formDataObject}
      formState={formState}
      labels={labels}
      setFormValue={setFormValue}
      settingList={settingList}
      tileId={tileId}
    />
  )
}
