import React, { memo, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import { emails } from '@dataplace.ai/constants'
import { ReactComponent as CloseIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/add.svg'
import { ReactComponent as NoDataAvailableImage } from '../../../../../../../../libs/shared/assets/src/lib/images/noDataAvailable.svg'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2.5rem 2.5rem 0.625rem 2.5rem;
  `
})

const TopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 1.875rem;
`
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1.875rem;
`

const IconWrapper = styled.div(() => css`
    margin: 0 2.5rem 2.5rem 0;
    width: 90%;
    > svg {
      margin: 1.25rem auto;
    }
`)

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Heading = styled.h2(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
    `
})
const Description = styled.h2(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      max-width: 30rem;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-bottom: 2.5rem;
      `
})

export interface IDataNotAvailableModalState {
  heading: string
  text: string
}

interface IDataNotAvailable {
  state: IDataNotAvailableModalState | null,
  setModalState: (state: IDataNotAvailableModalState | null) => void
}

export const DataNotAvailableModal = memo(({
  state, setModalState,
}: IDataNotAvailable):JSX.Element => {
  const [open, setOpen] = useState(false)
  const closeModal = () => {
    setOpen(false)
    setModalState(null)
  }
  const { t } = useTranslation()
  const { language } = window.navigator

  useEffect(() => {
    setOpen(!!state)
  }, [state])

  return (
    <>
      <PopupWithOverlay
        closeOnDocumentClick={false}
        modal
        onClose={closeModal}
        open={open}
      >
        <Wrapper>
          <TopWrapper>
            <HeaderWrapper>
              <Heading>{state?.heading}</Heading>
              <CloseIcon
                height='18'
                onClick={closeModal}
                style={{
                  transform:'rotate(45deg)',
                  cursor: 'pointer',
                }}
                width='18'
              />
            </HeaderWrapper>
            <Description>{state?.text}</Description>
          </TopWrapper>
          <IconWrapper>
            <NoDataAvailableImage />
          </IconWrapper>
          <BottomWrapper>
            <Button
              href={language?.includes('pl') ? `mailto:${emails.sales_pl}` : `mailto:${emails.sales_en}`}
              margin='0 0 1.25rem 0'
            >
              {t('generic.contact_us')}
            </Button>
          </BottomWrapper>
        </Wrapper>
      </PopupWithOverlay>
    </>
  )
})
