import { IMapLocationProps } from '@dataplace.ai/ui-components/atoms'

interface BuildingsParams {
  schools?: number
  hotels?: number
  cultural?: number
  offices?: number
  residential?: number
}

interface TrafficParams {
  pedestrian?: number
  car?: number
}

interface PoisParams {
  chainStoreDistance?: number
  restaurants?: number
  gasStation?: number
  hardwareStore?: number
  discounts?: number
  dolciSaporiRailwaySchoolsAdministration?: number
  dolciSaporiGroceryDrugstoresNonfoodDiscount?: number
  dolciSaporiSchoolsDormitoriesAdministration?: number
  dolciSaporiGroceryHardwareAlcoholStores?: number
  dolciSaporiHardwareStores?: number
  dolciSaporiFastfood?: number
  dolciSaporiRestaurants?: number
}

interface BehavioralParams {
  heavyFastFoodUsers?: number
  partyAnimals?: number
}

interface PopulationParams {
  population?: number
  demography?: number
  income?: number
}

export interface Parameters {
  buildings: BuildingsParams
  traffic: TrafficParams
  pois: PoisParams
  behavioral: BehavioralParams
  sociodemographic?: PopulationParams
}
export interface FormFields {
  schools?: number
  hotels?: number
  cultural?: number
  offices?: number
  residential?: number
  population?: number
  demography?: number
  income?: number
  pedestrian?: number
  car?: number
  chainStoreDistance?: number
  restaurants?: number
  gasStation?: number
  hardwareStore?: number
  discounts?: number
  heavyFastFoodUsers?: number
  partyAnimals?: number
  dolciSaporiSchoolsDormitoriesAdministration?: number
  dolciSaporiFastfood?: number
  dolciSaporiRestaurants?: number
  dolciSaporiGroceryDrugstoresNonfoodDiscount?: number
  dolciSaporiHardwareStores?: number
  dolciSaporiRailwaySchoolsAdministration?: number
  dolciSaporiGroceryHardwareAlcoholStores?: number
}

export interface FormElement {
  fieldId: string
  title: string
  description: string
}

export interface FormsElements {
  category: string
  title: string
  catColor: string[]
  elements: FormElement[]
}

// RESULT TYPES

export interface Location {
  id: number;
  address: string;
  score: number;
}

interface Ranking {
  places: {
    current: number;
    lowest: number;
  };
  list: Location[];
}

interface Metric {
  [key: string]: number;
}

export interface SimilarLocation {
  address: string
  similarity: {
    total: number
    metric: Metric
  }
}

export interface SubCategory {
  value: number
  mean: {
    value: number
    deviation: number
  }
  rank: number
  scoreShare: number
}

export interface ResponseParameters {
  [key: string]: SubCategory
}

export interface PotentialModel {
  score: number
  parameters: ResponseParameters
  ranking: Ranking
  similarLocations: SimilarLocation[]
}

export interface TileData<T> {
  loading: boolean
  error: string
  value: T
  mapLocation?: IMapLocationProps
  comparedMapLocation?: IMapLocationProps
}

export interface SettingsListElement {
  label: string,
  value: string | boolean | number | null | undefined,
  visible: string | boolean | number | null | undefined,
}
export interface Settings {
  locationType: string
  parameters: Parameters
}

export type DataType = TileData<{
  potentialModel: PotentialModel
  settings: Settings
}>

export const LocationTypeOptions = {
  HIGH_STREET: 'high_street',
  RETAIL_PARK: 'retail_park',
  LOCAL: 'local',
  DOLCI_SAPORI_SMALL_TOWN: 'dolci_sapori_small_town',
  DOLCI_SAPORI_PH: 'dolci_sapori_ph',
  DOLCI_SAPORI_HIGH_STREET: 'dolci_sapori_high_street',
} as const

export type LocationTypeOptionsType = (typeof LocationTypeOptions)[keyof typeof LocationTypeOptions]
