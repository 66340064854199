import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

interface SegmentProps {
  $width: number
  $color: string
  $invalidData: boolean
}

export interface HorizontalSegmentedElement {
  fieldColor: string,
  value: number,
}

export interface HorizontalSegmentedChartProps {
  data: HorizontalSegmentedElement[]
  message: string
  invalidData: boolean
}

const HorizontalSegmented = styled.div(() => css`
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`)

const BarContainer = styled.div(() => css`
  display: flex;
  width: 100%;
  height: 25px;
  border-radius: 10px;
  overflow: hidden;
  margin: 5px 0 15px 0;
`)

const Segment = styled.div<SegmentProps>(({
  $width,
  $color,
  $invalidData,
}) => css`
  width: ${$width}%;
  background-color: ${$color};
  font-size: 14px;
  white-space: nowrap;
  ${$invalidData && css`opacity: 0.2;`}
`)

const ValidationInfo = styled.div((
  {
    theme:
    {
      typography,
      palette,
    },
  },
) => css`
  font-size: ${typography.tiny.pt_11_regular.fontSize};
  font-weight: ${typography.tiny.pt_11_regular.fontWeight};
  line-height: ${typography.tiny.pt_11_regular.lineHeight};
  color: ${palette.results.red.dark};
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: absolute;
  top: -2px;
  z-index: 1;
`)

export function HorizontalSegmentedChart({
  data,
  message,
  invalidData,
}: HorizontalSegmentedChartProps): JSX.Element {
  const total = useMemo(() => data.reduce((acc, { value }) => acc + value, 0), [data])

  return (
    <HorizontalSegmented>
      {invalidData
        ? <ValidationInfo>{message}</ValidationInfo>
        : null}
      <BarContainer>
        {data.map((el) => (
          <Segment
            key={el.fieldColor}
            $color={el.fieldColor}
            $invalidData={invalidData}
            $width={(el.value / total) * 100}
          />
        ))}
      </BarContainer>
    </HorizontalSegmented>
  )
}

export default HorizontalSegmentedChart
