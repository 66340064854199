import { AvoidPrintPageBreak, DoughnutChart } from '@dataplace.ai/ui-components/atoms'
import BubbleChart from 'apps/placeme/src/components/molecules/PlacemeNavbar/components/BubbleChart/BubbleChart'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import ComparedInfo from './ComparedInfo'
import useBuildingTypeMappings from './hooks/useBuildingTypeMappings'
import { DistributionKeys } from './@types/IBuildingsTileData'

interface BuildingsChartsProps {
  compared?: boolean
  showComparedAddress: boolean
  density: number
  distribution: {
    residental?: number
    commercialAndServices?: number
    public?: number
    other?: number
  }
  distributionArea: {
    residental?: number
    commercialAndServices?: number
    public?: number
    other?: number
  },
}

interface ReduceResult {
  chartData: number[]
  sortedColors: string[]
  sortedLabels: string[]
  distributionValues: number[]
  doughnutLabels: string[]
}

const SubTitle = styled.p(({ theme: { palette } }) => css`
  font-size: 0.75rem;
  margin-bottom: 2rem;
  color: ${palette.black};
`)

const Title = styled.h2(({ theme: { palette } }) => css`
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 500;
  color: ${palette.black};
  border-top: 1px solid ${palette.light.darker};
  padding-top: 1.5rem;
`)

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ChartContainer = styled.div`
  padding: 1rem 0;
  position: relative;
`

const Description = styled.p`
  font-size: 1rem;
  position: absolute;
  top: 185px;
  left: 100px;
  max-width: 35%;
  display: flex;
  flex-direction: column;
  z-index: 1;
`

const LegendWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
`

const Legend = styled.p(({ theme: { palette } }) => css`
  color: ${palette.gray.lighter};
  text-transform: uppercase;
  font-size: 0.85rem;
`)

const LegendElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`

const Line = styled.span<{color: string}>(({ color }) => css`
  width: 70px;
  height: 5px;
  background-color: ${color};
  margin-bottom: 10px;
`)

const BuildingsCharts: React.FC<BuildingsChartsProps> = ({
  density,
  distribution,
  distributionArea,
  compared = false,
  showComparedAddress,
}) => {
  const { t } = useTranslation()
  const { buildingTypeMappings } = useBuildingTypeMappings()

  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  const densityValue = density * 100
  const remainingValue = 100 - densityValue

  const buildingTypes = Object.keys(buildingTypeMappings) as DistributionKeys[]

  const {
    chartData,
    sortedColors,
    sortedLabels,
    distributionValues,
    doughnutLabels,
  } = useMemo(() => {
    const result: ReduceResult = {
      chartData: [remainingValue],
      sortedColors: [defaultTheme.palette.lightGrey],
      sortedLabels: [],
      distributionValues: [],
      doughnutLabels: [t('placeme.buildings.types.without_buildings')],
    }

    buildingTypes.forEach((type) => {
      const distributionValue = Math.round((distribution[type] ?? 0) * 100)
      const distributionAreaValue = Math.round((distributionArea[type] ?? 0) * 100)

      result.chartData.push(distributionAreaValue)
      result.sortedColors.push(buildingTypeMappings[type].color)
      result.sortedLabels.push(buildingTypeMappings[type].label)
      result.distributionValues.push(Math.round(distributionValue))
      result.doughnutLabels.push(buildingTypeMappings[type].label)
    })

    return result
  }, [distribution, distributionArea, buildingTypeMappings, remainingValue])

  return (
    <AvoidPrintPageBreak>
      {showComparedAddress
        ? (
          <ComparedInfo address={compared ? comparedLocation?.location?.address : value?.address} />
        )
        : null}
      <Title>{t('placeme.buildings.header.buildings_type')}</Title>
      <Wrapper>
        <ChartContainer>
          <SubTitle>{t('placeme.buildings.span.buildings_type')}</SubTitle>
          <BubbleChart
            colorsArray={sortedColors}
            sortedLabels={sortedLabels}
            valuesArray={distributionValues}
          />
        </ChartContainer>
        <ChartContainer>
          <SubTitle>{t('placeme.buildings.span.buildings_coverage')}</SubTitle>
          <DoughnutChart
            colors={sortedColors}
            cutoutPercentage={75}
            data={chartData}
            height={300}
            labels={doughnutLabels}
            valueType='percentages'
            width={300}
          />
          <Description>
            <strong>{`${Math.round(densityValue)}% `}</strong>
            {t('placeme.buildings.chart.inside_text')}
          </Description>
        </ChartContainer>
      </Wrapper>
      <LegendWrapper>
        {sortedLabels.map((label, index) => (
          <LegendElement key={label}>
            <Line color={sortedColors[index + 1]} />
            <Legend>{label}</Legend>
          </LegendElement>
        ))}
      </LegendWrapper>
    </AvoidPrintPageBreak>
  )
}

export default BuildingsCharts
