import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{[key: string]: unknown}>(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 396px;
    `,
)

interface ILoaderProps {
  product?: string
  svgProps?: React.DetailedHTMLProps<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement>
  wrapperProps?: React.ComponentProps<typeof Wrapper>
}

export const Loader: React.FC<ILoaderProps> = ({
  product, svgProps, wrapperProps,
}) :JSX.Element => (
  <Wrapper {...wrapperProps}>
    <object
      data={`assets/loaders/${product || 'placeme'}/small.svg`}
      type='image/svg+xml'
      width='110'
      {...svgProps}
    >
      svg-animation
    </object>
  </Wrapper>
)
