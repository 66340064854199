import React from 'react'
import styled from 'styled-components'

type InfoLabelWrapperProps = {
  double?: boolean
}

const InfoLabelWrapper = styled.div<InfoLabelWrapperProps>`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    color: ${({ double }) => (double ? 'black' : 'white')};
    background-color: ${({ color }) => color};
    border-radius: 8px;
    font-weight: 600;
    padding: 10px 18px;

    h5{
        margin:0;
        font-size: 11px;
        text-transform: ${({ double }) => (double ? 'none' : 'uppercase')}
    }

    h2{
        font-size: 20px;
        margin:0;
        margin-top: 3px;
    }
`

export interface IInfoLabelProps {
  color:string;
  double?: boolean
  firstTitle: string
  secondTitle?: string
}

export function InfoLabel({
  firstTitle, secondTitle, double, color,
}: IInfoLabelProps):JSX.Element {
  return (
    <InfoLabelWrapper
      color={color}
      double={double}
    >
      <h5>{firstTitle}</h5>
      <h2>{secondTitle}</h2>
    </InfoLabelWrapper>
  )
}

export default InfoLabel
