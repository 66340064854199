/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import NewComparedLocationHeader from '@placeme/components/atoms/ComparedLocationHeader/NewComparedLocationHeader'
import { Bean } from '@dataplace.ai/ui-components/molecules'
import { ILocation } from '@dataplace.ai/types'
import { IChooseLocationSlice } from 'apps/placeme/src/features/ChooseLocationReport/@types/IChooseLocationSlice'
import { StyledBigTitle, StyledDescription, StyledHeader, TextContainer, TextRowContainer } from '../Investments.styles'
import { CommercialAndServicesData } from '../@types/IInvestmentsTileData'
import InvestmentsList from './InvestmentsList'
import { investmentDeviationTypes, TOOLTIP_MAX_WIDTH } from '../utils/constants'
import { getDeviation } from '../utils/functions'
import useGetDeviationTranslations from '../hooks/useGetDeviationTranslations'
import { NoInvestments } from './NoInvestments'

interface CommercialAndServicesProps {
  commercialAndServices?: CommercialAndServicesData
  comparedCommercialAndServices?: CommercialAndServicesData
  locationData: IChooseLocationSlice['value']
  comparedLocationData?: ILocation
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function CommercialAndServices({
  commercialAndServices, comparedCommercialAndServices, locationData, comparedLocationData,
}: CommercialAndServicesProps) {
  const { t } = useTranslation()
  const { getDeviationTranslation } = useGetDeviationTranslations()

  const deviations = useMemo(() => {
    if (!commercialAndServices?.list.length || !comparedCommercialAndServices?.list.length) return

    return {
      investments: getDeviation(commercialAndServices.list.length, comparedCommercialAndServices.list.length),
    }
  }, [commercialAndServices, comparedCommercialAndServices, getDeviation])

  const deviationTranslation = useMemo(() => {
    if (!deviations) return

    return {
      investments: getDeviationTranslation({
        deviation: deviations.investments,
        deviationType: investmentDeviationTypes.commercialAndServicesInvestment,
      }),
    }
  }, [deviations])

  return (
    <>
      <TextContainer>
        <TextRowContainer>
          <StyledHeader $semiBold>{t('placeme.investments.commercial_and_services_potential').toUpperCase()}</StyledHeader>
        </TextRowContainer>
        <StyledDescription $semiBold>{t('placeme.investments.commercial_and_services_potential.description')}</StyledDescription>
      </TextContainer>
      {commercialAndServices?.list.length
        ? (
          <>
            <TextContainer>
              <TextRowContainer>
                <StyledHeader>{t('placeme.investments.num_of_investments')}</StyledHeader>
                <StyledBigTitle $semiBold>{commercialAndServices.list.length}</StyledBigTitle>
                {deviations
                  ? (
                    <Bean
                      tooltipMaxWidth={TOOLTIP_MAX_WIDTH}
                      tooltipText={deviationTranslation?.investments}
                      value={deviations.investments}
                    />
                  )
                  : null}
              </TextRowContainer>
              <StyledDescription>{t('placeme.investments.num_of_commercial_investments.description')}</StyledDescription>
            </TextContainer>
            {comparedCommercialAndServices?.list.length
              ? <NewComparedLocationHeader address={locationData?.address} />
              : null}
            <InvestmentsList
              commonInvestments={commercialAndServices.list}
              title={t('placeme.investments.commercial_and_services.new_buildings_list')}
            />
          </>
        )
        : <NoInvestments />}
      {comparedCommercialAndServices?.list.length
        ? (
          <>
            <NewComparedLocationHeader
              address={comparedLocationData?.address}
              compared
            />
            <InvestmentsList
              commonInvestments={comparedCommercialAndServices.list}
              title={t('placeme.investments.commercial_and_services.new_buildings_list')}
            />
          </>
        )
        : null}
    </>
  )
}

export default CommercialAndServices
