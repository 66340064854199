import React, { useMemo } from 'react'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PieChartSection from './PieChartSection'
import ComparedInfo from '../ComparedInfo'
import { differentCategories } from '../utils/constants'
import { BuildingTypeCategory, CategoryDetailsType, CategoryKeys, DistributionKeys, IBuildingDetails } from '../@types/IBuildingsTileData'
import { useGetBuildingsTexts } from '../hooks/useGetBuildingsTexts'
import { useGenerateLabels } from '../hooks/useGenerateLabels'

const StyledSpan = styled.span`
  font-weight: 700;
  font-size: 1rem;
  &::first-letter {
    text-transform: uppercase;
  }
`

const generateTemplate = (columnsCount: number) => `1fr repeat(${columnsCount}, 1fr)`

interface PieAndTableProps {
  category: CategoryDetailsType
  categoryName: DistributionKeys
  showComparedAddress?: boolean
}

const PieAndTable: React.FC<PieAndTableProps> = ({
  category, categoryName, showComparedAddress = false,
}) => {
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  const {
    categories,
    dominantTypeInfo,
  } = useGetBuildingsTexts({
    dominantType: categoryName,
  })
  const {
    pieLabels,
    tableLabels,
  } = useGenerateLabels(category)

  const pieChartData = useMemo(() => Object.keys(category)
    .filter(key => key !== differentCategories.overall && key !== differentCategories.mostCommonType)
    .map(key => ({
      key: key as CategoryKeys,
      value: Math.round(((category[key] as IBuildingDetails)?.shareInType || 0) * 100),
    })), [category])

  const pieDataWithLabels = useMemo(() => pieChartData.map((data, index) => ({
    label: pieLabels[index],
    value: data.value,
  })), [pieChartData])
  pieDataWithLabels.sort((a, b) => b.value - a.value)

  const sortedPieChartData = pieDataWithLabels.map(item => item.value)
  const sortedPieLabels = pieDataWithLabels.map(item => item.label)

  const renderTableContent = (data: BuildingTypeCategory, dominantTypeText: string | undefined) => {
    const columns = Object.keys(data.overall) as (keyof IBuildingDetails)[]
    const rows = Object.keys(data).filter(key => key !== differentCategories.mostCommonType) as CategoryKeys[]

    const content = rows.map((row, index) => {
      const rowData = data[row]

      return [
        index === 0
          ? (
            <StyledSpan key={`${row}_type`}>{dominantTypeText || categories[row]}</StyledSpan>
          )
          : (
            <span key={`${row}_type`}>{categories[row]}</span>
          ),
        ...columns.map(column => (
          <span key={`${row}_${column}`}>{rowData?.[column]}</span>
        )),
      ]
    })

    return content
  }

  const tableContent = useMemo(() =>
    renderTableContent(category, dominantTypeInfo?.text), [category, dominantTypeInfo, renderTableContent])
  const columnsCount = useMemo(() => Object.keys(category.overall).length + 1, [category])
  const tableTemplate = useMemo(() => generateTemplate(columnsCount), [columnsCount])

  return (
    <>
      {!!comparedLocation?.location?.address !== false
        ? (
          <ComparedInfo
            address={showComparedAddress
              ? comparedLocation?.location?.address
              : value?.address}
          />
        )
        : null}
      {categoryName !== differentCategories.other
        ? (
          <PieChartSection
            buildingsTypes={sortedPieChartData}
            labels={sortedPieLabels}
            mostCommonType={category.mostCommonType as CategoryKeys}
            type={categoryName}
          />
        )
        : null}
      <Table
        buildings
        content={tableContent}
        gap='0.5rem'
        headerTemplate={tableTemplate}
        labels={tableLabels}
        rowTemplate={tableTemplate}
        whiteSpaces='brak-spaces'
      />
    </>
  )
}

export default PieAndTable
