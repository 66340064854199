import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@dataplace.ai/ui-components/atoms'
import { debounce } from '@dataplace.ai/functions/utils'
import { ReactComponent as CloseIcon } from 'libs/shared/assets/src/lib/icons/close.svg'
import { useComponentVisible } from '@dataplace.ai/functions/hooks/useComponentVisible'

import { ReactComponent as ArrowDown } from '@dataplace.ai/assets/lib/icons/arrows/arrDown.svg'
import { DropdownContent, DropdownHeader, DropdownHeaderBox, DropdownWrapper, ListItem, NoItemsToDisplay, SelectionBox, SelectionsWrapper, Wrapper } from './MultipleChoiceDropdown.styles'

export interface IChoiceObject {
  id: string
  name: string
  checked: boolean
  listItemName?: string | JSX.Element
  choices?: []
}

interface IMultipleChoiceDropdownProps {
  choices: IChoiceObject[]
  selectAll?: {
    text: string
  }
  onChange(areas: string[]): void
  width?: string
  label?: string
  subLabel?: string
  placeholder?: string
  icon?: JSX.Element
  style?: React.CSSProperties
  transparent?: boolean
  noArrow?: boolean
  noBorder?: boolean
  className?: string
  header?: boolean
}

export const MultipleChoiceDropdown: React.FC<IMultipleChoiceDropdownProps> = ({
  choices,
  onChange,
  width,
  placeholder,
  selectAll,
  style,
  transparent,
  noBorder,
  noArrow,
}): JSX.Element => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [choicesArray, setChoicesArray] = useState(choices)
  const [selectedItems, setSelectedItems] = useState<IChoiceObject[]>(choices?.filter(choice => choice.checked))
  const [inputValue, setInputValue] = useState('')
  const [placeholderValue, setPlaceholderValue] = useState(placeholder)
  const [allItemsSelected, setAllItemsSelected] = useState(false)
  const headerRef = useRef<HTMLDivElement>(null)
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)

  const toggleDropdown = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  const toggleChoice = (id: string) => {
    setInputValue(id)
    inputRef?.current?.focus()
    if (allItemsSelected) {
      setSelectedItems([])
      setAllItemsSelected(false)
    }
    const newChoice = choicesArray.find(choice => choice?.id === id)
    if (!newChoice) { return }
    const newChoices = selectedItems.find(item => item.id === newChoice.id)
      ? selectedItems.filter(choice => choice.id !== id)
      : [...selectedItems, newChoice]
    setSelectedItems(newChoices)
    setAllItemsSelected(newChoices?.length === choicesArray?.length)
    onChange(newChoices.map(choice => choice.id))
  }

  const toggleSelectAllItems = () => {
    if (!selectAll) { return }
    setAllItemsSelected(!allItemsSelected)
    setSelectedItems(allItemsSelected ? [] : choicesArray)
    onChange(choicesArray.map(choice => choice.id))
  }

  useEffect(() => {
    if (!choices) { return }
    setChoicesArray(choices)
  }, [choices])

  useEffect(() => {
    setPlaceholderValue(allItemsSelected
      ? selectAll?.text
      : selectedItems?.length
        ? choicesArray?.filter(choice => selectedItems?.find(item => item.id === choice?.id))
          ?.map(choice => choice?.name)
          ?.join(', ')
        : placeholder)
  }, [selectedItems])

  useEffect(() => {
    const handleResize = () => inputRef?.current?.clientWidth
    handleResize()
    const handleResizeDebounced = debounce(handleResize, 10)
    window.addEventListener('resize', handleResizeDebounced)
    return () => window.removeEventListener('resize', handleResizeDebounced)
  }, [])

  const wrapperWidth = useMemo(() => (width
    ? (`${parseFloat(width) + 4}rem`)
    : undefined
  ), [width])

  return (
    <Wrapper
      width={wrapperWidth}
    >
      <DropdownWrapper
        ref={ref}
        style={style}
      >
        <DropdownHeader
          ref={headerRef}
          active={isComponentVisible}
          noBorder={noBorder}
          onClick={toggleDropdown}
          transparent={transparent}
          width={width}
        >
          <DropdownHeaderBox>
            {placeholderValue}
          </DropdownHeaderBox>
          {!noArrow && (
            <ArrowDown
              className='arrow'
              height='6'
              width='10'
            />
          )}
        </DropdownHeader>
        <DropdownContent
          active={isComponentVisible}
          width={width}
        >
          {selectAll && choicesArray?.length && (
            <ListItem
              key='_select_all_'
              onClick={toggleSelectAllItems}
            >
              <Checkbox
                key='_select_all_checkbox_'
                checked={allItemsSelected}
                id='_select_all_checkbox_'
                readOnly
              />
              {selectAll.text || t('generic.all')}
            </ListItem>
          )}
          {(() => {
            if (choicesArray?.length) {
              const filteredArray = choicesArray.filter(choice => choice?.id?.includes(inputValue))
              return filteredArray.length
                ? choicesArray.map((choice) => (
                  <ListItem
                    key={choice?.id}
                    onClick={() => toggleChoice(choice?.id)}
                  >
                    <Checkbox
                      key={choice?.id}
                      checked={!!selectedItems.find(item => item.id === choice.id)}
                      id={choice?.id}
                      readOnly
                    />
                    {choice?.listItemName}
                  </ListItem>
                ))
                : <NoItemsToDisplay>{t('generic.table.no_items_to_display')}</NoItemsToDisplay>
            }
            return null
          })()}
        </DropdownContent>
      </DropdownWrapper>
      <SelectionsWrapper>
        {allItemsSelected
          ? (
            <SelectionBox key='__all__'>
              <span>{selectAll?.text}</span>
              <CloseIcon onClick={toggleSelectAllItems} />
            </SelectionBox>
          )
          : selectedItems?.map(item => (
            <SelectionBox key={item.id}>
              <span>{item?.listItemName}</span>
              <CloseIcon onClick={() => toggleChoice(item.id)} />
            </SelectionBox>
          ))}
      </SelectionsWrapper>
    </Wrapper>
  )
}
