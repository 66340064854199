import { ReactNode } from 'react'

const AUTH_URL = `${process.env.NX_WEBAPP3_URL}/auth`
const ACCOUNT_URL = `${process.env.NX_WEBAPP3_URL}/account`
const ACCOUNT_WORKSPACE_URL = `${ACCOUNT_URL}/workspace`
export const PLACEMEWA3_URL = `${process.env.NX_WEBAPP3_URL}/placeme`
export const WHITESPOT_URL = `${PLACEMEWA3_URL}/whitespot`
export const WHITESPOT_RESULT_URL = `${WHITESPOT_URL}/result`
export const REPORTS_WA3_URL = `${PLACEMEWA3_URL}/reports/all`

export const redirectToSignOut = ():void => {
  window.location.assign(`${AUTH_URL}/sign-out`)
}

export const redirectToSignIn = ():void => {
  window.location.assign(`${AUTH_URL}/sign-in`)
}

export const redirectToSignUp = (_ = ''):void => {
  window.location.assign(`${AUTH_URL}/sign-up`)
}

export const redirectToAccount = ():void => {
  window.location.assign(`${ACCOUNT_URL}/user`)
}

export const redirectToAccountUsers = ():void => {
  window.location.assign(`${ACCOUNT_WORKSPACE_URL}/users`)
}

export const redirectToAccountPackages = ():void => {
  window.location.assign(`${ACCOUNT_WORKSPACE_URL}/package`)
}

export const redirectToUsage = ():void => {
  window.location.assign(`${ACCOUNT_WORKSPACE_URL}/usage`)
}

export const redirectToAccountSettings = ():void => {
  window.location.assign(`${ACCOUNT_WORKSPACE_URL}/settings`)
}

export const redirectToReports = ():void => {
  window.location.assign(REPORTS_WA3_URL)
}

export const redirectToMyChain = (): void => {
  window.location.assign(`${process.env.NX_WEBAPP3_URL}/chain/kanban`)
}

// pricelist

export const redirectToAccountPricelist = ():void => {
  window.location.assign(`${ACCOUNT_WORKSPACE_URL}/packages/upgrade`)
}

export const redirectToPlaceme = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/account|auth|target/gi, 'placeme')
    window.location.replace(link)
  } else {
    window.location.replace('http://localhost:4202')
  }
}

export const redirectToPlacemeAnalyse = (analyseId: string):void => {
  if (!window.location.origin.includes('localhost')) {
    const link = `${window.location.origin.replace(/account|auth|target/gi, 'placeme')}/analyse/${analyseId}`
    window.location.replace(link)
  } else {
    window.location.replace(`http://localhost:4202/analyse/${analyseId}`)
  }
}

export interface IRedirect {
  workspaceId?: ReactNode | string
  status?: string;
}
