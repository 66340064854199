import React from 'react'
import { FormElement } from '@placeme/components/atoms/FormElement/FormElement'
import { FormElement as FormElementType, FormFieldValue } from '../@types/IAnwPotentialModelTileData'
import { ParametersWrapper } from '../AnwPotentialTile/AnwPotentialModelTile.styles'

export const FormElementWrapper: React.FC<{
  elements: FormElementType[],
  setFormValue: (key: string, value: FormFieldValue) => void,
  grid?: number
}> = ({
  elements,
  grid,
  setFormValue,
}) => (
  <ParametersWrapper $grid={grid}>
    {elements.map((element) => (
      <FormElement
        key={element.inputKey}
        dropdownWidth={element.width}
        formStateElement={element.formStateElement}
        inputKey={element.inputKey}
        options={element.options}
        setFormValue={setFormValue}
        title={element.title}
      />
    ))}
  </ParametersWrapper>
)
