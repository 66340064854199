import React from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { ButtonProduct } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { useTranslation } from 'react-i18next'

const StyledButtonProduct = styled(ButtonProduct)(({ theme }) => {
  const { palette } = theme
  return css`
  :focus{
    background: ${palette.blue};
    color: ${palette.light.white};
    border: 1px solid ${palette.dark.main};
  }
  `
})

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    min-width: 300px;
  `
})

const Header = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-bottom: 0.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_medium.fontSize};
    font-weight: ${typography.main.pt_15_medium.fontWeight};
    line-height: ${typography.main.pt_15_medium.lineHeight};
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    margin-bottom: 0.5rem;
    color: ${palette.results.red.dark};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

const Footer = styled.div(
  () => css`
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  `,
)

export interface ChosenRangeExistPopupProps {
  isOpen: boolean
  close: () => void
  tileType?: string
}

export const ChosenRangeExistPopup = ({
  close, isOpen, tileType,
}: ChosenRangeExistPopupProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Popup
      closeOnDocumentClick={false}
      modal
      onClose={close}
      open={isOpen}
      overlayStyle={defaultTheme.overlay}
    >

      <Wrapper>
        <Header>{tileType === 'gravity_model' ? t('placeme.chosen_range_exist_for_tile_type.gravity_model') : t('placeme.chosen_range_exist_for_tile_type')}</Header>
        <Content>{tileType === 'gravity_model' ? t('placeme.choose_another_range_gravity_model') : t('placeme.choose_another_range')}</Content>
        <Footer>
          <StyledButtonProduct
            onClick={close}
            small
          >
            {t('generic.ok')}
          </StyledButtonProduct>
        </Footer>
      </Wrapper>

    </Popup>
  )
}
