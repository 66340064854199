import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import React from 'react'

export const footTimeRanges: ResourceWithId[] = [
  {
    id: 'walk_0_4',
    content: <span>0 - 4 min</span>,
  },
  {
    id: 'walk_0_7',
    content: <span>0 - 7 min</span>,
  },
  {
    id: 'walk_0_11',
    content: <span>0 - 11 min</span>,
  },
  {
    id: 'walk_0_15',
    content: <span>0- 15 min</span>,
  },
  {
    id: 'walk_0_20',
    content: <span>0 - 20 min</span>,
  }]

export const carTimeRanges: ResourceWithId[] = [
  {
    id: 'car_0_4',
    content: <span>0 - 4 min</span>,
  },
  {
    id: 'car_0_7',
    content: <span>0 - 7 min</span>,
  },
  {
    id: 'car_0_11',
    content: <span>0 - 11 min</span>,
  },
  {
    id: 'car_0_15',
    content: <span>0 - 15 min</span>,
  },
  {
    id: 'car_0_20',
    content: <span>0 - 20 min</span>,
  }]

export const contentTimeRanges = [
  {
    id: 'car_0_4',
    content: '0 - 4 ',
  },
  {
    id: 'car_0_7',
    content: '0 - 7 ',
  },
  {
    id: 'car_0_11',
    content: '0 - 11 ',
  },
  {
    id: 'car_0_15',
    content: '0 - 15 ',
  },
  {
    id: 'car_0_20',
    content: '0 - 20 ',
  },
  {
    id: 'walk_0_4',
    content: '0 - 4 ',
  },
  {
    id: 'walk_0_7',
    content: '0 - 7 ',
  },
  {
    id: 'walk_0_11',
    content: '0 - 11',
  },
  {
    id: 'walk_0_15',
    content: '0 - 15 ',
  },
  {
    id: 'walk_0_20',
    content: '0 - 20 ',
  },
]
