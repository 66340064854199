/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AdditionalInfo } from '../Investments.styles'

interface ExpandableProps {
  investorName: string
  dateOfApplication?: string
  capacity?: number | string
  info?: string
  apartmentsCount?: string | number
  dateOfCompletion?: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Expandable({
  investorName, dateOfApplication, capacity,
  info, apartmentsCount, dateOfCompletion,
}: ExpandableProps) {
  const { t } = useTranslation()

  const isAValidString = useCallback((str: string) => (
    typeof str === 'string' && (str.toLowerCase() !== 'none' && str.toLocaleLowerCase() !== 'nan')
  ), [])

  return (
    <AdditionalInfo>
      <div>
        <div>
          <span>{`${t('placeme.investments_tile.investor_name')}:`}</span>
          <span>
            {isAValidString(investorName)
              ? investorName
              : '-'}

          </span>
        </div>
        {info
          ? (
            <div>
              <span>{`${t('placeme.investments_tile.info')}:`}</span>
              <span>{info}</span>
            </div>
          )
          : null}
      </div>
      <div>
        <div>
          {dateOfApplication
            ? (
              <>
                <span>{`${t('placeme.investments_tile.date_of_application')}:`}</span>
                <span>{dateOfApplication}</span>
              </>
            )
            : null}
          {dateOfCompletion
            ? (
              <>
                <span>{`${t('placeme.investments.investment_completion_date')}:`}</span>
                <span>{dateOfCompletion}</span>
              </>
            )
            : null}
        </div>
        <div>
          {capacity
            ? (
              <>
                <span>{`${t('placeme.investments_tile.capacity')}:`}</span>
                <span>
                  {typeof capacity === 'number' || isAValidString(capacity)
                    ? `${capacity} m3`
                    : '-'}
                </span>
              </>
            )
            : null}
          {apartmentsCount
            ? (
              <>
                <span>{`${t('placeme.buildings.list.number_of_apartments')}:`}</span>
                <span>{apartmentsCount}</span>
              </>
            )
            : null}
        </div>
      </div>
    </AdditionalInfo>
  )
}

export default Expandable
