import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ background: 'light' | 'dark' }>(({
  theme,
  background,
}) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
` })

export const TypeRangeWrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    background: ${palette.light.white};
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;
    color: ${palette.black};
    margin-top: 1.5rem;
` })

export const ParametersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
`

export const StyledWrapper = styled.div<{ width?: string }>(({ width }) => css`
  width: ${width};
`)

export const StyledSpan = styled.span`
  height: 3rem;
  display: grid;
  align-content: flex-end;
`

export const InputWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.dark.normal};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
    display: grid;
    gap: 0.5rem;
    margin-top: 1.75rem;
    :first-of-type {
        margin-right: 1rem;
    }
` })

export const DropdownWrapper = styled.div<{ maxWidth?: string }>(
  ({ maxWidth }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: ${maxWidth || null};
    
    > * {
        cursor: pointer;
    }
`,
)

export const DropdownWrapperBox = styled.div(({ theme }) => css`
  padding: 3px;
  :hover {
    font-weight: ${theme.typography.main.pt_15_medium.fontWeight};
  }
`)

export const DividerBox = styled.div`
  height: 3rem;
`
