import React, { useEffect, useMemo, useState } from 'react'
import { TileSectionIds } from '@dataplace.ai/constants'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'

import { PotentialWithinChainTile } from '../../Potential/PotentialWithinChain/PotentialWithinChainTile'
import useGetFormData from './hooks/useGetFormData'
import { DataType, FormFields, Parameters } from '../../Potential/PotentialWithinChain/@types/IPotentialWithinChain'
import { useGetFormFields } from '../../Potential/PotentialWithinChain/PotentialWithinChainData/hooks/formData/useGetFormFields'
import { useSelectedSettings } from '../../Potential/PotentialWithinChain/hooks/resultData/useSelectedSettings'

const initialState = {
  demography: 0,
  offices: 0,
  pedestrian: 0,
  car: 0,
  dolciSaporiRailwaySchoolsAdministration: 0,
  dolciSaporiGroceryDrugstoresNonfoodDiscount: 0,
  dolciSaporiSchoolsDormitoriesAdministration: 0,
  dolciSaporiGroceryHardwareAlcoholStores: 0,
  dolciSaporiHardwareStores: 0,
  dolciSaporiFastfood: 0,
  dolciSaporiRestaurants: 0,
} as const

interface DolSapPotentialWithinChainProps {
  data: DataType
  tileId: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DolSapPotentialWithinChain({
  data,
  tileId,
}: DolSapPotentialWithinChainProps) {
  const { dropdownElements } = useGetFormData()
  const {
    locationType, setLocationTypeId,
  } = useGetFormFields()
  const { values } = useSelector((state: RootState) => state.analysis)
  const { value: { address } = {} } = useSelector((state: RootState) => state.location)

  const [formState, setFormState] = useState<FormFields>(initialState)

  useEffect(() => {
    if (data?.value?.settings) {
      setLocationTypeId(data?.value?.settings.locationType)
    }
  }, [data, values])

  const { settingsList } = useSelectedSettings({
    settings: data?.value?.settings,
    locationType,
    dropdownElements,
  })

  const catchmentId = useMemo(() =>
    getTileInfo(tileId, TileSectionIds.DOL_SAP, values)?.chosenRange?.catchmentId, [tileId, values])

  const body = useMemo(() => {
    if (!locationType) return null
    const parameters: Parameters = {
      buildings: {
        offices: formState.offices,
      },
      traffic: {
        pedestrian: formState.pedestrian,
        car: formState.car,
      },
      pois: {
        dolciSaporiRailwaySchoolsAdministration: formState.dolciSaporiRailwaySchoolsAdministration,
        dolciSaporiGroceryDrugstoresNonfoodDiscount: formState.dolciSaporiGroceryDrugstoresNonfoodDiscount,
        dolciSaporiSchoolsDormitoriesAdministration: formState.dolciSaporiSchoolsDormitoriesAdministration,
        dolciSaporiGroceryHardwareAlcoholStores: formState.dolciSaporiGroceryHardwareAlcoholStores,
        dolciSaporiHardwareStores: formState.dolciSaporiHardwareStores,
        dolciSaporiFastfood: formState.dolciSaporiFastfood,
        dolciSaporiRestaurants: formState.dolciSaporiRestaurants,
      },
      sociodemographic: {
        demography: formState.demography,
      },
      behavioral: {},
    }

    return {
      catchmentId,
      locationType: locationType.id,
      parameters,
      address,
    }
  }, [formState, catchmentId, locationType])

  return (
    <PotentialWithinChainTile
      body={body}
      categoryId={TileSectionIds.DOL_SAP}
      data={data}
      endpoint={ENDPOINTS.DOL_SAP_POTENTIAL_WITHIN_CHAIN}
      formState={formState}
      locationType={locationType}
      options={dropdownElements}
      setFormState={setFormState}
      setLocationTypeId={setLocationTypeId}
      settingList={settingsList}
      tileId={tileId}
    />
  )
}

export default DolSapPotentialWithinChain
