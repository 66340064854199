import { useTranslation } from 'react-i18next'
import { IChoiceObject } from '@dataplace.ai/ui-components/organisms'
import { FormFields, InitialFormState } from '../../../McdPotentialModel'
import { useGetDropdownOptions } from './useGetDropdownOptions'

interface FormElement {
  formStateElement: string | number | boolean | null
  inputKey: FormFields
  options: {
    value: string | number | boolean
    label?: string
  }[]
  title: string
}

interface FormElementMulti {
  inputKey: FormFields
  options: IChoiceObject[]
  title: string
}

export interface FormDataObject {
  formElementData: { [key: string]: FormElement }
  multipleChoiceData: { [key: string]: FormElementMulti }
  additionalFormElementData?: { [key: string]: FormElement } | null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormFieldsData = (formState: InitialFormState, additionalFields: boolean) => {
  const { t } = useTranslation()
  const {
    locationTypeOptions,
    options,
    sizeOptions,
    locationAdditionsChoices,
  } = useGetDropdownOptions()

  const formElementData: {[key: string]: FormElement} = {
    locationType: {
      formStateElement: formState.locationType,
      inputKey: FormFields.locationType,
      options: locationTypeOptions,
      title: t('placeme.mcd_potential_model_tile.criteria.choose_location_type'),
    },
    plannedShoppingMall: {
      formStateElement: formState.plannedShoppingMall,
      inputKey: FormFields.plannedShoppingMall,
      options,
      title: t('placeme.mcd_potential_model_tile.criteria.if_future_shopping_mall'),
    },
  }

  const multipleChoiceData: {[key: string]: FormElementMulti} = {
    multipleChoices: {
      inputKey: FormFields.locationAdditions,
      options: locationAdditionsChoices,
      title: t('placeme.mcd_potential_model_tile.criteria.choose_location_additions'),
    },
  }

  const additionalFormElementData: {[key: string]: FormElement} = {
    plannedShoppingMallSize: {
      formStateElement: formState.plannedShoppingMallSize,
      inputKey: FormFields.plannedShoppingMallSize,
      options: sizeOptions,
      title: t('placeme.mcd_potential_model_tile.criteria.what_future_shopping_mall_size'),
    },
    plannedShoppingMallFoodCourtExists: {
      formStateElement: formState.plannedShoppingMallFoodCourtExists,
      inputKey: FormFields.plannedShoppingMallFoodCourtExists,
      options,
      title: t('placeme.mcd_potential_model_tile.criteria.if_food_court_exists'),
    },
  }

  return {
    formElementData,
    multipleChoiceData,
    additionalFormElementData: additionalFields ? additionalFormElementData : null,
  }
}
