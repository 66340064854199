import { useTranslation } from 'react-i18next'
import { IDemoSalesPredictionModel } from '../../../DemoSalesPredictionModel'
import { useGetDropdownOptions } from '../formData/useGetDropdownOptions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSelectedSettings = (data: IDemoSalesPredictionModel) => {
  const {
    locationAdditionsChoices, sizeOptions, locationTypeOptions,
  } = useGetDropdownOptions()
  const { t } = useTranslation()
  if (!data) return []

  const { settings } = data.value
  const settingsList = [{
    label: t('placeme.demo.sales_prediction_model.criteria.location_type'),
    value: locationTypeOptions.find(option => option.value === settings.locationType)?.label ?? '',
    visible: true,
  },
  {
    label: t('placeme.demo.sales_prediction_model.criteria.location_additions'),
    value: locationAdditionsChoices.filter(choice =>
      data.value.settings.locationAdditions?.find(item => item === choice?.id))
      ?.map(choice => choice?.name)
      ?.join(', '),
    visible: true,
  },
  ]
  if (settings.plannedShoppingMall) {
    settingsList.push(
      {
        label: t('placeme.demo.sales_prediction_model.criteria.planned_shopping_mall'),
        value: t('generic.yes'),
        visible: true,
      },
      {
        label: t('placeme.demo.sales_prediction_model.criteria.planned_shopping_mall_size'),
        value: sizeOptions.find(option => option.value === settings.plannedShoppingMallSize)!.label,
        visible: true,
      },
      {
        label: t('placeme.demo.sales_prediction_model.criteria.planned_shopping_mall_food_court_exists'),
        value: settings.plannedShoppingMallFoodCourtExists ? t('generic.yes') : t('generic.no'),
        visible: true,
      },
    )
  }

  return settingsList
}
