import React, { memo } from 'react'
import styled from 'styled-components'
import { GeosuggestForm } from '@dataplace.ai/ui-components/molecules'
import { Header, Paragraph } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { ILocation } from '@dataplace.ai/types'

const ChooseLocationTileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 500;
    position: absolute;
    left:41px;
    top: 165px;
`
export interface IChooseLocationTileProps {
  handleClick: (location: ILocation) => void,
  handleGeosuggestSelect: (location: ILocation) => void,
  loading?: boolean
}

export const ChooseLocationTile = memo(({
  handleClick, handleGeosuggestSelect, loading,
}: IChooseLocationTileProps) => {
  const { t } = useTranslation()

  return (
    <ChooseLocationTileWrapper>
      <Header
        color='black'
        type='h3'
      >
        {t('placeme.choose_location.header')}
      </Header>
      <Paragraph
        margin='10px 0 15px'
        size='small'
      >
        {t('placeme.choose_location.description')}
      </Paragraph>
      <GeosuggestForm
        buttonText={t('generic.analyse')}
        handleSelect={handleGeosuggestSelect}
        handleSubmit={handleClick}
        loading={loading}
        submitButtonDisplayed
      />
    </ChooseLocationTileWrapper>
  )
})
