import React from 'react'
import styled, { css } from 'styled-components'
import { CoinsButton, Icon, NoPrint } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import Map from '../../../../assets/src/lib/icons/map.png'

const AddTileWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px dashed #e1e2e3;
    background-color: ${palette.light.lighter};
    padding: 8px;
  `
})

const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  flex-grow: 1;
`

const Title = styled.span(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    color: ${palette.blue};
    font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
  `
})

const Description = styled.span(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    color: ${palette.dark.normal};
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};
  `
})

export interface IAddMapViewProps {
  onChange:() => void
  title: string
  description: string
  buttonText?: string
  buttonValue: number
}

export function AddMapView({
  onChange, title, description, buttonText, buttonValue,
}: IAddMapViewProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <NoPrint>
      <AddTileWrapper>
        <Icon
          alt='Map'
          height='3.8rem'
          src={Map}
          width='6.3rem'
        />
        <FlexColumnWrapper>
          <Title>{t(title)}</Title>
          <Description>{t(description)}</Description>
        </FlexColumnWrapper>
        <CoinsButton
          isUnlimited
          margin='0 0 0 1.82rem'
          onClick={onChange}
          text={buttonText ? t(buttonText) : t('generic.apply')}
          value={buttonValue}
        />
      </AddTileWrapper>
    </NoPrint>
  )
}
