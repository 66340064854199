import { IAnalysesData } from 'apps/placeme/src/features/AnalyseAndReports/components/organisms/AnalyseAndReportBody/@types/IAnalysesData'
import { ITileData } from '../../../../slice/@types/ITileData'

interface FetchProjectIdProps {
  projectName: string
  existingProjects: ({ folderId: string; folderName: string; } | null)[],
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchProjectId = ({
  projectName,
  existingProjects,
}: FetchProjectIdProps) => {
  if (!existingProjects.length) return ''

  let projectId = ''
  existingProjects.forEach((project) => {
    if (!project || project.folderName !== projectName) return
    projectId = project.folderId
  })

  return projectId
}

interface FetchExistingProps {
  folderId?: string
  data?: ITileData
  projectsData?: IAnalysesData
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchExistingReports = ({
  folderId,
  data,
  projectsData,
}: FetchExistingProps) => {
  if (!data) return []

  const reports = projectsData?.folders.map((folder) =>
    (!folder.folderId.toLocaleLowerCase().includes('all')
      ? {
        folderId: folder.folderId,
        reports: folder.values.map((report =>
          report.id
        )),
      }
      : null))

  return reports?.find((elem) => elem?.folderId === folderId)?.reports
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const folderNames = (existingProjects: ({ folderId: string; folderName: string;} | null)[]) => {
  if (!existingProjects.length) return []

  const returnProjects: string[] = []
  existingProjects.forEach((project) => {
    if (project?.folderName) {
      returnProjects.push(project.folderName)
    }
  })

  return returnProjects
}
