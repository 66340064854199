import React from 'react'

import styled from 'styled-components'

const Page404Component = styled.div`
  color: #d71435;
`

export const Page404 = (): JSX.Element => (
  <Page404Component>
    <h1>404</h1>
  </Page404Component>
)
