import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@dataplace.ai/features'
import { RootState } from '../../../../../redux/store'
import { SectionTile } from '../../molecules'
// comment for clients version
// import { DataSetUpdateInfoPageLink } from '../../atoms'

const Wrapper = styled.div<{isInWhiteOrTrial?: boolean}>(({
  theme, isInWhiteOrTrial,
}) => {
  const { typography } = theme
  return css`
    height: ${isInWhiteOrTrial && 'calc(100% - 128px)'};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > :first-child > div {
      border-top: none;
    }
  `
})

const NoItems = React.memo(styled.div<{isInWhiteOrTrial?: boolean}>(({
  theme, isInWhiteOrTrial,
}) => {
  const { typography } = theme
  return css`
    display: flex;
    align-items: center;
    justify-content: ${isInWhiteOrTrial ? 'center' : 'flex-start'};
    margin: ${!isInWhiteOrTrial && '3rem 0'};
    padding-left: ${!isInWhiteOrTrial && '3rem'};
    width: ${isInWhiteOrTrial && '100%'};
    height: ${isInWhiteOrTrial && '90%'};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > img {
      width: ${isInWhiteOrTrial ? '40%' : '239px'};
    }

    > div {
      width:  ${isInWhiteOrTrial ? '20%' : '50%'};
      margin-left:  ${isInWhiteOrTrial ? '2rem' : '4rem'};

      > span:nth-of-type(1n) {
        margin-bottom: 0.5rem;
        display: block;
        font-size: ${typography.main.pt_15_regular.fontSize};
        font-weight: ${typography.main.pt_15_regular.fontWeight};
        line-height: ${typography.main.pt_15_regular.lineHeight};
      }
  
      > span:nth-of-type(2n) {
        display: block;
        font-size: ${typography.big.pt_18_semibold.fontSize};
        font-weight: ${typography.big.pt_18_semibold.fontWeight};
        line-height: ${typography.big.pt_18_semibold.lineHeight};
      }
    }
  `
}))

export const AnalysePageBody = ({ userId }:{userId: string}): JSX.Element => {
  // constants
  const { t } = useTranslation()
  // we need ref to get current state in event listener
  const authContext = useContext(AuthContext)
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }
  const { values } = useSelector((state: RootState) => state.analysis)
  const { currentSubscriptionData } = useSelector((state: RootState) => state.location)

  const isInWhiteOrTrial = currentSubscriptionData?.value?.planExact && ['white', 'trial'].includes(currentSubscriptionData?.value?.planExact)

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  return (
    <Wrapper isInWhiteOrTrial={isInWhiteOrTrial as boolean}>
      {!!values.length
      && userId
      && values.map((value) => (
        <SectionTile
          key={value.id}
          sectionTile={value}
          userId={userId}
        />
      ))}
      {!values.length && (
        <>
          <NoItems isInWhiteOrTrial={isInWhiteOrTrial}>
            <img
              alt='noItems'
              src='assets/images/noItems2.svg'
            />
            <div>
              <span>{t('placeme.analyse_page.no_items')}</span>
              <span>{t('placeme.analyse_page.no_items_description')}</span>
            </div>
          </NoItems>
        </>
      )}
    </Wrapper>
  )
}
