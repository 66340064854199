import { Button, TileFormParagraph, TileFormWrapper } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { ReactComponent as WarningIcon } from 'libs/shared/assets/src/lib/icons/warrningIcon.svg'
import { ReactComponent as RefreshIcon } from 'libs/shared/assets/src/lib/icons/refreshIcon.svg'

interface InvalidStyleProps {
  $invalidColor?: boolean
}

interface CategoryColorProps {
  $catColor: string
}

export const TileFormParagraphWithTooltip = styled(TileFormParagraph)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const StyledTileFormWrapper = styled(TileFormWrapper)(({ theme: { palette } }) => css`
  background-color: ${palette.light.main};
`)

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  padding: 1.25rem;
`

export const ImportanceWrapper = styled.div(({
  theme: {
    palette, corners,
  },
}) => css`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  background: ${palette.light.white};
  border: 1.5px dashed ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
  padding: 1rem;
  flex-direction: column;
`)

export const Description = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  padding: 0.5rem;
  color: ${palette.dark.normal};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
`)

export const ImportanceSelectorWrapper = styled.div(({ theme: { palette } }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${palette.black};
  margin-bottom: 6px;
  position: relative;
`)

export const CategoryTitle = styled.h2(({ theme: { typography } }) => css`
  font-size: ${typography.small.pt_14_semibold.fontSize};
  font-weight: ${typography.small.pt_14_semibold.fontWeight};
  line-height: ${typography.small.pt_14_semibold.lineHeight};
  text-transform: uppercase;
  margin-bottom: 5px;
`)

export const ImportanceSelectorElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SubCatTitle = styled.h3(({ theme: { typography } }) => css`
  font-size: ${typography.small.pt_13_medium.fontSize};
  font-weight: ${typography.small.pt_13_medium.fontWeight};
  line-height: ${typography.small.pt_13_medium.lineHeight};
  display: flex;
  align-items: center;
`)

export const SubCatDescription = styled.span(({ theme: { typography } }) => css`
  font-size: ${typography.tiny.pt_10_regular.fontSize};
  font-weight: ${typography.tiny.pt_10_regular.fontWeight};
  line-height: ${typography.tiny.pt_10_regular.lineHeight};
`)

export const ImportanceSelector = styled.input<InvalidStyleProps>(({
  theme: { palette }, $invalidColor,
}) => css`
  width: 38px;
  height: 36px;
  margin: 4px 0 4px 10px;
  padding-left: 12px;
  border: 1px solid ${$invalidColor ? palette.results.red.dark : palette.dark.lightest};
  border-radius: 6px;
  appearance: none;
  -moz-appearance: textfield;

  &:focus {
    outline: none;
  }

  &::after {
    content: '%';
    position: absolute;
    right: 8px;
    top: 50%; 
    transform: translateY(-50%);
    pointer-events: none;
  } 

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`)

interface StyledSymbolProps {
  $valueLength: number
}

export const StyledSymbol = styled.span<StyledSymbolProps>(({ $valueLength }) => css`
  position: absolute;
  ${$valueLength === 1 ? css`right: 20px;` : $valueLength === 2 ? css`right: 13px;` : css`right: 6px;`}
`)

export const ColorfulShape = styled.div<CategoryColorProps>(({ $catColor }) => css`
  width: 16px;
  height: 16px;
  background-color: ${$catColor};
  border-radius: 6px;
  margin-right: 7px;
`)

export const SumLabel = styled.div(({
  theme:{
    typography,
    palette,
  },
}) => css`
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
  color: ${palette.dark.normal};
`)

export const PercentagesValue = styled.div<InvalidStyleProps>(({
  theme: {
    typography,
    palette,
  },
  $invalidColor,
}) => css`
  font-size: ${typography.main.pt_15_medium.fontSize};
  font-weight: ${typography.main.pt_15_medium.fontWeight};
  line-height: ${typography.main.pt_15_medium.lineHeight};
  color: ${$invalidColor ? palette.results.red.dark : palette.black};
  margin-left: 5px;
`)

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SumWrapper = styled.div(({ theme: { palette } }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${palette.light.darkest};
  margin-top: 10px;
  padding-top: 12px;
`)

export const Percentages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RestoreDefaultBtn = styled.button(({
  theme: {
    typography,
    palette,
  },
}) => css`
  background: none;
  color: ${palette.blue};
  font-size: ${typography.tiny.pt_12_medium.fontSize};
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`)

export const ValidationInfo = styled.div(({
  theme:{
    typography,
    palette,
  },
}) => css`
  font-size: ${typography.tiny.pt_11_regular.fontSize};
  font-weight: ${typography.tiny.pt_11_regular.fontWeight};
  line-height: ${typography.tiny.pt_11_regular.lineHeight};
  color: ${palette.results.red.dark};
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 10px 0;
`)

export const StyledWarningIcon = styled(WarningIcon)(({ theme: { palette } }) => css`
  margin-right: 5px;
  
  path {
    stroke: ${palette.results.red.dark};
  }
`)

export const StyledRefreshIcon = styled(RefreshIcon)`
  margin-right: 5px;
`

export const FooterWrapper = styled.div`
  margin: 0 1rem;
`

export const StyledButton = styled(Button)`
  padding: 9px;
`
