export const mapConst = {
  zoom:{
    13: 13,
    15: 15,
  },
}

export const minTrafficDate = new Date(2022, 0, 1)

export enum TrafficIntensity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export const defaultMaxRanges = {
  car: 15,
  walk: 30,
  bike: 30,
  line: 5000,
  custom: 79000000,
}
