import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface DropdownOptions {
  salesAreaOptions: Array<{ value: string; label: string }>
  plannedStationTypes: Array<{ value: string; label: string }>
  options: Array<{ value: boolean; label: string }>
  cafeMoyaOptions: Array<{ value: string; label: string }>
  plannedDistributorsNumber: Array<{ value: number; label?: string }>
  sizeOptions: Array<{ value: string; label?: string }>
}

export const useGetAnwDropdownOptions = (): DropdownOptions => {
  const { t } = useTranslation()

  const plannedDistributorsNumber = useMemo(() => Array.from({
    length: 16,
  }, (_, index) => ({
    value: index + 1,
  })), [])

  const salesAreaOptions = [
    {
      value: 'no_store',
      label: t('placeme.anw_potential_model_tile.area_type.no_store'),
    },
    {
      value: 'booth',
      label:  t('placeme.anw_potential_model_tile.area_type.booth'),
    },
    {
      value: 'lt_40',
      label:  t('placeme.anw_potential_model_tile.area_type.lt_40'),
    },
    {
      value: 'btw_40_60',
      label:  t('placeme.anw_potential_model_tile.area_type.btw_40_60'),
    },
    {
      value: 'btw_60_90',
      label:  t('placeme.anw_potential_model_tile.area_type.btw_60_90'),
    },
    {
      value: 'mt_90',
      label:  t('placeme.anw_potential_model_tile.area_type.mt_90'),
    },
  ]

  const plannedStationTypes = [
    {
      value: 'f',
      label: t('placeme.anw_potential_model_tile.station_type.f'),
    },
    {
      value: 'w',
      label: t('placeme.anw_potential_model_tile.station_type.w'),
    },
    {
      value: 'fhz',
      label: t('placeme.anw_potential_model_tile.station_type.fhz'),
    },
    {
      value: 'wet',
      label: t('placeme.anw_potential_model_tile.station_type.wet'),
    },
    {
      value: 'wh',
      label: t('placeme.anw_potential_model_tile.station_type.wh'),
    },
    {
      value: 'whz',
      label: t('placeme.anw_potential_model_tile.station_type.whz'),
    },
    {
      value: 'we',
      label: t('placeme.anw_potential_model_tile.station_type.we'),
    },
    {
      value: 'd',
      label: t('placeme.anw_potential_model_tile.station_type.d'),
    },
  ]

  const options = [
    {
      value: true,
      label: t('generic.yes'),
    },
    {
      value: false,
      label: t('generic.no'),
    },
  ]

  const cafeMoyaOptions = [
    {
      value: 'none',
      label:  t('placeme.anw_potential_model_tile.cafe_stock.none'),
    },
    {
      value: 'x',
      label: 'X',
    },
    {
      value: 'l',
      label: 'L',
    },
    {
      value: 'xl',
      label: 'XL',
    },
    {
      value: 'xxl',
      label: 'XXL',
    },
  ]

  const sizeOptions = [{
    value: 'small',
    label: t('placeme.demo.sales_prediction_model.criteria.future_shopping_mall_size.small'),
  },
  {
    value: 'medium',
    label: t('placeme.demo.sales_prediction_model.criteria.future_shopping_mall_size.medium'),
  },
  {
    value: 'big',
    label: t('placeme.demo.sales_prediction_model.criteria.future_shopping_mall_size.big'),
  }]

  return {
    salesAreaOptions,
    plannedStationTypes,
    options,
    cafeMoyaOptions,
    plannedDistributorsNumber,
    sizeOptions,
  }
}
