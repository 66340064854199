/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMapLocationProps, Button, NoPrint } from '@dataplace.ai/ui-components/atoms'
import { InfoBean, NestedSelect } from '@dataplace.ai/ui-components/molecules'
import { ILocation } from '@dataplace.ai/types'
import { ISectionTile } from 'apps/placeme/src/features/Analyse/slice/@types/ISectionTile'
import { IChooseLocationSlice } from 'apps/placeme/src/features/ChooseLocationReport/@types/IChooseLocationSlice'
import { TableFilter } from '@dataplace.ai/ui-components/organisms/FilterableSortableTable/molecules'
import { ISortableTableHeaderProps } from '@dataplace.ai/ui-components/organisms/FilterableSortableTable/@types/ISortableTableHeader.props'
import NewComparedLocationHeader from '@placeme/components/atoms/ComparedLocationHeader/NewComparedLocationHeader'
import { TileSectionIds } from '@dataplace.ai/constants'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { saveTiles } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { usePersistingTableFilter } from '@dataplace.ai/ui-components/organisms/FilterableSortableTable/molecules/TableFilter/hooks'
import { CommonInvestment, OthersData } from '../@types/IInvestmentsTileData'
import {
  NestedSelectWrapper, StyledAdditionalDataWrapper,
  StyledDescription, TextRowContainer,
} from '../Investments.styles'
import MapVisualisation from './MapVisualisation'
import useOtherInvestments from '../hooks/useOtherInvestments'
import { TOOLTIP_MAX_WIDTH } from '../utils/constants'

const HEADERS = [
  {
    label: 'address',
    name: 'address',
    type: 'text',
    sortable: true,
    filter: {
      enabled: true,
      clearable: true,
    },
  },
  {
    label: 'investor',
    name: 'investor',
    type: 'text',
    sortable: true,
    filter: {
      enabled: true,
      clearable: true,
    },
  },
  {
    label: 'volume',
    name: 'volume',
    type: 'volume',
    sortable: true,
    filter: {
      enabled: true,
      clearable: true,
      checkboxes: true,
    },
  },
]

export interface CommonInvestmentWithCategory extends CommonInvestment {
  category: string
}

interface OthersProps {
  otherInvestments?: OthersData
  comparedOtherInvestments?: OthersData
  sectionTilesData: ISectionTile[]
  tileId: string
  locationData: IChooseLocationSlice['value']
  comparedLocationData?: ILocation
  catchmentId?: string
  setMapLocation: Dispatch<SetStateAction<IMapLocationProps>>
  setComparedMapLocation: Dispatch<SetStateAction<IMapLocationProps>>
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Others({
  otherInvestments, comparedOtherInvestments, sectionTilesData, comparedLocationData,
  tileId, locationData, catchmentId, setMapLocation, setComparedMapLocation,
}: OthersProps) {
  const { t } = useTranslation()
  const [showAdditionalData, setShowAdditionalData] = useState(false)
  const { isPrinting } = useCheckIfPrinting()
  const dispatch = useAppDispatch()
  const {
    setSelectedCategories,
    categories,
    selectedCategories,
    selectedInvestmentsWithCategories,
    setFilteredListWithCategories,
    selectedInvestmentsTables,
    otherInvestmentsLayers,
    filteredInvestments,
    otherInvestmentsDataExists,
  } = useOtherInvestments({
    investments: otherInvestments,
    tileId,
    sectionTilesData,
  })
  const {
    setSelectedCategories: setComparedSelectedCategories,
    categories: comparedCategories,
    selectedCategories: comparedSelectedCategories,
    selectedInvestmentsWithCategories: comparedSelectedInvestmentsWithCategories,
    setFilteredListWithCategories: setComparedFilteredListWithCategories,
    selectedInvestmentsTables: comparedSelectedInvestmentsTables,
    otherInvestmentsLayers: comparedOtherInvestmentsLayers,
    filteredInvestments: comparedFilteredInvestments,
    otherInvestmentsDataExists: comparedOtherInvestmentsDataExists,
  } = useOtherInvestments({
    investments: comparedOtherInvestments,
    tileId,
    sectionTilesData,
    compared: true,
  })

  // ////////////////////////////////////////////////////////////////////////// Filter Settings
  const {
    tileSettings,
    popupRef,
    applyFiltersAndClose: _applyFiltersAndClose,
    deleteActiveFilter,
    tableFilterProps: {
      setTempFilter,
      filterData,
      tempFilter,
      ...tableFilterProps
    },
  } = usePersistingTableFilter({
    tileType: 'investmentsNew',
    tileId,
    headers: HEADERS as ISortableTableHeaderProps[],
    setData: setFilteredListWithCategories,
    data: selectedInvestmentsWithCategories,
  })
  // ////////////////////////////////////////////////////////////////////////// Filter Settings END

  // ////////////////////////////////////////////////////////////////////////// Compared Filter Settings
  const {
    popupRef: comparedPopupRef,
    applyFiltersAndClose: _comparedApplyFiltersAndClose,
    deleteActiveFilter: comparedDeleteActiveFilter,
    tableFilterProps: {
      setTempFilter: comparedSetTempFilter,
      filterData: comparedFilterData,
      tempFilter: comparedTempFilter,
      ...comparedTableFilterProps
    },
  } = usePersistingTableFilter({
    tileType: 'investmentsNew',
    tileId,
    headers: HEADERS as ISortableTableHeaderProps[],
    setData: setComparedFilteredListWithCategories,
    data: comparedSelectedInvestmentsWithCategories,
  })
  const comparedApplyFiltersAndClose = useCallback(() => {
    _comparedApplyFiltersAndClose({
      comparedOtherInvestmentsTableFilter: comparedTempFilter,
    })
  }, [_comparedApplyFiltersAndClose, comparedTempFilter])
  // ////////////////////////////////////////////////////////////////////////// Compared Filter Settings END

  const applyFiltersAndClose = useCallback(() => {
    _applyFiltersAndClose({
      otherInvestmentsTableFilter: tempFilter,
    })
  }, [_applyFiltersAndClose, tempFilter])

  useEffect(() => {
    const filterSettings = tileSettings.value?.otherInvestmentsTableFilter
    if (typeof filterSettings !== 'undefined') {
      setTempFilter(filterSettings)
      filterData()
    }
    const comparedFilterSettings = tileSettings.value?.comparedOtherInvestmentsTableFilter
    if (typeof comparedFilterSettings !== 'undefined') {
      comparedSetTempFilter(comparedFilterSettings)
      comparedFilterData()
    }
  }, [tileSettings.value])

  const noOthersDataBean = useMemo(() => {
    if (otherInvestmentsDataExists && comparedOtherInvestmentsDataExists) return null
    let tooltipText = t('placeme.investments.no_planned_others_investments')
    if (otherInvestmentsDataExists && !comparedOtherInvestmentsDataExists) {
      tooltipText = `${t('placeme.investments.no_others_investments_for')}${' '}${comparedLocationData?.address}`
    }
    if (!otherInvestmentsDataExists && comparedOtherInvestmentsDataExists) {
      tooltipText = `${t('placeme.investments.no_others_investments_for')}${' '}${locationData?.address}`
    }
    return (
      <InfoBean
        tooltipMaxWidth={TOOLTIP_MAX_WIDTH}
        tooltipText={tooltipText}
      />
    )
  }, [locationData, comparedLocationData, otherInvestmentsDataExists, comparedOtherInvestmentsDataExists])

  useEffect(() => {
    if (!catchmentId) return
    const pdfTiles = sectionTilesData.map(cat => {
      if (cat.id === TileSectionIds.SURROUNDINGS) {
        return {
          ...cat,
          tiles: cat.tiles.map(tile => {
            if (tile.chosenRange?.catchmentId === catchmentId) {
              return {
                ...tile,
                pdfIncludedSections: {
                  others: filteredInvestments ?? {},
                  comparedOthers: comparedFilteredInvestments ?? {},
                },
              }
            } return tile
          }),
        }
      }
      return cat
    })
    dispatch(saveTiles(pdfTiles))
  }, [filteredInvestments, comparedFilteredInvestments])

  useEffect(() => {
    if (!isPrinting) return
    setShowAdditionalData(true)
    setFilteredListWithCategories(selectedInvestmentsWithCategories ?? [])
  }, [isPrinting, selectedInvestmentsWithCategories])

  return (
    isPrinting || showAdditionalData
      ? (
        <>
          {selectedInvestmentsWithCategories
            ? (
              <>
                {comparedOtherInvestments
                  ? <NewComparedLocationHeader address={locationData?.address} />
                  : null}
                <NoPrint>
                  <NestedSelectWrapper>
                    <span>{t('placeme.investments_tile.investment_categories')}</span>
                    <NestedSelect
                      name=''
                      onChange={setSelectedCategories}
                      options={categories}
                      selected={selectedCategories}
                      width='50%'
                    />
                  </NestedSelectWrapper>
                </NoPrint>
                <TableFilter
                  {...tableFilterProps}
                  applyFiltersAndClose={applyFiltersAndClose}
                  deleteActiveFilter={name => deleteActiveFilter('otherInvestmentsTableFilter', name)}
                  headers={HEADERS as ISortableTableHeaderProps[]}
                  popupRef={popupRef}
                />
                {selectedInvestmentsTables}
                <MapVisualisation
                  catchmentId={catchmentId}
                  locationData={locationData}
                  mapLayers={otherInvestmentsLayers}
                  setMapLocation={setMapLocation}
                />
              </>
            )
            : (
              <TextRowContainer>
                <NewComparedLocationHeader address={locationData?.address} />
                {noOthersDataBean}
              </TextRowContainer>
            )}
          {comparedOtherInvestments && comparedSelectedInvestmentsWithCategories
            ? (
              <>
                {comparedOtherInvestmentsDataExists
                  ? (
                    <NewComparedLocationHeader
                      address={comparedLocationData?.address}
                      compared
                    />
                  )
                  : null}
                <NoPrint>
                  <NestedSelectWrapper>
                    <span>{t('placeme.investments_tile.investment_categories')}</span>
                    <NestedSelect
                      name=''
                      onChange={setComparedSelectedCategories}
                      options={comparedCategories}
                      selected={comparedSelectedCategories}
                      width='50%'
                    />
                  </NestedSelectWrapper>
                  <TableFilter
                    {...comparedTableFilterProps}
                    applyFiltersAndClose={comparedApplyFiltersAndClose}
                    deleteActiveFilter={name => comparedDeleteActiveFilter('comparedOtherInvestmentsTableFilter', name)}
                    headers={HEADERS as ISortableTableHeaderProps[]}
                    popupRef={comparedPopupRef}
                  />
                </NoPrint>
                {comparedSelectedInvestmentsTables}
                <MapVisualisation
                  catchmentId={catchmentId}
                  locationData={comparedLocationData}
                  mapLayers={comparedOtherInvestmentsLayers}
                  setMapLocation={setComparedMapLocation}
                />
              </>
            )
            : null}
          {comparedOtherInvestments && !comparedOtherInvestmentsDataExists
            ? (
              <TextRowContainer>
                <NewComparedLocationHeader address={comparedLocationData?.address} />
                {noOthersDataBean}
              </TextRowContainer>
            )
            : null}
        </>
      )
      : (
        <NoPrint>
          <StyledAdditionalDataWrapper>
            <TextRowContainer $maxWidth='50%'>
              <StyledDescription>{t('placeme.investments.additional_data.description')}</StyledDescription>
            </TextRowContainer>
            <Button onClick={() => setShowAdditionalData(true)}>
              {t('placeme.buildings.additional.btn_check_more')}
            </Button>
          </StyledAdditionalDataWrapper>
        </NoPrint>
      )
  )
}

export default Others
