import React from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetDropdownOptions = () => {
  const { t } = useTranslation()

  const locationAdditionsChoices = [
    {
      id: 'drive_thru',
      name: t('placeme.mcd_potential_model_tile.location_additions.drive_thru'),
      checked: false,
      listItemName: <span>{t('placeme.mcd_potential_model_tile.location_additions.drive_thru')}</span>,
    },
    {
      id: 'mccafe_counter',
      name: t('placeme.mcd_potential_model_tile.location_additions.mccafe_counter'),
      checked: false,
      listItemName: <span>{t('placeme.mcd_potential_model_tile.location_additions.mccafe_counter')}</span>,
    },
    {
      id: 'inside_playland',
      name: t('placeme.mcd_potential_model_tile.location_additions.inside_playland'),
      checked: false,
      listItemName: <span>{t('placeme.mcd_potential_model_tile.location_additions.inside_playland')}</span>,
    },
    {
      id: 'outside_playland',
      name: t('placeme.mcd_potential_model_tile.location_additions.outside_playland'),
      checked: false,
      listItemName: <span>{t('placeme.mcd_potential_model_tile.location_additions.outside_playland')}</span>,
    },
    {
      id: 'pylon',
      name: t('placeme.mcd_potential_model_tile.location_additions.pylon'),
      checked: false,
      listItemName: <span>{t('placeme.mcd_potential_model_tile.location_additions.pylon')}</span>,
    },
  ]

  const options = [
    {
      value: true,
      label: t('generic.yes'),
    },
    {
      value: false,
      label: t('generic.no'),
    },
  ]

  const locationTypeBaseOptions = [{
    value: 'freestander',
    label: t('placeme.mcd.competition_traffic.dropdown.option.freestander'),
  },
  {
    value: 'in_store',
    label: t('placeme.mcd.competition_traffic.dropdown.option.instore'),
  },
  {
    value: 'mall',
    label: t('placeme.mcd.competition_traffic.dropdown.option.mall'),
  }]

  const locationTypeOptions = [...locationTypeBaseOptions, {
    value: 'mop_autohof',
    label: t('placeme.mcd.potential_model.dropdown.option.mop_autohof'),
  },
  {
    value: 'mop_entrance',
    label: t('placeme.mcd.potential_model.dropdown.option.mop_entrance'),
  },
  {
    value: 'mop_exit',
    label: t('placeme.mcd.potential_model.dropdown.option.mop_exit'),
  },
  {
    value: 'mop_between',
    label: t('placeme.mcd.potential_model.dropdown.option.mop_between'),
  }]

  const competitionLocationTypeOptions = [...locationTypeBaseOptions, {
    value: 'mop',
    label: t('placeme.mcd.competition_traffic.dropdown.option.mop'),
  }]

  const sizeOptions = [{
    value: 'small',
    label: t('placeme.mcd_potential_model_tile.criteria.what_future_shopping_mall_size.small'),
  },
  {
    value: 'medium',
    label: t('placeme.mcd_potential_model_tile.criteria.what_future_shopping_mall_size.medium'),
  },
  {
    value: 'big',
    label: t('placeme.mcd_potential_model_tile.criteria.what_future_shopping_mall_size.big'),
  }]

  return {
    locationAdditionsChoices,
    options,
    locationTypeOptions,
    sizeOptions,
    competitionLocationTypeOptions,
  }
}

