import React, { useEffect, useState } from 'react'
import { FlashMessage } from '@dataplace.ai/features'
import styled from 'styled-components'
import { animated, useTransition } from 'react-spring'
import { NoPrint } from '@dataplace.ai/ui-components/atoms'
import { IFlashMessage } from './@types'

const Container = styled.div`
  padding: 0 1.5rem;
  position: fixed;
  top: 2rem;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9999;
`

export const FlashMessagesProvider: React.FC = (): JSX.Element => {
  const [messages, setMessages] = useState<IFlashMessage[]>([])
  const transitions = useTransition(messages.reverse()
    .slice(0, 3), {
    keys: item => item?.id,
    from: {
      transform: 'translateX(150%)',
    },
    enter: {
      transform: 'translateX(0%)',
    },
    leave: {
      transform: 'translateX(150%)',
    },
  })

  const getMessages = () => {
    const { flashMessages: messagesJSON } = sessionStorage
    if (messagesJSON) {
      try {
        return JSON.parse(messagesJSON)
      } catch (error) {
        return null
      }
    }
    return null
  }

  const deleteMessage = (id: string) => {
    const messages = getMessages()
    if (messages) {
      const updatedMessages = messages.filter((message: IFlashMessage) => message.id !== id)
      setMessages(updatedMessages)
      sessionStorage.flashMessages = JSON.stringify(updatedMessages)
    }
  }

  const updateMessages = () => {
    const messages = getMessages()
    if (messages) {
      messages.forEach(({
        timeout, id,
      }: IFlashMessage) => {
        if (timeout) {
          setTimeout(() => deleteMessage(id), timeout)
        }
      })
      setMessages(messages)
    }
  }

  useEffect(() => {
    updateMessages()
    window.addEventListener('flashMessagesUpdate', updateMessages)

    return () => window.removeEventListener('flashMessagesUpdate', updateMessages)
  }, [])

  return (
    <NoPrint>
      <Container>
        {transitions((style, item) => (
          <animated.div
            key={item?.id}
            style={style}
          >
            <FlashMessage
              key={item?.id}
              id={item?.id}
              message={item?.message}
              onClose={deleteMessage}
              type={item?.type}
            />
          </animated.div>
        ))}
      </Container>
    </NoPrint>
  )
}
