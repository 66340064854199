import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useAppDispatch, useAppSelector } from 'apps/placeme/src/redux/hooks'
import { LinkWithIconAddNote, LinkWithIconDelete, LinkWithIconEdit, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import { WarningModal } from '@dataplace.ai/ui-components/molecules'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@dataplace.ai/features'
import {
  deleteNoteAction,
  deleteNoteFromDBAction,
  fetchNotesAction,
} from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { PopupActions } from 'reactjs-popup/dist/types'
import { INote } from '../../../slice/@types/INote'
import { ITile } from '../../../slice/@types/ITile'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { ReactComponent as File } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/file.svg'
import { NotePopup } from '../NotePopup'

dayjs.extend(relativeTime)

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    padding: 1.25rem;
    background-color: ${palette.light.white};
    flex-direction: column;

    > span {
      margin-bottom: 0.25rem;
    }

    > span:last-child {
      margin-bottom: 0;
    }
  `
})

const Note = styled.span(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.darker};
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    overflow-x: hidden;
    overflow-wrap: break-word;
  `
})

const Info = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
      margin-right: 0.25rem;
    }

    > svg > path {
      stroke: ${palette.blue};
    }

    > span {
      margin-right: 0.25rem;
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }
  `
})

const LoaderWrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export interface ITileNotesProps {
  notes?: INote[]
  tile: ITile
  sectionTile: ISectionTile
  dataLoaded: boolean
  token: string
}

export const TileNotes: FC<ITileNotesProps> = ({
  notes,
  tile,
  sectionTile,
  dataLoaded,
  token,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const { analyseId } = useAppSelector(state => state.location)
  const [lastNonUserNoteIndex, setLastNonUserNoteIndex] = useState(-1)

  useEffect(() => {
    if (notes && authContext?.userData?.user?.uid) {
      const reversedIndex = [...notes].reverse().findIndex(note => note.userId !== authContext?.userData?.user?.uid)
      const lastIndex = reversedIndex >= 0 ? notes.length - 1 - reversedIndex : reversedIndex
      setLastNonUserNoteIndex(lastIndex)
    }
  }, [notes, authContext?.userData?.user?.uid])

  useEffect(() => {
    if (analyseId && token && dataLoaded && tile?.chosenRange?.catchmentId) {
      dispatch(fetchNotesAction(analyseId, sectionTile.id, tile.chosenRange.catchmentId))
    }
  }, [analyseId, token, dataLoaded])

  const handleDeleteNote = (note: INote) => {
    dispatch(deleteNoteAction(sectionTile.id, tile.id, note))
    if (dataLoaded && token) {
      dispatch(deleteNoteFromDBAction(note))
    }
  }

  const warningRef = useRef<PopupActions>(null)
  const closeWarning = warningRef?.current?.close

  return notes !== undefined && notes?.length
    ? (
      <Wrapper>
        {notes?.map((note, index) => (
          <Note key={note.id}>
            <span>{note.text}</span>
            <Info>
              <File />
              <span>{`${note.author},`}</span>
              <span>{dayjs(note.creationDate).fromNow()}</span>

              {authContext?.userData?.user?.uid === note.userId && (
                <>
                  <NotePopup
                    dataLoaded={dataLoaded}
                    editing
                    maxLength={500}
                    note={note}
                    sectionTile={sectionTile}
                    tile={tile}
                    trigger={(
                      <LinkWithIconEdit
                        label='placeme.note.edit'
                      />
                    )}
                  />

                  <PopupWithOverlay
                    ref={warningRef}
                    modal
                    trigger={(
                      <LinkWithIconDelete
                        label='placeme.note.delete'
                      />
                    )}
                  >
                    <WarningModal
                      handleAgreement={() => handleDeleteNote(note)}
                      handleCancel={closeWarning}
                      heading={t('placeme.note.delete.heading')}
                      redWarning={t('placeme.note.delete.red_warning')}
                    />
                  </PopupWithOverlay>
                </>
              )}
              {lastNonUserNoteIndex === index && (
                <NotePopup
                  close={closeWarning}
                  dataLoaded={dataLoaded}
                  maxLength={500}
                  sectionTile={sectionTile}
                  tile={tile}
                  trigger={(
                    <LinkWithIconAddNote
                      icon='plus'
                      label='placeme.note.edit'
                    />
                  )}
                />
              )}
            </Info>
          </Note>
        ))}
      </Wrapper>
    )
    : dataLoaded && !Array.isArray(notes)
      ? (
        <LoaderWrapper>
          <object
            data='assets/loaders/placeme/small.svg'
            type='image/svg+xml'
            width='100px'
          >
            placeme loader
          </object>
        </LoaderWrapper>
      )
      : null
}
