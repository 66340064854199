import React from 'react'
import { useTranslation } from 'react-i18next'
import { DemoInfoSection } from '@dataplace.ai/ui-components/molecules/DemoInfoSection/DemoInfoSection'
import { TileFooter } from '../../../../../atoms/TileFooter/TileFooter'
import { AdditionalParamsWrapper, TypeRangeWrapper, Wrapper } from '../AnwPotentialTile/AnwPotentialModelTile.styles'
import { FormDataObject } from '../AnwPotentialModel/hooks/formData/useFormFieldsData'
import { FormDataObjectDemo } from '../DemoScoringPrediction/hooks/formData/useFormFieldsData'
import { FormFieldValue } from '../@types/IAnwPotentialModelTileData'
import { FormElementWrapper } from './FormElementWrapper'

interface IAnwPotentialModelForm {
  accepted: boolean,
  setFormValue: (key: string, value: FormFieldValue) => void;
  handleSubmit: () => void,
  handleDeleteTile: () => void,
  areAllFieldsFilled: boolean,
  formDataObject: FormDataObject | FormDataObjectDemo,
  categoryId: string,
}

export const AnwPotentialModelForm: React.FC<IAnwPotentialModelForm> = ({
  accepted,
  setFormValue,
  handleSubmit,
  handleDeleteTile,
  areAllFieldsFilled,
  formDataObject,
  categoryId,
}) => {
  const { t } = useTranslation()

  const {
    formElementData,
    additionalFormElementData,
  } = formDataObject

  return (
    <Wrapper background={!accepted ? 'dark' : 'light'}>
      <h4>{t('placeme.anw_potential_model.header')}</h4>
      <DemoInfoSection categoryId={categoryId} />
      <TypeRangeWrapper>
        <FormElementWrapper
          elements={formElementData.slice(0, 3)}
          setFormValue={setFormValue}
        />
        <FormElementWrapper
          elements={formElementData.slice(3, 7)}
          grid={4}
          setFormValue={setFormValue}
        />
        {additionalFormElementData && (
          <AdditionalParamsWrapper>
            <span>{t('placeme.anw_potential_model.additional_form_step_header')}</span>
            <FormElementWrapper
              elements={additionalFormElementData.slice(0, 3)}
              setFormValue={setFormValue}
            />
            <FormElementWrapper
              elements={additionalFormElementData.slice(3, 6)}
              setFormValue={setFormValue}
            />
            <FormElementWrapper
              elements={additionalFormElementData.slice(6, 7)}
              setFormValue={setFormValue}
            />
          </AdditionalParamsWrapper>
        )}
      </TypeRangeWrapper>
      <TileFooter
        disabled={!areAllFieldsFilled}
        isUnlimited
        label={t('generic.apply')}
        onAccept={handleSubmit}
        onCancel={handleDeleteTile}
        value='1'
      />
    </Wrapper>
  )
}
