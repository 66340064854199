import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { DatasetObjectOptions, IRadarChartProps, ISimilarLocation } from '../@types/IRadarChartData'
import { labels } from '../data'

const SHARED_DATASETS_OPTIONS = {
  label: '',
  borderWidth: 2,
  backgroundColor: 'transparent',
  lineTension: 0,
  pointBorderWidth: 2.5,
  pointRadius: 1,
  data: [0],
  hidden: false,
}

const DATASET_COLORS = [
  '#DB615D',
  '#E69C5A',
  '#348700',
  '#9D0584',
  '#57B3CB',
  '#362FB7',
  '#9A9A9F',
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useRadarChart({
  similarLocations,
  analysedAddress,
}: IRadarChartProps) {
  const { t } = useTranslation()
  const [visibleAllDatasets, setVisibleAllDatasets] = useState(false)

  const analysedLocation: ISimilarLocation = useMemo(() => ({
    address: `${t('placeme.analysed_location')} (${analysedAddress})`,
    similarity: {
      total: 100,
      metric: Object.keys(similarLocations[0].similarity.metric).reduce((obj, key) => ({
        ...obj,
        [key]: 0,
      }), {}),
    },
  }), [])

  const finalSimilarLocations = useMemo(() => ([
    ...similarLocations,
    analysedLocation,
  ]), [similarLocations])

  const datasetOptions = useMemo(() => (
    DATASET_COLORS.map(color => ({
      ...SHARED_DATASETS_OPTIONS,
      borderColor: color,
    }))
  ), [])

  const locationsSimilarityData = useMemo(() => (
    finalSimilarLocations.map(location => Object.values(location.similarity.metric))
  ), [])

  const radarLabels = useMemo(() => (
    Object.keys(finalSimilarLocations[0].similarity.metric).map(key => labels.find(label => label.key === key)?.value || 'value')
  ), [])

  const [datasets, setDatasets] = useState<DatasetObjectOptions[]>(
    locationsSimilarityData.map((location, index) => {
      datasetOptions[index].data = location
      datasetOptions[index].label = finalSimilarLocations[index].address
      return datasetOptions[index]
    }),
  )

  const radarData = useMemo(() => ({
    labels: radarLabels.map(label => t(`${label}`)),
    datasets,
  }), [locationsSimilarityData, datasets])

  const updateDataset = useCallback((datasetIndex: number) => {
    const changedDataset = datasets[datasetIndex]
    if (!changedDataset) return
    changedDataset.hidden = !changedDataset.hidden
    setDatasets([...datasets])
  }, [datasets, setDatasets])

  const toggleDatasetsVisibility = useCallback(() => {
    const revealedDatasets = datasets.map(dataset => {
      dataset.hidden = !visibleAllDatasets
      return dataset
    })
    setDatasets(revealedDatasets)
    setVisibleAllDatasets(prev => !prev)
  }, [visibleAllDatasets])

  return {
    radarData,
    updateDataset,
    toggleDatasetsVisibility,
    visibleAllDatasets,
  }
}

export default useRadarChart
