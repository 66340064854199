
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, TextButton } from '@dataplace.ai/ui-components/atoms'
import { sortByText } from '@dataplace.ai/functions/utils'
import styled, { css } from 'styled-components'
import { IPois } from 'apps/placeme/src/features/Analyse/components/organisms/Tiles/Market/Pois/@types/IPoisTileData'
import { sortCategoriesNestedSelectList } from 'apps/placeme/src/features/Analyse/components/organisms/Tiles/Market/Pois/functions'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { ISortableTableHeaderProps, ITableFilter } from '../../@types'
import { FilterSection } from '../FilterSection'
import { ITextFilter } from '../TextFilter'
import { Checkbox } from '../CheckboxFilter/components/Checkbox'

const { v4: uuidv4 } = require('uuid')

const CheckboxesOutsideWrapper = styled.div(({ theme: { palette } }) => css`
  position: relative;
  max-height: 15rem;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-top: 1px solid ${palette.gray.light};
  border-bottom: 1px solid ${palette.gray.light};
`)

const CheckboxesWrapper = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > div {
    margin-top: 1rem;
  }

  > div:last-of-type {
    margin-bottom: 1rem;
  }

  label {
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    cursor: pointer;
  }
`)

const TextButtonComponent: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => <TextButton {...props} />

const StyledTextButton = styled(TextButtonComponent)(({
  theme: {
    palette, typography,
  },
}) => css`
  color: ${palette.blue};
  margin-top: .5rem;
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_regular.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};
`)

export interface ICategoryFilter extends ITableFilter {
  [key: string]: {
    type: string
    rule: string
  }
}

interface ICategoryFilterProps {
  data: IPois[];
  filter: ITextFilter,
  setFilterValue(filter: ITableFilter): React.Dispatch<React.SetStateAction<ITableFilter>> | void;
  header: ISortableTableHeaderProps
  clearable?: boolean
  checkboxes?: boolean
}

export const PoisCategoryFilter = ({
  setFilterValue, header, filter, clearable, data, checkboxes,
}: ICategoryFilterProps): JSX.Element => {
  const filterId = `${uuidv4()}`
  const { t } = useTranslation()
  const categories = useMemo(() => {
    if (data?.length) {
      const categoriesObjects : { id: string, content: string}[] = []
      data?.forEach(investment => {
        const categoryId = investment?.category
        if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
          categoriesObjects.push({
            id: categoryId,
            content: t(`placeme.pois_tile.category.${categoryId.toLocaleLowerCase().split(/[_ -]+/)
              .join('-')}`),
          })
        }
      })
      return sortCategoriesNestedSelectList(categoriesObjects).sort((a, b) => a.content.localeCompare(b.content))
    } return []
  }, [data])
  const [showAllCheckBoxes, setShowAllCheckboxes] = useState(false)
  const [checkboxFilter, setCheckboxFilter] = useState('')
  const [filteredSuggestions,
    setFilteredSuggestions] = useState<ResourceWithId[]>(categories)
  const checkboxArray = showAllCheckBoxes
    ? sortByText(filteredSuggestions, 'category', 'ASC')
    : sortByText(filteredSuggestions, 'category', 'ASC').slice(0, 3)
  const [selectedCategories, setSelectedCategories] = useState<string[] | string>(filter[header.name]?.rule || [])

  const setCategoryCheckboxFilterValue = (value: string) => {
    if (!value) {
      setCheckboxFilter('')
      setShowAllCheckboxes(false)
      setFilteredSuggestions(categories)
    } else {
      setCheckboxFilter(value)
      const tempSuggestions = categories.filter(item => typeof item.content! === 'string' && item.content!.toLocaleLowerCase().includes(value))
      setFilteredSuggestions(tempSuggestions)
      setShowAllCheckboxes(true)
    }
  }

  const clearCheckboxFilter = () => {
    const newFilter = {
      ...filter,
    }
    delete newFilter[header.name]
    setCheckboxFilter('')
    setFilteredSuggestions(categories)
    setShowAllCheckboxes(false)
    setSelectedCategories([])
    setFilterValue(newFilter)
  }

  const handleCheckboxChange = (checked: boolean, category: string) => {
    let newSelectedCategories: string[] = []
    if (checked) {
      if (category === 'all') {
        newSelectedCategories = [...categories.map(cat => cat.id), 'all']
      } else {
        newSelectedCategories = [...selectedCategories, category]
        if (newSelectedCategories.length === categories.length) {
          newSelectedCategories.push('all')
        }
      }
    }
    else if (category === 'all') {
      newSelectedCategories = []
    } else {
      newSelectedCategories = [...selectedCategories].filter(selectedCategory => selectedCategory !== category && selectedCategory !== 'all')
    }
    const newFilter = {
      ...filter,
    }
    if (!newSelectedCategories.length) {
      delete newFilter[header.name]
    } else {
      newFilter[header.name] = {
        type: 'category',
        rule: newSelectedCategories.sort((a, b) => a.localeCompare(b)),
      }
    }
    setSelectedCategories(newSelectedCategories)
    setFilterValue(newFilter)
  }

  useEffect(() => {
    if (!filter[header.name]?.rule) {
      setSelectedCategories([])
    }
  }, [filter])

  return (
    <FilterSection
      clear={clearCheckboxFilter}
      clearable={clearable}
      header={t('generic.category')}
    >
      <Input
        onChange={e => {
          setCategoryCheckboxFilterValue(e.target.value)
        }}
        // to be added to poeditor
        placeholder={t('generic.search')}
        value={checkboxFilter}
      />
      {checkboxes && (
        <>
          <CheckboxesOutsideWrapper>
            <CheckboxesWrapper>
              <Checkbox
                checked={selectedCategories.includes('all')}
                id={`${filterId}_all`}
                label={(<label htmlFor={`${filterId}_all`}>{t('generic.all')}</label>)}
                onChange={(e) => handleCheckboxChange(e.target.checked, 'all')}
              />
              {checkboxArray.map(category => (
                <Checkbox
                  key={`${filterId}_${category.id}`}
                  checked={selectedCategories.includes(category.id)}
                  id={`${filterId}_${category.id}`}
                  label={(
                    <label htmlFor={`${filterId}_${category.id}`}>
                      {t(`placeme.pois_tile.category.${category.id.toLocaleLowerCase().split(/[_ -]+/)
                        .join('-')}`)}
                    </label>
                  )}
                  onChange={(e) => handleCheckboxChange(e.target.checked, category.id)}
                />
              ))}
            </CheckboxesWrapper>
          </CheckboxesOutsideWrapper>
          {filteredSuggestions.length > 3 && (
            <StyledTextButton
              onClick={() => setShowAllCheckboxes(!showAllCheckBoxes)}
            >
              {showAllCheckBoxes ? t('generic.hide') : t('generic.show_more')}
            </StyledTextButton>
          )}
        </>
      )}

    </FilterSection>
  )
}

PoisCategoryFilter.defaultProps = {
  clearable: false,
  checkboxes: false,
}

