import { DataType } from '../../../../../Potential/SalesPotential/@types/ISalesPotentialTileData'

export type PotentialLabels = {key: DataType, value: string}[];

export const labels: PotentialLabels = [
  {
    key: 'discountStoreDistance',
    value: 'placeme.demo.scoring_prediction.radar.discount_store_distance',
  },
  {
    key: 'buildings',
    value: 'placeme.demo.scoring_prediction.radar.buildings',
  },
  {
    key: 'dailyTrafficCompetitor',
    value: 'placeme.demo.scoring_prediction.radar.daily_traffic_competitor',
  },
  {
    key: 'restaurantDistance',
    value: 'placeme.demo.scoring_prediction.radar.restaurant_distance',
  },
  {
    key: 'competitorDistance',
    value: 'placeme.demo.scoring_prediction.radar.daily_traffic_competitor_distance',
  },
  {
    key: 'households',
    value: 'placeme.demo.scoring_prediction.radar.households',
  },
  {
    key: 'dailyTraffic',
    value: 'placeme.demo.scoring_prediction.radar.daily_traffic',
  },
]
