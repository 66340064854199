import { DataType } from '../../../../../Potential/SalesPotential/@types/ISalesPotentialTileData'

export type PotentialLabels = {key: DataType, value: string}[];

export const labels: PotentialLabels = [
  {
    key: 'carWash',
    value: 'placeme.anw_sales_potential_tile.radar.carwash',
  },
  {
    key: 'buildings',
    value: 'placeme.anw_sales_potential_tile.radar.buildings',
  },
  {
    key: 'tirDistance',
    value: 'placeme.anw_sales_potential_tile.radar.tirDistance',
  },
  {
    key: 'restaurantDistance',
    value: 'placeme.anw_sales_potential_tile.radar.restaurant_distance',
  },
  {
    key: 'competitorDistance',
    value: 'placeme.anw_sales_potential_tile.radar.competitor_distance',
  },
  {
    key: 'households',
    value: 'placeme.anw_sales_potential_tile.radar.households',
  },
  {
    key: 'dailyTraffic',
    value: 'placeme.anw_sales_potential_tile.radar.daily_traffic',
  },
]
