import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { ILinkWithIconInterface } from '@dataplace.ai/ui-components/atoms/LinksWithIcon/@types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Trash } from '../../../../../assets/src/lib/icons/dataplace/trash.svg'

const Button = styled.button<{focusStyle: boolean}>(({
  theme, focusStyle,
}) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    border: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: ${corners.default.borderRadius};
    outline: none;
    background-color: transparent;
    color: ${palette.results.red.dark};
    cursor: pointer;
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};

    :hover {
      background-color: ${palette.light.darker};
    }

    ${focusStyle && css`
      :focus {
        color: ${palette.light.white};
        background-color: ${palette.results.red.light};
        > svg > path {
          stroke: ${palette.light.white};
        }
      }
    `}

    > span {
      margin-left: 0.25rem;
    }
  `
})

export const LinkWithIconDelete = forwardRef(({
  onClick, focusStyle = true, label = 'generic.delete', className, hideLabel,
}: ILinkWithIconInterface): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Button
      className={className}
      focusStyle={focusStyle}
      onClick={onClick}
    >
      <Trash />
      {!hideLabel && <span>{(label && t(label)) || t('generic.change')}</span>}
    </Button>
  )
})
