
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { IMapLocationProps, LinkWithIconCheckOnMap, MapTile, TileFormWrapper, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { tilesData } from 'apps/placeme/src/features/Analyse/utils/tilesData'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { BasicLayers, GeojsonLayers, WMSLayers, FeatureCollectionLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { subDays } from 'date-fns'
import { minTrafficDate } from 'apps/placeme/src/features/Analyse/utils/constants'
import { ITrafficVisualisationTileData } from './@types/ITrafficVisualisationTileData'
import { RootState } from '../../../../../../../redux/store'
import { RangeAndDateTile } from '../../../../molecules'
import { SettingsBox } from '../../../../molecules/SettingsBox'
import { ComparedLocationHeader } from '../../../../atoms'

const StyledTileFormWrapper = styled(TileFormWrapper)<{background: 'light' | 'dark'}>(({
  theme, background,
}) => {
  const { palette } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
  `
})

interface IExtendedFeatureCollection extends IFeatureCollection {
  data:{
    features:{
      type: string;
      geometry: {
        coordinates: number[];
        type: string;
      };
      properties: {
        title?: string | HTMLElement,
        pinnedItem?: {
          class: string;
          html: string;
        };
        id?: string,
        style:{
          color: string,
          fillColor:string,
          weight:number,
        }

      };
    }[];
  }}

export const TrafficVisualisationMapTile:
React.FC<{data: ITrafficVisualisationTileData, tileId: string, userId: string, }> = ({
  data, tileId, userId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { value } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const [accepted, setAccepted] = useState<boolean>(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  useEffect(() => {
    if (!data) {
      setAccepted(false)
    }
  }, [data])

  useEffect(() => {
    if (data?.value && mapLocation) {
      dispatch(saveTileData('visualization', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  const getCatchmentId = values?.find(c => c.id === 'visualization')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const handleMapOpen = (compared: boolean) => {
    if (compared) { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
    else { setIsMapDisplayed(!isMapDisplayed) }
  }

  const getLayers = (compared: boolean) => {
    if (data?.value) {
      const items = compared ? data?.value?.comparedLocation : data?.value
      const features : IExtendedFeatureCollection['data']['features'] = []
      const tile = values?.find(c => c.id === 'visualization')?.tiles?.find(t =>
        t.id === tileId)
      const rangeCoords = compared
        ? tile?.comparedChosenRange?.geoJSON?.coordinates
        : tile?.chosenRange?.geoJSON?.coordinates
      items?.trafficVisualisationMap?.features?.forEach(feature => {
        features.push({
          geometry: feature?.geometry,
          properties: {
            ...feature?.properties,
          },
          type: 'Feature',
        }) })

      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
        {
          id: `traffic_layer_${getCatchmentId}`,
          layer: {
            data: {
              features,
              type: 'FeatureCollection',
            },
            options: {
              type: 'geojson',
              id: 'traffic_layer_map',
            },
          },
        }]

      // range layer
      if (rangeCoords) {
        layers.push({
          id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
          layer: {
            data: {
              coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: compared ? 'traffic_map_range_compared' : 'traffic_map_range',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        })
      }

      return layers
    }
    return undefined
  }

  return (
    <StyledTileFormWrapper background={accepted ? 'light' : 'dark'}>
      {((!accepted && !data?.value) || (!accepted && !data?.value && !data?.loading) || !data)
        ? (
          <RangeAndDateTile
            accepted={accepted}
            category='visualization'
            maxDate={subDays(new Date(), 3)}
            maxRanges={tilesData.find(cat => cat.id === 'visualization')?.tiles.find(tile => tile.id === tileId.split('-')[0])?.maxRanges}
            minDate={minTrafficDate}
            setAccepted={setAccepted}
            tile={tileId}
            userId={userId}
          />
        )
        : ((!data || data?.loading
          ? <Loader />
          : (
            <TileFormWrapper>
              <SettingsBox
                noEdit
                sectionTile='visualization'
                setAccepted={setAccepted}
                tile={tileId}
                typeRanges={{
                  date: {
                    startDate: data?.value?.dataRanges?.startDate,
                    endDate: data?.value?.dataRanges?.endDate,
                  },
                }}
              />
              {data?.value?.comparedLocation
                    && (
                      <ComparedLocationHeader>
                        <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                        {' '}
                        <span>{value?.address}</span>
                      </ComparedLocationHeader>
                    )}
              <TitleFormSectionSubTitle>
                <span>{t('placeme.traffic_visualisation_tile.section_sub_title_2_span_1')}</span>
                <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
              </TitleFormSectionSubTitle>
              <MapTile
                dragging
                height='300px'
                layers={getLayers(false)}
                location={value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={value?.address}
                setMapLocation={setMapLocation}
                showScaleControl
                width='100%'
                zoom={15}
                zoomControl
              />
              {isMapDisplayed
                && (
                  <MapVisualizationModal
                    isDisplayed={isMapDisplayed}
                    layers={getLayers(false)}
                    location={value}
                    mapId={`traffic-visualisation-tile-${values?.find(c => c.id === 'traffic')?.tiles?.find(t => t.id === 'traffic_visualization')?.chosenRange?.catchmentId}`}
                    setIsDisplay={setIsMapDisplayed}
                    zoom={15}
                  />
                ) }
              {data?.value?.comparedLocation
           && (
             <>
               <ComparedLocationHeader
                 second
                 style={{
                   paddingTop: '2rem',
                 }}
               >
                 <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                 {' '}
                 <span>{comparedLocation?.location?.address}</span>
               </ComparedLocationHeader>

               <TitleFormSectionSubTitle>
                 <span>{t('placeme.traffic_visualisation_tile.section_sub_title_2_span_1')}</span>
                 <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
               </TitleFormSectionSubTitle>
               <MapTile
                 dragging
                 height='300px'
                 layers={getLayers(true)}
                 location={comparedLocation?.location}
                 mapId='example-map-data-tile'
                 pinDisplayed
                 popupHeading={`${t('generic.chosen_location')}:`}
                 popupParagraph={comparedLocation?.location?.address}
                 setMapLocation={setComparedMapLocation}
                 showScaleControl
                 width='100%'
                 zoom={15}
                 zoomControl
               />
               {isComparedMapDisplayed
                && (
                  <MapVisualizationModal
                    isDisplayed={isComparedMapDisplayed}
                    layers={getLayers(false)}
                    location={comparedLocation?.location}
                    mapId={`traffic-visualisation-tile-${values?.find(c => c.id === 'traffic')?.tiles?.find(t => t.id === 'traffic_visualization')?.chosenRange?.catchmentId}_compared`}
                    setIsDisplay={setIsComparedMapDisplayed}
                    zoom={15}
                  />
                ) }
             </>
           )}
            </TileFormWrapper>
          )))}
    </StyledTileFormWrapper>
  )
}

export default TrafficVisualisationMapTile
