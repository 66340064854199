export const generateColors = (values: number[], baseColor: string): string[] => {
  const total = values.length

  const hexToRgb = (hex: string) => {
    let r = 0
    let g = 0
    let b = 0

    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16)
      g = parseInt(hex[2] + hex[2], 16)
      b = parseInt(hex[3] + hex[3], 16)
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16)
      g = parseInt(hex[3] + hex[4], 16)
      b = parseInt(hex[5] + hex[6], 16)
    }

    return {
      r,
      g,
      b,
    }
  }

  const {
    r,
    g,
    b,
  } = hexToRgb(baseColor)

  return values.map((_, index) => {
    const alpha = 1 - (index / total) * 0.7
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  })
}
