import React, { useEffect, useMemo, useState } from 'react'
import { IMapTile } from '@dataplace.ai/ui-components/atoms/MapTile/@types/IMapTile'
import { IMapLocationProps } from '@dataplace.ai/ui-components/atoms'
import { IEventLayerClick, IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { useTranslation } from 'react-i18next'
import { renderToStaticMarkup } from 'react-dom/server'
import { ILocation } from '@dataplace.ai/types'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { TileSectionIds } from '@dataplace.ai/constants'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { ReactComponent as WalkingIcon } from 'libs/shared/assets/src/lib/icons/rangeIcons/walkWhite.svg'
import { ReactComponent as DrivingIcon } from 'libs/shared/assets/src/lib/icons/rangeIcons/carWhite.svg'
import { ReactComponent as CyclingIcon } from 'libs/shared/assets/src/lib/icons/rangeIcons/bike-white.svg'
import { ReactComponent as RadiusIcon } from 'libs/shared/assets/src/lib/icons/rangeIcons/rangeWhite.svg'
import { IOverlappingRangesData, IOverlappingRangesLayers } from '../@types/IOverlappingRanges'

interface MapDataProps {
  data: IOverlappingRangesData
  tileId: string
  contentTimeRanges: ResourceWithId[]
  value?: {
    address: string,
    lat: number,
    lng: number,
    country: string,
  },
  comparedLocation?: {
    location: ILocation
    alreadyGenerated: boolean
    generatedFromNow: boolean
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMapData = ({
  data,
  tileId,
  value,
  comparedLocation,
  contentTimeRanges,
}: MapDataProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [layers, setLayers] = useState<IMapTile['layers']>()
  const [comparedLayers, setComparedLayers] = useState<IMapTile['layers']>()
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const dataValue = data?.value

  const createFeature = (
    geometry: IFeatureCollection['data']['features'][0]['geometry'],
    properties: IFeatureCollection['data']['features'][0]['properties'],
    type: string,
    comparedLocation: boolean,
    numbers: string[],
    fillColor: string,
    fillOpacity: number,
    index?: number,
    totalFeatures?: number,
  ) => ({
    geometry,
    properties: {
      ...properties,
      title: `
        <div style="display: flex; flex-direction: column;">
          <span>
            ${contentTimeRanges?.find(range => range?.id === properties.id)?.content}
            ${t(`placeme.overlapping_ranges.time_selector.by_${type}`)}
          </span>
          <span>
            ${t('placeme.overlapping_ranges.checked_in')}: ${properties.population}
          </span>
        </div>`,
      onClick: (e: IEventLayerClick) => {
        const coordinatesMulti = e?.target?.feature?.geometry?.coordinates as number[][][]
        const coordinatesPolygon = e?.target?.feature?.geometry?.coordinates as number[][]
        const c = (e?.target?.feature?.geometry?.type !== 'Polygon'
          ? coordinatesMulti[0][0][0]
          : coordinatesPolygon[0][0]) as unknown as number[]
        handleLayerClick(c, numbers, e, comparedLocation)
      },
      style: {
        color: fillColor,
        fillColor,
        fillOpacity: fillOpacity ?? handleOpacity(totalFeatures ?? 0, index ?? 0),
        weight: 1,
      },
    },
    type: 'Feature',
  })

  const getNumber = useMemo(() => (value: string) => value?.split('_')[1], [value])
  const iconMap: Record<string, React.ElementType> = {
    walking: WalkingIcon,
    driving: DrivingIcon,
    cycling: CyclingIcon,
    line: RadiusIcon,
  }
  const DEFAULT_ICON = WalkingIcon
  const getIcon = (value: string) => {
    const type = value?.split('_')[0]
    const IconComponent = iconMap[type] || DEFAULT_ICON
    return renderToStaticMarkup(<IconComponent width={15} />)
  }

  const handleOpacity = useMemo(() => (amount: number, index: number) => (0.70 / amount) * (index + 1), [])

  const handleLayerClick = (
    coordiantes: number[],
    numbers: string[],
    e: IEventLayerClick,
    comparedLocation: boolean,
  ) => {
    const properties = e?.target?.feature?.properties
    const type = properties?.id.split('_')[0]
    const layers = comparedLocation
      ? getLayers(dataValue?.comparedLocation?.overlappingRanges, true)
      : getLayers(dataValue?.overlappingRanges, false)

    if (!layers) return
    const newFeatures = layers[0]?.layer?.data?.features?.filter(feat =>
      feat?.properties?.id !== properties?.id)

    newFeatures.push(
      createFeature(
        e?.target?.feature?.geometry,
        {
          id: properties?.id,
          catchmentArea: properties?.catchmentArea,
          population: properties?.population,
        },
        type,
        comparedLocation,
        numbers,
        '#348700',
        0.6,
      ),
    )

    newFeatures.push({
      geometry: {
        coordinates: coordiantes,
        type: 'Point',
      },
      properties: {
        pinnedItem: {
          class: 'poi-img',
          html: `
          <span style="display: flex; width: 75px; height: 27px; border-radius: 6px; color: white; background-color: #348700; align-items: center; justify-content: center;">
            ${getIcon(properties?.id)}
            <p style="font-size: 12px; text-align: center; display:flex; align-items: center; justify-content: center; margin-left: 5px;">
              ${numbers} min
            </p>
          </span>`,
        },
      },
      type: 'Feature',
    })

    newFeatures.sort((a, b) => {
      if (b?.properties?.catchmentArea && a?.properties?.catchmentArea) {
        return b.properties?.catchmentArea - a?.properties?.catchmentArea
      }
      return 0
    })

    if (!comparedLocation) {
      setLayers([{
        id: 'overlapping_ranges_layer',
        layer: {
          data: {
            features: newFeatures,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'overlapping_ranges',
          },
        },
      }])
    } else {
      setComparedLayers([{
        id: 'overlapping_ranges_layer',
        layer: {
          data: {
            features: newFeatures,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'overlapping_ranges',
          },
        },
      }])
    }
  }

  const handleValueLabelClick = (id: string, comparedLocation: boolean) => {
    const val = !comparedLocation ? dataValue?.overlappingRanges : dataValue?.comparedLocation?.overlappingRanges
    const feature = val?.data?.features?.find(feat => feat?.properties?.id === id)
    if (!feature) return
    const numbers = feature ? [getNumber(feature?.properties?.id)] : []
    const coordinatesMulti = feature?.geometry?.coordinates as number[][][]
    const coordinatesPolygon = feature?.geometry?.coordinates as number[][][]
    const c = (feature?.geometry?.type !== 'Polygon' ? coordinatesMulti[0][0][0] : coordinatesPolygon[0][0]) as unknown as number[]
    const e = {
      target: {
        feature: {
          properties: {
            catchmentArea: feature?.properties?.catchmentArea,
            id: feature?.properties?.id,
            population: feature?.properties?.population,
          },
          geometry: {
            coordinates: feature?.geometry?.coordinates,
            type: feature?.geometry?.type,
          },
        },
      },
    }
    handleLayerClick(c, numbers, e, comparedLocation)
  }

  const getLayers = (data: IOverlappingRangesLayers, comparedLocation: boolean) => {
    if (!data) return undefined

    const features : IFeatureCollection['data']['features'] = []
    const dataFeatures = data?.data?.features

    dataFeatures?.forEach((item, index) => {
      const properties = item?.properties
      const type = properties?.id.split('_')[0]
      const numbers = [getNumber(properties?.id)]
      features.push(
        createFeature(
          item?.geometry,
          {
            id: properties?.id,
            catchmentArea: properties?.catchmentArea,
            population: properties?.population,
          },
          type,
          comparedLocation,
          numbers,
          '#423AB3',
          handleOpacity(dataFeatures?.length, index),
          index,
          dataFeatures?.length,
        ),
      )
    })

    features.sort((a, b) => {
      if (b?.properties?.catchmentArea && a?.properties?.catchmentArea) {
        return b?.properties?.catchmentArea - a?.properties?.catchmentArea
      }
      return 0
    })

    return [{
      id: 'overlapping_ranges_layer',
      layer: {
        data: {
          features,
          type: 'FeatureCollection',
        },
        options: {
          type: 'geojson',
          id: 'overlapping_ranges',
        },
      },
    }]
  }

  useEffect(() => {
    if (!dataValue?.overlappingRanges || !mapLocation) return

    dispatch(saveTileData(TileSectionIds.INHABITANTS_AND_VISITORS, tileId, {
      ...data,
      mapLocation,
      comparedMapLocation: dataValue?.comparedLocation ? comparedMapLocation : undefined,
    }))
  }, [mapLocation, comparedMapLocation])

  useEffect(() => {
    if (!dataValue) return
    setLayers(getLayers(dataValue?.overlappingRanges, false))

    if (dataValue?.comparedLocation?.overlappingRanges) {
      setComparedLayers(getLayers(dataValue?.comparedLocation?.overlappingRanges, true))
    }
  }, [dataValue])

  return {
    layers,
    comparedLayers,
    mapLocation,
    comparedMapLocation,
    setMapLocation,
    setComparedMapLocation,
    handleValueLabelClick,
  }
}
