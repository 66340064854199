import { createFlashMessage, getAxios, setCookieWithoutJSON } from '.'
import { localStorageWorkspaceId, refreshWorkspaceId } from './storage'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFirstWorkspaceId = async () => {
  let permissions = []

  const axiosInstance = await getAxios({
    errCallbackFn: () => { permissions = [] },
  })
  delete axiosInstance.defaults.headers['Workspace-Id']
  const response = await axiosInstance.get('permission')

  if (response) {
    if (Array.isArray(response.data)) {
      permissions = response.data
    } else {
      permissions = []
      createFlashMessage({
        message: 'status.error.workspace.no_permissions',
      })
    }
  }

  if (permissions.length > 0) {
    const workspaceId = permissions?.[0]?.workspaceId
    window.localStorage.setItem('workspaceId', workspaceId)
    setCookieWithoutJSON('workspaceId', workspaceId)
  } else {
    createFlashMessage({
      message: 'status.error.workspace.no_permissions',
    })
  }
  window.dispatchEvent(new CustomEvent('refreshWorkspaceId'))
}

export const getValidWorkspace = async (currentWorkspaceId: string):Promise<boolean> => {
  let permissions = []

  const axiosInstance = await getAxios({
    errCallbackFn: () => { permissions = [] },
  })
  delete axiosInstance.defaults.headers['Workspace-Id']
  const response = await axiosInstance.get('permission')

  if (response) {
    if (Array.isArray(response.data)) {
      permissions = response.data
    } else {
      permissions = []
      createFlashMessage({
        message: 'status.error.workspace.no_permissions',
      })
    }
  }

  if (!(permissions.length > 0)) {
    createFlashMessage({
      message: 'status.error.workspace.no_permissions',
    })
    return false
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!permissions.find(workspace => workspace.workspaceId === currentWorkspaceId)) { // if current workspace is not in permissions
    setCookieWithoutJSON(localStorageWorkspaceId, permissions?.[0]?.workspaceId)
    window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
    return true
    // eslint-disable-next-line
  } else {
    setCookieWithoutJSON(localStorageWorkspaceId, currentWorkspaceId)
    window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
    return true
  }
}

