import { useTranslation } from 'react-i18next'
import { LocationTypeOptions } from '../../../@types/IPotentialWithinChain'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetFormData = () => {
  const { t } = useTranslation()

  return {
    dropdownElements: [
      {
        id: LocationTypeOptions.HIGH_STREET,
        content: t('placeme.demo.potential_within_chain.dropdown.high_street'),
      },
      {
        id: LocationTypeOptions.LOCAL,
        content: t('placeme.demo.potential_within_chain.dropdown.local'),
      },
      {
        id: LocationTypeOptions.RETAIL_PARK,
        content: t('placeme.demo.potential_within_chain.dropdown.retail_park'),
      },
    ],
  }
}
