import { ComparedLocationHeader } from '@placeme/components/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface ComparedInfoProps {
  address?: string
}

const StyledComparedLocationHeader = styled(ComparedLocationHeader)`
  margin-top: 2rem;
`

const Title = styled.h5`
  margin-right: 5px;
`

const ComparedInfo: React.FC<ComparedInfoProps> = ({ address }) => {
  const { t } = useTranslation()

  return (
    <StyledComparedLocationHeader>
      <Title>{t('placeme.municipality_population.compared_location.header_1')}</Title>
      <span>{address}</span>
    </StyledComparedLocationHeader>
  )
}

export default ComparedInfo
