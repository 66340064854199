import React from 'react'
import styled, { css } from 'styled-components'
import { TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { PlacemeSelect } from '../PlacemeSelect'
import { IPointsTypeSelector } from './@types/IPointsTypeSelector'

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  margin: 1.5rem 0 1rem 0;
`

const StyledSpan = styled.span(({
  theme: {
    typography,
    palette,
  },
}) => css`
  width: 100%;
  padding: 0;
  color: ${palette.dark.normal};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
`)

const StyledPlacemeSelect = styled(PlacemeSelect)(() => css`
  max-width: 350px;
`)

const Selector = styled.div(({
  theme: {
    palette, corners,
  },
}) => css`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  background: ${palette.light.white};
  border: 1.5px dashed ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`)

export const PointsTypeSelector = ({
  value,
  onChange,
  options,
}: IPointsTypeSelector): JSX.Element => {
  const { t } = useTranslation()

  const handleChange = (_: string, value: ResourceWithId) => {
    onChange(value)
  }

  return (
    <>
      <StyledTitleFormSectionSubTitle>
        {t('placeme.potential_within_chain.dropdown.title')}
      </StyledTitleFormSectionSubTitle>
      <Selector>
        <StyledSpan>{t('placeme.potential_within_chain.dropdown.description')}</StyledSpan>
        <StyledPlacemeSelect
          name=''
          onChange={handleChange}
          options={options}
          placeholder={t('placeme.potential_within_chain.dropdown.placecholder')}
          selected={value}
        />
      </Selector>
    </>
  )
}
