import React from 'react'
import styled, { css } from 'styled-components'
import { Radar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { ModelParams } from 'apps/placeme/src/features/Analyse/components/organisms/Tiles/Potential/SalesPotential/@types/ISalesPotentialTileData'
import { SalesPotentialLabels } from 'apps/placeme/src/features/Analyse/components/organisms/Tiles/Potential/SalesPotential/data'
import { Influence } from 'apps/placeme/src/features/Analyse/components/organisms/Tiles/Anw/AnwPotentialModel/@types/IAnwPotentialModelTileData'
import { IRadarChartProps } from './@types/IRadarChart.props'

const RadarWrapper = styled.div`
  padding: 30px 0;
`
const Label = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${palette.black};
  margin-top: 30px;

  > span {
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  }
` })

export const getAnwRadarChartData = (data: Influence): number[] =>
  data && Object.values(data)?.map(value => value)

export const getRadarChartData = (data: ModelParams): number[] =>
  data && Object.values(data)?.map(value => value?.influence)

export const getRadarChartLabels = (data: ModelParams | Influence, labels: SalesPotentialLabels): string[] =>
  data && Object.keys(data)?.map(key => labels?.find(label => label?.key === key)?.valueForChart || labels?.find(label => label?.key === key)?.value || 'Value')

export const RadarChart = ({
  data, labels, secondData, minScale = 0, maxScale,
}: IRadarChartProps): JSX.Element => {
  const { t } = useTranslation()

  const minDisplay = minScale || 1
  const maxDisplay = maxScale || Math.max(...(Array.isArray(data) ? data : []))

  const dataset = {
    labels: labels?.map(label => t(`${label}`)),
    datasets: secondData
      ? [
        {
          label: '',
          borderWidth: 1,
          backgroundColor: 'rgba(54, 47, 183, 0.3)',
          borderColor: '#362FB7',
          lineTension: 0,
          pointBorderWidth: 1.5,
          pointRadius: 1,
          data,
        },
        {
          label: '',
          borderWidth: 1,
          backgroundColor: 'rgba(216, 168, 0, 0.3)',
          borderColor: '#D8A800',
          lineTension: 0,
          pointBorderWidth: 1.5,
          pointRadius: 1,
          data: secondData,
        },
      ]
      : [
        {
          label: '',
          borderWidth: 1,
          backgroundColor: 'rgba(54, 47, 183, 0.3)',
          borderColor: '#362FB7',
          lineTension: 0,
          pointBorderWidth: 1.5,
          pointRadius: 1,
          data,
        },
      ],
  }
  return (
    <RadarWrapper>
      <Radar
        data={dataset}
        options={{
          legend: {
            display: false,
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
          tooltips: {
            enabled: false,
          },
          scale: {
            pointLabels: {
              // Splits a string `label` into lines, each with a maximum length of 40 characters.
              // This helps in limiting text length to maintain the style of radar charts.
              callback(label: string) {
                const words = label.split(' ')
                const lines = words.reduce<string[]>((acc, word) => {
                  const lastLine = acc[acc.length - 1]
                  if (lastLine && lastLine.length + word.length + 1 <= 40) {
                    acc[acc.length - 1] += ` ${word}`
                  } else {
                    acc.push(word)
                  }
                  return acc
                }, [])
                return lines
              },
              fontSize: 11,
            },
            ticks: {
              beginAtZero: true,
              min: minScale,
              max: maxScale,
            },
          },

        }}
      />
      <Label>
        <span><strong>{t('placeme.sales_potential_tile.radar.legend.key')}</strong></span>

        <span>
          {`${minDisplay} - ${t('placeme.sales_potential_tile.radar.legend.value.lowest')} ${maxDisplay} - ${t('placeme.sales_potential_tile.radar.legend.value.highest')}`}
        </span>
      </Label>
    </RadarWrapper>
  )
}
