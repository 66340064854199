import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { ISectionTile } from 'apps/placeme/src/features/Analyse/slice/@types/ISectionTile'
import { TileSectionIds } from '@dataplace.ai/constants'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { CommercialAndServicesData, ResidentialData } from '../@types/IInvestmentsTileData'
import { investmentTypes } from './constants'

type IconTypes = (typeof investmentTypes)[keyof typeof investmentTypes]

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function getInvestmentIcon(category: string) {
  switch (category) {
    case investmentTypes.administration:
      return 'assets/icons/placeme/administrationInvestments.svg'
    case investmentTypes.residential:
      return 'assets/icons/placeme/residentialInvestments.svg'
    case investmentTypes.commercialAndServices:
      return 'assets/icons/placeme/servicesInvestments.svg'
    case investmentTypes.others:
      return 'assets/icons/placeme/otherInvestments.svg'
    case investmentTypes.infrastructural:
      return 'assets/icons/placeme/infrastructuralInvestments.svg'
    default:
      return 'assets/icons/placeme/otherInvestments.svg'
  }
}

interface GetLayersProps {
  tileId: string
  data?: ResidentialData | CommercialAndServicesData
  sectionTilesData: ISectionTile[]
  iconType: IconTypes
  features?: IFeatureCollection['data']['features']
  compared?: boolean
}

export function getLayers({
  tileId, data, sectionTilesData, iconType, compared = false,
}: GetLayersProps) {
  if (!data) return
  const tile = getTileInfo(tileId, TileSectionIds.SURROUNDINGS, sectionTilesData)
  const features : IFeatureCollection['data']['features'] = []
  const rangeCoords = compared
    ? tile?.comparedChosenRange?.geoJSON?.coordinates
    : tile?.chosenRange?.geoJSON?.coordinates
  data.list.forEach(invest => {
    features.push({
      geometry: {
        coordinates: [invest?.lng, invest?.lat],
        type: 'Point',
      },
      properties: {
        title: `<div style="display: flex; flex-direction: column;"><span>${invest?.name}</span><span>${invest?.address}</span></div>`,
        pinnedItem: {
          iconAnchor:[11, 11],
          class: 'poi-img',
          html: `<img alt='' src='${getInvestmentIcon(iconType)}'/>`,
        },
      },
      type: 'Feature',
    })
  })

  const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
    {
      id: 'investment-layer',
      layer: {
        data: {
          features,
          type: 'FeatureCollection',
        },
        options: {
          type: 'geojson',
          id: 'investments',
        },
      },
    }]
  // range layer
  if (rangeCoords) {
    layers.push({
      id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
      layer: {
        data: {
          coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
          type: 'Polygon',
          properties: {},
        },
        options:{
          type: 'geojson',
          id: compared ? 'invest_range_compared' : 'invest_range',
          style: {
            color: '#0000a2',
            fillColor:'#0000a2',
            weight: 1.5,
            fillOpacity: 0.05,
          },
        },
      },
    })
  }

  return layers
}

export function getDeviation(value: number, comparedValue: number) {
  if (value === comparedValue) return 0
  return Math.round(((value / comparedValue) - 1) * 10000) / 100
}
