import { IDropdownCaretProps } from '@dataplace.ai/ui-components/atoms/Icons/DropdownCaret/@types/IDropdownCaret.props'
import styled, { css } from 'styled-components'
import {
  mediaMax as media,
} from '@dataplace.ai/ui-components/themes'

export const Wrapper = styled.div<{width?: string}>(({ width }) => css`
  width: ${width || '100%'};
`)

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 500;
  gap: 0.5rem;
  width: 70%;
`

export const DropdownContent = styled.div<IDropdownCaretProps>(({
  active, theme, height, width,
}) => {
  const { shadows } = theme

  return css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${active ? '10px' : '0'};
    background-color: white;
    height: ${active ? 'auto' : 0};
    overflow: hidden;
    width: ${width || 'max-content'};
    margin-top: 2.5rem;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: height 0.3s;
    border-radius: 6px;
    position: absolute;
    transform: ${height && `translate(0px, ${height}px)`};
    box-shadow: ${shadows.hover.boxShadow};
    z-index: 999;
  `
})

export const DropdownHeader = styled.div<IDropdownCaretProps>(({
  active, theme, transparent, noBorder, width,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    background-color: ${transparent ? 'transparent' : 'white'};
    border-radius: 6px;
    border: 1px solid ${noBorder ? 'none' : (active ? palette.product.location.main : '#E1E2E3')};
    padding: 5px 9px;
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};
    z-index: 998;
    width: ${width || '100%'};

    ${media.desktop_medium`
      font-size: 11px;
    `}

    .arrow {
      margin-left: 0.5rem;
      float: right;
      path {
        stroke: ${palette.blue};
      }
      transform: ${active ? 'rotate(180deg)' : 'rotate(0deg)'};
      min-width: 0.45rem;
      max-width: 0.45rem;
    }
  `
})

export const DropdownHeaderBox = styled.div`
  margin: 0 auto;
`

export const ListItem = styled.li(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    padding: 0.7rem;
    list-style: none;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    z-index:999;
    width: 8.5rem;

    :hover {
      font-weight: ${typography.main.pt_15_medium.fontWeight};
    }
  `
})

export const NoItemsToDisplay = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    padding: 0.75rem 2.5rem;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
  `
})

export const SelectionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: space-evenly;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`

export const SelectionBox = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.blue};
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 0.6rem;
    padding: 0.2rem 0.6rem;

  span {
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    color: ${palette.light.lighter} !important;
  }

  svg {
    cursor: pointer;
    width: 1rem;
    > path {
      stroke: ${palette.light.lighter};
    }
  }
` })
