import { useTranslation } from 'react-i18next'
import { FormFields, InitialFormState } from '../../AnwPotentialModel'
import { useGetAnwDropdownOptions } from './useGetAnwDropdownOptions'
import { FormElement } from '../../../@types/IAnwPotentialModelTileData'

export interface FormDataObject {
  formElementData: FormElement[];
  additionalFormElementData?: FormElement[] | null;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormFieldsData = (
  formState: InitialFormState,
  areAllFieldsFilled: boolean,
  isShoppingMallFilled: boolean,
) => {
  const { t } = useTranslation()
  const {
    salesAreaOptions,
    plannedStationTypes,
    options,
    cafeMoyaOptions,
    plannedDistributorsNumber,
    sizeOptions,
  } = useGetAnwDropdownOptions()

  const formElementData: FormElement[] = [
    {
      formStateElement: formState.distributorsNumber,
      inputKey: FormFields.distributorsNumber,
      options: plannedDistributorsNumber,
      title: t('placeme.anw_potential_model.criteria.distributors_number'),
    },
    {
      formStateElement: formState.salesArea,
      inputKey: FormFields.salesArea,
      options: salesAreaOptions,
      title: t('placeme.anw_potential_model.criteria.choose_type_area_store'),
      width: '10rem',
    },
    {
      formStateElement: formState.stationType,
      inputKey: FormFields.stationType,
      options: plannedStationTypes,
      title: t('placeme.anw_potential_model.criteria.station_type'),
      width: '12rem',
    },
    {
      formStateElement: formState.availableCafeStock,
      inputKey: FormFields.availableCafeStock,
      options: cafeMoyaOptions,
      title: t('placeme.anw_potential_model.criteria.cafe_stock'),
    },
    {
      formStateElement: formState.carwash,
      inputKey: FormFields.carwash,
      options,
      title: t('placeme.anw_potential_model.criteria.is_carwash'),
    },
    {
      formStateElement: formState.restaurant,
      inputKey: FormFields.restaurant,
      options,
      title: t('placeme.anw_potential_model.criteria.restaurant_on_station'),
    },
    {
      formStateElement: formState.open24H,
      inputKey: FormFields.open24H,
      options,
      title: t('placeme.anw_potential_model.criteria.open_24h'),
    },
  ]

  const additionalFormElementData: FormElement[] = [
    {
      formStateElement: formState.expressway,
      inputKey: FormFields.expressway,
      options,
      title: t('placeme.anw_potential_model.criteria.is_highway_planned'),
    },
    {
      formStateElement: formState.competitiveStation,
      inputKey: FormFields.competitiveStation,
      options,
      title: t('placeme.anw_potential_model.criteria.is_competitive_company'),
    },
    {
      formStateElement: formState.restaurantPlanned,
      inputKey: FormFields.restaurantPlanned,
      options,
      title: t('placeme.anw_potential_model.criteria.is_restaurant_planned'),
    },
    {
      formStateElement: formState.truckParking,
      inputKey: FormFields.truckParking,
      options,
      title: t('placeme.anw_potential_model.criteria.is_track_parking_planned'),
    },
    {
      formStateElement: formState.shoppingMall,
      inputKey: FormFields.shoppingMall,
      options,
      title: t('placeme.anw_potential_model_tile.criteria.if_shopping_mall'),
    },
  ]
  if (isShoppingMallFilled) {
    additionalFormElementData.push({
      formStateElement: formState.shoppingMallSize,
      inputKey: FormFields.shoppingMallSize,
      options: sizeOptions,
      title: t('placeme.anw_potential_model_tile.criteria.what_future_shopping_mall_size'),
    })
  }

  return {
    formElementData,
    additionalFormElementData: areAllFieldsFilled ? additionalFormElementData : null,
  }
}
