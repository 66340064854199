import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { anwCategoryId } from '@dataplace.ai/constants'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { FormFieldValue, IAnwPotentialModelTileData } from '../@types/IAnwPotentialModelTileData'
import { AnwPotentialModelTile } from '../AnwPotentialTile/AnwPotentialModelTile'
import { useSelectedSettings } from './hooks/resultData/useSelectedSettings'
import { labels } from './hooks/resultData/data'
import { useFormFieldsData } from './hooks/formData/useFormFieldsData'

export enum FormFields {
  distributorsNumber = 'distributorsNumber',
  salesArea = 'salesArea',
  stationType = 'stationType',
  availableCafeStock = 'availableCafeStock',
  carwash = 'carwash',
  restaurant = 'restaurant',
  open24H = 'open24H',
  shoppingMall = 'shoppingMall',
  shoppingMallSize = 'shoppingMallSize',
  expressway = 'expressway',
  competitiveStation = 'competitiveStation',
  restaurantPlanned = 'restaurantPlanned',
  truckParking = 'truckParking',
}

export interface InitialFormState {
  distributorsNumber: number | null;
  salesArea: string | null;
  stationType: string | null;
  availableCafeStock: string | null;
  carwash: boolean | null;
  restaurant: boolean | null;
  open24H: boolean | null;
  shoppingMall: boolean;
  shoppingMallSize?: string;
  expressway: boolean;
  competitiveStation: boolean;
  restaurantPlanned: boolean;
  truckParking: boolean;
  [key: string]: number | string | boolean | null | undefined;
}

const initialState = {
  distributorsNumber: null,
  salesArea: null,
  stationType: null,
  availableCafeStock: null,
  carwash: null,
  restaurant: null,
  open24H: null,
  expressway: false,
  competitiveStation: false,
  restaurantPlanned: false,
  truckParking: false,
  shoppingMall: false,
  shoppingMallSize: 'medium',
}

const requiredFields = [
  FormFields.distributorsNumber,
  FormFields.salesArea,
  FormFields.stationType,
  FormFields.availableCafeStock,
  FormFields.carwash,
  FormFields.restaurant,
  FormFields.open24H,
]

export const AnwPotentialModel: React.FC<{
  data: IAnwPotentialModelTileData,
  tileId: string,
}> = ({
  data, tileId,
}) => {
  const { values } = useSelector((state: RootState) => state.analysis)
  const { value: { address } = {} } = useSelector((state: RootState) => state.location)

  const [formState, setFormState] = useState<InitialFormState>(initialState)

  const areAllFieldsFilled = useMemo(() =>
    requiredFields.every(key => formState[key] !== null), [formState])

  const settingList = useSelectedSettings(data)
  const formDataObject = useFormFieldsData(formState, areAllFieldsFilled, formState.shoppingMall)

  const setFormValue = useCallback((key: string, value: FormFieldValue) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }, [setFormState])

  const catchmentId = useMemo(() => values.find(cat => cat.id === anwCategoryId)?.tiles.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId, [values])

  const body = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mainBody: any = {
      catchmentId,
      address,
      distributorsNumber: formState.distributorsNumber,
      salesArea: formState.salesArea,
      stationType: formState.stationType,
      availableCafeStock: formState.availableCafeStock,
      carwash: formState.carwash,
      restaurant: formState.restaurant,
      open_24h: formState.open24H,
      plannedAdditions: {
        expressway: formState.expressway,
        competitiveStation: formState.competitiveStation,
        restaurant: formState.restaurantPlanned,
        truckParking: formState.truckParking,
        shoppingMall: formState.shoppingMall,
      },
    }
    if (formState.shoppingMall) {
      mainBody.plannedAdditions.shoppingMallSize = formState.shoppingMallSize
    }

    return mainBody
  }, [formState, catchmentId, address])

  return (
    <AnwPotentialModelTile
      areAllFieldsFilled={areAllFieldsFilled}
      body={body}
      categoryId={anwCategoryId}
      data={data}
      endpoint={ENDPOINTS.ANW_POTENTIAL_MODEL_TILE}
      formDataObject={formDataObject}
      formState={formState}
      labels={labels}
      setFormValue={setFormValue}
      settingList={settingList}
      tileId={tileId}
    />
  )
}
