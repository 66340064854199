import styled, { css } from 'styled-components'

export const Wrapper = styled.div(({ theme:{ palette } }) => css`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  background-color: ${palette.light.white};
`)

interface TitleProps {
  $borderTop?: boolean
}

export const Title = styled.h4<TitleProps>(({
  theme: {
    palette,
    typography,
  },
  $borderTop,
}) => css`
  font-weight: 500;
  font-size: ${typography.main.pt_15_semibold.fontSize};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
  letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
  text-transform: ${typography.main.pt_15_semibold_upper.textTransform};
  color: ${palette.black};
  margin-bottom: 0.5rem;
  ${$borderTop && css`
    border-top: 1px solid ${palette.light.darker};
    padding-top: 1.5rem;
  `}
`)

export const SubTitle = styled.h5<TitleProps>(({
  theme: {
    palette,
    typography,
  },
  $borderTop,
}) => css`
  font-weight: 600;
  font-size: ${typography.tiny.pt_12_semibold.fontSize};
  line-height: ${typography.tiny.pt_12_semibold.lineHeight};
  letter-spacing: ${typography.tiny.pt_12_semibold.letterSpacing};
  text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
  color: ${palette.black};
  margin-bottom: 10px;
  ${$borderTop && css`
      border-top: 1px solid ${palette.light.darker};
      padding-top: 1.5rem;
      margin-top: 1.5rem;
  `}
  `)

export const Section = styled.div(({
  theme: {
    palette,
    corners,
  },
}) => css`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${palette.light.white};
  border: 1px solid ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
  color: ${palette.black};
  margin-bottom: 30px;
`)

export const Estimation = styled.div(() => css`
  margin: 10px 0;
`)

export const EstimationLabel = styled.span(({
  theme: {
    palette,
    typography,
  },
}) => css`
  color: ${palette.black};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
`)

export const EstimationParagraph = styled.p(({
  theme: {
    palette,
    typography,
  },
}) => css`
  font-size: ${typography.main.pt_15_semibold.fontSize};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
  font-weight: ${typography.main.pt_15_semibold.fontWeight};
  letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
  color: ${palette.black};
  margin-bottom: 10px;
  > strong {
      font-size: 28px;
      line-height: 40px;
      margin-left: 0.5rem;
  }
`)

export const RankingDescription = styled.span(({
  theme: {
    typography, palette,
  },
}) => css`
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
  margin-bottom: 10px;
  color: ${palette.dark.normal};
`)

export const TagButtonWrapper = styled.div(() => css`
  width: 100%;
  display: flex;
  justify-content: flex-start; 
  margin-top: 20px;
`)

export const SimilarAdressWrapper = styled.ul(({ theme: { palette } }) => css`
  width: 100%;
  list-style-type: none;

  >li:nth-child(2n){
  background-color: ${palette.light.main};
  }
`)

interface TableSpanProps {
  $bold?: boolean
  $left?: boolean
}

export const TableSpan = styled.span<TableSpanProps>(({
  theme: { typography },
  $bold,
  $left,
}) => css`
  display: flex !important;
  align-items: center;
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${$bold ? typography.small.pt_13_semibold.fontWeight : typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
  white-space: wrap !important;
  ${$left ? css`justify-content: flex-start;` : css`justify-content: flex-end;`}
`)

export const SeeMoreWrapper = styled.div(({ theme: { palette } }) => css`
  background-color: ${palette.light.darker};
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -20px;
  margin-bottom: -20px;
  margin-top: 20px;
  padding: 26px 20px;
  border-top: 1px solid ${palette.light.darkest};
`)

export const StyledSpan = styled.span(() => css`
  max-width: 60%;
  margin-right: 1rem;
`)
