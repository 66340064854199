import { Tooltip } from '@dataplace.ai/ui-components/atoms'
import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as HelpIcon } from '../../../../assets/src/lib/icons/dataplace/help-circle.svg'

interface WrapperProps {
  $positive: boolean
  $width?: string
}
const Wrapper = styled.div<WrapperProps>(({
  theme: { palette },
  $positive,
  $width,
}) => css`
  display: flex;
  width: ${$width || 'auto'};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 7px;
  background: ${$positive ? palette.results.green.opaque : palette.results.red.opaque};
  border-radius: 40px;
`)

const StyledHelpIcon = styled(HelpIcon)<{$positive: boolean}>(({
  theme: { palette },
  $positive,
}) => css`
  width: 15px;
  height:15px;
  margin-left: 5px;
  path {
    fill:${$positive ? palette.results.green.light : palette.results.red.light};
  }
`)

const Number = styled.p<{$positive: boolean}>(({
  theme: {
    typography,
    palette,
  },
  $positive,
}) => css`
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
  color: ${$positive ? palette.results.green.light : palette.results.red.light};
  white-space: nowrap;
`)

interface TooltipWrapperProps {
  $maxWidth?: string
}

export const TooltipWrapper = styled.div<TooltipWrapperProps>(({ $maxWidth }) => css`
  display: flex;
  flex-direction: column;
  color: white;
  ${$maxWidth && css`max-width: ${$maxWidth};`}
`)

export interface IBean{
  value: number
  width?: string
  tooltipText?: string
  tooltipMaxWidth?: string
  style?: React.CSSProperties
}

export const Bean = ({
  value,
  width,
  style,
  tooltipText,
  tooltipMaxWidth,
}:IBean): JSX.Element => {
  const positive = !!(value && value >= 0)

  return (
    <Tooltip
      content={<TooltipWrapper $maxWidth={tooltipMaxWidth}>{tooltipText}</TooltipWrapper>}
      position='bottom right'
    >
      <Wrapper
        $positive={positive}
        $width={width}
        style={style}
      >
        <Number $positive={positive}>{value > 0 ? `+ ${value}%` : `${value}%`}</Number>
        <StyledHelpIcon $positive={positive} />
      </Wrapper>
    </Tooltip>
  )
}
