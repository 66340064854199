/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { Pagination } from '@dataplace.ai/ui-components/molecules'
import { StyledHeader } from '../Investments.styles'
import { CommonInvestment, ResidentialInvestment } from '../@types/IInvestmentsTileData'
import Expandable from './Expandable'
import { investmentsTableData } from '../data'

interface InvestmentsListProps {
  title?: string
  residentialInvestments?: ResidentialInvestment[]
  commonInvestments?: CommonInvestment[]
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function InvestmentsList({
  title, residentialInvestments, commonInvestments,
}: InvestmentsListProps) {
  const { t } = useTranslation()
  const { labels } = investmentsTableData

  const residentialInvestmentsData = useMemo(() => (
    residentialInvestments?.map(investment => [investment.name, investment.address, investment.distance,
      investment.apartmentsCount, investment.date, investment.investor,
    ]) as string[][]
  ), [residentialInvestments])

  const commonInvestmentsData = useMemo(() => (
    commonInvestments?.map(investment => [investment.name, investment.address, investment.distance,
      investment.volume, investment.dateOfApplication, investment.info, investment.investor,
    ]) as string[][]
  ), [commonInvestments])

  return (
    <>
      {title
        ? <StyledHeader>{title}</StyledHeader>
        : null}
      <Pagination
        data={residentialInvestmentsData ?? commonInvestmentsData ?? []}
        dataLimit={5}
        justifyNavigation='center'
        renderComponent={(data) => {
          const newData = data as string[]
          return (
            <Table
              content={
                residentialInvestments
                  ? newData?.map(investment => [
                    <span key={investment[0]}>{investment[0]}</span>,
                    <span key={investment[1]}>{investment[1]}</span>,
                    <span key={investment[2]}>{investment[2]}</span>,
                  ])
                  : commonInvestmentsData
                    ? newData?.map(investment => [
                      <span key={investment[0]}>{investment[0]}</span>,
                      <span key={investment[1]}>{investment[1]}</span>,
                      <span key={investment[2]}>{investment[2]}</span>,
                    ])
                    : []
              }
              expandableComponent={residentialInvestments
                ? newData.map(investment => (
                  <Expandable
                    key={investment[1]}
                    apartmentsCount={investment[3]}
                    dateOfCompletion={investment[4]}
                    investorName={investment[5]}
                  />
                ))
                : commonInvestmentsData
                  ? newData.map(investment => (
                    <Expandable
                      key={investment[1]}
                      capacity={investment[3]}
                      dateOfApplication={investment[4]}
                      info={investment[5]}
                      investorName={investment[6]}
                    />
                  ))
                  : undefined}
              gap='1rem'
              headerTemplate='5fr 3fr 4fr 1fr'
              labels={labels.map((label) => (
                <span key={label}>{t(label)}</span>
              ))}
              rowTemplate='5fr 3fr 4fr 1fr'
              whiteSpaces='wrap'
            />
          )
        }}
      />
    </>
  )
}

export default InvestmentsList
