import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Checkbox } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { ISortableTableHeaderProps, ITableFilter } from '../../@types'
import { FilterSection } from '../FilterSection'

const CheckboxesWrapper = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;

  span {
    /* margin-left: .5rem; */
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  }
`)

const SelectAllWrapper = styled.div(() => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`)

export interface ITextFilter extends ITableFilter {
  [key: string]: {
    type: string
    rule: string | string[]
  }
}

interface ITextFilterProps {
  filter: ITextFilter,
  setFilterValue(filter: ITableFilter): React.Dispatch<React.SetStateAction<ITableFilter>> | void;
  header: ISortableTableHeaderProps
  clearable?: boolean
  checkboxes?: boolean
}

export const TextFilter = ({
  setFilterValue, header, filter, clearable, checkboxes,
}: ITextFilterProps): JSX.Element => {
  const { t } = useTranslation()
  const [textFilter, setTextFilter] = useState(filter[header.name]?.rule || '')

  const setTableFilterValue = (value: string) => {
    if (!value) {
      clearField()
    } else {
      const newFilter = {
        ...filter,
        [header.name]: {
          type: 'text',
          rule: value,
        },
      }
      setFilterValue(newFilter)
      setTextFilter(value)
    }
  }

  const clearField = () => {
    const clearedFilter = {
      ...filter,
    }
    delete clearedFilter[header.name]
    setFilterValue(clearedFilter)
    setTextFilter('')
  }

  useEffect(() => {
    setTextFilter(filter[header.name]?.rule || '')
  }, [filter])

  return (
    <FilterSection
      clear={clearField}
      clearable={clearable}
      header={t(`generic.${header.label}`)}
    >
      <Input
        onChange={e => {
          setTableFilterValue(e.target.value)
        }}
        placeholder={t('generic.search')}
        value={textFilter}
      />

      {checkboxes && (
        <CheckboxesWrapper>
          <SelectAllWrapper>
            <Checkbox
              label={<span>{t('generic.all')}</span>}
            />
          </SelectAllWrapper>
        </CheckboxesWrapper>
      )}

    </FilterSection>
  )
}

TextFilter.defaultProps = {
  clearable: false,
  checkboxes: false,
}
