import { IRange, RangeType } from '@dataplace.ai/types'
import { rangeTypes } from '@dataplace.ai/constants'
import { I18nProvider } from 'libs/shared/features/src/components/I18nProvider'
import { getCookieWithoutJSON } from './cookies'

// Do dodawania do tlumaczen przy odmianie okresow
export const polishPeriod = (value: number): string => {
  if (value === 1) { return '' } // 1 minuta, dzien, miesiac, rok
  if (value <= 4 || (value > 21 && value % 10 <= 4)) { return '_pl_x2_to_x4' } // 22 minuty, dni, miesiace, lata
  return '_pl_x5_to_x1' // 6 minut, 14 dni, 21 miesiecy, 5 lat
}

export const getRangeTranslation = (range: IRange | undefined): string => {
  if (range?.id?.includes(rangeTypes.custom)) { return I18nProvider.t('placeme.range.custom_draw_range') }
  switch (range?.type) {
    case rangeTypes.walk: { return `${range?.value} ${I18nProvider.t(`placeme.range.minutes_by_foot${polishPeriod(range.value)}`)}` }
    case rangeTypes.car: { return `${range?.value} ${I18nProvider.t(`placeme.range.minutesbycar${polishPeriod(range.value)}`)}` }
    case rangeTypes.line: { return range?.value
      ? (range.value > 999
        ? `${range.value / 1000} km`
        : `${range.value} m`)
      : '' }
    default: { return '' }
  }
}

export const getRangeTranslationFromRangeId = (id: string | undefined): string => {
  if (!id) { return '' }
  const value = parseInt(id.split('-')?.[0], 10)
  const rangeType = id.split('-')?.[1] as RangeType
  const range = {
    id,
    type: rangeType,
    value,
  }
  return getRangeTranslation(range)
}

export const getLanguage = (): string => {
  const language = getCookieWithoutJSON('language')
  if (language) {
    if (language !== 'pl') return 'en-pl'
  }
  return 'pl'
}
