import { TileFormRow } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormRow/TileFormRow'
import React from 'react'
import { TileFormColumn } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormColumn/TileFormColumn'
import { TileFormRowWithData } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormRowWithData/TileFormRowWithData'
import { DoughnutChart } from '@dataplace.ai/ui-components/atoms/Charts/DoughnutChart/DoughnutChart'
import { TileFormSectionTitle } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormSectionTitle/TileFormSectionTitle'
import { TileFormSpan } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormSpan/TileFormSpan'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { ISegmentationData } from '../SegmentationTile'
import { useGetDoughnutData } from './useGetDoughutData'

export const DoughnutElement: React.FC<{ element: 'segment' | 'traffic', data: ISegmentationData }> = ({
  element, data,
}) => {
  const { elementData } = useGetDoughnutData(data, element)

  return (
    <>
      <TileFormSectionTitle>{elementData.title}</TileFormSectionTitle>
      <TileFormSpan>{elementData.subtitle}</TileFormSpan>
      <TileFormRow
        width='100%'
        wrap='nowrap'
      >
        <TileFormColumn
          align='start'
          width='60%'
        >
          <TileFormRowWithData legend={defaultTheme.palette.product.location.main}>
            <span>{elementData.firstSegment.span}</span>
            <span>{`${parseFloat((elementData.firstSegment.value).toFixed(1))}%`}</span>
          </TileFormRowWithData>
          <TileFormRowWithData legend={defaultTheme.palette.product.location.medium}>
            <span>{elementData.secondSegment.span}</span>
            <span>{`${parseFloat((elementData.secondSegment.value).toFixed(1))}%`}</span>
          </TileFormRowWithData>
        </TileFormColumn>
        <TileFormColumn width='40%'>
          <DoughnutChart
            data={[elementData.firstSegment.value,
              elementData.secondSegment.value]
              .map(number => parseFloat(number.toFixed(1)))}
            innerLabels
            labels={[elementData.firstSegment.span, elementData.secondSegment.span]}
            valueType='percentages'
            width={230}
          />
        </TileFormColumn>
      </TileFormRow>
    </>
  )
}
