import { TileFormRowWithData, TileFormSection } from '@dataplace.ai/ui-components/atoms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { PointsTypeSelector } from '@placeme/components/molecules/PointsTypeSelector/PointsTypeSelector'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { sum, values } from 'lodash'
import { useTranslation } from 'react-i18next'
import { TileFooter } from '@placeme/components/atoms'
import { DemoInfoSection } from '@dataplace.ai/ui-components/molecules/DemoInfoSection/DemoInfoSection'
import { FooterWrapper, FormWrapper, StyledTileFormWrapper, TileFormParagraphWithTooltip } from './PotentialWithinChainForm.styles'
import { FormFields } from '../@types/IPotentialWithinChain'
import { PercentagesForm } from './PercentagesForm'

interface IPotentialWithinChainForm {
  categoryId: string
  options?: Pick<ResourceWithId, 'id' | 'content'>[]
  setFormState: Dispatch<SetStateAction<FormFields>>
  setLocationTypeId: Dispatch<SetStateAction<string | undefined>>
  locationType?: ResourceWithId
  formState: FormFields
  handleDeleteTile: () => void
  handleSave: () => void
}

export const PotentialWithinChainForm: React.FC<IPotentialWithinChainForm> = ({
  categoryId, setFormState, setLocationTypeId, locationType, formState, handleDeleteTile, handleSave, options,
}) => {
  const { t } = useTranslation()

  const setInitialState = () => {
    if (locationType?.initState) {
      setFormState(locationType.initState)
    }
  }

  const handleChange = (value: ResourceWithId) => {
    setLocationTypeId(value.id)
    setInitialState()
  }

  useEffect(() => {
    setInitialState()
  }, [setLocationTypeId, locationType])

  const percentagesSum = useMemo(() => sum(values(formState)), [formState])
  const invalidSum = percentagesSum !== 100

  const setFormValue = useCallback((key: string, value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: Number(value),
    }))
  }, [setFormState])

  return (
    <StyledTileFormWrapper>
      <FormWrapper>
        <TileFormSection>
          <TileFormRowWithData>
            <span>{t('placeme.potential_within_chain.form.title')}</span>
          </TileFormRowWithData>
          <TileFormParagraphWithTooltip>
            {t('placeme.potential_within_chain.form.sub_title')}
          </TileFormParagraphWithTooltip>
          <DemoInfoSection categoryId={categoryId} />
        </TileFormSection>
        <PointsTypeSelector
          onChange={handleChange}
          options={options}
          value={locationType}
        />
        {locationType
          ? (
            <PercentagesForm
              formState={formState}
              invalidSum={invalidSum}
              percentagesSum={percentagesSum}
              setFormValue={setFormValue}
              setInitialState={setInitialState}
              value={locationType}
            />
          )
          : null}
      </FormWrapper>
      <FooterWrapper>
        <TileFooter
          disabled={invalidSum}
          isUnlimited
          label={t('generic.apply')}
          onAccept={handleSave}
          onCancel={handleDeleteTile}
          value='1'
        />
      </FooterWrapper>
    </StyledTileFormWrapper>
  )
}
