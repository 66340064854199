import React from 'react'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'

type LineWrapperProps = {
  width?: number
}
const LineWrapper = styled.div<LineWrapperProps>`
    width: ${({ width }) => `${width}px`};
`

export interface ILineChartProps {
  labels: string[],
  width?: number,
  datasets: {
    label: string
    data: number[],
    fill: boolean,
    backgroundColor: string,
    borderColor: string,
    borderWidth: number,
  }[],
  axisLabels?: {xAxis:string, yAxis:string}
  valueType?: string,
  innerLabels?: boolean,
}

export function LineChart({
  width, axisLabels, labels, datasets, innerLabels, valueType,
}: ILineChartProps):JSX.Element {
  return (
    <LineWrapper width={width}>
      <Line
        data={{
          labels,
          datasets,
        }}
        options={{
          plugins:{
            datalabels: {
              display: innerLabels || false,
              color: 'white',
              font: {
                size: '16',
                weight: 'bold',
              },
              formatter(value: number) {
                switch (valueType) {
                  case 'percentages':
                    return `${value}%`
                  default:
                    return value
                }
              },
            },
          },
          scales: {
            yAxes: [
              {
                scaleLabel:{
                  display: true,
                  labelString: axisLabels?.yAxis,
                  fontStyle: 'bold',
                },
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display:false,
                },
              },
            ],
            xAxes: [
              {
                scaleLabel:{
                  display: true,
                  labelString: axisLabels?.xAxis,
                  fontStyle: 'bold',
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            align: 'start',
            labels:{
              boxWidth: 12,
            },
          },
        }}
      />
    </LineWrapper>
  )
}

export default LineChart
