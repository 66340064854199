export const initalRangesState = {
  driving: [],
  cycling: [],
  walking: [],
  line: [],
}

export const timeRangesData = {
  walking: [
    {
      id: 'walking_5',
      length: 5,
      content: '5 min',
    },
    {
      id: 'walking_7',
      length: 7,
      content: '7 min',
    },
    {
      id: 'walking_10',
      length: 10,
      content: '10 min',
    },
    {
      id: 'walking_15',
      length: 15,
      content: '15 min',
    },
  ],
  driving: [
    {
      id: 'driving_5',
      length: 5,
      content: '5 min',
    },
    {
      id: 'driving_7',
      length: 7,
      content: '7 min',
    },
    {
      id: 'driving_10',
      length: 10,
      content: '10 min',
    },
    {
      id: 'driving_15',
      length: 15,
      content: '15 min',
    },
  ],
  cycling: [
    {
      id: 'cycling_5',
      length: 5,
      content: '5 min',
    },
    {
      id: 'cycling_7',
      length: 7,
      content: '7 min',
    },
    {
      id: 'cycling_10',
      length: 10,
      content: '10 min',
    },
    {
      id: 'cycling_15',
      length: 15,
      content: '15 min',
    },
  ],
  line: [
    {
      id: 'line_250',
      length: 250,
      content: '250 m',
    },
    {
      id: 'line_500',
      length: 500,
      content: '500 m',
    },
    {
      id: 'line_1000',
      length: 1000,
      content: '1000 m',
    },
    {
      id: 'line_3000',
      length: 3000,
      content: '3000 m',
    },
  ],
}
