import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

interface StyledSettingsProps {
  $marginTop?: string
}

const StyledSettings = styled.div<StyledSettingsProps>(({
  theme: {
    palette, corners,
  },
  $marginTop,
}) => css`
    display: flex;
    flex-direction: column;
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;
    margin-top: ${$marginTop || '1rem'};
    margin-bottom: 1rem;
    background-color: ${palette.light.main};
  `)

const StyledTitle = styled.span(
  ({ theme }) => {
    const {
      typography, palette,
    } = theme
    return css`
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
      padding-bottom: 0.5rem;
    `
  },
)

interface StyledBoxProps {
  $column?: boolean
}

const StyledBox = styled.div<StyledBoxProps>(({
  theme: {
    typography, palette,
  },
  $column,
}) => css`
    font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
    line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
    margin-bottom: 0.5rem;
    color: ${palette.dark.normal};
    gap: 0.3rem;
    width: 100%;
    display: flex;
    ${$column && css`flex-direction: column;`}
  `)

const StyledLabel = styled.p(
  ({ theme }) => {
    const {
      typography, palette,
    } = theme
    return css`
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    `
  },
)

export interface SettingsListElement {
  label: string,
  value: string | boolean | number | null | undefined,
  visible: string | boolean | number | null | undefined,
  column?: boolean
}

export interface SelectedSettings {
  selectedSettings: SettingsListElement[]
  marginTop?: string
}

export const Settings = ({
  selectedSettings,
  marginTop,
}: SelectedSettings): JSX.Element => {
  const { t } = useTranslation()

  return (
    <StyledSettings $marginTop={marginTop}>
      <StyledTitle>{t('generic.settings')}</StyledTitle>
      {selectedSettings.map(setting => (
        setting.visible
          ? (
            <StyledBox
              key={setting.label}
              $column={setting.column}
            >
              <StyledLabel>{`${setting.label}:`}</StyledLabel>
              {setting.value}
            </StyledBox>
          )
          : null
      ))}
    </StyledSettings>
  )
}
