import { useContext } from 'react'
import { IUserSettingsContext, UserSettingsContext } from '../UserSettingsContext'

export function useUserSettingsContext(): IUserSettingsContext {
  const context = useContext(UserSettingsContext)

  if (!context) {
    throw new Error('useUserSettingsContext must be used within a UserSettingsContextProvider')
  }

  return context
}
