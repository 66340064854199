import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { TitleFormSectionSubTitle, CloseIcon } from '@dataplace.ai/ui-components/atoms'
import { ResourcesSelector } from '@dataplace.ai/ui-components/organisms'

const SelectorWrapper = styled.div(({
  theme: {
    palette,
    corners,
  },
}) => css`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${palette.light.white};
  border: 1px dashed ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
`)

const ListSpan = styled.span`
  flex-grow: 1;
`

const ContentSpan = styled.span`
  margin-right: 5px;
`

const ListElem = styled.div(({
  theme: {
    palette,
    corners,
    typography,
  },
}) => css`
  display: flex;
  width: 50%;
  position: relative;
  padding: 0.375rem 0.5rem;
  flex-direction: row;
  margin: 0.25rem 0;
  color: ${palette.black};
  align-items: center;
  background-color: ${palette.light.main};
  border: 1px solid ${palette.light.darker};
  border-radius: ${corners.default.borderRadius};
  font-size: ${typography.main.pt_15_regular.fontSize};
  font-weight: ${typography.main.pt_15_regular.fontWeight};
  line-height: ${typography.main.pt_15_regular.lineHeight};

  &:hover {
    color: ${palette.blue};
  }
`)

const Info = styled.p(({ theme: { palette } }) => css`
  color: ${palette.results.red.dark};
  margin-top: 1rem;
`)

interface SelectorProps {
  hasItems: boolean
  ranges: Record<string, ResourceWithId[]>
  setRanges: (type: string, ranges: ResourceWithId[]) => void
  timeRangesData: Record<string, ResourceWithId[]>
}

const Selector: React.FC<SelectorProps> = ({
  hasItems,
  ranges,
  setRanges,
  timeRangesData,
}) => {
  const { t } = useTranslation()
  const [isMaxLengthInfoVisible, setIsMaxLengthInfoVisible] = useState<boolean>(false)

  const handleDelete = (type: string, resourceId: string) => {
    setRanges(type, ranges[type].filter(range => range.id !== resourceId))
    setIsMaxLengthInfoVisible(false)
  }

  const totalItemsCount = Object.keys(ranges).reduce(
    (acc, type) => acc + ranges[type].length, 0,
  )

  const handleAdd = (type: string, resources: string[]) => {
    const newSelection = timeRangesData[type].filter((timeRange) => resources.includes(timeRange.id))
    const newItems = newSelection.filter((timeRange) => !ranges[type].some((existing) => existing.id === timeRange.id))
    const newSelectionCount = newItems.length

    // we allow a maximum of 3 ranges for one analysis
    if (totalItemsCount + newSelectionCount <= 3) {
      setRanges(type, newSelection)
      setIsMaxLengthInfoVisible(false)
    } else {
      setIsMaxLengthInfoVisible(true)
    }
  }

  return (
    <SelectorWrapper>
      {hasItems
        ? Object.keys(ranges).map((type) => (
          <>
            {ranges[type].map((resource) => (
              <ListElem key={resource.id}>
                <>
                  <ContentSpan>
                    {resource.content}
                  </ContentSpan>
                  <ListSpan>
                    {t(`placeme.overlapping_ranges.time_selector.by_${type}`)}
                  </ListSpan>
                </>
                <CloseIcon onClick={() => handleDelete(type, resource.id)} />
              </ListElem>
            ))}
          </>
        ))
        : null}
      {isMaxLengthInfoVisible ? <Info>{t('placeme.overlapping_ranges.time_selector.ranges_limit_info')}</Info> : null}
      {Object.keys(timeRangesData).map((type) => (
        <>
          <TitleFormSectionSubTitle key={type}>
            {t(`placeme.overlapping_ranges.time_selector.by_${type}`)}
          </TitleFormSectionSubTitle>
          <ResourcesSelector
            multiSelect
            onChange={(resources) => handleAdd(type, resources)}
            resources={timeRangesData[type]}
            value={ranges[type].map((tr) => tr.id)}
          />
        </>
      ))}
    </SelectorWrapper>
  )
}

export default Selector
