export const tilesWithDoublePropertiesChoose = [
  'traffic_visualisation',
  'customer_origin',
  'traffic_visualisation_map',
  'cannibalization',
  'transport',
  'potential_model',
  'gravity_model',
  'satellite',
  'access_range',
  'overlapping_ranges',
  'potential_model',
  'local_market_share',
  'traffic_visualisation_big_ben',
  'old_traffic_visualisation_big_ben',
  'mcd_potential_model',
  'potential_within_chain',
  'demo_potential_within_chain',
  'demo_sales_prediction_model',
  'demo_scoring_prediction',
  'scoring_model_big_ben',
  'dol_sap_potential_within_chain',
]

export const tilesWithoutRangeChoosing = [
  'cities_population',
  'municipality_population',
  'look_alike_big_ben',
  'segmentation_big_ben',
  'land_use_plan',
  'mcd_competition_traffic',
  'mcd_store_chain_impact',
  'location_type',
  'demo_store_chain_impact',
]
