import { useTranslation } from 'react-i18next'
import { useGetDemoDropdownOptions } from '../formData/useGetDemoDropdownOptions'
import { IDemoPotentialModelTileData } from '../../../@types/IAnwPotentialModelTileData'

interface OptionTypes {
  value: string | boolean | number;
  label: string;
}

const findOption = (options: OptionTypes[], settingType: string | boolean | number | null) => options
  .find(option => option.value === settingType)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDemoSelectedSettings = (data: IDemoPotentialModelTileData) => {
  const {
    salesAreaOptions, plannedShopType, options,
  } = useGetDemoDropdownOptions()
  const { t } = useTranslation()
  if (!data) return []

  const { settings } = data.value

  return [
    {
      label: t('placeme.demo.scoring_prediction.criteria.self_checkouts_number'),
      value: settings.selfCheckoutsNumber.toString(),
      visible: true,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.shop_type'),
      value: findOption(plannedShopType, settings.shopType)?.label,
      visible: true,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.sales_area'),
      value: findOption(salesAreaOptions, settings.salesArea)?.label,
      visible: true,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.available_premium_stock'),
      value: findOption(options, settings.availablePremiumStock)?.label,
      visible: true,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.pylon'),
      value: findOption(options, settings.pylon)?.label,
      visible: true,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.open_saturdays'),
      value: findOption(options, settings.openSaturdays)?.label,
      visible: true,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.pickup_point'),
      value: findOption(options, settings.pickupPoint)?.label,
      visible: true,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.expressway'),
      value: findOption(options, settings.plannedAdditions?.expressway)?.label,
      visible: findOption(options, settings.plannedAdditions?.expressway)?.value,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.competitive_store'),
      value: findOption(options, settings.plannedAdditions?.competitiveStation)?.label,
      visible: findOption(options, settings.plannedAdditions?.competitiveStation)?.value,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.restaurant'),
      value: findOption(options, settings.plannedAdditions?.restaurant)?.label,
      visible: findOption(options, settings.plannedAdditions?.restaurant)?.value,
    },
    {
      label: t('placeme.demo.scoring_prediction.criteria.discount_store'),
      value: findOption(options, settings.plannedAdditions?.truckParking)?.label,
      visible: findOption(options, settings.plannedAdditions?.truckParking)?.value,
    },
  ]
}
