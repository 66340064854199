import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, NoPrint } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { saveTiles } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { TileSectionIds } from '@dataplace.ai/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { useTranslation } from 'react-i18next'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { camelToSnake } from '@dataplace.ai/functions/utils'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { useTileSettings } from 'apps/placeme/src/contexts/UserSettings/hooks/useTileSettings'
import { CategoryDetailsType, DistributionKeys, IAdditionalBuildingsDataType, IBuildingsTileDataTypeValue } from '../@types/IBuildingsTileData'
import CategoryDetails from './CategoryDetails'

const BuildingTypeSelect = styled.div(({ theme: { palette } }) => css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${palette.light.main};
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  border: 1px dashed ${palette.light.darkest};
`)

interface BuildingsTypeProps {
  tileId: string
  additionalData: IBuildingsTileDataTypeValue
}

const BuildingsType: React.FC<BuildingsTypeProps> = ({
  tileId,
  additionalData,
}) => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const dispatch = useAppDispatch()
  const { values } = useSelector((state: RootState) => state.analysis)
  const [expandedCategory, setExpandedCategory] = useState<string[]>([])
  const {
    tileSettings, updateTileSettings,
  } = useTileSettings({
    tileType: 'buildings',
    tileId,
  })
  const [isAdditionalVisible, setIsAdditionalVisible] = useState<boolean>(false)

  const handleExpand = (categoryName: string) =>
    setExpandedCategory(prevExpandedCategories => (prevExpandedCategories.includes(categoryName)
      ? prevExpandedCategories.filter(name => name !== categoryName)
      : [...prevExpandedCategories, categoryName]))

  const {
    buildings,
    comparedLocation,
  } = additionalData

  const categories = Object.keys(buildings.additionalData) as Array<keyof typeof buildings.additionalData>

  const catchmentId = useMemo(() =>
    getTileInfo(tileId, TileSectionIds.SURROUNDINGS, values)?.chosenRange?.catchmentId, [tileId, values])

  const isExpanded = useCallback((categoryName: keyof IAdditionalBuildingsDataType) => (
    isPrinting ? true : expandedCategory.includes(categoryName)
  ), [isPrinting, expandedCategory])

  const handleAdditionalInfoToggleClick = useCallback(() => {
    const isVisibile = !isAdditionalVisible
    setIsAdditionalVisible(isVisibile)
    updateTileSettings({
      additionalDataVisible: isVisibile,
    })
  }, [isAdditionalVisible])

  useEffect(() => {
    if (!catchmentId) return

    const toExclude = categories
      ?.filter(cat => !expandedCategory.includes(cat))
      .map(elem => camelToSnake(elem))

    const pdfTiles = values?.map(cat => {
      if (cat?.id === TileSectionIds.SURROUNDINGS) {
        return {
          ...cat,
          tiles: cat?.tiles.map(tile => {
            if (tile?.chosenRange?.catchmentId === catchmentId) {
              return {
                ...tile,
                pdfExcluded: toExclude,
              }
            }
            return tile
          }),
        }
      }
      return cat
    })
    dispatch(saveTiles(pdfTiles))
  }, [expandedCategory])

  useEffect(() => {
    if (isPrinting) setIsAdditionalVisible(true)
  }, [isPrinting])

  useEffect(() => {
    const additionalDataVisible = tileSettings.value?.additionalDataVisible
    if (typeof additionalDataVisible !== 'undefined') setIsAdditionalVisible(additionalDataVisible)
  }, [tileSettings.value])

  return (
    <>
      <NoPrint>
        <BuildingTypeSelect>
          <Button
            onClick={handleAdditionalInfoToggleClick}
          >
            {t(!isAdditionalVisible ? 'placeme.buildings.additional.btn_check_more' : 'placeme.buildings.additional.btn_hide_more')}
          </Button>
        </BuildingTypeSelect>
      </NoPrint>
      {isAdditionalVisible
        ? (categories.map(categoryName => (
          <CategoryDetails
            key={categoryName}
            category={buildings?.additionalData[categoryName] as CategoryDetailsType}
            categoryName={categoryName as DistributionKeys}
            comparedCategory={comparedLocation?.buildings?.additionalData[categoryName] as CategoryDetailsType}
            isExpanded={isExpanded(categoryName)}
            onExpand={() => handleExpand(categoryName)}
          />
        )))
        : null}
    </>
  )
}

export default BuildingsType
