import { ITile } from '../../../../slice/@types/ITile'

interface BBox {
  northEastLat: number
  northEastLng: number
  southWestLat: number
  southWestLng: number
}

interface GetTileObjProps {
  tile?: ITile
  bBox?: BBox
  comparedBBox?: BBox
  objToInclude?: Record<string, unknown>
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getTileObj({
  tile, bBox, comparedBBox, objToInclude = {},
}: GetTileObjProps) {
  return {
    catchmentId: tile?.chosenRange?.catchmentId || '',
    bBox: bBox ? [bBox?.southWestLng, bBox?.southWestLat, bBox?.northEastLng, bBox?.northEastLat] : undefined,
    zoom: tile?.data?.mapLocation?.zoom,
    comparedBBox: comparedBBox
      ? [
        comparedBBox?.southWestLng,
        comparedBBox?.southWestLat,
        comparedBBox?.northEastLng,
        comparedBBox?.northEastLat,
      ]
      : undefined,
    comparedZoom: tile?.data?.comparedMapLocation?.zoom,
    ...objToInclude,
  }
}
