import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { IDropdownButtonProps } from '../@types/IDropdownButton.props'

const SecondButton = styled.button(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    display: flex;
    padding: 5px 15px;
    color: ${palette.dark.main};
    background-color: white;
    width:100%;
    outline: none;
    border: none;
    cursor: pointer;
    >span {
      color: ${palette.dark.main};
      font-size: ${typography.small.pt_13_medium.fontSize};
      font-weight: ${typography.small.pt_13_medium.fontWeight};
      line-height: ${typography.small.pt_13_medium.lineHeight};
    }

    :first-of-type{
      padding-top: 10px;
    }

    :last-of-type{
      border-radius: 0 0 6px 6px;
      padding-bottom: 10px;
    }

    :hover{
      background: ${palette.light.lighter};
    }

    :disabled {
    background-color: ${palette.dark.lightest};
    border: 1px solid #e1e2e3;
    border-right: none;
    border-left: none;
    color: white;
    cursor: default;
    }
  `
})

export const DropdownContent = memo(({ dropdownContent }: {dropdownContent: IDropdownButtonProps['dropdownContent']}) : JSX.Element => (
  <>
    {dropdownContent?.map(item => (
      <SecondButton
        key={item.id}
        disabled={item.disabled}
        onClick={item.onClick}
      >
        {item.button}
      </SecondButton>
    ))}
  </>
))
