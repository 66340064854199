import { FeatureAccessId } from 'apps/placeme/src/constants/constants'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'

export type FeatureAccessData = Record<string, boolean>

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFeatureAccess = () => {
  const { currentSubscriptionData } = useSelector((state: RootState) => state.location)
  const featureAccess = currentSubscriptionData?.value?.featureAccess

  function hasFeatureAccess(featureId: FeatureAccessId) {
    return featureAccess?.[featureId]?.hasAccess
  }

  return {
    hasFeatureAccess,
    featureAccess,
    isLoading: currentSubscriptionData?.loading,
  }
}
