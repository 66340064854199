import { useTranslation } from 'react-i18next'
import { Table } from '@dataplace.ai/ui-components/organisms'
import React from 'react'
import { RankingDescription, SimilarAdressWrapper, Title } from './PotentialWithinChainResult.styles'
import { SimilarLocation } from '../@types/IPotentialWithinChain'
import { generateSimilarityContent } from '../helpers/generateSimilarityContent'

interface ISimilarAdress {
  similarLocations: SimilarLocation[];
}

export const SimilarAdress: React.FC<ISimilarAdress> = ({ similarLocations }) => {
  const { t } = useTranslation()

  const similarityContent = generateSimilarityContent(similarLocations)

  return (
    <SimilarAdressWrapper>
      <Title $borderTop>
        {t('placeme.potential_within_chain.similar')}
      </Title>
      <RankingDescription>
        {t('placeme.potential_within_chain.similar.description')}
      </RankingDescription>
      <Table
        buildings
        content={similarityContent}
        gap='1rem'
        headerTemplate='0.25fr 2fr 2fr'
        labels={[
          '',
          t('placeme.potential_within_chain.similar.label1'),
          t('placeme.potential_within_chain.similar.label2'),
        ]}
        margin='15px 0 0 0'
        rowTemplate='0.25fr 2fr 2fr'
        textLeftIndex={2}
      />
    </SimilarAdressWrapper>
  )
}
