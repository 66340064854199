import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Loader } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import RadiusTimeSelector from '@placeme/components/molecules/RadiusTimeSelector/RadiusTimeSelector'
import { TileSectionIds } from '@dataplace.ai/constants'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { deleteTileAction } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { useFetchTileData } from 'apps/placeme/src/customHooks/useFetchTileData'
import { useComparedData } from '@placeme/components/molecules/RadiusTimeSelector/hooks/useComparedData'
import { initalRangesState, timeRangesData } from './data/ranges'
import { IOverlappingRangesData } from './@types/IOverlappingRanges'
import { useMapData } from './hooks/useMapData'
import { useTileDataHandler } from './hooks/useTileDataHandler'

const Wrapper = styled.div((
  { theme: { palette } },
) => css`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  background-color: ${palette.light.white};
`)

const OverlappingRanges: React.FC<{ data: IOverlappingRangesData, isExtraPaid?: boolean, tileId: string}> = ({
  data,
  tileId,
  isExtraPaid,
}) => {
  const dispatch = useAppDispatch()
  const {
    value,
    analyseId,
  } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const [val, setVal] = useState<ResourceWithId[]>([])
  const [accepted, setAccepted] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [canComparedLocationBeRequested, setCanComparedLocationBeRequested] = useState(false)

  const handleDeleteTile = useCallback(() => {
    dispatch(deleteTileAction('', TileSectionIds.INHABITANTS_AND_VISITORS, tileId, analyseId || ''))
  }, [dispatch, tileId, analyseId])

  const contentTimeRanges = Object.values(timeRangesData).flat()

  const timeRanges = data?.value?.overlappingRanges?.data?.features?.map(feature => ({
    label: feature?.properties?.id,
    area: feature?.properties?.catchmentArea,
    population: feature?.properties?.population,
  }))

  const comparedTimeRanges = data?.value?.comparedLocation?.overlappingRanges?.data?.features?.map(feature => ({
    label: feature?.properties?.id,
    area: feature?.properties?.catchmentArea,
    areaDiff: feature?.properties?.catchmentAreaDiff,
    population: feature?.properties?.population,
    populationDiff:  feature?.properties?.populationDiff,
  }))

  const tileInfo = useMemo(() => getTileInfo(tileId, TileSectionIds.INHABITANTS_AND_VISITORS, values), [tileId, values])

  const body = useMemo(() => ({
    catchmentId: tileInfo?.chosenRange?.catchmentId,
    ranges: val.map(({
      id,
      length,
    }) => {
      const [rangeType] = id.split('_')
      return {
        rangeType,
        length,
      }
    }),
  }), [tileInfo?.chosenRange?.catchmentId, val])

  const { fetchData } = useFetchTileData(
    data,
    ENDPOINTS.OVERLAPPING_RANGE_TILE,
    tileId,
    TileSectionIds.INHABITANTS_AND_VISITORS,
    body,
  )

  const {
    layers,
    comparedLayers,
    setMapLocation,
    setComparedMapLocation,
    handleValueLabelClick,
  } = useMapData({
    data,
    tileId,
    value,
    comparedLocation,
    contentTimeRanges,
  })

  useComparedData({
    tileId,
    canComparedLocationBeRequested,
    val,
    sectionName: TileSectionIds.INHABITANTS_AND_VISITORS,
  })

  useTileDataHandler({
    data,
    accepted,
    setAccepted,
    fetchData,
    setCanComparedLocationBeRequested,
    setLoading,
    tileId,
    sectionId: TileSectionIds.INHABITANTS_AND_VISITORS,
  })

  useEffect(() => {
    if (data?.value) setLoading(false)
  }, [data?.value])

  if (loading) return <Loader />

  return (
    <Wrapper>
      <RadiusTimeSelector
        comparedLayers={comparedLayers}
        comparedTimeRanges={comparedTimeRanges}
        contentTimeRanges={contentTimeRanges}
        handleDeleteTile={handleDeleteTile}
        handleValueLabelClick={handleValueLabelClick}
        initalRangesState={initalRangesState}
        isExtraPaid={isExtraPaid}
        layers={layers}
        onSubmit={setVal}
        setAccepted={setAccepted}
        setComparedMapLocation={setComparedMapLocation}
        setMapLocation={setMapLocation}
        tileId={tileId}
        tileName='overlapping_ranges'
        tileSection={TileSectionIds.INHABITANTS_AND_VISITORS}
        timeRanges={timeRanges}
        timeRangesData={timeRangesData}
      />
    </Wrapper>
  )
}

export default OverlappingRanges
