import { createFlashMessage } from '@dataplace.ai/functions/utils'
import { ILocation, IRange } from '@dataplace.ai/types'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { compareLocationCatchmentAndDataAction } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { RootState } from 'apps/placeme/src/redux/store'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

interface ComparedDataProps {
  tileId: string
  canComparedLocationBeRequested: boolean
  val: ResourceWithId[]
  sectionName: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useComparedData = ({
  tileId,
  canComparedLocationBeRequested,
  val,
  sectionName,
}: ComparedDataProps) => {
  const dispatch = useAppDispatch()
  const {
    analyseId, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const tileInfo = useMemo(() =>
    getTileInfo(tileId, sectionName, values), [tileId, values])

  const handleFetchComparedData = useCallback(async () => {
    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
    try {
      // post project - with compared location - create compared analyse
      if (!comparedAnalyseId) await dispatch(createNewAnalyse(analyseId, comparedLocation?.location))
    } catch {
      createFlashMessage({
        message: 'status.info.generic.something_went_wrong',
      })
    } finally {
      const overlappingRangesTile = getTileInfo(tileId, sectionName, values)
      const catchmentId = overlappingRangesTile?.chosenRange?.catchmentId
      dispatch(compareLocationCatchmentAndDataAction(
        '',
        catchmentId || '',
        overlappingRangesTile?.chosenRange as IRange,
        overlappingRangesTile?.section || '',
        overlappingRangesTile?.id || '',
        currentSubscriptionData?.value?.subscriptionId || '',
        comparedLocation?.location as ILocation,
        {
          ranges: val.map(v => {
            const [rangeType] = v.id.split('_')
            return {
              rangeType,
              length: v.length,
            }
          }),
        },
      ))
    }
  }, [canComparedLocationBeRequested, tileInfo?.chosenRange?.catchmentId])

  useEffect(() => {
    const overlappingRangesTile = getTileInfo(tileId, sectionName, values)

    if (
      !canComparedLocationBeRequested
      || !comparedLocation?.generatedFromNow
      || !overlappingRangesTile
      || !tileInfo?.chosenRange?.catchmentId
      || overlappingRangesTile.chosenRange?.type === 'custom'
      || !comparedLocation?.location
    ) return

    handleFetchComparedData()
  }, [canComparedLocationBeRequested, tileInfo?.chosenRange?.catchmentId])
}
