import { useTranslation } from 'react-i18next'
import { BuildingTypeCategory, CategoryKeys, IBuildingDetails } from '../@types/IBuildingsTileData'
import { useGetBuildingsTexts } from './useGetBuildingsTexts'
import { differentCategories } from '../utils/constants'

export const useGenerateLabels = (data: BuildingTypeCategory): { pieLabels: CategoryKeys[], tableLabels: string[] } => {
  const { tableHeaders } = useGetBuildingsTexts()
  const { t } = useTranslation()

  const overallKeys = Object.keys(data.overall) as (keyof IBuildingDetails)[]
  const pieLabels = Object.keys(data)
    .filter(key => key
    !== differentCategories.overall && key !== differentCategories.mostCommonType) as CategoryKeys[]
  const tableLabels = [t('placeme.buildingtile.table_1_label_1'), ...overallKeys.map(key => tableHeaders[key])]

  return {
    pieLabels,
    tableLabels,
  }
}
