import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { deleteTileAction, saveNewRangeAction } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { Loader } from '@dataplace.ai/ui-components/atoms'
import { useFetchTileData } from 'apps/placeme/src/customHooks/useFetchTileData'
import { PotentialWithinChainForm } from './PotentialWithinChainForm/PotentialWithinChainForm'
import { DataType, FormFields, SettingsListElement } from './@types/IPotentialWithinChain'
import { PotentialWithinChainResult } from './PotentialWithinChainResult/PotentialWithinChainResult'

interface IPotentialWithinChainTile {
  options: Pick<ResourceWithId, 'id' | 'content'>[]
  setFormState: Dispatch<SetStateAction<FormFields>>
  setLocationTypeId: Dispatch<SetStateAction<string | undefined>>
  locationType?: ResourceWithId
  formState: FormFields
  data: DataType
  body: unknown
  tileId: string
  categoryId: string
  endpoint: string
  settingList: SettingsListElement[]
}

export const PotentialWithinChainTile: React.FC<IPotentialWithinChainTile> = ({
  options,
  setFormState,
  setLocationTypeId,
  locationType,
  formState,
  data,
  tileId,
  categoryId,
  body,
  endpoint,
  settingList,
}) => {
  const authContext = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const { analyseId } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)

  const [canBeRequest, setCanBeRequest] = useState<boolean>(false)

  const rangeValue = 250

  const { fetchData } = useFetchTileData(
    data,
    endpoint,
    tileId,
    categoryId,
    body,
    setCanBeRequest,
  )

  const handleSave = () => {
    setCanBeRequest(true)
    fetchData()
  }

  const handleDeleteTile = useCallback(() => {
    dispatch(deleteTileAction('', categoryId, tileId, analyseId || ''))
  }, [dispatch, '', categoryId, tileId, analyseId])

  const tile = useMemo(() =>
    values?.find(category => category.id === categoryId)?.tiles.find(tile => tile.id === tileId),
  [values])

  useEffect(() => {
    if (tile?.data?.value || !canBeRequest || tile?.chosenRange?.catchmentId) return

    dispatch(saveNewRangeAction('', authContext.userData.user?.uid || '', categoryId, tileId, {
      id: `${tileId}-${rangeValue}-line`,
      value: rangeValue,
      type: 'line',
    }))
  }, [canBeRequest])

  useEffect(() => {
    if (!data?.value && canBeRequest) {
      handleSave()
    }
  }, [!data?.value, tile?.chosenRange?.catchmentId, canBeRequest])

  if (canBeRequest && (data?.loading || !data?.value)) return <Loader />

  return (
    ((!canBeRequest && !data?.value)
      ? (
        <PotentialWithinChainForm
          categoryId={categoryId}
          formState={formState}
          handleDeleteTile={handleDeleteTile}
          handleSave={handleSave}
          locationType={locationType}
          options={options}
          setFormState={setFormState}
          setLocationTypeId={setLocationTypeId}
        />
      )
      : (
        <PotentialWithinChainResult
          categoryId={categoryId}
          data={data}
          locationType={locationType}
          settingList={settingList}
        />
      ))
  )
}
