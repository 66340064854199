import * as Sentry from '@sentry/react'
import { PATHS } from 'apps/placeme/src/constants/paths'
import React from 'react'
import styled from 'styled-components'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SentryErrorBoundary({
  children, name,
}: React.PropsWithChildren<{ name: string }>) {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={scope => {
        scope.setTag('errorBoundary', name)
      }}
      fallback={<ErrorFallback />}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "'Inter', sans-serif";
  position: fixed;
  height: 100vh;
  width: 100vw;
`

const StyledHeader = styled.h4`
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.4rem;
  margin: 1rem 0;
  color: #15181F;
`

const StyledText = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 0 0 1rem;
  color: #001D62;
`
const StyledAnchor = styled.a`
  color: #FFFFFF;
  background-color: #234596;
  text-decoration: none;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  padding: 0.6rem 1.1rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.25rem;
  transition: all 0.5s;
  cursor: pointer;
  height: fit-content;
  width: fit-content;

  &&:hover {
    background-color: rgba(35, 69, 150, 1);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08), 0px 0px 24px rgba(95, 82, 73, 0.08);
  }
`

function ErrorFallback() {
  return (
    <StyledDiv>
      <StyledHeader>Something went wrong</StyledHeader>
      <StyledText>Please return to our homepage</StyledText>
      <StyledAnchor href={PATHS.CHOOSE_LOCATION}>Home</StyledAnchor>
    </StyledDiv>
  )
}

export default SentryErrorBoundary
