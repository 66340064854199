import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAxios } from '@dataplace.ai/functions/utils'
import { AuthContext } from '@dataplace.ai/features'
import { TileData } from '@placeme/components/organisms/Tiles/Anw/AnwPotentialModel/@types/IAnwPotentialModelTileData'
import { useAppDispatch } from '../redux/hooks'
import { fetchWorkspaceUsageValue, saveTileData } from '../features/Analyse/slice/analysisSlice'
import { RootState } from '../redux/store'
import { fetchSubscriptionInfo } from '../features/ChooseLocationReport/chooseLocationSlice'

export function useFetchTileData<T, U>(
  data: TileData<T>,
  endpoint: string,
  tileId: string,
  categoryId: string,
  body: U,
  setCanBeRequest?: Dispatch<SetStateAction<boolean>>,
): { fetchData: () => Promise<void | null> } {
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const { values } = useSelector((state: RootState) => state.analysis)
  const { currentSubscriptionData } = useSelector((state: RootState) => state.location)
  const [token, setToken] = useState('')

  const fetchData = useCallback(async () => {
    const catchmentId = values.find(c => c.id === categoryId)?.tiles
      ?.find(t => t.id === tileId)?.chosenRange?.catchmentId

    if (!token.length || !catchmentId) return

    let saveData = {
      loading: false,
      error: '',
      value: null,
    }

    const axiosInstance = await getAxios({
      errCallbackFn: (e) => {
        saveData = {
          loading: false,
          error: e.message,
          value: null,
        }
      },
    })
    const response = await axiosInstance.post(endpoint, body)
    if (!response) return setCanBeRequest ? setCanBeRequest(false) : null
    saveData = {
      loading: false,
      error: '',
      value: response.data,
    }

    if (response.status === 204) {
      window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
      window?.dispatchEvent(new CustomEvent('noDataModal'))
    }

    if (saveData.value) {
      dispatch(saveTileData(categoryId, tileId, saveData))
      dispatch(fetchSubscriptionInfo(currentSubscriptionData?.value?.subscriptionId || ''))
    }
  }, [token, values, body])

  useEffect(() => {
    if (token.length && !data?.mapLocation) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  return {
    fetchData,
  }
}
