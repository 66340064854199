import React, { useCallback, useEffect, useState } from 'react'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import Popup from 'reactjs-popup'
import { useTranslation } from 'react-i18next'

import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { IAnalysesData } from 'apps/placeme/src/features/AnalyseAndReports/components/organisms/AnalyseAndReportBody/@types/IAnalysesData'
import { redirectToReports } from '@dataplace.ai/functions/utils'
import { useDownloadPDF } from 'apps/placeme/src/customHooks/useDownloadPDF'
import { saveSavingState } from '../../../slice/analysisSlice'
import { exitFromAnalyseAction } from '../../../functions/exitFromAnalyse'
import { initialValues } from './utils/initialValues'
import PdfInfoView from './PdfInfoView'
import { Trigger, TopWrapper, Wrapper, Title } from './DownloadModal.styles'
import DownloadAnalyseForm from './DownloadAnalyseForm'

export interface IDownloadAnalyseModalV2Props {
  trigger: JSX.Element
  analyseId: string
  comparedReportId?: string
}

export const DownloadAnalyseModalV2 = ({
  trigger,
  analyseId,
  comparedReportId,
}: IDownloadAnalyseModalV2Props): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const address = useSelector((state: RootState) => state.location?.value?.address || '')
  const {
    pdfState,
    setPdfState,
    downloadPDF,
  } = useDownloadPDF({
    reportId: analyseId,
    address,
    comparedReportId,
  })
  const [open, setOpen] = useState(false)
  const [existingProjects, setProjects] = useState<({ folderId: string; folderName: string } | null)[]>([])
  const [redirect, setRedirect] = useState(false)
  const {
    canBeSave, isEdit, savingState,
  } = useSelector((state: RootState) => state.analysis)
  const { data } = useSelector(
    (state: RootState) => state.analysesAndReports,
  )

  // the initial name of the pdf is the address being analyzed
  initialValues.reportName = address || ''

  const projectsData: IAnalysesData = data?.value as IAnalysesData
  // we need ref to get current state in event listener

  const handleRedirectToAnalysisList = useCallback(() => {
    exitFromAnalyseAction(dispatch, isEdit, analyseId)
    setRedirect(true)
  }, [dispatch, isEdit, analyseId])

  const handleUnderstand = useCallback(() => {
    setPdfState({
      loading: false,
      status: null,
    })
    if (!canBeSave || isEdit) return
    dispatch(saveSavingState({
      loading: false,
      error: null,
      value: null,
    }))
    if (savingState?.value) handleRedirectToAnalysisList()
    setOpen(false)
  }, [canBeSave, isEdit, dispatch, savingState, handleRedirectToAnalysisList])

  // gets existing projects list
  useEffect(() => {
    if (!projectsData) return

    const projects = (projectsData?.folders || []).map((folder) =>
      (!['all', 'whitespots'].includes(folder.folderId.toLocaleLowerCase())
        ? {
          folderId: folder.folderId,
          folderName: folder.folderName,
        }
        : null))
    setProjects(projects)
  }, [data?.loading])

  if (redirect) redirectToReports()

  return (

    <Popup
      closeOnDocumentClick={false}
      modal
      onClose={handleUnderstand}
      onOpen={() => setOpen(true)}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger>{trigger}</Trigger>}
    >
      <Wrapper>
        <TopWrapper>
          {(pdfState.status === null)
            ? <Title>{t('placeme.download_analyse_modal.title')}</Title>
            : <Title>{pdfState?.status === 'error' ? t('placeme.pdf_generation_error') : t('placeme.pdf_generation')}</Title>}
        </TopWrapper>
        {((pdfState.status === null) || (canBeSave && !isEdit ? (!savingState?.value && !savingState?.error) : false))
          ? (
            <DownloadAnalyseForm
              analyseId={analyseId}
              downloadPDF={downloadPDF}
              existingProjects={existingProjects}
              pdfStateLoading={pdfState.loading}
              setOpen={setOpen}
            />
          )
          : (
            <PdfInfoView
              canBeSave={canBeSave}
              handleUnderstand={handleUnderstand}
              isEdit={false}
              pdfState={pdfState}
              savingState={savingState}
            />
          )}
      </Wrapper>
    </Popup>
  )
}
