import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { ButtonProduct } from '@dataplace.ai/ui-components/atoms'
import { handleMenuModalClose } from '@dataplace.ai/functions/utils'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { useTranslation } from 'react-i18next'

const Trigger = styled.div(
  () => css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
)

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    min-width: 300px;
  `
})

const Header = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-bottom: 0.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_medium.fontSize};
    font-weight: ${typography.main.pt_15_medium.fontWeight};
    line-height: ${typography.main.pt_15_medium.lineHeight};
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    margin-bottom: 0.5rem;
    color: ${palette.results.red.dark};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

const Footer = styled.div(
  () => css`
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  `,
)

export interface ExitFromAnalysePopupProps {
  trigger: JSX.Element
  handleDelete?: () => void
  contentAcceptButton?: string
  contentText?: string
  headerText?: string
}

export const ExitFromAnalysePopup = ({
  trigger,
  handleDelete,
  contentAcceptButton,
  contentText,
  headerText,
}: ExitFromAnalysePopupProps): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const closeModal = () => setOpen(false)
  return (
    <Popup
      modal
      onClose={closeModal}
      onOpen={() => {
        handleMenuModalClose()
        setOpen(true)
      }}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger>{trigger}</Trigger>}
    >

      <Wrapper>
        <Header>{headerText || t('placeme.do_you_want_delete_analyse')}</Header>
        <Content>{contentText || t('placeme.analyse_not_saved')}</Content>
        <Footer>
          <ButtonProduct
            deleteButton
            margin='0 5px 0 0'
            onClick={() => {
              if (handleDelete) handleDelete()
              closeModal()
            }}
            small
          >
            {contentAcceptButton || t('generic.delete')}
          </ButtonProduct>
          <ButtonProduct
            onClick={closeModal}
            small
          >
            {t('generic.cancel')}
          </ButtonProduct>
        </Footer>
      </Wrapper>

    </Popup>
  )
}
