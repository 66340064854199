import { useTranslation } from 'react-i18next'
import { LocationTypeOptions } from '../../../Potential/PotentialWithinChain/@types/IPotentialWithinChain'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useGetFormData() {
  const { t } = useTranslation()

  return {
    dropdownElements: [
      {
        id: LocationTypeOptions.DOLCI_SAPORI_HIGH_STREET,
        content: t('placeme.potential_within_chain.dropdown.high_street'),
      },
      {
        id: LocationTypeOptions.DOLCI_SAPORI_SMALL_TOWN,
        content: t('placeme.potential_within_chain.dropdown.small_town'),
      },
      {
        id: LocationTypeOptions.DOLCI_SAPORI_PH,
        content: t('placeme.potential_within_chain.dropdown.ph'),
      },
    ],
  }
}

export default useGetFormData
