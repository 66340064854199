/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMemo } from 'react'
import { snakeToCamel } from '@dataplace.ai/functions/utils'
import { useGetBuildingsTexts } from './useGetBuildingsTexts'
import { BuildingsTypesSubCategories, IBuildingsTileDataType } from '../@types/IBuildingsTileData'

const getValueDifference = (current: number, compare?: number) =>
  (compare !== undefined ? compare - current : undefined)
const formatPercentage = (value?: number) => (value !== undefined ? `${Math.round(value * 100)}%` : undefined)

export const useGenerateElements = (data: IBuildingsTileDataType) => {
  const {
    value: {
      buildings: {
        basicData: {
          buildingTypes: {
            distribution,
            dominantType,
          },
          buildings,
          apartments,
        },
      },
      comparedLocation,
    },
  } = data

  const dominantTypeCamel = snakeToCamel(dominantType) as keyof BuildingsTypesSubCategories
  const dominantTypeValue = distribution[dominantTypeCamel]
  const compareDominantTypeValue = comparedLocation
    ?.buildings?.basicData?.buildingTypes?.distribution[dominantTypeCamel]
  const {
    buildingsTexts: { buildnigsDataList: buildingsContent }, dominantTypeInfo,
  } = useGetBuildingsTexts({
    dominantType,
  })

  const listElementsData = useMemo(() => [
    {
      compareValue: comparedLocation?.buildings?.basicData?.buildings?.count,
      description: buildingsContent.firstParam.description,
      difference: comparedLocation?.buildings?.basicData?.buildings?.countDiff,
      elemValue: buildings.count,
      title: buildingsContent.firstParam.title,
    },
    {
      compareValue: formatPercentage(comparedLocation?.buildings?.basicData?.buildings?.density),
      description: buildingsContent.secondParam.description,
      difference: comparedLocation?.buildings?.basicData?.buildings?.densityDiff,
      elemValue: formatPercentage(buildings?.density),
      title: buildingsContent.secondParam.title,
    },
    {
      compareValue: formatPercentage(compareDominantTypeValue),
      description: buildingsContent.thirdParam.description,
      difference: dominantTypeValue && getValueDifference(
        Math.round(dominantTypeValue * 100), compareDominantTypeValue && Math.round(compareDominantTypeValue * 100),
      ),
      elemValue: `${dominantTypeInfo?.text} (${formatPercentage(dominantTypeValue)})`,
      title: buildingsContent.thirdParam.title,
    },
    {
      compareValue: comparedLocation?.buildings?.basicData?.apartments?.count,
      description: buildingsContent.fourthParam.description,
      difference: comparedLocation?.buildings?.basicData?.apartments?.countDiff,
      elemValue: apartments.count,
      title: buildingsContent.fourthParam.title,
    },
  ], [
    comparedLocation,
    buildingsContent,
    buildings.count,
    buildings.density,
    dominantTypeInfo?.text,
    apartments.count,
    compareDominantTypeValue,
    dominantTypeValue,
  ])

  return {
    listElementsData,
  }
}
