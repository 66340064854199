
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { ButtonProduct, CloseIcon, Tag } from '@dataplace.ai/ui-components/atoms'
import { TextButton } from '@dataplace.ai/ui-components/atoms/TextButton'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { handleMenuModalClose } from '@dataplace.ai/functions/utils'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { emailRegex } from '@dataplace.ai/constants'
import { Form, TextInput } from '@dataplace.ai/ui-components/molecules'
import { Formik } from 'formik'
import { addWorkspace } from '@dataplace.ai/functions/requests'

const { v4: uuidv4 } = require('uuid')

const Trigger = styled.div(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
)

const ChooseOwnerWrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem;
    min-width: 460px;
  `
})

const ChooseOwnerHeader = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    > span {
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }

    > button {
      cursor: pointer;
      border: none;
      outline: none;
      background: none;
    }
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    margin-bottom: 0.5rem;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
    max-width: 90%;
  `
})

const StyledTextButton = styled(TextButton)`
  margin-left: 20px;
  position: relative;
  top: 10px;
`

const ChooseOwnerFooter = styled.div(() => css`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
`)
const UsersToInviteHeader = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  color: ${palette.black};
  font-size: ${typography.tiny.pt_12_semibold.fontSize};
  font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
  line-height: ${typography.tiny.pt_12_semibold.lineHeight};
  margin: 12px 0;
`
})
export interface IAddWorkspaceModalProps {
  trigger: JSX.Element;
  successHandler?: ()=>void
}

export const AddWorkspaceModal = ({
  trigger, successHandler,
}: IAddWorkspaceModalProps): JSX.Element => {
  const { t } = useTranslation()
  const [newWorkspaceName, setNewWorkspaceName] = useState('')
  const [email, setEmail] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [open, setOpen] = useState(false)

  const closeModal = () => setOpen(false)
  const openModal = () => setOpen(true)
  const deleteMailFromList = (mail: string) => {
    setEmails(emails.filter(m => m !== mail))
  }

  return (
    <Popup
      modal
      onClose={() => {
        closeModal()
      }}
      onOpen={() => {
        openModal()
        handleMenuModalClose()
      }}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger>{trigger}</Trigger>}
    >
      <>

        <ChooseOwnerWrapper>
          <ChooseOwnerHeader>
            <span>
              {t('account.add_new_workspace')}
            </span>
            <button
              onClick={() => {
                closeModal()
              }}
              type='button'
            >
              <CloseIcon />
            </button>
          </ChooseOwnerHeader>
          <Content>{t('account.add_new_workspace.additional_info')}</Content>
          <TextInput
            label={t('account.set_new_workspace')}
            name='workspaceName'
            onBlur={() => {
            }}
            onChange={(_: string, value: string) => setNewWorkspaceName(value)}
            placeholder={t('account.new_workspace_name')}
            value={newWorkspaceName}
          />
          {emails.length > 0
          && <UsersToInviteHeader>{`${t('account.users_to_invite')}:`}</UsersToInviteHeader>}
          {emails.length > 0
          && emails.map(mail => (
            <Tag
              key={mail + uuidv4()}
              color='#FAFBFC'
              margin='0 0 5px 0'
            >
              <p>{mail}</p>
              <CloseIcon
                onClick={() => deleteMailFromList(mail)}
                style={{
                  marginLeft: '15px',
                }}
              />
            </Tag>
          ))}
          <Formik
            initialValues={{
              email,
            }}
            onSubmit={(_values, { resetForm }) => {
              setEmails([...emails, email])
              resetForm()
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .matches(
                  emailRegex,
                  t('generic.incorrect_email'),
                ),
            })}
          >
            {({
              errors,
              handleBlur,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => {
              const onInputChange = (field: string, value: string) => {
                setFieldValue(field, value)
                setEmail(value)
              }
              return (
                <Form
                  onSubmit={handleSubmit}
                  style={{
                    display:'flex',
                    alignItems: 'center',
                    flexDirection:'row',
                    margin: '10px 0',
                  }}
                >
                  <TextInput
                    error={(touched.email && errors.email) ? errors.email : undefined}
                    label={t('account.invite_users_description')}
                    name='email'
                    onBlur={handleBlur}
                    onChange={onInputChange}
                    placeholder={t('generic.type_email')}
                    value={values.email}
                  />

                  <StyledTextButton
                    type='submit'
                  >
                    <p>{t('generic.add')}</p>
                  </StyledTextButton>

                </Form>
              ) }}

          </Formik>
          <ChooseOwnerFooter>
            <TextButton
              onClick={() => {
                closeModal()
              }}
            >
              <p>{t('generic.cancel')}</p>
            </TextButton>
            <ButtonProduct
              disabled={!newWorkspaceName.length}
              margin='0 0 0 20px'
              maxWidth='80px'
              onClick={() => {
                addWorkspace(newWorkspaceName, emails, successHandler)
                closeModal()
              }}
            >
              {t('generic.add')}
            </ButtonProduct>
          </ChooseOwnerFooter>
        </ChooseOwnerWrapper>

      </>
    </Popup>
  )
}
