import { useTranslation } from 'react-i18next'
import { ReactComponent as Residental } from 'libs/shared/assets/src/lib/icons/residential.svg'
import { ReactComponent as Public } from 'libs/shared/assets/src/lib/icons/public.svg'
import { ReactComponent as CommercialAndServices } from 'libs/shared/assets/src/lib/icons/commercialAndService.svg'
import { ReactComponent as Other } from 'libs/shared/assets/src/lib/icons/other.svg'
import React from 'react'
import { anyCaseToCamelCase } from '@dataplace.ai/functions/utils'
import { DistributionKeys } from '../@types/IBuildingsTileData'

interface IGetBuildingsTexts {
  dominantType?: DistributionKeys
}

interface BuildingTypeElement {
  text: string
  detailsChartText?: string
  icon: JSX.Element
}

interface BuildingType {
  residental: BuildingTypeElement
  commercialAndServices: BuildingTypeElement
  public: BuildingTypeElement
  other: BuildingTypeElement
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetBuildingsTexts = ({ dominantType }: IGetBuildingsTexts = {}) => {
  const { t } = useTranslation()

  const buildingsTexts = {
    buildnigsDataList: {
      firstParam: {
        title: t('placeme.buildings.list.number_of_buildings'),
        description: t('placeme.buildings.list.number_of_buildings_desc'),
      },
      secondParam: {
        title: t('placeme.buildings.list.housing_density'),
        description: t('placeme.buildings.list.housing_density_desc'),
      },
      thirdParam: {
        title: t('placeme.buildings.list.type_of_buildings'),
        description: t('placeme.buildings.list.list.type_of_buildings_desc'),
      },
      fourthParam: {
        title: t('placeme.buildings.list.number_of_apartments'),
        description: t('placeme.buildings.list.number_of_apartments_desc'),
      },
      buildingsType: {
        residental: {
          text: t('placeme.buildings.list.residental'),
          detailsChartText: t('placeme.buildings.span.residental'),
          icon: <Residental />,
        },
        commercialAndServices: {
          text: t('placeme.buildings.list.commercial_and_services'),
          detailsChartText: t('placeme.buildings.span.commercial_and_services'),
          icon: <CommercialAndServices />,
        },
        public: {
          text: t('placeme.buildings.list.public'),
          detailsChartText: t('placeme.buildings.span.public'),
          icon: <Public />,
        },
        other: {
          text: t('placeme.buildings.list.other'),
          icon: <Other />,
        },
      } as BuildingType,
    },
  }

  const dominantTypeText = dominantType ? anyCaseToCamelCase(dominantType) as keyof BuildingType : undefined
  const dominantTypeInfo = dominantTypeText
    ? buildingsTexts.buildnigsDataList.buildingsType[dominantTypeText]
    : undefined

  const tableHeaders = {
    type: t('placeme.buildingtile.table_1_label_1'),
    numberOfBuildings: t('placeme.buildingtile.table_1_label_4'),
    avgNumberOfLevels: t('pdf.furgonetka.buildings.theader_5'),
    numberOfApartments: t('pdf.furgonetka.buildings.theader_6'),
    area: t('placeme.buildingtile.table_1_label_2'),
    volume: t('placeme.buildingtile.table_1_label_3'),
    shareInType: t('table.shareInType'),
  }

  const categories = {
    overall: t('generic.all'),
    singleHouses: t('placeme.buildings.subtype.single_houses'),
    collectiveHousing: t('placeme.buildings.subtype.collective_housing'),
    hotels: t('placeme.buildings.subtype.hotels'),
    officeBuildings: t('placeme.buildings.subtype.office_buildings'),
    commercial: t('placeme.buildings.subtype.commercial'),
    cultureAndArt: t('placeme.buildings.subtype.culture_and_art'),
    scientificInstitutions: t('placeme.buildings.subtype.scientific_institutions'),
    healthcare: t('placeme.buildings.subtype.healthcare'),
    sport: t('placeme.buildings.subtype.sport'),
    religionBuildings: t('placeme.buildings.subtype.religion_buildings'),
    othersPublic: t('placeme.buildings.subtype.others_public'),
  }

  return {
    buildingsTexts,
    dominantTypeInfo,
    tableHeaders,
    categories,
  }
}
