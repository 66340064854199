
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, TextButton } from '@dataplace.ai/ui-components/atoms'
import { sortByText } from '@dataplace.ai/functions/utils'
import styled, { css } from 'styled-components'
import { ISortableTableHeaderProps, ITableFilter } from '../../@types'
import { FilterSection } from '../FilterSection'
import { ITextFilter } from '../TextFilter'
import { Checkbox } from './components/Checkbox'
import { CheckboxesOutsideWrapper, CheckboxesWrapper } from './CheckboxFilter.styles'

const { v4: uuidv4 } = require('uuid')

const TextButtonComponent: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => <TextButton {...props} />

const StyledTextButton = styled(TextButtonComponent)(({
  theme: {
    palette, typography,
  },
}) => css`
  color: ${palette.blue};
  margin-top: .5rem;
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_regular.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};
`)

export interface IBrandFilter extends ITableFilter {
  [key: string]: {
    type: string
    rule: string
  }
}

interface IBrandFilterProps {
  data: Record<string, string>[];
  filter: ITextFilter,
  setFilterValue(filter: ITableFilter): React.Dispatch<React.SetStateAction<ITableFilter>> | void;
  header: ISortableTableHeaderProps
  clearable?: boolean
  checkboxes?: boolean
  name: string
}

export const CheckboxFilter = ({
  setFilterValue, header, filter, clearable, data, checkboxes, name,
}: IBrandFilterProps): JSX.Element => {
  const filterId = `${uuidv4()}`
  const { t } = useTranslation()
  const elements = useMemo(() => {
    if (data?.length) {
      const elementsList : string[] = []
      data?.forEach(element => {
        const elementName = element[name]
        if (!elementsList.find(elem => elem === elementName)) {
          elementsList.push(elementName)
        }
      })

      return elementsList.sort((a, b) => a.localeCompare(b))
    } return []
  }, [data])
  const [showAllCheckBoxes, setShowAllCheckboxes] = useState(false)
  const [checkboxFilter, setCheckboxFilter] = useState('')
  const [filteredSuggestions,
    setFilteredSuggestions] = useState<string[]>(elements)
  const checkboxArray = showAllCheckBoxes
    ? sortByText(filteredSuggestions, name, 'ASC')
    : sortByText(filteredSuggestions, name, 'ASC').slice(0, 3)
  const [selectedElements, setSelectedElements] = useState<string[] | string>(filter[header.name]?.rule || [])

  const setBrandCheckboxFilterValue = (value: string) => {
    if (!value) {
      setCheckboxFilter('')
      setShowAllCheckboxes(false)
      setFilteredSuggestions(elements)
    } else {
      setCheckboxFilter(value)
      const tempSuggestions = elements.filter(item => item.toLocaleLowerCase().includes(value))
      setFilteredSuggestions(tempSuggestions)
      setShowAllCheckboxes(true)
    }
  }

  const clearCheckboxFilter = () => {
    const newFilter = {
      ...filter,
    }
    delete newFilter[header.name]
    setCheckboxFilter('')
    setFilteredSuggestions(elements)
    setShowAllCheckboxes(false)
    setSelectedElements([])
    setFilterValue(newFilter)
  }

  const handleCheckboxChange = (checked: boolean, element: string) => {
    let newSelectedElements: string[] = []
    if (checked) {
      if (element === 'all') {
        newSelectedElements = [...elements, 'all']
      } else {
        newSelectedElements = [...selectedElements, element]
        if (newSelectedElements.length === elements.length) {
          newSelectedElements.push('all')
        }
      }
    }
    else if (element === 'all') {
      newSelectedElements = []
    } else {
      newSelectedElements = [...selectedElements].filter(selectedElement => selectedElement !== element && selectedElement !== 'all')
    }
    const newFilter = {
      ...filter,
    }
    if (!newSelectedElements.length) {
      delete newFilter[header.name]
    } else {
      newFilter[header.name] = {
        type: name,
        rule: newSelectedElements,
      }
    }
    setSelectedElements(newSelectedElements)
    setFilterValue(newFilter)
  }

  useEffect(() => {
    if (!filter[header.name]?.rule) {
      setSelectedElements([])
    }
  }, [filter])

  return (
    <FilterSection
      clear={clearCheckboxFilter}
      clearable={clearable}
      header={t(`generic.${header.label}`)}
    >
      <Input
        onChange={e => {
          setBrandCheckboxFilterValue(e.target.value)
        }}
        placeholder={t('generic.search')}
        value={checkboxFilter}
      />
      {checkboxes && (
        <>
          <CheckboxesOutsideWrapper>
            <CheckboxesWrapper>
              <Checkbox
                checked={selectedElements.includes('all')}
                id={`${filterId}_all`}
                label={(<label htmlFor={`${filterId}_all`}>{t('generic.all')}</label>)}
                onChange={(e) => handleCheckboxChange(e.target.checked, 'all')}
              />
              {checkboxArray.map(element => (
                <Checkbox
                  key={`${filterId}_${element}`}
                  checked={selectedElements.includes(element)}
                  id={`${filterId}_${element}`}
                  label={(
                    <label htmlFor={`${filterId}_${element}`}>
                      {element}
                    </label>
                  )}
                  onChange={(e) => handleCheckboxChange(e.target.checked, element)}
                />
              ))}
            </CheckboxesWrapper>
          </CheckboxesOutsideWrapper>
          {filteredSuggestions.length > 3 && (
            <StyledTextButton
              onClick={() => setShowAllCheckboxes(!showAllCheckBoxes)}
            >
              {showAllCheckBoxes ? t('generic.hide') : t('generic.show_more')}
            </StyledTextButton>
          )}
        </>
      )}

    </FilterSection>
  )
}

CheckboxFilter.defaultProps = {
  clearable: false,
  checkboxes: false,
}

