import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'

// require hex format
export const categoriesColors = {
  residental: `${defaultTheme.palette.product.marketing.main}`,
  commercialAndServices: `${defaultTheme.palette.product.location.main}`,
  public: `${defaultTheme.palette.results.red.dark}`,
  other: `${defaultTheme.palette.seaBlue}`,
}

export const differentCategories = {
  overall: 'overall',
  mostCommonType: 'mostCommonType',
  other: 'other',
} as const
