import React from 'react'
import { Loader } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { ILocationTypeData } from './@types/ILocationType'
import { DoughnutElement } from './DoughnutElement'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const LocationType: React.FC<{ data: ILocationTypeData}> = ({ data }) => {
  if (data?.loading || !data?.value) return <Loader />

  return (
    <Wrapper>
      <DoughnutElement data={data} />
    </Wrapper>
  )
}

export default LocationType
