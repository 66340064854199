import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDemoChainImpactTexts = () => {
  const { t } = useTranslation()
  const demoChainImpact = {
    section: {
      title: t('placeme.demo.store_chain_impact.section_title_1'),
      paragraph: t('placeme.demo_potential_model_tile.label.transactions'),
    },
    table: {
      tooltipPositive: t('placeme.demo.store_chain_impact.tooltip.part_1.positive'),
      tooltipNegative: t('placeme.demo.store_chain_impact.tooltip.part_1.negative'),
      tooltipPart2: t('placeme.demo.store_chain_impact.tooltip.part_2'),
      labels: {
        column1: t('placeme.demo.store_chain_impact.table.headers.name'),
        column2: t('placeme.demo.store_chain_impact.table.headers.site_id'),
        column3: t('placeme.demo.store_chain_impact.table.headers.current'),
        column4: t('placeme.demo.store_chain_impact.table.headers.predicted'),
        column5: t('placeme.demo.store_chain_impact.table.headers.distance'),
      },
    },
  }

  return {
    demoChainImpact,
  }
}
