import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ background: 'light' | 'dark' }>(
  ({
    theme:
    { palette }, background,
  }) => css`
      display: flex;
      flex-direction: column;
      padding: 1.25rem 1.5rem;
      background-color: ${background === 'light' ? palette.light.white : palette.light.main};
    `,
)

export const EstimationParagraph = styled.p(({
  theme: {
    palette,
    typography,
  },
}) => css`
    font-size: ${typography.main.pt_15_semibold.fontSize};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
    color: ${palette.black};
    > strong {
        font-size: 28px;
        line-height: 40px;
        margin-left: 0.5rem;
    }
`)

export const EstimationLabel = styled.span(({
  theme: {
    palette,
    typography,
  },
}) => css`
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
`)

export const Estimation = styled.div(({ theme: { palette } }) => css`
    padding-bottom: 30px;
    margin: 30px 0;
    border-bottom: 1px solid ${palette.light.darkest};
`)

export const ChartTitle = styled.h5(({
  theme: {
    palette,
    typography,
  },
}) => css`
    font-weight: 500;
    font-size: ${typography.main.pt_15_semibold_upper.fontSize};
    line-height: ${typography.main.pt_15_semibold_upper.lineHeight};
    letter-spacing: ${typography.main.pt_15_semibold_upper.letterSpacing};
    text-transform: ${typography.main.pt_15_semibold_upper.textTransform};
    color: ${palette.black};
    `)

export const Title = styled.h4(({
  theme: {
    palette,
    typography,
  },
}) => css`
    font-weight: 500;
    font-size: ${typography.main.pt_15_semibold.fontSize};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
    text-transform: ${typography.main.pt_15_semibold_upper.textTransform};
    color: ${palette.black};
    `)

export const SectionTitle = styled.h4(({ theme: { typography } }) => css`
    font-size: ${typography.main.pt_15_medium_upper.fontSize};
    font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
    line-height: ${typography.main.pt_15_medium_upper.lineHeight};
    letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
    text-transform: ${typography.main.pt_15_medium_upper.textTransform};
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`)

export const SectionMainText = styled.span(({ theme: { typography } }) => css`
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    margin-bottom: 10px;
`)

export const Section = styled.div(({
  theme: {
    palette,
    corners,
  },
}) => css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
    margin-bottom: 30px;
    `)

export const TagButtonWrapper = styled.div(() => css`
    width: 100%;
    display: flex;
    justify-content: flex-end; 
    margin-top: 100px;
`)

export const SimilarAdressWrapper = styled.ul(({ theme: { palette } }) => css`
    width: 100%;
    list-style-type: none;

    >li:nth-child(2n){
    background-color: ${palette.light.main};
    }
`)

export const SimilarAdressElement = styled.li(({ theme: { typography } }) => css`
    display: flex;
    flex-direction: column;
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    padding: 10px 0 10px 10px;
    width: 100%;
    border-radius: 6px;
    align-items: flex-start;
`)

export const AdressContentWrapper = styled.div(() => css`
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
`)
export const AdressItem = styled.div(({ theme: { typography } }) => css`
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
    margin: 0 5px;
`)
