/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { AuthContext } from '@dataplace.ai/features'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { BarChart, DoughnutChart, GaugeChart, IMapLocationProps, LinkWithIconCheckOnMap, MapTile, RangeChart, Result, Results, TileFormColumn, TileFormRowWithData, TileFormSpan, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { getAxios, getRangeTranslationFromRangeId } from '@dataplace.ai/functions/utils'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import NumberFormat from 'react-number-format'
import { bigBenCategoryId, bigBenChainRankingParameters } from '@dataplace.ai/constants'
import { IRange } from '@dataplace.ai/types'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { BasicLayers, GeojsonLayers, WMSLayers, FeatureCollectionLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import AddMoreData from '../../../../molecules/AddMoreData/AddMoreData'
import { useAppDispatch } from '../../../../../../../redux/hooks'
import { fetchWorkspaceUsageValue, saveTileData } from '../../../../../slice/analysisSlice'
import { IPopulationAndSpending, IPopulationTileData } from './@types/IPopulationAndSpendingTileData'
import { RankingCustomCatchmentSpan } from '../atoms'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
    gap: 2rem;
  `
})

const Section = styled.div(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};

    > h4 {
      font-size: ${typography.main.pt_15_medium_upper.fontSize};
      font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
      line-height: ${typography.main.pt_15_medium_upper.lineHeight};
      letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
      text-transform: ${typography.main.pt_15_medium_upper.textTransform};
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > h5 {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }

    > p {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      margin-bottom: 15px;

      > span {
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};
        font-family: 'Inter',sans-serif;
        display: inline;
        margin-left: 0.25rem;
      }
    }

    > span {
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      margin-bottom: 10px;

      > strong {
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
        display: block;
        margin-top: 10px;
      }
      
      >b{
        font-weight: 600;
        font-size: ${typography.main.pt_15_regular.fontSize};
      }
      
      > p {
        font-size: ${typography.small.pt_14_semibold.fontSize};
        font-weight: ${typography.small.pt_14_semibold.fontWeight};
        line-height: ${typography.small.pt_14_semibold.lineHeight};
        display: inline;
        margin-top: 10px;
      }
    }
  `
})

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`

const LegendWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

const Legend = styled.span<{color: string}>(({
  theme, color,
}) => {
  const { typography } = theme
  return css`
    display: inline-block;
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    border-left: 10px solid ${color};
    padding: 0.25rem 0.5rem;
  ` })

const Divider = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    width: 100%;
    height: 1px;
    border-top: 1px solid ${palette.light.darkest};
    margin-bottom: 1rem;
` })

const MapWrapper = styled.div`
  margin: 1rem 0 2rem;
  width: 100%;
  height: 300px;

  @media print {
    margin: 0;
  }
`

export const PopulationAndSpendingTile: React.FC<{ data: IPopulationTileData, tileId: string }> = ({
  data, tileId,
}) => {
  // variables
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const {
    values, canBeSave,
  } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  const rangeTranslation = useMemo(() =>
    getRangeTranslationFromRangeId(data?.value?.catchmentMode),
  [JSON.stringify(data?.value?.catchmentMode)])

  // states
  const [token, setToken] = useState('')
  const [initialData, setInitialData] = useState<IPopulationAndSpending|undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [catchmentId, setCatchmentId] = useState<undefined | string>(undefined)
  const [chosenRange, setChosenRange] = useState<undefined | IRange>(undefined)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  // functions
  const calculateResultBean = (whichSpot: number, all: number): {state: Result, label: string} => {
    if ((whichSpot / all) * 100 >= 60) { return {
      state: 'positive',
      label: t('generic.high'),
    } }
    if ((whichSpot / all) * 100 <= 40) { return {
      state: 'negative',
      label: t('generic.low'),
    } }
    return {
      state: 'average',
      label: t('generic.average'),
    }
  }

  const handleMapOpen = () => setIsMapDisplayed(!isMapDisplayed)

  const getCatchmentId = () => values?.find(c => c.id === bigBenCategoryId)?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const getChosenRange = () => values?.find(c => c.id === bigBenCategoryId)?.tiles?.find(t =>
    t.id === tileId)?.chosenRange

  const fetchData = useCallback(async (addData?:IPopulationAndSpending) => {
    if (token.length) {
      let body: {
        [key: string]: unknown
      } = {
        catchmentId,
      }
      if (addData) {
        body = {
          ...body,
          extra: true,
        }
      } else {
        body = {
          ...body,
          parameters: ['people', 'purchasing_power', 'alcohol_spendings', 'total_number_of_shops'],
          rankingParameter: [
            bigBenChainRankingParameters.population,
          ],
        }
      }

      const getTileType = (id: string) => id.split('-')[0]
      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]
      let saveData
      const axiosInstance = await getAxios({
        errCallbackFn: (e) => {
          saveData = {
            loading: false,
            error: e.message,
            value: null,
          }
        },
      })
      const response = await axiosInstance.post(endpoint || '', body)
      if (response) {
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      }

      if (!addData) {
        dispatch(saveTileData(bigBenCategoryId, tileId, saveData))
      } else {
        dispatch(saveTileData(bigBenCategoryId, tileId, {
          ...data,
          value: {
            ...addData,
            additionalData: saveData?.value?.additionalData,
          },
        }))
      }
    }
  }, [token])

  const getLayers = () => {
    const tile = values?.find(c => c.id === 'big_ben')?.tiles?.find(t =>
      t.id === tileId)
    if (data?.value?.additionalData?.populationHeatmap) {
      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
        // heatmap layer
        {
          id: data?.value?.additionalData?.populationHeatmap?.id,
          layer: {
            ...data?.value?.additionalData?.populationHeatmap,
            options: {
              type: data?.value?.additionalData?.populationHeatmap?.type,
              id: data?.value?.additionalData?.populationHeatmap?.id,
              style: {
                ...data?.value?.additionalData?.populationHeatmap?.style,
                blur: 50,
                radius: mapLocation.zoom * 5,
                minOpacity: 0.0,
                gradient: {
                  0: '#0099FF',
                  0.5: 'yellow',
                  1: '#FF0000',
                },
              },

            },
          },
        },
        // ranger layer
        {
          id:  tile?.id || '',
          layer: {
            data: {
              coordinates: tile?.chosenRange?.geoJSON?.coordinates as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: 'population_and_spending_range',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        },
      ]
      return layers
    }
    return undefined
  }

  const memoLayers = useMemo(() => getLayers(), [mapLocation.zoom])

  const canFetchDataForPrinting = useMemo(() => (
    !!(!data?.loading && data?.value && initialData && canBeSave)
  ), [data, initialData, canBeSave])

  // hooks
  useEffect(() => {
    setCatchmentId(getCatchmentId())
    setChosenRange(getChosenRange())
  }, [values])

  useEffect(() => {
    if (token.length && !data?.mapLocation) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (!data?.value) fetchData()
  }, [fetchData, !data?.value])

  useEffect(() => {
    if (data?.value) {
      if (data?.value?.additionalData) {
        setInitialData(undefined)
      }
      else {
        setInitialData(data?.value)
      }
    }
  }, [data])

  useEffect(() => {
    if (data?.value?.additionalData?.populationHeatmap && mapLocation) {
      dispatch(saveTileData('big_ben', tileId, {
        ...data,
        mapLocation,
      }))
    }
  }, [mapLocation])

  return !data?.value || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <Section>
          <h4>{t('placeme.population_and_finances_tile.section_title_1')}</h4>
          <p>
            {t('placeme.population_and_finances_tile.people.line_1')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.people?.residentsValue}
            />
          </p>
          <span>{t('placeme.population_and_finances_tile.people.line_2')}</span>

          <span><strong>{t('placeme.network_ranking.title')}</strong></span>
          <span>
            {t('placeme.population_and_finances_tile.people.line_3')}
            <RankingCustomCatchmentSpan
              chosenRange={chosenRange}
              rangeTranslation={rangeTranslation}
              tileId={tileId}
            />
          </span>
          <RangeChart
            label='generic.place'
            max={data?.value?.totalNumberOfShops}
            modal={{
              catchmentId,
              parameter: bigBenChainRankingParameters.population,
              rangeTranslation,
            }}
            tooltip1='placeme.population_and_spending_tile.population.tooltip1'
            tooltip1Value={data?.value?.people?.highestResidentsValue}
            tooltip2='placeme.population_and_spending_tile.population.tooltip2'
            tooltip2Value={data?.value?.people?.lowestResidentsValue}
            value={data?.value?.people?.residentsValueRank}
          />

        </Section>

        <Section>
          <h4>{t('placeme.population_and_finances_tile.section_title_2')}</h4>
          <p>
            {t('placeme.population_and_finances_tile.purchasing.line_1')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.purchasingPower?.averageSalary?.toFixed(0)}
            />
            {` PLN ${t('generic.net')}`}
          </p>
          <p>
            {t('placeme.population_and_finances_tile.purchasing.line_2')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.purchasingPower?.totalEarnings?.toFixed(0)}
            />
            {` PLN ${t('generic.net')}`}
          </p>

          <span><strong>{t('placeme.network_ranking.title')}</strong></span>
          <span>
            {t('placeme.population_and_finances_tile.purchasing.line_3')}
            <RankingCustomCatchmentSpan
              chosenRange={chosenRange}
              rangeTranslation={rangeTranslation}
              tileId={tileId}
            />
          </span>
          <RangeChart
            label='generic.place'
            max={data?.value?.totalNumberOfShops}
            modal={{
              catchmentId,
              parameter: bigBenChainRankingParameters.purchasing_power,
              rangeTranslation,
            }}
            tooltip1='placeme.population_and_spending_tile.purchasing.tooltip1'
            tooltip1Value={data?.value?.purchasingPower?.highestAverageSalary}
            tooltip2='placeme.population_and_spending_tile.purchasing.tooltip2'
            tooltip2Value={data?.value?.purchasingPower?.lowestAverageSalary}
            value={data?.value?.purchasingPower?.averageSalaryRank}
          />

        </Section>

        <Section>
          <h4>{t('placeme.population_and_finances_tile.section_title_3')}</h4>
          <p>
            {t('placeme.population_and_finances_tile.alcohol.line_1')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.alcoholSpendings?.perCapita?.toFixed(0)}
            />
            {` PLN ${t('generic.net')}`}
          </p>
          <p>
            {t('placeme.population_and_finances_tile.alcohol.line_2')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.alcoholSpendings?.catchmentSum?.toFixed(0)}
            />
            {` PLN ${t('generic.net')}`}
          </p>

          <span><strong>{t('placeme.network_ranking.title')}</strong></span>
          <span>
            {t('placeme.population_and_finances_tile.alcohol.line_3')}
            <RankingCustomCatchmentSpan
              chosenRange={chosenRange}
              rangeTranslation={rangeTranslation}
              tileId={tileId}
            />
          </span>
          <RangeChart
            label='generic.place'
            max={data?.value?.totalNumberOfShops}
            modal={{
              catchmentId,
              parameter: bigBenChainRankingParameters.spending,
              rangeTranslation,
            }}
            tooltip1='placeme.population_and_spending_tile.alcohol.tooltip1'
            tooltip1Value={data?.value?.alcoholSpendings?.highestPerCapita}
            tooltip2='placeme.population_and_spending_tile.alcohol.tooltip2'
            tooltip2Value={data?.value?.alcoholSpendings?.lowestPerCapita}
            value={data?.value?.alcoholSpendings?.perCapitaRank}
          />

        </Section>

        {!data?.value?.additionalData
               && canBeSave
        && (
          <AddMoreData
            canFetchDataForPrinting={canFetchDataForPrinting}
            loading={loading}
            onClick={() => {
              setLoading(true)
              fetchData(initialData)
            }}
            tiles={['placeme.population_and_finances_tile.section_title_4', 'placeme.population_and_finances_tile.section_title_5', 'placeme.population_and_finances_tile.section_title_6', 'placeme.population_and_finances_tile.section_title_7']}
          />
        )}

        {data?.value?.additionalData && (
          <>
            <Section>
              <h4>{t('placeme.population_and_finances_tile.section_title_4')}</h4>
              <FlexWrapper>
                <TileFormColumn
                  align='start'
                  width='65%'
                >
                  <TileFormRowWithData legend='#362FB7'>
                    <span>{t('placeme.population_and_finances_tile.demography.women')}</span>
                    <span>{`${parseFloat((data?.value?.additionalData?.sexStructure?.f * 100).toFixed(1))}%`}</span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.population_and_finances_tile.demography.women_ratio')}
                  </TileFormSpan>
                  <TileFormRowWithData legend='#7E7AD2'>
                    <span>{t('placeme.population_and_finances_tile.demography.men')}</span>
                    <span>{`${parseFloat((data?.value?.additionalData?.sexStructure?.m * 100).toFixed(1))}%`}</span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.population_and_finances_tile.demography.men_ratio')}
                  </TileFormSpan>
                </TileFormColumn>

                <TileFormColumn width='34%'>

                  <DoughnutChart
                    data={[
                      data?.value?.additionalData?.sexStructure?.f * 100,
                      data?.value?.additionalData?.sexStructure?.m * 100]
                      .map(number => parseFloat(number.toFixed(1)))}
                    innerLabels
                    labels={[t('placeme.population_and_finances_tile.demography.women'), t('placeme.population_and_finances_tile.demography.men')]}
                    valueType='percentages'
                    width={150}
                  />
                </TileFormColumn>
              </FlexWrapper>
            </Section>

            <Section>
              <h4>{t('placeme.population_and_finances_tile.section_title_5')}</h4>
              <p>
                {t('placeme.population_and_finances_tile.age.line_1')}
                {' '}
                <span>
                  {data?.value?.additionalData?.averageAge}
                  {' '}
                  {t('generic.years')}
                </span>
              </p>
              <span>{t('placeme.population_and_finances_tile.age.line_2')}</span>

              <span><strong>{t('placeme.network_ranking.title')}</strong></span>
              <span>
                {t('placeme.population_and_finances_tile.age.line_3')}
                <RankingCustomCatchmentSpan
                  chosenRange={chosenRange}
                  rangeTranslation={rangeTranslation}
                  tileId={tileId}
                />
              </span>
              <RangeChart
                label='generic.place'
                max={data?.value?.totalNumberOfShops}
                modal={{
                  catchmentId,
                  parameter: bigBenChainRankingParameters.average_age,
                  rangeTranslation,
                }}
                tooltip1='placeme.population_and_spending_tile.age.tooltip1'
                tooltip1Value={data?.value?.additionalData?.highestAverageAge}
                tooltip2='placeme.population_and_spending_tile.age.tooltip2'
                tooltip2Value={data?.value?.additionalData?.lowestAverageAge}
                value={data?.value?.additionalData?.averageAgeRank}
              />

            </Section>

            <Section>
              <h4>{t('placeme.population_and_finances_tile.section_title_6')}</h4>
              <LegendWrapper>
                <Legend color='#362FB7'>{t('generic.women')}</Legend>
                <Legend color='#7E7AD2'>{t('generic.men')}</Legend>
              </LegendWrapper>
              <span><strong>{t('placeme.population_and_spending.bar_title')}</strong></span>

              <BarChart
                dataset={[
                  {
                    label: t('placeme.demography_tile.bar_chart_dataset_label_1'),
                    data: data?.value?.additionalData?.ageRange?.map(ar => (ar.f)),
                  },
                  {
                    label: t('placeme.demography_tile.bar_chart_dataset_label_2'),
                    data: data?.value?.additionalData?.ageRange?.map(ar => (ar.m)),
                  },
                ]}
                labels={data?.value?.additionalData?.ageRange?.map(ar => (ar.label))}
              />
              <span
                style={{
                  textAlign: 'center',
                  marginTop: 0,
                }}
              >
                <strong>{t('placeme.population_and_spending.bar_label')}</strong>
              </span>

            </Section>
            <Section>
              <h4>
                {t('placeme.population_and_finances_tile.section_title_7')}
                <Results
                  state={calculateResultBean(12, 123).state}
                  value={calculateResultBean(12, 123).label}
                />
              </h4>

              <h5>{t('placeme.population_and_finances_tile.density')}</h5>

              <GaugeChart
                height={150}
                legend={
                  ['placeme.population_and_finances_tile.gauge.lowest', 'placeme.population_and_finances_tile.gauge.highest']
                }
                unit='%'
                value={data?.value?.additionalData?.densityValue * 100}
              />

              <Divider />

              <span><strong>{t('placeme.network_ranking.title')}</strong></span>
              <span>
                {t('placeme.population_and_finances_tile.density.line_3')}
                <RankingCustomCatchmentSpan
                  chosenRange={chosenRange}
                  rangeTranslation={rangeTranslation}
                  tileId={tileId}
                />
              </span>
              <RangeChart
                label='generic.place'
                max={data?.value?.totalNumberOfShops}
                modal={{
                  catchmentId,
                  parameter: bigBenChainRankingParameters.density_value,
                  rangeTranslation,
                }}
                percent
                tooltip1='placeme.population_and_spending_tile.density.tooltip2'
                tooltip1Value={data?.value?.additionalData?.highestDensityValue * 100}
                tooltip2='placeme.population_and_spending_tile.density.tooltip1'
                tooltip2Value={data?.value?.additionalData?.lowestDensityValue * 100}
                value={data?.value?.additionalData?.densityValueRank}
              />
              {data?.value?.additionalData?.populationHeatmap && (
                <>
                  <TitleFormSectionSubTitle>
                    <span>{t('placeme.people_tile.section_sub_title_2_span_1')}</span>
                    <LinkWithIconCheckOnMap onClick={() => handleMapOpen()} />
                  </TitleFormSectionSubTitle>
                  <MapWrapper>
                    <MapTile
                      height='100%'
                      layers={memoLayers}
                      location={value}
                      mapId='example-map-data-tile'
                      pinDisplayed
                      popupHeading={t('generic.chosen_location')}
                      popupParagraph={value?.address}
                      setMapLocation={setMapLocation}
                      showScaleControl
                      width='100%'
                      zoom={15}
                      zoomControl
                    />
                  </MapWrapper>
                  <span>
                    {' '}
                    {t('placeme.population_and_spending_tile.highest_inhabitants_value.description_1')}
                    {' '}
                    <b>{data?.value?.additionalData?.highestInhabitantsAddress}</b>
                    {'. '}
                    {t('placeme.population_and_spending_tile.highest_inhabitants_value.description_2')}
                    {' '}
                    <b>{data?.value?.additionalData?.highestInhabitantsValue}</b>
                    {'. '}
                  </span>

                  {isMapDisplayed && (
                    <MapVisualizationModal
                      isDisplayed={isMapDisplayed}
                      layers={memoLayers}
                      location={value}
                      mapId={`people-tile-${values?.find(c => c.id === 'big_ben')?.tiles?.find(t => t.id === 'big_ben_population')?.chosenRange?.catchmentId}`}
                      setIsDisplay={setIsMapDisplayed}
                      setMapLocation={setMapLocation}
                      zoom={15}
                    />
                  )}
                </>
              )}
            </Section>
          </>
        )}
      </Wrapper>
    )
}

export default PopulationAndSpendingTile
