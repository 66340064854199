import { ReactComponent as InfoIcon } from 'libs/shared/assets/src/lib/icons/info.svg'
import { TileSectionIds } from 'libs/shared/constants/src/lib/sectionIds'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    gap: 1rem;
    margin: 0.5rem 0;
`

const StyledInfoIcon = styled(InfoIcon)`
    width: 3.8rem;
`

export const Title = styled.h4(({
  theme: {
    palette,
    typography,
  },
}) => css`
    font-weight: 500;
    font-size: ${typography.main.pt_15_semibold.fontSize};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
    color: ${palette.results.red.dark};
  `)

export const DemoInfoSection: React.FC<{categoryId: string}> = ({ categoryId }) => {
  const { t } = useTranslation()

  if (categoryId !== TileSectionIds.DEMO) return null

  return (
    <Wrapper>
      <StyledInfoIcon />
      <Title>
        {t('placeme.demo.description')}
      </Title>
    </Wrapper>
  )
}
