import React from 'react'
import { useTranslation } from 'react-i18next'
import { MultipleChoiceDropdown } from '@dataplace.ai/ui-components/organisms/MultipleChoiceDropdown/MultipleChoiceDropdown'
import { FormElement } from '@placeme/components/atoms/FormElement/FormElement'
import { DemoInfoSection } from '@dataplace.ai/ui-components/molecules/DemoInfoSection/DemoInfoSection'
import { TileFooter } from '../../../../atoms/TileFooter/TileFooter'
import { InputWrapper, ParametersWrapper, StyledSpan, StyledWrapper, TypeRangeWrapper, Wrapper } from './McdPotentialModelTile.styles'
import { FormFieldValue, InitialFormState } from './McdPotentialModel'
import { FormDataObject } from './McdPotentialModelData/hooks/formData/useFormFieldData'
import { FormDataObjectDemo } from './DemoSalesPredictionModelData/hooks/formData/useFormFieldData'

interface IMcdPotentialModelForm {
  categoryId: string,
  formState: InitialFormState,
  formDataObject: FormDataObject | FormDataObjectDemo,
  setFormValue: (key: string, value: FormFieldValue) => void,
  setLocationAdditions: React.Dispatch<React.SetStateAction<string[]>>,
  handleSubmit: () => void,
  handleDeleteTile: () => void
}

export const McdPotentialModelForm: React.FC<IMcdPotentialModelForm> = ({
  handleSubmit,
  handleDeleteTile,
  formState,
  formDataObject,
  setLocationAdditions,
  setFormValue,
  categoryId,
}) => {
  const { t } = useTranslation()

  const {
    formElementData,
    additionalFormElementData,
    multipleChoiceData,
  } = formDataObject
  const [firstElementKey, secondElementKey] = Object.keys(formElementData)
  const firstElement = formElementData[firstElementKey]
  const secondElement = formElementData[secondElementKey]

  return (
    <Wrapper background='dark'>
      <h4>{t('placeme.mcd_potential_model_tile.header')}</h4>
      <DemoInfoSection categoryId={categoryId} />
      <TypeRangeWrapper>
        <p>{t('placeme.mcd_potential_model_tile.input_info')}</p>
        <ParametersWrapper>
          <FormElement
            key={firstElement.inputKey}
            dropdownWidth='8rem'
            formStateElement={firstElement.formStateElement}
            inputKey={firstElement.inputKey}
            options={firstElement.options}
            setFormValue={setFormValue}
            title={firstElement.title}
          />
          <StyledWrapper width='60%'>
            {Object.values(multipleChoiceData).map(formEl => (
              <InputWrapper key={formEl.inputKey}>
                <StyledSpan>{formEl.title}</StyledSpan>
                <MultipleChoiceDropdown
                  choices={formEl.options}
                  onChange={setLocationAdditions}
                  placeholder={t('generic.select')}
                  selectAll={{
                    text: t('generic.all'),
                  }}
                  width='14rem'
                />
              </InputWrapper>
            ))}
          </StyledWrapper>
        </ParametersWrapper>
        <FormElement
          key={secondElement.inputKey}
          formStateElement={secondElement.formStateElement}
          inputKey={secondElement.inputKey}
          options={secondElement.options}
          setFormValue={setFormValue}
          title={secondElement.title}
        />
        {additionalFormElementData
          ? (
            <ParametersWrapper>
              {Object.values(additionalFormElementData).map(formEl => (
                <FormElement
                  key={formEl.inputKey}
                  dropdownWidth='10rem'
                  formStateElement={formEl.formStateElement}
                  inputKey={formEl.inputKey}
                  options={formEl.options}
                  setFormValue={setFormValue}
                  title={formEl.title}
                />
              ))}
            </ParametersWrapper>
          )
          : null}
      </TypeRangeWrapper>
      <TileFooter
        disabled={!formState.locationType}
        isUnlimited
        label={t('generic.apply')}
        onAccept={handleSubmit}
        onCancel={handleDeleteTile}
        value='1'
      />
    </Wrapper>
  )
}
