import React from 'react'
import { SimilarLocation } from '../@types/IPotentialWithinChain'
import { TableSpan } from '../PotentialWithinChainResult/PotentialWithinChainResult.styles'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const generateSimilarityContent = (similarLocations: SimilarLocation[]) => similarLocations.map((el, index) => (
  [
    <TableSpan
      key={el.address}
      $bold
      $left
    >
      {index + 1}
    </TableSpan>,
    <TableSpan
      key={el.address}
      $bold
      $left
    >
      {el.address}
    </TableSpan>,
    <TableSpan
      key={el.address}
      $bold
    >
      {el.similarity.total}
      %
    </TableSpan>,
  ]
))
