import React from 'react'
import { IBuildingsTileDataType } from '../@types/IBuildingsTileData'
import ListElement from './ListElement'
import { useGenerateElements } from '../hooks/useGenerateElements'

const BuildnigsDataList: React.FC<{ data: IBuildingsTileDataType }> = ({ data }) => {
  const { listElementsData } = useGenerateElements(data)

  return (
    <>
      {listElementsData.map(({
        title,
        description,
        compareValue,
        difference,
        elemValue,
      }) => (
        <ListElement
          key={`${title}-${description}`}
          compareValue={compareValue}
          description={description}
          difference={difference}
          elemValue={elemValue}
          title={title}
        />
      ))}
    </>
  )
}

export default BuildnigsDataList
