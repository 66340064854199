import { Tooltip } from '@dataplace.ai/ui-components/atoms'
import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as HelpIcon } from '../../../../assets/src/lib/icons/dataplace/help-circle.svg'

const Wrapper = styled.div<{positive:boolean, width?:string, margin?: string}>(({
  theme, positive, width, margin,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    width: ${width || 'auto'};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 7px;
    background: ${positive ? palette.results.green.opaque : palette.results.red.opaque};
    border-radius: 40px;
    margin: ${margin};


    >p{
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
        color: ${positive ? palette.results.green.light : palette.results.red.light};
        white-space: nowrap;
    }

    .help-icon{
        width: 15px;
        height:15px;
        margin-left: 5px;

        path{
            fill:${positive ? palette.results.green.light : palette.results.red.light};
        }
    }
    
    `
})

const TooltipWrapper = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
        display: flex;
        flex-direction: column;
        color: white;
        max-width: 300px;
        >p{
            font-size: ${typography.small.pt_13_regular.fontSize};
            font-weight: ${typography.small.pt_13_regular.fontWeight};
            line-height: ${typography.small.pt_13_regular.lineHeight};
        }
        .bolded{
            font-size: ${typography.small.pt_13_semibold.fontSize};
            font-weight: ${typography.small.pt_13_semibold.fontWeight};
            line-height: ${typography.small.pt_13_semibold.lineHeight};
            margin-bottom: 8px;
        }
    `
})

export interface IComparedLocationBean{
  comparedAddress?: string
  comparedValue?: number | string
  difference?: number | string
  mainAddress?: string
  mainValue?: number | string
  width?: string
  className?: string
  style?: React.CSSProperties
  margin?: string
}

export const ComparedLocationBean = ({
  difference, comparedValue, comparedAddress, mainValue, mainAddress, width, className, style, margin,
}:IComparedLocationBean) :JSX.Element => (
  <Tooltip
    content={(
      <TooltipWrapper>
        <p>{mainAddress}</p>
        <span className='bolded'>{mainValue}</span>
        <p>{comparedAddress}</p>
        <span className='bolded'>
          {comparedValue}
          {' '}
          (
          {difference && difference as number > 0 ? `+ ${difference}` : difference}
          %)
        </span>
      </TooltipWrapper>
    )}
    position='bottom right'
  >
    <Wrapper
      className={className}
      margin={margin}
      positive={!!(difference && difference as number >= 0)}
      style={style}
      width={width}
    >
      <p>
        {difference && typeof difference === 'number' && difference > 0 ? `+ ${difference}` : difference}
        {difference && typeof difference === 'number' ? '%' : ''}
      </p>

      <HelpIcon className='help-icon' />

    </Wrapper>
  </Tooltip>
)
