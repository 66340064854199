export const spendingTableData = {
  labels: [
    'placeme.spending_tile.table_label_1',
    'placeme.spending_tile.table_label_2',
    'placeme.spending_tile.table_label_3',
    'placeme.spending_tile.table_label_4',
  ],
}

export const spendingTableDataForeign = {
  labels: [
    'placeme.spending_tile.table_label_1',
    'placeme.spending_tile.table_label_2',
    'placeme.spending_tile.table_label_3',
  ],
}
