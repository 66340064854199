import { redirectToReports } from '@dataplace.ai/functions/utils/navigate'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { PATHS } from '../constants/paths'

function useRedirectBasedOnProjectState(): (isProjectSaved?: boolean) => void {
  const navigate = useNavigate()

  const redirectBasedOnProjectState = useCallback((isProjectSaved?: boolean) => {
    setTimeout(() => {
      if (isProjectSaved) return redirectToReports()
      return (
        navigate(`/${PATHS.CHOOSE_LOCATION}`, {
          replace: true,
        })
      )
    }, 200)
  }, [])

  return redirectBasedOnProjectState
}

export default useRedirectBasedOnProjectState
