import React from 'react'
import styled, { css } from 'styled-components'
import { Doughnut } from 'react-chartjs-2'
import { IDoughnutChartProps } from '@dataplace.ai/ui-components/atoms/Charts/DoughnutChart/@types/IDoughnutChart.props'

type DoughnutWrapperProps = {
  width?: number
  height?: number
}

const DoughnutWrapper = styled.div<DoughnutWrapperProps>(({
  width,
  height,
}) => css`
  ${width && css`width: ${width}px;`}
  ${height && css`height: ${height}px;`}
  position: relative;
  z-index: 2;
`)

const backgroundColors = [
  '#362FB7',
  '#7E7AD2',
  '#ADAAE3',
  '#9D0584',
  '#C759B5',
  '#E1A8D8',
]

export function DoughnutChart({
  data,
  labels,
  width,
  height,
  radiansNumber,
  innerLabels,
  valueType,
  tooltips = true,
  colors,
  cutoutPercentage = 50,
}: IDoughnutChartProps): JSX.Element {
  return (
    <DoughnutWrapper
      height={height}
      width={width}
    >
      <Doughnut
        data={{
          labels,
          datasets: [
            {
              label: '',
              data,
              backgroundColor: colors || backgroundColors,
              borderWidth: 0,
            },
          ],
        }}
        options={{
          cutoutPercentage,
          plugins: {
            datalabels: {
              display: innerLabels || false,
              color: 'white',
              font: {
                size: '14',
                weight: 'bold',
              },
              formatter(value: number) {
                switch (valueType) {
                  case 'percentages':
                    return `${value}%`
                  default:
                    return value
                }
              },
            },
          },
          rotation: radiansNumber ? radiansNumber * Math.PI : 2 * Math.PI,
          circumference: radiansNumber ? radiansNumber * Math.PI : 2 * Math.PI,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: tooltips,
          },
        }}
      />
    </DoughnutWrapper>
  )
}

export default DoughnutChart
