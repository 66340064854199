export enum TileSectionIds {
  ANW = 'anw',
  MCD = 'mcd',
  BIGBEN = 'big_ben',
  VISUALIZATION = 'visualization',
  TRAFFIC = 'traffic',
  SURROUNDINGS = 'surroundings',
  POTENTIAL = 'potential',
  ACCESS_LOGISTICS = 'access_logistics',
  MARKET = 'market',
  POPULATION = 'population',
  DEMO = 'demo',
  BUILDINGS = 'buildings',
  INHABITANTS_AND_VISITORS = 'inhabitants_and_visitors',
  DOL_SAP = 'dol_sap'
}
