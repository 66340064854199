import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { anwCategoryId } from '@dataplace.ai/constants'
import { useFetchTileData } from 'apps/placeme/src/customHooks/useFetchTileData'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { Table } from '@dataplace.ai/ui-components/organisms/Table'
import { ICompetitionWithinReachTileData } from './@types/ICompetitionWithinReach'
import { CompetitionWithinRangeMap } from './CompetitionWithinReachMap'

const tableTemplate = '2fr 1fr 1fr'

const Wrapper = styled.div(({ theme: { palette } }) => `
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  background-color: ${palette.light.white};
`)

const Section = styled.div((
  {
    theme: {
      palette, corners,
    },
  },
) => `
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${palette.light.white};
  border: 1px solid ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
  color: ${palette.black};
  margin-bottom: 30px;
`)

const SectionMainText = styled.p(({ theme: { typography } }) => `
  font-size: ${typography.main.pt_15_semibold.fontSize};
  font-weight: ${typography.main.pt_15_semibold.fontWeight};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
  margin-bottom: 15px;
`)

const SectionListWrapper = styled.ul(({ theme: { palette } }) => `
  width:100%;
  list-style-type: none;

  li:nth-child(2n) {
    background-color: ${palette.light.main};
  }
`)

const SectionTitle = styled.h4(({ theme: { typography } }) => `
  font-size: ${typography.main.pt_15_medium_upper.fontSize};
  font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
  line-height: ${typography.main.pt_15_medium_upper.lineHeight};
  letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
  text-transform: ${typography.main.pt_15_medium_upper.textTransform};
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`)

const CompetitorsNumber = styled.span(({ theme: { typography } }) => `
  font-size: ${typography.big.pt_28_semibold.fontSize};
  font-weight: ${typography.big.pt_28_semibold.fontWeight};
  line-height: ${typography.big.pt_28_semibold.lineHeight};
  font-family: 'Inter',sans-serif;
  display: inline;
  margin-left: 0.25rem;
`)

export const CompetitionWithinReach: React.FC<{
  data: ICompetitionWithinReachTileData,
  tileId: string
}> = ({
  data,
  tileId,
}) => {
  const { t } = useTranslation()
  const { values } = useSelector((state: RootState) => state.analysis)
  const tile = useMemo(() => {
    const foundTile = values?.find(cat => cat.id === anwCategoryId)?.tiles?.find(t =>
      t.id === tileId)
    if (!foundTile) {
      throw new Error(`Missing tileId in tiles: ${tileId}`)
    }
    return foundTile
  },
  [values])

  const catchmentId = useMemo(() => tile?.chosenRange?.catchmentId, [values])

  const { fetchData } = useFetchTileData(
    data,
    ENDPOINTS.ANW_COMPETITION_WITHIN_REACH_TILE,
    tileId,
    anwCategoryId,
    {
      catchmentId,
    },
  )

  useEffect(() => {
    if (!data?.value) {
      fetchData()
    }
  }, [!data?.value, values, fetchData])

  if (!data?.value || data?.loading) {
    return <Loader />
  }

  return (
    <Wrapper>
      <Section>
        <SectionTitle>{t('placeme.anw_competition_within_reach.section_title_1')}</SectionTitle>
        <SectionMainText>
          {t('placeme.anw_competition_within_reach.competition.line_1')}
          {' '}
          <CompetitorsNumber>{data?.value?.competition?.competitors?.length}</CompetitorsNumber>
        </SectionMainText>
        <SectionListWrapper>
          <Table
            content={data.value.competition.competitors?.map(comp => [
              <span key={`${comp.address}_addr`}>{comp.address}</span>,
              <span key={`${comp.address}_brand`}>{comp.brand}</span>,
              <span key={`${comp.address}_dist`}>{comp.distance}</span>,
            ])}
            headerTemplate={tableTemplate}
            labels={[
              t('placeme.anw_competition_within_reach.table_label_2'),
              t('placeme.anw_competition_within_reach.table_label_1'),
              t('placeme.anw_competition_within_reach.table_label_3'),
            ]}
            rowTemplate={tableTemplate}
          />
        </SectionListWrapper>
        <CompetitionWithinRangeMap
          data={data}
          tile={tile!}
        />
      </Section>
    </Wrapper>
  )
}
