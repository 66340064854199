import axios from 'axios'
import { config } from '../../config'
import { getCookieWithoutJSON } from './cookies'

interface DownloadReportPDFProps {
  reportId: string
  address: string
  comparedReportId?: string
}
export const downloadReportPDF = async ({
  reportId, address, comparedReportId,
}: DownloadReportPDFProps): Promise<void> => {
  async function download() {
    const serverUrl = config.PDF_SERVER_URL
    if (!serverUrl) throw new Error('PDF server url is unset.')
    const response = await axios.post<Blob>(serverUrl, {
      reportId,
      address: address || reportId,
      comparedReportId,
      workspaceId: getCookieWithoutJSON('workspaceId'),
      language: getCookieWithoutJSON('language'),
    }, {
      responseType: 'blob',
      headers: {
        Authorization: getCookieWithoutJSON('__session'),
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Expires: 0,
      },
      timeout: 0,
      withCredentials: true,
    })
    const { data } = response
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(data)
    link.href = url
    const filename = decodeURIComponent(response?.headers['content-disposition'].split('filename=')[1])
    link.download = filename
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
  let retries = 5
  while (retries) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await download()
      break
    } catch (error) {
      retries -= 1
      if (retries === 0) throw error
    }
  }
}
