import React, { useEffect, useState } from 'react'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  MapTile,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { ITile } from 'apps/placeme/src/features/Analyse/slice/@types/ITile'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import styled, { css } from 'styled-components'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { anwCategoryId } from '@dataplace.ai/constants'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { mapConst } from 'apps/placeme/src/features/Analyse/utils/constants'
import { useGetLayers } from './useGetLayers'
import { ICompetitionWithinReachTileData } from './@types/ICompetitionWithinReach'

const MapWrapper = styled.div`
  margin: 1rem 0;
  width: 100%;
  height: 300px;

  @media print {
    margin: 0;
  }
`

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(({ theme: { palette } }) => css`
      border-top: 1px solid ${palette.light.darker};
      padding-top: 2rem;
      margin-bottom: 0;
`)

export const CompetitionWithinRangeMap: React.FC<{
  data: ICompetitionWithinReachTileData,
  tile: ITile
}> = ({
  data, tile,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isMapModalDisplayed, setIsMapModalDisplayed] = useState(false)
  const { value } = useSelector((state: RootState) => state.location)
  const handleMapOpen = () => setIsMapModalDisplayed(!isMapModalDisplayed)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })

  useEffect(() => {
    if (data.value.competition.competitors?.length && mapLocation) {
      dispatch(saveTileData(anwCategoryId, tile.id, {
        ...data,
        mapLocation,
      }))
    }
  }, [mapLocation])

  const layers = useGetLayers(data, tile)

  return (
    <>
      <StyledTitleFormSectionSubTitle>
        <span>{t('placeme.pois_tile.points_on_map')}</span>
        <LinkWithIconCheckOnMap onClick={() => handleMapOpen()} />
      </StyledTitleFormSectionSubTitle>
      <MapWrapper>
        <MapTile
          dragging
          height='100%'
          layers={layers}
          location={value}
          mapId='example-map-data-tile'
          pinDisplayed
          popupHeading={`${t('generic.chosen_location')}:`}
          popupParagraph={value?.address}
          setMapLocation={setMapLocation}
          showScaleControl
          width='100%'
          zoom={mapConst.zoom[13]}
          zoomControl
        />
      </MapWrapper>
      {isMapModalDisplayed
        ? (
          <MapVisualizationModal
            isDisplayed={isMapModalDisplayed}
            layers={layers}
            location={value}
            mapId={`pois-map-${tile?.chosenRange?.catchmentId}`}
            setIsDisplay={setIsMapModalDisplayed}
            zoom={mapConst.zoom[13]}
          />
        )
        : null}
    </>
  )
}
