import * as Sentry from '@sentry/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
`

const StyledText = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 0 0 1rem;
  color: #001D62;
  text-align: center;
`

function ErrorFallback() {
  const { t } = useTranslation()
  return (
    <StyledDiv>
      <StyledText>{t('placeme.tile_error_fallback_text')}</StyledText>
    </StyledDiv>
  )
}

function TileErrorBoundary({
  children, name,
}: React.PropsWithChildren<{ name: string }>): JSX.Element {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={scope => {
        scope.setTag('errorBoundary', name)
      }}
      fallback={<ErrorFallback />}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default TileErrorBoundary
