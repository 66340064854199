import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { NoPrint } from '@dataplace.ai/ui-components/atoms'
import { IPagination } from './@types/IPagination'
import { ReactComponent as Arrow } from '../../../../assets/src/lib/icons/arrows/arrRight.svg'

interface INavigationWrapper {
  justifyNavigation?: string
}
const NavigationWrapper = styled.div<INavigationWrapper>(({
  theme: { typography }, justifyNavigation,
}) => css`
  margin-top: 2rem;
  display: flex;
  justify-content: ${justifyNavigation || 'flex-end'};
  align-items: center;

  p { 
    font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
  }
`)

const CurrentValueWrapper = styled.div(({ theme: { palette } }) => css`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.light.darker};
  border-radius: 6px;
  border: none;
  margin-right: 11px;
`)

const Of = styled.p`
  margin-right:11px;
`

const MaxValueWrapper = styled.div(({ theme: { palette } }) => css`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.light.lighter};
  border-radius: 6px;
  border: 1px solid ${palette.light.darker};
  margin-right: 11px;
`)
const NavButton = styled.button(({ theme: { palette } }) => css`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.light.lighter};
  border-radius: 6px;
  border: 1px solid ${palette.light.darker};
  margin-right: 11px;
  cursor: pointer;
`)

export const Pagination = ({
  data, renderComponent, dataLimit: limit, justifyNavigation,
}:IPagination):JSX.Element => {
  const [dataLimit, setDataLimit] = useState(limit)
  const [pages, setPages] = useState(Math.ceil(data.length / dataLimit) || 1)
  const [currentPage, setCurrentPage] = useState(1)
  const { isPrinting } = useCheckIfPrinting()
  const { t } = useTranslation()

  useEffect(() => {
    if (data) {
      setPages(Math.ceil(data.length / dataLimit) || 1)
      setCurrentPage(1)
    }
  }, [data, dataLimit])

  const goToNextPage = () => {
    setCurrentPage((page) => page + 1)
  }

  const goToPreviousPage = () => {
    setCurrentPage((page) => page - 1)
  }

  const getPaginatedData = useMemo(() => {
    const startIndex = currentPage * dataLimit - dataLimit
    const endIndex = startIndex + dataLimit
    return data.slice(startIndex, endIndex)
  }, [dataLimit, currentPage, data])

  useEffect(() => {
    if (!isPrinting) return
    setDataLimit(data.length)
    setCurrentPage(1)
  }, [isPrinting])

  return (
    <>
      {renderComponent(getPaginatedData)}
      {data?.length
        ? (
          <NoPrint>
            <NavigationWrapper justifyNavigation={justifyNavigation}>
              {currentPage > 1 && (
                <NavButton
                  onClick={goToPreviousPage}
                >
                  <Arrow
                    style={{
                      transform: 'rotate(180deg)',
                    }}
                  />
                </NavButton>
              )}
              <CurrentValueWrapper><p>{currentPage}</p></CurrentValueWrapper>
              <Of>{t('generic.pagination.of')}</Of>
              <MaxValueWrapper><p>{pages}</p></MaxValueWrapper>
              {currentPage < pages && (
                <NavButton
                  onClick={goToNextPage}
                >
                  <Arrow />
                </NavButton>
              )}
            </NavigationWrapper>
          </NoPrint>
        )
        : null}
    </>
  )
}
