import { createSlice } from '@reduxjs/toolkit'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { createFlashMessage } from '@dataplace.ai/functions/utils'
import type { AppThunk } from '../../../redux/store'
import { ENDPOINTS } from '../../../constants/endpoints'
import { IAnalysesAndReportsState } from './@types/IAnalysesAndReportsState'

const initialState : IAnalysesAndReportsState = {
  data: {
    value: null,
    loading: true,
    error: '',
  },
}

const analysesAndReportsSlice = createSlice({
  name: 'analysesAndReports',
  initialState,
  reducers: {
    saveAnalysesAndReports: (state, action) => {
      state.data = action.payload
    },
  },
})

export const fetchAnalysesAndReports = (): AppThunk => async (dispatch): Promise<void> => {
  let data
  const axiosInstance = await getAxios({
    errCallbackFn: (e) => {
      data = {
        loading: false,
        error: e,
        value: null,
      }
    },
  })
  const response = await axiosInstance.get(ENDPOINTS.REPORTS_GET)
  if (response) {
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
  }
  dispatch(saveAnalysesAndReports(data))
}

export const deleteAnalyse = (analyseId: string, isWhitespot?: boolean):
AppThunk => async (): Promise<void> => {
  const axiosInstance = await getAxios()
  const response = await axiosInstance.delete(`${isWhitespot ? ENDPOINTS.WHITESPOT : ENDPOINTS.CREATE_DELETE_SHARE_ANALYSE}/${analyseId}`)
  if (!response) return
  createFlashMessage({
    message: 'status.success.report.deleted',
  })
}

export const changeAnalyseName = (
  analyseId: string,
  analyseName: string,
  isWhitespot?: boolean,
): AppThunk => async (): Promise<void> => {
  const body = {
    name: analyseName,
  }
  const axiosInstance = await getAxios()
  const response = await axiosInstance.patch(
    `${isWhitespot ? ENDPOINTS.WHITESPOT : ENDPOINTS.CREATE_DELETE_SHARE_ANALYSE}/${analyseId}`,
    body,
  )
  if (!response) return
  createFlashMessage({
    message: 'status.success.report.name_changed',
  })
}

export const deleteFolder = (folderId: string): AppThunk => async (dispatch): Promise<void> => {
  let data
  const axiosInstance = await getAxios({
    errCallbackFn: (e) => {
      data = {
        loading: false,
        error: e,
        value: null,
      }
    },
  })
  const response = await axiosInstance.delete(`${ENDPOINTS.CREATE_GET_ADD_DELETE_FOLDER}/${folderId}`)
  if (response) {
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
    createFlashMessage({
      message: 'status.success.folder.deleted',
    })
    if (data?.value) {
      dispatch(fetchAnalysesAndReports())
    }
  }
}

export const { saveAnalysesAndReports } = analysesAndReportsSlice.actions

export default analysesAndReportsSlice.reducer
