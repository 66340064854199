import { Comparison, Loader, Tooltip } from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import React from 'react'
import styled, { css } from 'styled-components'
import { IStoreChainImpactTileData, TranslationObjectType } from './@types/IStoreChainImpact'

const ComparisonSpan = styled.span`
  display: flex !important;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const CenteredSpan = styled.span`
  text-align: center !important;
`

const Section = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
    margin-bottom: 30px;
  `
})

const StyledParagraph = styled.p(({ theme }) => {
  const { typography } = theme

  return css`
  font-size: ${typography.main.pt_15_semibold.fontSize};
  font-weight: ${typography.main.pt_15_semibold.fontWeight};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
  margin-bottom: 15px;
`
})

const StledSpan = styled.span(({ theme }) => {
  const { typography } = theme

  return css`
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
    font-family: 'Inter',sans-serif;
    display: inline;
    margin-left: 0.25rem;
  `
})

const Title = styled.h4(({ theme }) => {
  const { typography } = theme

  return css`
    font-size: ${typography.main.pt_15_medium_upper.fontSize};
    font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
    line-height: ${typography.main.pt_15_medium_upper.lineHeight};
    letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
    text-transform: ${typography.main.pt_15_medium_upper.textTransform};
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
})

const tableTemplate = '3fr 2fr 3fr 3fr 2fr'

export const StoreChainImpactResult: React.FC<{
  data: IStoreChainImpactTileData,
  texts: TranslationObjectType
}> = ({
  data,
  texts,
}) => {
  const {
    section,
    table,
  } = texts
  const tableColumnNames = [
    table.labels.column1,
    table.labels.column2,
    table.labels.column3,
    table.labels.column4,
    table.labels.column5,
  ]

  if (data?.loading || !data?.value) return <Loader />

  return (
    <Wrapper>
      <Section>
        <Title>{section.title}</Title>
        <StyledParagraph>
          {section.paragraph}
          {': '}
          <StledSpan>{data?.value?.storeChainImpact?.locations?.length}</StledSpan>
        </StyledParagraph>
      </Section>
      <Table
        content={data.value.storeChainImpact.locations.map(location => [
          <span key={`${location.siteId}_name`}>
            <Tooltip
              content={(
                <span>
                  {location.name}
                </span>
              )}
              maxWidth='25rem'
              position='right bottom'
            >
              {location.name}
            </Tooltip>
          </span>,
          <CenteredSpan key={`${location.siteId}_siteId`}>{location.siteId}</CenteredSpan>,
          <CenteredSpan key={`${location.siteId}_current`}>{location.transactions.current}</CenteredSpan>,
          <>
            <ComparisonSpan key={`${location.transactions.predicted}_predicted`}>
              {location.transactions.predicted}
              <Tooltip
                content={(
                  <span>
                    {location.transactions.percentageChange < 0
                      ? table.tooltipNegative
                      : table.tooltipPositive}
                    {` ${(Math.abs(location.transactions.percentageChange) * 100).toFixed(2)}% `}
                    {table.tooltipPart2}
                  </span>
                )}
                maxWidth='25rem'
                position='left bottom'
              >
                <Comparison
                  blackArrow
                  state={location.transactions.percentageChange < 0 ? 'negative' : 'positive'}
                  value={`${(Math.abs(location.transactions.percentageChange) * 100).toFixed(2)}%`}
                  withIcon={false}
                />
              </Tooltip>
            </ComparisonSpan>
            <CenteredSpan key={`${location.siteId}_dist`}>{location.distance}</CenteredSpan>
          </>,
        ])}
        headerTemplate={tableTemplate}
        headerTextAlign='center'
        labels={tableColumnNames}
        rowTemplate={tableTemplate}
      />
    </Wrapper>
  )
}
