import { useTranslation } from 'react-i18next'
import { IDemoSalesPredictionModel } from '../../../DemoSalesPredictionModel'

export interface BarData {
  [key: string]: {
    title: string
    paragraph: string
    datasets: {
      bar: {
        label1: string
        value: number
      }
      topLine: {
        label2?: string
        value: number
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetChartData = (data: IDemoSalesPredictionModel) => {
  const { t } = useTranslation()
  const barData = {
    transactions: {
      title: t('placeme.demo.sales_prediction_model.chart_label.transactions'),
      paragraph: t('placeme.demo.sales_prediction_model.label.transactions'),
      datasets: {
        bar: {
          label1: t('placeme.demo.sales_prediction_model.label.transactions'),
          value: data?.value.potentialModel.transactions,
        },
        topLine: {
          label2: t('placeme.demo.sales_prediction_model.chart_label.average_chain_transactions'),
          value: data?.value.potentialModel.averageChainTransactions,
        },
      },
    },
    sales: {
      title: t('placeme.demo.sales_prediction_model.chart_label.sales'),
      paragraph: t('placeme.demo.sales_prediction_model.label.sales'),
      datasets: {
        bar: {
          label1: t('placeme.demo.sales_prediction_model.label.sales'),
          value: data?.value.potentialModel.sales,
        },
        topLine: {
          label2: t('placeme.demo.sales_prediction_model.chart_label.average_chain_sales'),
          value: data?.value.potentialModel.averageChainSales,
        },
      },
    },
  }

  return barData
}
