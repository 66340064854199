import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { SelectCaret } from '@dataplace.ai/ui-components/atoms'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'

interface WrapperProps {
  $active: boolean
  $isPrinting: boolean
}

const Wrapper = styled.div<WrapperProps>(({
  theme, $active, $isPrinting,
}) => {
  const {
    shadows, corners, palette,
  } = theme

  return css`
    padding: 0.25rem 0;
    
    :nth-child(even) {
      background-color: ${palette.light.main};
    }
    
    ${$active && css`
      display: flex;
      flex-direction: column;
      border-radius: ${corners.default.borderRadius};
      box-shadow: ${$isPrinting ? 'none' : shadows.tiny.boxShadow};
    `}
  `
})

const GridRow = styled.div<{
  rowTemplate: string,
  gap?: string,
  whiteSpaces?: string,
  pois?: boolean,
  textLeftIndex?: number }>(({
  theme, rowTemplate, gap, whiteSpaces, pois, textLeftIndex,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    grid-template-columns: ${rowTemplate};
    grid-gap: ${gap};
    display: grid;
    padding: 0.5rem;
    align-items: center;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    ${textLeftIndex && css`
      > span:nth-child(${textLeftIndex}) {
        text-align: left;
      }
    `}

    > span {
      display: inline-block;
      white-space: ${whiteSpaces || 'nowrap'};
      text-overflow: ellipsis;
      overflow: hidden; 
      text-align: right;

      @media print {
        white-space: normal;
      }

    ${pois && css`

      :nth-of-type(2){
        display: flex;
        justify-content: flex-end;
      }
      :nth-of-type(3){
        display: flex;
        justify-content: flex-end;
      }
    `}
    }

    > span:first-child {
      text-align: left;
    }

    .bank{
        display: flex;
        align-items: center;

        >div{
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-left: 5px;

          >svg{
            path{
              stroke: #909090;
            }
          }
        }
      }
  `
})

export interface ITableRowProps {
  content: JSX.Element[];
  expandable?: JSX.Element[][] | null;
  rowTemplate: string;
  gap?: string;
  expandableComponent?: JSX.Element | null;
  subRow?: JSX.Element[] | null;
  whiteSpaces?: string
  pois?:boolean
  expandableRowTemplate?: string,
  textLeftIndex?: number
  tableRowsExpanded?: boolean
}

export const TableRow = ({
  content,
  rowTemplate,
  gap,
  expandable,
  expandableComponent,
  subRow,
  whiteSpaces,
  pois,
  expandableRowTemplate,
  textLeftIndex,
  tableRowsExpanded = false,
}: ITableRowProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(tableRowsExpanded)
  const { isPrinting } = useCheckIfPrinting()

  return (
    <Wrapper
      $active={active}
      $isPrinting={isPrinting}
      className='avoid-page-break'
    >
      <GridRow
        gap={gap}
        pois={pois}
        rowTemplate={rowTemplate}
        textLeftIndex={textLeftIndex}
        whiteSpaces={whiteSpaces}
      >
        {content}
        {(!isPrinting && (expandable || expandableComponent))
          ? (
            <SelectCaret
              active={active}
              onClick={() => setActive(!active)}
            />
          )
          : null}
      </GridRow>
      {(active && expandable)
      && (
        <GridRow
          gap={gap}
          rowTemplate={expandableRowTemplate || rowTemplate}
          textLeftIndex={textLeftIndex}
          whiteSpaces={whiteSpaces}
        >
          {expandable}
        </GridRow>
      )}
      {(active && expandableComponent)}

      {(subRow && subRow)}
    </Wrapper>
  )
}

