export const tilesWithoutComparedLocation = [
  'scoring_model_big_ben',
  'segmentation_big_ben',
  'look_alike_big_ben',
  'pois_big_ben',
  'traffic_big_ben',
  'population_big_ben',
  'local_market_share',
  'potential_model',
  'traffic_visualisation_big_ben',
  'old_traffic_visualisation_big_ben',
  'mcd_competition_traffic',
  'mcd_store_chain_impact',
  'mcd_potential_model',
  'anw_potential_model',
  'demo_scoring_prediction',
  'demo_sales_prediction_model',
  'anw_competition',
  'potential_within_chain',
  'demo_potential_within_chain',
  'demo_store_chain_impact',
  'location_type',
  'dol_sap_potential_within_chain',
]
