import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface DropdownOptions {
  salesAreaOptions: Array<{ value: string; label: string }>
  plannedShopType: Array<{ value: string; label: string }>
  options: Array<{ value: boolean; label: string }>
  plannedSelfCheckoutsNumber: Array<{ value: number; label?: string }>
}

export const useGetDemoDropdownOptions = (): DropdownOptions => {
  const { t } = useTranslation()

  const plannedSelfCheckoutsNumber = useMemo(() => Array.from({
    length: 16,
  }, (_, index) => ({
    value: index + 1,
  })), [])

  const salesAreaOptions = [
    {
      value: 'lt_60',
      label:  t('placeme.demo.scoring_prediction.area_type.lt_60'),
    },
    {
      value: 'btw_60_90',
      label:  t('placeme.demo.scoring_prediction.area_type.btw_60_90'),
    },
    {
      value: 'btw_90_120',
      label:  t('placeme.demo.scoring_prediction.area_type.btw_90_120'),
    },
    {
      value: 'mt_120',
      label:  t('placeme.demo.scoring_prediction.area_type.mt_120'),
    },
  ]

  const plannedShopType = [
    {
      value: 'high_street',
      label: t('placeme.demo.scoring_prediction.shop_type.high_street'),
    },
    {
      value: 'local_shop',
      label: t('placeme.demo.scoring_prediction.shop_type.local_shop'),
    },
    {
      value: 'retail_park',
      label: t('placeme.demo.scoring_prediction.shop_type.retail_park'),
    },
  ]

  const options = [
    {
      value: true,
      label: t('generic.yes'),
    },
    {
      value: false,
      label: t('generic.no'),
    },
  ]

  return {
    salesAreaOptions,
    plannedShopType,
    options,
    plannedSelfCheckoutsNumber,
  }
}
