import { TileFormRow } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormRow/TileFormRow'
import React from 'react'
import { TileFormColumn } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormColumn/TileFormColumn'
import { TileFormRowWithData } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormRowWithData/TileFormRowWithData'
import { DoughnutChart } from '@dataplace.ai/ui-components/atoms/Charts/DoughnutChart/DoughnutChart'
import { TileFormSectionTitle } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormSectionTitle/TileFormSectionTitle'
import { TileFormSpan } from '@dataplace.ai/ui-components/atoms/TileComponents/TileFormSpan/TileFormSpan'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { ILocationTypeData } from './@types/ILocationType'
import { useGetDoughnutData } from './hooks/useGetDoughnutData'

export const DoughnutElement: React.FC<{data: ILocationTypeData }> = ({ data }) => {
  const { elementData } = useGetDoughnutData(data.value)
  const {
    firstSegment,
    secondSegment,
    title,
    subtitle,
  } = elementData
  const generatePercent = (value: number) => parseFloat((value * 100).toFixed(1))

  return (
    <>
      <TileFormSectionTitle>{title}</TileFormSectionTitle>
      <TileFormSpan>{subtitle}</TileFormSpan>
      <TileFormRow
        width='100%'
        wrap='nowrap'
      >
        <TileFormColumn
          align='start'
          width='60%'
        >
          <TileFormRowWithData legend={defaultTheme.palette.product.location.main}>
            <span>{firstSegment.span}</span>
            <span>{`${generatePercent(firstSegment.value)}%`}</span>
          </TileFormRowWithData>
          <TileFormRowWithData legend={defaultTheme.palette.product.location.medium}>
            <span>{secondSegment.span}</span>
            <span>{`${generatePercent(secondSegment.value)}%`}</span>
          </TileFormRowWithData>
        </TileFormColumn>
        <TileFormColumn width='40%'>
          <DoughnutChart
            data={[firstSegment.value,
              secondSegment.value]
              .map(number => generatePercent(number))}
            innerLabels
            labels={[firstSegment.span, secondSegment.span]}
            valueType='percentages'
            width={230}
          />
        </TileFormColumn>
      </TileFormRow>
    </>
  )
}
