import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useHandleTranslationForInvestName() {
  const { t } = useTranslation()

  const handleTranslateForInvestName = (name: string) => t(`placeme.investments_tile.name.${name.toLocaleLowerCase().split(/[_/ -]+/)
    .join('_')}`)

  return {
    handleTranslateForInvestName,
  }
}

export default useHandleTranslationForInvestName
