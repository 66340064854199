import React, { useEffect, useMemo, useState } from 'react'
import { TileSectionIds } from '@dataplace.ai/constants'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { PotentialWithinChainTile } from './PotentialWithinChainTile'
import { useGetFormData } from './PotentialWithinChainData/hooks/formData/useGetFormData'
import { FormFields, Parameters, DataType, LocationTypeOptions } from './@types/IPotentialWithinChain'
import { useSelectedSettings } from './hooks/resultData/useSelectedSettings'
import { useGetFormFields } from './PotentialWithinChainData/hooks/formData/useGetFormFields'

const initialState = {
  schools: 0,
  hotels: 0,
  cultural: 0,
  offices: 0,
  residential: 0,
  inhabitantsNumber: 0,
  demography: 0,
  earnings: 0,
  pedestrian: 0,
  car: 0,
  chainStoreDistance: 0,
  restaurants: 0,
  petrolStations: 0,
  constructionStores: 0,
  discountStores: 0,
  heavyFastFoodUsers: 0,
  partyAnimals: 0,
}

export const PotentialWithinChain: React.FC<{data: DataType, tileId: string }> = ({
  data,
  tileId,
}) => {
  const { values } = useSelector((state: RootState) => state.analysis)
  const { value: { address } = {} } = useSelector((state: RootState) => state.location)

  const [formState, setFormState] = useState<FormFields>(initialState)

  const { dropdownElements } = useGetFormData()

  const {
    locationType, setLocationTypeId,
  } = useGetFormFields()

  useEffect(() => {
    if (data?.value?.settings) {
      setLocationTypeId(data?.value?.settings.locationType)
    }
  }, [data, values])

  const { settingsList } = useSelectedSettings({
    settings: data?.value?.settings,
    locationType,
    dropdownElements,
  })

  const catchmentId = useMemo(() =>
    getTileInfo(tileId, TileSectionIds.POTENTIAL, values)?.chosenRange?.catchmentId, [tileId, values])

  const body = useMemo(() => {
    if (!locationType) return null
    const parameters: Parameters = {
      buildings: {
        schools: formState.schools,
        hotels: formState.hotels,
        cultural: formState.cultural,
        offices: formState.offices,
        residential: formState.residential,
      },
      traffic: {
        pedestrian: formState.pedestrian,
        car: formState.car,
      },
      pois: {
        chainStoreDistance: formState.chainStoreDistance,
        restaurants: formState.restaurants,
        gasStation: formState.gasStation,
        hardwareStore: formState.hardwareStore,
        discounts: formState.discounts,
      },
      behavioral: {
        heavyFastFoodUsers: formState.heavyFastFoodUsers,
        partyAnimals: formState.partyAnimals,
      },
    }

    if (locationType.id !== LocationTypeOptions.HIGH_STREET) {
      parameters.sociodemographic = {
        population: formState.population,
        demography: formState.demography,
        income: formState.income,
      }
    }

    return {
      catchmentId,
      locationType: locationType.id,
      parameters,
      address,
    }
  }, [formState, catchmentId, locationType])

  return (
    <PotentialWithinChainTile
      body={body}
      categoryId={TileSectionIds.POTENTIAL}
      data={data}
      endpoint={ENDPOINTS.POTENTIAL_WITHIN_CHAIN}
      formState={formState}
      locationType={locationType}
      options={dropdownElements}
      setFormState={setFormState}
      setLocationTypeId={setLocationTypeId}
      settingList={settingsList}
      tileId={tileId}
    />
  )
}
