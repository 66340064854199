import { useCallback } from 'react'
import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { googleAPIsURLs } from '@dataplace.ai/constants'
import { config } from '../../config'

export interface GeocodedLocation {
  formattedAddress: string
  placeId: string
  geometry: {
    location: {
      lat: number
      lng: number
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useGeocoder() {
  const reverseGeocode = useCallback(
    async (lat: number | string, lng: number | string): Promise<GeocodedLocation | undefined> => {
      const res = await axios.get(`${googleAPIsURLs.geocode}?latlng=${lat},${lng}&key=${config.GEOCODE_KEY}`)

      if (!res.data.results?.length) {
        return undefined
      }

      return camelcaseKeys(res.data.results[0], {
        deep: true,
      })
    }, [],
  )

  const geocode = useCallback(async (address: string) => {
    const res = await axios.get(`${googleAPIsURLs.geocode}?address=${address}&key=${config.GEOCODE_KEY}`)

    if (!res.data.results?.length) {
      return undefined
    }

    return camelcaseKeys(res.data.results[0], {
      deep: true,
    })
  }, [])

  return {
    reverseGeocode,
    geocode,
  }
}

export default useGeocoder
