/* eslint-disable no-case-declarations */
/* eslint-disable prefer-destructuring */
import { IOverlappingRangesData } from '@placeme/components/organisms/Tiles/Population/OverlappingRanges/@types/IOverlappingRanges'
import { ITile } from '../../../slice/@types/ITile'
import { IAccessRangeTileData } from '../../organisms/Tiles/AccessLogistics/AccessRange/@types/IAccessRangeTileData'
import { ITransportTileData } from '../../organisms/Tiles/AccessLogistics/Transport/@types/ITransportTileData'
import { ICannibalizationTileData } from '../../organisms/Tiles/Market/Cannibalization/@types/ICannibalizationTileData'
import { IGravityModelTileData } from '../../organisms/Tiles/Potential/GravityModel/@types/IGravityModelTileData'
import { ICustomerOriginTileData } from '../../organisms/Tiles/Traffic/CustomerOrigin/@types/ICustomerOriginTileData'
import { ITrafficVisualisationTileData } from '../../organisms/Tiles/Traffic/TrafficVisualisation/@types/ITrafficVisualisationTileData'

export const getBodyPropertiesForDoublePropertiesTile = (
  tileId: string,
  tilesWithDataAndNonDrawingRange: ITile[],
) : Record<string, unknown> => {
  const tile = tilesWithDataAndNonDrawingRange?.find(tile => tile?.id === tileId)
  const tileType = tileId.split('-')[0]

  switch (tileType) {
    case 'traffic_visualisation':
      const dataTrafficVisualisation = tile?.data as ITrafficVisualisationTileData
      return {
        dataRanges: {
          startDate: dataTrafficVisualisation?.value?.dataRanges?.startDate,
          endDate: dataTrafficVisualisation?.value?.dataRanges?.endDate,
        },
      }
    case 'traffic_visualisation_map':
      const dataTrafficVisualisationMap = tile?.data as ITrafficVisualisationTileData
      return {
        dataRanges: {
          startDate: dataTrafficVisualisationMap?.value?.dataRanges?.startDate,
          endDate: dataTrafficVisualisationMap?.value?.dataRanges?.endDate,
        },
      }
    case 'customer_origin':
      const dataCustomerOrigin = tile?.data as ICustomerOriginTileData
      return {
        dataRanges: {
          startDate: dataCustomerOrigin?.value?.dataRanges?.startDate,
          endDate: dataCustomerOrigin?.value?.dataRanges?.endDate,
        },
      }
    case 'cannibalization':
      const dataCannibalizationTile = tile?.data as ICannibalizationTileData
      return {
        addresses: dataCannibalizationTile?.value?.cannibalization?.map(item => ({
          id: item?.id,
          address: item?.address,
          lat: item?.lat,
          lng: item?.lng,
        })),
      }
    case 'access_range':
      const dataAccessRange = tile?.data as IAccessRangeTileData
      return {
        ranges: dataAccessRange?.value?.accessRange?.data?.features?.map(feat => feat?.properties).map(v => {
          const sliced = v.id.split('_')
          return {
            id: v.id,
            rangeType: sliced[0],
            min: sliced[1],
            max: sliced[2],
          }
        }),
      }
    case 'overlapping_ranges':
      const dataOverlappingRange = tile?.data as IOverlappingRangesData
      return {
        ranges: dataOverlappingRange
          ?.value?.overlappingRanges?.data?.features?.map(feat => feat?.properties).map(v => {
            const [rangeType, length] = v.id.split('_')
            return {
              rangeType,
              length: parseInt(length, 10),
            }
          }),
      }
    case 'gravity_model':
      const dataGravityModel = tile?.data as IGravityModelTileData
      return {
        categoryId: dataGravityModel?.value?.category,
      }
    case 'transport':
      const dataTransport = tile?.data as ITransportTileData
      if (dataTransport?.value?.transport?.fileFromTheUser) {
        return {
          listOfFiles: [dataTransport?.value?.transport?.fileFromTheUser?.id],
          transportMode:  dataTransport?.value?.transport?.transportMode,
        }
      } if (dataTransport?.value?.transport?.listOfFiles) {
        return {
          listOfFiles: dataTransport?.value?.transport?.listOfFiles?.map(item => item?.id),
          transportMode:  dataTransport?.value?.transport?.transportMode,
        }
      }
      return {
        addresses: dataTransport?.value?.transport?.originalAddresses?.map(item => ({
          id: item?.id,
          address: item?.address,
          lat: item?.lat,
          lng: item?.lng,
        })),
        transportMode:  dataTransport?.value?.transport?.transportMode,
      }

    default:
      return {}
  }
}

export const creditsNumber = (tilesWithDataAndNonDrawingRange: ITile[], whiteTiles?: string[]) : number => {
// to do case for cannibalization etc.
  let creditsAmount = 0
  if (tilesWithDataAndNonDrawingRange.length) {
    tilesWithDataAndNonDrawingRange.forEach(tile => {
      if (!whiteTiles?.includes(tile.id.split('-')[0])) {
        switch (tile.id.split('-')[0]) {
          case 'cannibalization':
            const thisCannibalizationTileData = tile?.data as ICannibalizationTileData
            creditsAmount += thisCannibalizationTileData?.value?.cannibalization?.length
            break
          case 'access_range':
            const thisAccessRangeTileData = tile?.data as IAccessRangeTileData
            creditsAmount += thisAccessRangeTileData?.value?.accessRange?.data?.features?.length || 0
            break
          default:
            creditsAmount += 1
        }
      }
    })
  }
  return creditsAmount
}
