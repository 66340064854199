import styled, { css } from 'styled-components'
import { TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'

export const Wrapper = styled.div(({ theme: { palette } }) => css`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  background-color: ${palette.light.white};
  gap: 2rem;
`)

export const TitleWithLegend = styled.span(({
  theme: {
    palette, typography,
  },
}) => css`
  margin: 1rem 0 0;
  display: flex;
  flex-direction: row;
  color: ${palette.black};
  background-color: ${palette.light.white};
  font-size: ${typography.main.pt_15_semibold.fontSize};
  font-weight: ${typography.main.pt_15_semibold.fontWeight};
  line-height: ${typography.main.pt_15_semibold.lineHeight};

  > img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
`)

export const NestedSelectWrapper = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  color: ${palette.black};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
  gap: 0.5rem;
`)

export const MapWrapper = styled.div`
  margin: 1rem 0;
  width: 100%;
  height: 300px;

  @media print {
    margin: 0;
  }
`

export const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(({ theme: { palette } }) => css`
  border-top: 1px solid ${palette.light.darker};
  padding-top: 2rem;
  margin-bottom: 0;
`)

export const AdditionalInfo = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  padding: 0.5rem;
  border-top: 1px solid ${palette.light.darker};
  flex-direction: row;
  flex-wrap: wrap;

  div {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
  }

  > div {
    width: 50%;
  }

  > div > span:first-child,
  > div > div > span:first-child {
    margin-bottom: 0.25rem;
    color: ${palette.dark.normal};
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
  }

  > div > div > span {
    color: ${palette.black};
    font-size: ${typography.small.pt_13_semibold.fontSize};
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
    line-height: ${typography.small.pt_13_semibold.lineHeight};
    letter-spacing: ${typography.small.pt_13_semibold.letterSpacing};
    text-transform: ${typography.small.pt_13_semibold.textTransform};
  }

  > div > span {
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    letter-spacing: ${typography.small.pt_13_regular.letterSpacing};
    text-transform: ${typography.small.pt_13_regular.textTransform};
  }
`)

export const ObjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ScrollWrapper = styled.div<{ $isPrinting: boolean }>(({ $isPrinting }) => css`
  margin: 1rem 0;
  max-height: 650px;
  overflow-y: auto;
  overscroll-behavior: contain;
  -ms-overflow-style: none;

  .ps{
    overflow: visible !important;
  }

  ${$isPrinting && css`max-height: none;`}
`)

export interface TextProps {
  $semiBold?: boolean
}

export const StyledP = styled.p<TextProps>(({
  $semiBold = false, theme: { palette },
}) => css`
  margin: 0;
  color: ${palette.black};
  font-weight: ${$semiBold ? 600 : 400};
`)

export const StyledHeader = styled(StyledP)(({ theme: { typography } }) => css`
  font-size: ${typography.big.pt_18_semibold.fontSize};
`)

export const StyledBigTitle = styled(StyledP)(({ theme: { typography } }) => css`
  font-size: ${typography.big.pt_22_semibold.fontSize};
`)

export const StyledDescription = styled.p<TextProps>(({
  theme: {
    typography, palette,
  },
}) => css`
  color: ${palette.black};
  font-size: ${typography.small.pt_13_regular.fontSize};
`)

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const TextRowContainer = styled.div<{$maxWidth?: string}>(({ $maxWidth }) => css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  ${$maxWidth && css`max-width: ${$maxWidth};`}
`)

export const StyledAdditionalDataWrapper = styled.div(({
  theme: {
    palette, corners,
  },
}) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: ${palette.light.main};
  border: 1px dashed ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
`)
