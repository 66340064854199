import { getCookieWithoutJSON, localStorageWorkspaceId, refreshWorkspaceId } from '../utils'
import { getAxios } from '../utils/axios'
import { createFlashMessage } from '../utils/createFlashMessage'

export const inviteUsers = async (email: string, workspaceId: string) : Promise<void> => {
  const body = {
    email,
    workspaceId,
    language: getCookieWithoutJSON('language') || 'pl',
  }

  const axiosInstance = await getAxios()
  await axiosInstance.post('invitation', body)
}

export const addWorkspace = async (
  newWorkspaceName: string,
  emails: string[],
  successHandler?: ()=>void,
) : Promise<void> => {
  const body = {
    name: newWorkspaceName,
    userLimit: 33,
  }
  let saveData: { value?: {id: string} ; loading?: boolean; error?: string }
  const axiosInstance = await getAxios({
    errCallbackFn: (e) => {
      saveData = {
        loading: false,
        error: e.message,
      }
    },
  })
  const response = await axiosInstance.post('workspace', body)
  if (!response) return

  saveData = {
    loading: false,
    error: '',
    value: response.data,
  }

  if (saveData?.value?.id) {
    createFlashMessage({
      message: 'status.success.workspace.created',
    })
    if (emails.length) {
      emails.forEach(email => inviteUsers(email, saveData?.value?.id as string))
    }

    if (successHandler) successHandler()

    window?.localStorage.setItem(localStorageWorkspaceId, saveData?.value?.id)
    window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
  }
}
