import { AuthContext } from '@dataplace.ai/features'
import { createFlashMessage, getWorkspaceIdFromLocalStorage } from '@dataplace.ai/functions/utils'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchTilesFromRemoteConfigAction, saveTilesFromRemoteConfigAction } from '../../slice/analysisSlice'

export const RemoteConfigProvider: React.FC = (): JSX.Element => {
  // constants
  const authContext = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const location = useLocation()

  // states
  const [token, setToken] = useState('')
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceIdFromLocalStorage())

  // hooks
  useEffect(() => {
    if (authContext.userData.userLoaded && !!authContext.userData.user) {
      authContext.userData?.user?.getIdToken()?.then(response => {
        setToken(response)
      })
    } else if (authContext.userData.userLoaded && !authContext.userData.user) {
      setToken('0') // for ensuring that token is loaded but undefined
    }
  }, [authContext?.userData])

  useEffect(() => {
    function listener() {
      setWorkspaceId(getWorkspaceIdFromLocalStorage())
    }
    window?.addEventListener('workspaceIdCookieSet', listener)

    return () => window?.removeEventListener('workspaceIdCookieSet', listener)
  }, [token])

  useEffect(() => {
    const placemeTiles = window?.localStorage?.getItem('placemeTiles')
    if (placemeTiles && token && workspaceId) {
      try {
        const placemeTilesJSON = JSON.parse(placemeTiles)
        dispatch(saveTilesFromRemoteConfigAction(placemeTilesJSON))
      } catch (e) {
        createFlashMessage({
          message: 'status.error.remoteConfig.tiles.parsing_failed',
        })
      }
    }
    if (token && workspaceId) {
      dispatch(fetchTilesFromRemoteConfigAction())
    }
  }, [token, location, workspaceId])

  return <></>
}
