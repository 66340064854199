import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { useTranslation } from 'react-i18next'
import { MainDropdown } from '@dataplace.ai/ui-components/molecules'
import { getRadarChartData, getRadarChartLabels, NoPrint, RadarChart, RangeChart, TagButton } from '@dataplace.ai/ui-components/atoms'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { getAxios } from '@dataplace.ai/functions/utils'
import { ITileData } from 'apps/placeme/src/features/Analyse/slice/@types/ITileData'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { ReactComponent as Pin } from '@dataplace.ai/assets/lib/icons/pinIcon.svg'
import RankingModal from '@placeme/components/molecules/RankingModal/RankingModal'
import { deleteTileAction, fetchWorkspaceUsageValue, saveNewRangeAction, saveTileData } from '../../../../../slice/analysisSlice'
import { DataType, IPotentialTileData, ModelParams } from './@types/IPotentialTileData'
import { TileFooter } from '../../../../atoms'
import { SettingsBox } from '../../../../molecules/SettingsBox'
import { labels } from './data'

import {
  ChartTitle,
  DropdownOptionDiv,
  DropdownWrapper,
  Estimation,
  Section,
  SectionDescription,
  TagButtonWrapper,
  Title,
  TypeRangeWrapper,
  Wrapper,
} from './PotentialTile.styles'

export const PotentialTile: React.FC<{
  data: IPotentialTileData,
  tileId: string,
  accepted: boolean,
  setAccepted: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({
  data, tileId, accepted, setAccepted,
}) => {
  // variables
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const { values } = useSelector((state: RootState) => state.analysis)
  const {
    value, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)

  // states
  const [token, setToken] = useState('')
  const [openOnSundays, setOpenOnSundays] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  // functions
  const getTileType = (id: string) => id.split('-')[0]

  const fetchTileRange = () => values.find(cat => cat.id === 'big_ben')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

  const fetchData = useCallback(async (modelParams?: ModelParams, previous?: boolean) => {
    const catchmentId = values.find(cat => cat.id === 'big_ben')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

    if (accepted) {
      let body
      if (modelParams) {
        body = {
          catchmentId,
          address: value?.address,
          openOnSundays,
          data: mapModelParamsData(modelParams),
        }
      } else if (previous) {
        body = {
          catchmentId,
          previous_data: previous,
        }
      } else {
        body = {
          catchmentId,
          openOnSundays,
          address: value?.address,
        }
      }

      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]
      let saveData

      const axiosInstance = await getAxios({
        errCallbackFn: (e) => {
          saveData = {
            loading: false,
            error: e.message,
            value: null,
          }
        },
      })
      const response = await axiosInstance.post<ITileData>(endpoint || '', body)
      if (response) {
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      }
      if (saveData?.value) { dispatch(saveTileData('big_ben', tileId, saveData)) }
    }
  }, [accepted, token, openOnSundays])

  const mapModelParamsData = (data: ModelParams): Record<DataType, number> => {
    let mappedData = {}

    for (const key in data) {
      mappedData = ({
        ...mappedData,
        [key]: data[key].number,
      })
    }
    return mappedData
  }

  const handleSave = () => {
    dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'big_ben', tileId, {
      id: `${tileId}-5-walk`,
      value: 5,
      type: 'walk',
    }))
  }

  const handleDeleteTile = () => {
    dispatch(deleteTileAction(token, 'big_ben', tileId, comparedAnalyseId || analyseId || ''))
  }

  // hooks
  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (fetchTileRange()) {
      setAccepted(true)
    }
  }, [fetchTileRange()])

  useEffect(() => {
    if (!data?.value) { fetchData() }
    else {
      setOpenOnSundays(data.value.scoringModel.openOnSundays)
    }
  }, [fetchData, data?.value])

  return (
    <>
      {!accepted
        ? (
          <Wrapper background={!accepted ? 'dark' : 'light'}>
            <Title>
              {t('placeme.big_ben_potential_tile.header')}
            </Title>
            <TypeRangeWrapper>
              <p>{t('placeme.big_ben_potential.input_info')}</p>
              <DropdownWrapper>
                <span>{t('placeme.type_range_selector.dropdown_label.open_on_sundays')}</span>
                <MainDropdown
                  content={(
                    <>
                      <DropdownOptionDiv onClick={() => setOpenOnSundays(true)}>
                        <p>{t('generic.yes')}</p>
                      </DropdownOptionDiv>
                      <DropdownOptionDiv onClick={() => setOpenOnSundays(false)}>
                        <p>{t('generic.no')}</p>
                      </DropdownOptionDiv>
                    </>
                  )}
                  header={openOnSundays ? t('generic.yes') : t('generic.no')}
                  transparent
                />
              </DropdownWrapper>
            </TypeRangeWrapper>

            <TileFooter
              isUnlimited
              label={t('generic.apply')}
              onAccept={handleSave}
              onCancel={handleDeleteTile}
              value='1'
            />
          </Wrapper>
        )
        : (!data?.value || data?.loading
          ? (<Loader />)
          : (
            <Wrapper background='light'>
              <Title>
                {t('placeme.big_ben.potential.title')}
              </Title>
              <SettingsBox
                noEdit
                sectionTile='big_ben'
                setAccepted={setAccepted}
                tile={tileId}
                typeRanges={{
                  openOnSundays,
                }}
              />

              <Estimation>
                <p>
                  {t('placeme.big_ben.potential')}
                  {' '}
                  <strong>{data?.value?.scoringModel?.score}</strong>
                </p>
                <span>{t('placeme.big_ben.potential.label')}</span>
              </Estimation>

              <Section>
                <ChartTitle>{t('placeme.sales_potential_tile.ranking.title')}</ChartTitle>
                <SectionDescription>{t('placeme.sales_potential_tile.ranking.description')}</SectionDescription>
                <RangeChart
                  label='generic.place'
                  max={data.value.scoringModel.ranking.places.lowest}
                  value={data.value.scoringModel.ranking.places.current}
                />
                <TagButtonWrapper>
                  <NoPrint>
                    <TagButton onClick={() => setIsModalOpen(true)}>
                      <Pin />
                      <p>{t('generic.see_ranking')}</p>
                    </TagButton>
                  </NoPrint>
                </TagButtonWrapper>
              </Section>
              <RankingModal
                currentPosition={data.value.scoringModel.ranking.places.current}
                isOpen={isModalOpen}
                rankingList={data.value.scoringModel.ranking.list}
                setIsOpen={setIsModalOpen}
                trimFloat
              />
              <ChartTitle>{t('placeme.sales_potential_tile.radar.title')}</ChartTitle>
              <RadarChart
                data={getRadarChartData(data?.value?.scoringModel?.partialResponse)}
                height={450}
                labels={getRadarChartLabels(data?.value?.scoringModel?.partialResponse, labels)}
              />
            </Wrapper>
          ))}
    </>
  )
}
