import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { IMapLocationProps, Loader } from 'libs/shared/ui-components/src/atoms'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { TileSectionIds } from '@dataplace.ai/constants'
import { RootState } from '../../../../../../../redux/store'
import BuildingsCharts from './BuildingsCharts'
import BuildingsMap from './BuildingsMap'
import BuildnigsDataList from './BuildnigsDataList/BuildingsDataList'
import BuildingsType from './BuildingsType/BuildingsType'
import { IBuildingsTileDataType } from './@types/IBuildingsTileData'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

export const BuildingsTile: React.FC<{data: IBuildingsTileDataType, tileId: string}> = ({
  data, tileId,
}) => {
  const dispatch = useAppDispatch()

  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)

  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const {
    buildingTypes,
    buildings,
  } = data?.value?.buildings?.basicData || {}
  const {
    buildingTypes: compareBuildingTypes,
    buildings: compareBuildings,
  } = data?.value?.comparedLocation?.buildings?.basicData || {}

  useEffect(() => {
    if (!data?.value || !mapLocation) return

    dispatch(saveTileData(TileSectionIds.SURROUNDINGS, tileId, {
      ...data,
      mapLocation,
      comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
    }))
  }, [mapLocation, comparedMapLocation])

  if (!data || data?.loading) return <Loader />

  return (
    <Wrapper>
      <BuildnigsDataList data={data || {}} />
      <BuildingsCharts
        density={buildings.density}
        distribution={buildingTypes.distribution}
        distributionArea={buildingTypes.distributionArea}
        showComparedAddress={!!data?.value.comparedLocation}
      />
      {compareBuildings && compareBuildingTypes
        ? (
          <BuildingsCharts
            compared
            density={compareBuildings?.density}
            distribution={compareBuildingTypes?.distribution}
            distributionArea={compareBuildingTypes?.distributionArea}
            showComparedAddress
          />
        )
        : null}

      <BuildingsType
        additionalData={data?.value || {}}
        tileId={tileId}
      />

      <BuildingsMap
        data={data?.value?.buildings}
        setMapLocation={setMapLocation}
        showComparedAddress={!!data?.value?.comparedLocation}
        tileId={tileId}
      />
      {data?.value?.comparedLocation
        ? (
          <BuildingsMap
            compared
            comparedData={data?.value?.comparedLocation?.buildings}
            data={data?.value?.buildings}
            setMapLocation={setComparedMapLocation}
            showComparedAddress
            tileId={tileId}
          />
        )
        : null}
    </Wrapper>
  )
}
