import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as HelpCircle } from '../../../../assets/src/lib/icons/dataplace/help-circle.svg'
import { ReactComponent as ArrowSpending } from '../../../../assets/src/lib/icons/placeme/arrowSpending.svg'

interface IWrapperProps {
  state?: 'positive' | 'negative'
  blackArrow?: boolean
}

const Wrapper = styled.span<IWrapperProps>(({
  theme, state, blackArrow,
}) => {
  const { palette } = theme
  return css`
    display: flex;
    padding: 0.25rem 0.5rem;
    border-radius: 2.5rem;
    align-items: center;
    margin-bottom: 0.125rem;
    justify-content: center;
    min-width: 2.5rem;
    width: min-content;

    .blackArrow {
      margin-right: 5px;
      transform: ${state === 'negative' && 'rotate(180deg)'};
      path{
        fill: ${palette.black};
      }
    }
    
    > svg {
      margin-left: 0.25rem;
      min-width: 1rem;
    }

    ${state === 'positive'
    && css`
      > svg > path {
        fill: ${palette.results.green.light};
      }
      color: ${blackArrow ? palette.black : palette.results.green.dark};
      background-color: ${blackArrow ? 'white' : palette.results.green.opaque};
    `}
    ${state === 'negative'
    && css`
      > svg > path {
        fill: ${palette.results.red.light};
      }
      color: ${blackArrow ? palette.black : palette.results.red.dark};
      background-color: ${blackArrow ? 'white' : palette.results.red.opaque};
    `}
  `
})

export interface ComparisonProps {
  value: string
  state?: 'positive' | 'negative'
  withIcon?: boolean
  blackArrow?: boolean
}

export const Comparison = ({
  value,
  state,
  withIcon = true,
  blackArrow,
}: ComparisonProps): JSX.Element => (
  <Wrapper
    blackArrow={blackArrow}
    state={state}
  >
    {blackArrow && <ArrowSpending className='blackArrow' />}
    {state ? (state === 'positive' ? '+' : '-') : null}
    {value}
    {!blackArrow && withIcon && <HelpCircle />}
  </Wrapper>
)
