import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { ITile } from 'apps/placeme/src/features/Analyse/slice/@types/ITile'
import { ICompetitionWithinReachTileData } from './@types/ICompetitionWithinReach'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetLayers = (data: ICompetitionWithinReachTileData, tile: ITile | undefined) => {
  const rangeCoords = tile?.chosenRange?.geoJSON?.coordinates
  const features : IFeatureCollection['data']['features'] = []
  data?.value.competition.competitors?.forEach((point) => features.push({
    geometry: {
      coordinates: [point.lng, point.lat],
      type: 'Point',
    },
    properties: {
      title: `<div style="display: flex; flex-direction: column;"><span>${point?.brand}</span><span>${point?.address}</span></div>`,
      pinnedItem: {
        iconAnchor:[11, 11],
        class: 'poi-img',
        html: `<img alt='' src='${point.logo || 'assets/icons/logoPlaceholder.svg'}' style="width:22px; height: 22px;"/>`,
      },
    },
    type: 'Feature',
  }))

  const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
    {
      id: 'pois-layer',
      layer: {
        data: {
          features,
          type: 'FeatureCollection',
        },
        options: {
          type: 'geojson',
          id: 'pois',
        },
      },
    },
  ]
  if (rangeCoords) {
    layers.push({
      id: tile?.id || '',
      layer: {
        data: {
          coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
          type: 'Polygon',
          properties: {},
        },
        options:{
          type: 'geojson',
          id: 'pois_range',
          style: {
            color: '#0000a2',
            fillColor:'#0000a2',
            weight: 1.5,
            fillOpacity: 0.05,
          },
        },
      },
    })
  }
  return layers
}
