import React, { useContext, useEffect, useState } from 'react'
import { BarChart, TileFormSection, TileFormSectionTitle, TileFormWrapper } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { IVisitingHoursTileData } from './@types/IVisitingHoursTileData'
import { fetchWorkspaceUsageValue } from '../../../../../slice/analysisSlice'
import { useAppDispatch } from '../../../../../../../redux/hooks'
import { ComparedLocationHeader } from '../../../../atoms'

const HoursWrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};
    margin-bottom: 1rem;

    >div{
      display: flex;
      flex-direction: row;
    }
  `
})

const Hours = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    color: ${palette.black};
    width: 40%;
    margin-right: 10%;

    > span:nth-child(1) {
      margin-bottom: 1rem;
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }

    > span:nth-child(2) {
      font-size: ${typography.big.pt_24_semibold.fontSize};
      font-weight: ${typography.big.pt_24_semibold.fontWeight};
      line-height: ${typography.big.pt_24_semibold.lineHeight};
    }
  `
})

export const VisitingHoursTile: React.FC<{data: IVisitingHoursTileData}> = ({ data }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <TileFormWrapper>
        <TileFormSection>
          <HoursWrapper>
            {data?.value?.comparedLocation
          && (
            <ComparedLocationHeader
              style={{
                padding: '1rem 0 1.5rem',
              }}
            >
              <span
                className='colored-label'
                style={{
                  backgroundColor: '#362FB7',
                }}
              />
              <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
              {' '}
              <span>{value?.address}</span>
            </ComparedLocationHeader>
          )}
            <div>
              <Hours>
                <span>{t('placeme.visiting_hours_tile.hours_1')}</span>
                <span>{`${data?.value?.visitingHours?.busyMorningHours?.start}:00 - ${data?.value?.visitingHours?.busyMorningHours?.end}:00`}</span>
              </Hours>
              <Hours>
                <span>{t('placeme.visiting_hours_tile.hours_2')}</span>
                <span>{`${data?.value?.visitingHours?.busyNightHours?.start}:00 - ${data?.value?.visitingHours?.busyNightHours?.end}:00`}</span>
              </Hours>
            </div>
          </HoursWrapper>
          {data?.value?.comparedLocation
           && (
             <HoursWrapper>
               <ComparedLocationHeader
                 style={{
                   padding: '1rem 0 1.5rem',
                 }}
               >
                 <span
                   className='colored-label'
                   style={{
                     backgroundColor: '#D8A800',
                   }}
                 />
                 <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                 {' '}
                 <span>{comparedLocation?.location?.address}</span>
               </ComparedLocationHeader>
               <div>
                 <Hours>
                   <span>{t('placeme.visiting_hours_tile.hours_1')}</span>
                   <span>{`${data?.value?.comparedLocation?.visitingHours?.busyMorningHours?.start}:00 - ${data?.value?.comparedLocation?.visitingHours?.busyMorningHours?.end}:00`}</span>
                 </Hours>
                 <Hours>
                   <span>{t('placeme.visiting_hours_tile.hours_2')}</span>
                   <span>{`${data?.value?.comparedLocation?.visitingHours?.busyNightHours?.start}:00 - ${data?.value?.comparedLocation?.visitingHours?.busyNightHours?.end}:00`}</span>
                 </Hours>
               </div>
             </HoursWrapper>
           )}
          <TileFormSectionTitle>{t('placeme.visiting_hours_tile.section_title_1')}</TileFormSectionTitle>
          {/* <BarChartScrollContainer> */}
          <BarChart
            axisLabels={{
              xAxis: t('placeme.visiting_hours.x_axis'),
              yAxis: t('placeme.visiting_hours.y_axis'),
            }}
            beginsAtZero
            dataset={[
              {
                label: 'procent osób odwiedzających',
                data: (data?.value?.visitingHours?.visitingHours || []).map(vh => Math.round(vh?.percent * 100)),
              },
              {
                label: 'procent osób odwiedzających(porównywana loklaizacja)',
                data: (data?.value?.comparedLocation?.visitingHours?.visitingHours || []).map(vh =>
                  Math.round(vh?.percent * 100)),
              },
            ]}
            labels={(data?.value?.visitingHours?.visitingHours || []).map(vh =>
              (vh?.hour === 0 ? '24:00' : `${vh?.hour.toString()}:00`))}
            valueType='percentages'
            visitingHours
          />
          {/* </BarChartScrollContainer> */}
        </TileFormSection>
      </TileFormWrapper>
    )
}

export default VisitingHoursTile
