import { useTranslation } from 'react-i18next'
import { IAnwPotentialModelTileData } from '../../../@types/IAnwPotentialModelTileData'
import { useGetAnwDropdownOptions } from '../formData/useGetAnwDropdownOptions'

interface OptionTypes {
  value: string | boolean | number;
  label: string;
}

const findOption = (options: OptionTypes[], settingType: string | boolean | number | null) => options
  .find(option => option.value === settingType)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSelectedSettings = (data: IAnwPotentialModelTileData) => {
  const {
    salesAreaOptions, plannedStationTypes, cafeMoyaOptions, sizeOptions, options,
  } = useGetAnwDropdownOptions()
  const { t } = useTranslation()
  if (!data) return []

  const { settings } = data.value

  const settingsList = [
    {
      label: t('placeme.type_date.distributors_number'),
      value: settings.distributorsNumber.toString(),
      visible: true,
    },
    {
      label: t('placeme.type_date.station_type'),
      value: findOption(plannedStationTypes, settings.stationType)?.label,
      visible: true,
    },
    {
      label: t('placeme.type_date.type_store_area'),
      value: findOption(salesAreaOptions, settings.salesArea)?.label,
      visible: true,
    },
    {
      label: t('placeme.type_date.available_cafe_stock'),
      value: findOption(cafeMoyaOptions, settings.availableCafeStock)?.label,
      visible: true,
    },
    {
      label: t('placeme.type_date.carwash'),
      value: findOption(options, settings.carwash)?.label,
      visible: true,
    },
    {
      label: t('placeme.type_date.restaurant'),
      value: findOption(options, settings.restaurant)?.label,
      visible: true,
    },
    {
      label: t('placeme.type_date.open24h'),
      value: findOption(options, settings.open24H)?.label,
      visible: true,
    },
    {
      label: t('placeme.anw_potential_model_tile.criteria.if_shopping_mall'),
      value: findOption(options, settings.plannedAdditions.shoppingMall)?.label,
      visible: findOption(options, settings.plannedAdditions?.shoppingMall)?.value,
    },
    {
      label: t('placeme.type_date.planned_additions.expressway'),
      value: findOption(options, settings.plannedAdditions?.expressway)?.label,
      visible: findOption(options, settings.plannedAdditions?.expressway)?.value,
    },
    {
      label: t('placeme.type_date.planned_additions.competitive_station'),
      value: findOption(options, settings.plannedAdditions?.competitiveStation)?.label,
      visible: findOption(options, settings.plannedAdditions?.competitiveStation)?.value,
    },
    {
      label: t('placeme.type_date.planned_additions.restaurant'),
      value: findOption(options, settings.plannedAdditions?.restaurant)?.label,
      visible: findOption(options, settings.plannedAdditions?.restaurant)?.value,
    },
    {
      label: t('placeme.type_date.planned_additions.truck_parking'),
      value: findOption(options, settings.plannedAdditions?.truckParking)?.label,
      visible: findOption(options, settings.plannedAdditions?.truckParking)?.value,
    },
  ]

  if (settings.plannedAdditions.shoppingMall) {
    settingsList.push(
      {
        label: t('placeme.anw_potential_model_tile.criteria.planned_shopping_mall.size'),
        value: sizeOptions.find(option => option.value === settings.plannedAdditions?.shoppingMallSize)!.label,
        visible: true,
      },
    )
  }
  return settingsList
}
