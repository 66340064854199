import styled, { css } from 'styled-components'
import { TagButtonProps } from './@types/TagButtonProps.props'

export const TagButton = styled.button<TagButtonProps>(({
  theme, backgroundColor,
}) => {
  const {
    palette, typography,
  } = theme

  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    padding: 0.3125rem 0.625rem;
    border-radius: 6px;
    background-color: ${backgroundColor || palette.light.darker};
    cursor: pointer;

    p{  
        color: ${palette.blue};
        font-size: ${typography.tiny.pt_12_medium.fontSize};
        font-weight: ${typography.tiny.pt_12_medium.fontWeight};
        line-height: ${typography.tiny.pt_12_medium.lineHeight};
        margin-left: 6px;
    }
`
})
