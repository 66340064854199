import styled, { css } from 'styled-components'

export const Wrapper = styled.div(({ theme: { palette } }) => css`
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  height: 8rem;
  width: 100%;
  border-bottom: 1px solid ${palette.light.darker};
  padding: 1rem 2.5rem;
  box-sizing: border-box;
  background-color: white;
  z-index: 500;
  transition: all 1s;

  @media (max-width: 1200px) {
    padding: 1rem 1.5rem;
  }

  @media (max-width: 960px) {
    align-items: flex-start;
    overflow: hidden;
  }

  > div:first-child {
    @media (max-width: 960px) {
      margin-top: 0.25rem;
    }
  }

  > div:last-child {
    @media (max-width: 960px) {
      margin-top: 1.25rem;
    }
  }
`)

export const LocationWrapper = styled.span(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  flex-direction: column;
  margin: 1.25rem;
  flex-grow: 1;

  @media (max-width: 960px) {
    align-items: start;
    margin: 0 1.25rem;
    overflow-y: scroll;
    height: 100%;
  }

  > span {
    color: ${palette.dark.normal};
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
  }

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
  }

  > div:nth-of-type(1) > span {
    width: fit-content;
    max-width: 80%;
    margin-right: 0.25rem;
    color: ${palette.black};
    font-size: ${typography.big.pt_22_semibold.fontSize};
    font-weight: ${typography.big.pt_22_semibold.fontWeight};
    line-height: ${typography.big.pt_22_semibold.lineHeight};

    @media (max-width: 1200px) {
      font-size: ${typography.big.pt_18_semibold.fontSize};
      font-weight: ${typography.big.pt_18_semibold.fontWeight};
      line-height: ${typography.big.pt_18_semibold.lineHeight};
    }

    @media (max-width: 960px) {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      max-width: 100%;
    }
  }
`)

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  > button {
    margin: 0 0.25rem;
  }
`

export const CreditsCounterWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 2.8vw;
  z-index: 600;
  transition: all 1s;
`

export const DownloadButton = styled.button(({
  theme: {
    palette, corners, typography,
  },
}) => css`
  border: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: ${corners.default.borderRadius};
  outline: none;
  background-color: transparent;
  color: ${palette.blue};
  cursor: pointer;
  font-size: ${typography.small.pt_13_medium.fontSize};
  font-weight: ${typography.small.pt_13_medium.fontWeight};
  line-height: ${typography.small.pt_13_medium.lineHeight};

  :hover {
    background-color: ${palette.light.darker};
  }

  :disabled {
    opacity: 0.5;
    cursor: default;
  }

  > span {
    margin-left: 0.25rem;
  }
`)

export const AddAnotherLocation = styled.div<{disabled?: boolean}>(({
  theme: {
    palette, typography,
  },
  disabled,
}) => css`
  display: flex;
  align-items: center;
  width: fit-content;

  :hover {
    cursor: pointer;
  }

  > span {
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

  > svg {
      margin-right: 4px;
  } 

  .trash-icon {
    cursor: pointer;
    margin-left: 5px;
    width: 15px;
    height: 15px;
    path {
      stroke: #F08F7F;
    }
  }

  ${disabled && css`
      :hover {
        cursor: default;
        > svg {
        background-color: transparent;
        cursor: default;
        path {
            stroke: ${palette.light.darkest};
          }
        }
      }

      > span {
        color: ${palette.dark.lightest};
      }

      > svg{
        path {
            stroke: ${palette.light.darkest};
          }
      }
  `}
`)

export const StyledDropdownButton = styled.span`
  display: flex;
  align-items: center;
`

export const StyledBlueSpan = styled.span(({ theme: { palette } }) => css`
  color: ${palette.blue} !important;
`)

export const DropdownContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`

export const StyledDropdownHeader = styled.div<{ $disabled?: boolean }>(({ $disabled = false }) => css`
  display: flex;
  gap: 0.5rem;
  align-items: center;


  ${$disabled && css`
    opacity: 0.5;
    cursor: default;
  `}
`)
