import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useChainImpactTexts = () => {
  const { t } = useTranslation()
  const chainImpact = {
    section: {
      title: t('placeme.mcd.store_chain_impact.section_title_1'),
      paragraph: t('placeme.mcd.store_chain_impact.label.transactions'),
    },
    table: {
      tooltipPositive: t('placeme.mcd.store_chain_impact.tooltip.part_1.positive'),
      tooltipNegative: t('placeme.mcd.store_chain_impact.tooltip.part_1.negative'),
      tooltipPart2: t('placeme.mcd.store_chain_impact.tooltip.part_2'),
      labels: {
        column1: t('placeme.mcd.store_chain_impact.table.headers.name'),
        column2: t('placeme.mcd.store_chain_impact.table.headers.site_id'),
        column3: t('placeme.mcd.store_chain_impact.table.headers.current'),
        column4: t('placeme.mcd.store_chain_impact.table.headers.predicted'),
        column5: t('placeme.mcd.store_chain_impact.table.headers.distance'),
      },
    },
  }

  return {
    chainImpact,
  }
}
