/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react'

import { IChildrenProps } from '@dataplace.ai/types'
import {
  getFirstWorkspaceId,
  getValidWorkspace,
} from '@dataplace.ai/functions/utils/workspaceManageFuncs'
import { getCookieWithoutJSON, getWorkspaceIdFromLocalStorage, navbarNotificationsEventName, refreshWorkspaceId, setCookieWithoutJSON } from '@dataplace.ai/functions/utils'
import { LoadingPage } from '@dataplace.ai/ui-components/pages'
import { AuthContext } from './AuthContext'

interface IAppWrapper extends IChildrenProps{
  product?: 'auth' | 'placeme' | 'target'
}
export const AppWrapper = ({
  children, product,
}: IAppWrapper): JSX.Element => {
  const authContext = useContext(AuthContext)
  const [token, setToken] = useState('')
  const [tempCurrentWorkspaceId, setTempCurrentWorkspaceId] = useState('')
  const [readyToLoadChildren, setReadyToLoadChildren] = useState(false)
  const [readyToLoadCookie, setReadyToLoadCookie] = useState(false)
  const workspaceId = getWorkspaceIdFromLocalStorage()
  // TOKEN - get.length > 1 user token
  useEffect(() => {
    if (authContext.userData.userLoaded && !!authContext.userData.user) {
      authContext.userData?.user?.getIdToken()?.then(response => {
        setToken(response)
      })
    } else if (authContext.userData.userLoaded && !authContext.userData.user) {
      setToken('0') // for ensuring that token is loaded but undefined
    }
  }, [authContext.userData])

  // FLASH MESSAGES & WORKSPACE ID
  useEffect(() => {
    // remove flash messages on first load
    sessionStorage.flashMessages = JSON.stringify([])
    const flashMessageEvent = new CustomEvent('flashMessagesUpdate')
    window.dispatchEvent(flashMessageEvent)
    // set workspaceId from cookie to localStorage and dispatch refreshWorkspaceId event on first load
    window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
    setReadyToLoadCookie(true)
  }, [])

  useEffect(() => {
    if (readyToLoadCookie) {
      setTempCurrentWorkspaceId(getWorkspaceIdFromLocalStorage() ?? '')
      window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
    }
  }, [readyToLoadCookie])

  // checks workspaceId - if there is none -> takes first from users workspaces
  useEffect(() => {
    if (token.length > 1 && authContext.userData?.user?.emailVerified) {
      if (!tempCurrentWorkspaceId || !tempCurrentWorkspaceId?.length) {
        getFirstWorkspaceId()
          .then(_ => setTempCurrentWorkspaceId(''))
          .then(_ => setReadyToLoadChildren(true))
      } else {
        // set default workspace if doesn't exist in local storage or current one is invalid
        getValidWorkspace(tempCurrentWorkspaceId)
          .then(_ => setTempCurrentWorkspaceId(''))
          .then(_ => setReadyToLoadChildren(true))
      }
    } else if (
      (token.length > 1 && !authContext.userData?.user?.emailVerified)
      || (token === '0' && authContext.userData.userLoaded)) {
      window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
      setTempCurrentWorkspaceId('')
      setReadyToLoadChildren(true)
    }
  }, [token, authContext.userData])

  // REMOVES error modals from local storage
  useEffect(() => {
    if (token) {
      window?.localStorage?.removeItem('transportTypeError')
      window?.dispatchEvent(new CustomEvent('transportTypeError'))
      window?.localStorage?.removeItem('timeoutModal')
      window?.dispatchEvent(new CustomEvent('timeoutModal'))
      window?.localStorage?.removeItem('serverErrorModal')
      window?.dispatchEvent(new CustomEvent('serverErrorModal'))
      if (!window?.location?.href?.includes('auth') && workspaceId) {
        sessionStorage.navbarNotifications = JSON.stringify([])
        window.dispatchEvent(new CustomEvent(navbarNotificationsEventName))
      }
    }
  }, [token, workspaceId])

  // SET LANGUAGE based on browser language
  useEffect(() => {
    if (getCookieWithoutJSON('language')) {
      return
    }
    const { language } = window.navigator
    if (language && !language.includes('pl')) {
      setCookieWithoutJSON('language', 'en')
    } else {
      setCookieWithoutJSON('language', 'pl')
    }
  }, [])

  return (
    <>
      {readyToLoadChildren ? children : <LoadingPage product={product || 'placeme'} />}
    </>
  )
}

export default AppWrapper
