/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMapLocationProps, LinkWithIconCheckOnMap, MapTile, NoPrint } from '@dataplace.ai/ui-components/atoms'
import { AddMapView, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { IChooseLocationSlice } from 'apps/placeme/src/features/ChooseLocationReport/@types/IChooseLocationSlice'
import { ILocation } from '@dataplace.ai/types'
import { MapWrapper, StyledTitleFormSectionSubTitle } from '../Investments.styles'

interface MapVisualisationProps {
  addMapTitle?: string
  addMapDescription?: string
  mapLayers?: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[]
  locationData?: IChooseLocationSlice['value'] | ILocation
  setMapLocation: Dispatch<SetStateAction<IMapLocationProps>>
  catchmentId?: string
  compared?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function MapVisualisation({
  addMapTitle, addMapDescription, mapLayers, locationData, setMapLocation, catchmentId, compared = false,
}: MapVisualisationProps) {
  const { t } = useTranslation()
  const [mapVisible, setMapVisible] = useState(false)
  const [isMapModalDisplayed, setIsMapModalDisplayed] = useState(false)
  const { isPrinting } = useCheckIfPrinting()

  useEffect(() => {
    if (isPrinting) setMapVisible(true)
  }, [isPrinting])

  return (
    <>
      {mapVisible
        ? (
          <>
            <NoPrint>
              <StyledTitleFormSectionSubTitle>
                <span>{t('placeme.investments_tile.points_on_map')}</span>
                <LinkWithIconCheckOnMap onClick={() => setIsMapModalDisplayed(!isMapModalDisplayed)} />
              </StyledTitleFormSectionSubTitle>
            </NoPrint>
            <MapWrapper>
              <MapTile
                height='100%'
                layers={mapLayers}
                location={locationData}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={locationData?.address}
                setMapLocation={setMapLocation}
                showScaleControl
                width='100%'
                zoomControl
              />
            </MapWrapper>
          </>
        )
        : (
          <AddMapView
            buttonValue={0}
            description={addMapDescription ?? 'placeme.add_investments_map.description'}
            onChange={() => setMapVisible(true)}
            title={addMapTitle ?? 'placeme.add_investments_map.title'}
          />
        )}
      {isMapModalDisplayed
        ? (
          <MapVisualizationModal
            isDisplayed={isMapModalDisplayed}
            layers={mapLayers}
            location={locationData}
            mapId={`investment-map-${catchmentId}${compared ? '_compared' : ''}`}
            setIsDisplay={setIsMapModalDisplayed}
          />
        )
        : null}
    </>
  )
}

export default MapVisualisation
