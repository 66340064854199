import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormSection, Select, TextInput } from '@dataplace.ai/ui-components/molecules'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Button, CloseIcon, TextButton } from '@dataplace.ai/ui-components/atoms'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { IAnalysesData } from 'apps/placeme/src/features/AnalyseAndReports/components/organisms/AnalyseAndReportBody/@types/IAnalysesData'
import styled from 'styled-components'
import { ReactComponent as FolderIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/profile/folder.svg'
import { BottomWrapper, Description } from './DownloadModal.styles'
import { IDownloadAnalyseInitialValues, initialValues } from './utils/initialValues'
import { saveAnalyse, saveSavingState } from '../../../slice/analysisSlice'
import { projectTypes } from './utils/constants'
import { fetchProjectId, fetchExistingReports, folderNames } from './utils/functions'

const StyledCloseIcon = styled(CloseIcon)`
  transform: rotate(45deg);
  cursor: pointer;
  margin-right: 5px;
`

const StyledParagraph = styled.p`
  display: flex;
  align-items: center;
`

const BottomSelect = styled.div`
  margin-top: 8px;
`

interface DownloadAnalyseFormProps {
  analyseId: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  existingProjects: ({ folderId: string; folderName: string; } | null)[]
  downloadPDF: () => Promise<void>
  pdfStateLoading: boolean
}

const DownloadAnalyseForm: React.FC<DownloadAnalyseFormProps> = ({
  analyseId,
  setOpen,
  existingProjects,
  downloadPDF,
  pdfStateLoading,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isNewProject, setIsNewProject] = useState(false)
  const {
    canBeSave, isEdit, savingState,
  } = useSelector((state: RootState) => state.analysis)
  const { data } = useSelector((state: RootState) => state.analysesAndReports)
  const projectsData: IAnalysesData = data?.value as IAnalysesData

  const isNewProjectToggle = useCallback(() => setIsNewProject((prev) => !prev), [])

  const handleSubmittedData = (e: IDownloadAnalyseInitialValues) => {
    downloadPDF()
    dispatch(saveSavingState({
      loading: true,
      error: null,
      value: null,
    }))

    const status = e.projectType.toLowerCase().includes('private') ? 'private' : 'view'
    let projectId
    let projectName

    if (!e.newProjectName.length) {
      projectId = fetchProjectId({
        projectName: e.projectName,
        existingProjects,
      })
    } else {
      projectName = e.newProjectName
    }

    dispatch(saveAnalyse(
      '',
      e.reportName,
      status,
      projectName,
      projectId,
      analyseId,
      fetchExistingReports({
        folderId: projectId,
        data,
        projectsData,
      }),
    ))
  }

  const validationSchema = useMemo(() => Yup.object().shape({
    newProjectName: ((folderNames(existingProjects).length === 0 || isNewProject) && canBeSave && !isEdit)
      ? Yup.string().max(50)
        .notOneOf(folderNames(existingProjects), t('placeme.create_folder.name_is_taken'))
        .required(t('generic.field_is_required'))
      : Yup.string().max(50)
        .notOneOf(folderNames(existingProjects), t('placeme.create_folder.name_is_taken')),
    projectName: (folderNames(existingProjects).length > 0 && !isNewProject && canBeSave && !isEdit)
      ? Yup.string().max(50)
        .required(t('generic.field_is_required'))
      : Yup.string().max(50),
    reportLanguage: Yup.string().required(t('generic.field_is_required')),
    reportName: Yup.string()
      .required(t('generic.field_is_required'))
      .max(100),
    tiles: Yup.array().when('data', {
      is: 'choosen',
      then: Yup.array().min(1),
    }),
  }), [isNewProject, canBeSave, isEdit, existingProjects])

  const noExistingProjects = useMemo(() => folderNames(existingProjects).length === 0, [existingProjects])
  const projectsExist = useMemo(() =>
    folderNames(existingProjects).length > 0 && !isNewProject, [existingProjects, isNewProject])

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={!canBeSave || isEdit}
      onSubmit={handleSubmittedData}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleSubmit,
        handleBlur,
        values,
        setFieldValue,
        touched,
        isValid,
      }) => (
        <Form
          centered
          onSubmit={handleSubmit}
        >
          <FormSection>
            <TextInput
              error={touched.reportName && errors.reportName ? errors.reportName : undefined}
              label={t('placeme.download_analyse_modal.label')}
              margin='0 0 1rem'
              name='reportName'
              onBlur={handleBlur}
              onChange={setFieldValue}
              placeholder={t('placeme.download_analyse_modal.type_report_name')}
              reset
              value={values.reportName}
            />
            <>
              <Select
                label={t('generic.save_analyse')}
                name='projectType'
                onChange={setFieldValue}
                options={projectTypes}
                placeholder={t('placeme.save_analyse.choose_project_type')}
                selected={t(values.projectType)}
              />
              {noExistingProjects
                ? (
                  <>
                    <Description>{t('placeme.save_analyse.create_first_project')}</Description>
                    <TextInput
                      error={
                        touched.newProjectName && errors.newProjectName
                          ? errors.newProjectName
                          : undefined
                      }
                      icon={<FolderIcon />}
                      label={t('placeme.save_analyse.project_name')}
                      margin='10px 0 0'
                      name='newProjectName'
                      onBlur={handleBlur}
                      onChange={setFieldValue}
                      placeholder={t('placeme.save_analyse.type_project_name')}
                      reset
                      value={values.newProjectName}
                    />
                  </>
                )
                : null}
              {projectsExist
                ? (
                  <>
                    <Description>{t('placeme.save_analyse.choose_project')}</Description>
                    <Select
                      error={
                        touched.projectName && errors.projectName
                          ? errors.projectName
                          : undefined
                      }
                      icon={<FolderIcon />}
                      label={t('placeme.save_analyse.project_name')}
                      maxHeight='155px'
                      name='projectName'
                      onChange={setFieldValue}
                      options={folderNames(existingProjects)}
                      placeholder={t('placeme.save_analyse.choose_project')}
                      selected={values.projectName}
                      withScroll
                    />
                    <BottomSelect>
                      <TextButton onClick={isNewProjectToggle}>
                        <StyledParagraph>
                          <StyledCloseIcon />
                          {t('placeme.save_analyse.add_new_project')}
                        </StyledParagraph>
                      </TextButton>
                    </BottomSelect>
                  </>
                )
                : null}
              {isNewProject
                ? (
                  <>
                    <Description>{t('placeme.save_analyse.add_new_project')}</Description>
                    <TextInput
                      error={
                        touched.newProjectName && errors.newProjectName
                          ? errors.newProjectName
                          : undefined
                      }
                      icon={<FolderIcon />}
                      label={t('placeme.save_analyse.project_name')}
                      margin='10px 0'
                      name='newProjectName'
                      onBlur={handleBlur}
                      onChange={setFieldValue}
                      placeholder={t('placeme.save_analyse.type_project_name')}
                      reset
                      value={values.newProjectName}
                    />
                    <TextButton onClick={isNewProjectToggle}><p>{t('placeme.save_analyse.choose_project')}</p></TextButton>
                  </>
                )
                : null}
            </>
            <BottomWrapper>
              <TextButton
                onClick={() => setOpen(false)}
                type='button'
              >
                <p>{t('generic.cancel')}</p>
              </TextButton>
              <Button
                disabled={isNewProject && !isValid}
                loading={pdfStateLoading || savingState.loading}
                loadingText={`${t('placeme.download_analyse_modal.button.loading')}...`}
                margin='0 0 0 20px'
                type='submit'
              >
                {t('placeme.download_analyse_modal.button')}
              </Button>
            </BottomWrapper>
          </FormSection>
        </Form>
      )}
    </Formik>
  )
}

export default DownloadAnalyseForm
