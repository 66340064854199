import { ComparedLocationBean } from '@dataplace.ai/ui-components/molecules'
import { RootState } from 'apps/placeme/src/redux/store'
import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

const Title = styled.h2(({ theme: { palette } }) => css`
  font-size: 1.2rem;
  font-weight: 400;
  margin: 8px 0;
  color: ${palette.black};
  display: flex;
  align-items: center;
`)

const Value = styled.strong(() => css`
  margin-left: 10px;
  display: flex;
  align-items: center;
`)

const Parameter = styled.div(() => css`
  margin: 1rem 0;
`)

const Description = styled.p(({ theme: { palette } }) => css`
  font-size: 0.9rem;
  color: ${palette.black};
`)

interface ListElementProps {
  title: string
  elemValue: number | string | undefined
  description: string
  compareValue?: number | string
  difference?: number | string
}

const ListElement: React.FC<ListElementProps> = ({
  title,
  elemValue,
  description,
  compareValue,
  difference,
}) => {
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)

  return (
    <Parameter>
      <Title>
        {title}
        <Value>
          {elemValue}
          {compareValue
            ? (
              <ComparedLocationBean
                comparedAddress={comparedLocation?.location?.address}
                comparedValue={compareValue}
                difference={difference}
                mainAddress={value?.address}
                mainValue={elemValue}
                margin='0 0 0 8px'
                width='72px'
              />
            )
            : null}
        </Value>
      </Title>
      <Description>{description}</Description>
    </Parameter>
  )
}

export default ListElement
