import { lazy } from 'react'
import { HasToBeAuthenticated } from '@dataplace.ai/features/guards'
import { PATHS } from './constants/paths'

// Placeme components
const ChooseMyLocation = lazy(
  () => import('./features/ChooseLocationReport/ChooseLocation'),
)

const AnalysePage = lazy(
  () => import('./features/Analyse/components/pages/AnalysePage'),
)

const AnalyseAndReportPage = lazy(
  () => import('./features/AnalyseAndReports/components/pages/AnalyseAndReportPage'),
)

const DataUpdateInfoPage = lazy(
  () => import('./features/Analyse/components/pages/DataSetUpdateInfoPage'),
)

// Auth routes
const placemeRoutes = {
  id: 'PlacemeRoot',
  rootPath: '/',
  redirect: PATHS.CHOOSE_LOCATION,
  guard: HasToBeAuthenticated,
  children: [
    {
      childPath: PATHS.CHOOSE_LOCATION,
      name: 'Choose Location',
      component: ChooseMyLocation,
    },
    {
      childPath: PATHS.ANALYSE,
      name: 'Analyse main',
      redirect: PATHS.ANALYSE_REPORTS,
    },
    {
      childPath: `${PATHS.ANALYSE}/:reportId`,
      name: 'Analyse',
      component: AnalysePage,
    },
    {
      childPath: PATHS.ANALYSE_REPORTS,
      name: 'Analyse and reports',
      component: AnalyseAndReportPage,
    },
    {
      childPath: PATHS.DATA_UPDATE_INFO,
      name: 'Data update info',
      component: DataUpdateInfoPage,
    },
  ],
}

export const routes = [placemeRoutes]
