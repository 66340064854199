import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { InvestmentDeviationTypes } from '../utils/constants'

interface GetDeviationTranslationProps {
  deviation: number
  deviationType: InvestmentDeviationTypes
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useGetDeviationTranslations() {
  const { t } = useTranslation()

  const higherOrLower = useCallback((deviation: number) => (deviation > 0 ? t('generic.fem.upper') : t('generic.fem.lower')), [])

  const getDeviationTranslation = useCallback(({
    deviation,
    deviationType,
  }: GetDeviationTranslationProps) => {
    const deviationTypeTranslation = t(`placeme.investments.${deviationType}.bean_tooltip`)
    return `${deviationTypeTranslation} ${Math.abs(deviation)}% ${higherOrLower(deviation)} ${t('placeme.comparison.bean_tooltip_suffix')}`
  }, [higherOrLower])

  return {
    getDeviationTranslation,
  }
}

export default useGetDeviationTranslations
