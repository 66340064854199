import { useState, useCallback } from 'react'
import { downloadReportPDF } from '@dataplace.ai/functions/utils'
import { captureException } from '@sentry/react'

export type DownloadStatus = 'success' | 'error' | null;

interface UseDownloadPDFProps {
  reportId: string
  address: string
  comparedReportId?: string
}

interface PdfState {
  loading: boolean;
  status: DownloadStatus;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDownloadPDF = ({
  reportId,
  address,
  comparedReportId,
}: UseDownloadPDFProps) => {
  const [pdfState, setPdfState] = useState<PdfState>({
    loading: false,
    status: null,
  })

  const downloadPDF = useCallback(async () => {
    setPdfState({
      loading: true,
      status: null,
    })
    try {
      await downloadReportPDF({
        reportId,
        address,
        comparedReportId,
      })
      setPdfState({
        loading: false,
        status: 'success',
      })
    } catch (error) {
      captureException(error)
      setPdfState({
        loading: false,
        status: 'error',
      })
    }
  }, [reportId, comparedReportId])

  return {
    pdfState,
    setPdfState,
    downloadPDF,
  }
}
