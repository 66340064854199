export type PotentialLabels = {
  key: string,
  value: string
}[];

export const labels: PotentialLabels = [
  {
    key: 'schools',
    value: 'placeme.potential_within_chain.form.buildings.schools',
  },
  {
    key: 'hotels',
    value: 'placeme.potential_within_chain.form.buildings.hotels',
  },
  {
    key: 'cultural',
    value: 'placeme.potential_within_chain.form.buildings.cultular',
  },
  {
    key: 'offices',
    value: 'placeme.potential_within_chain.form.buildings.offices',
  },
  {
    key: 'residential',
    value: 'placeme.potential_within_chain.form.buildings.residentials',
  },
  {
    key: 'population',
    value: 'placeme.potential_within_chain.form.population.inhabitantsNumber',
  },
  {
    key: 'demography',
    value: 'placeme.potential_within_chain.form.population.demography',
  },
  {
    key: 'income',
    value: 'placeme.potential_within_chain.form.population.earnings',
  },
  {
    key: 'pedestrian',
    value: 'placeme.potential_within_chain.form.traffic.pedestrian',
  },
  {
    key: 'car',
    value: 'placeme.potential_within_chain.form.traffic.car',
  },
  {
    key: 'chainStoreDistance',
    value: 'placeme.potential_within_chain.form.pois.chainStoreDistance',
  },
  {
    key: 'restaurants',
    value: 'placeme.potential_within_chain.form.pois.restaurants',
  },
  {
    key: 'gasStation',
    value: 'placeme.potential_within_chain.form.pois.petrolStations',
  },
  {
    key: 'hardwareStore',
    value: 'placeme.potential_within_chain.form.pois.constructionStores',
  },
  {
    key: 'discounts',
    value: 'placeme.potential_within_chain.form.pois.discountStores',
  },
  {
    key: 'heavyFastFoodUsers',
    value: 'placeme.potential_within_chain.form.behavioral.heavyFastFoodUsers',
  },
  {
    key: 'partyAnimals',
    value: 'placeme.potential_within_chain.form.behavioral.partyAnimals',
  },
  {
    key: 'dolciSaporiRailwaySchoolsAdministration',
    value: 'placeme.potential_within_chain.form.pois.dolciSaporiRailwaySchoolsAdministration',
  },
  {
    key: 'dolciSaporiGroceryHardwareAlcoholStores',
    value: 'placeme.potential_within_chain.form.pois.dolciSaporiGroceryHardwareAlcoholStores',
  },
  {
    key: 'dolciSaporiGroceryDrugstoresNonfoodDiscount',
    value: 'placeme.potential_within_chain.form.pois.dolciSaporiGroceryDrugstoresNonfoodDiscount',
  },
  {
    key: 'dolciSaporiSchoolsDormitoriesAdministration',
    value: 'placeme.potential_within_chain.form.pois.dolciSaporiSchoolsDormitoriesAdministration',
  },
  {
    key: 'dolciSaporiHardwareStores',
    value: 'placeme.potential_within_chain.form.pois.dolciSaporiHardwareStores',
  },
  {
    key: 'dolciSaporiFastfood',
    value: 'placeme.potential_within_chain.form.pois.dolciSaporiFastfood',
  },
  {
    key: 'dolciSaporiRestaurants',
    value: 'placeme.potential_within_chain.form.pois.dolciSaporiRestaurants',
  },
]
