
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  IMapLocationProps,
  Loader,
  TileFormParagraph,
  TileFormRowWithData,
  TileFormSection,
  TileFormWrapper,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { AuthContext } from '@dataplace.ai/features'
import { IEventLayerClick, IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { IMapTile } from '@dataplace.ai/ui-components/atoms/MapTile/@types/IMapTile'
import { ILocation, IRange } from '@dataplace.ai/types'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { TimeSelector } from '../../../../molecules'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { compareLocationCatchmentAndDataAction, saveNewRangeAction, saveTileData } from '../../../../../slice/analysisSlice'
import { IAccessRangeLayers, IAccessRangeTileData } from './@types/IAccessRangeTileData'
import { RootState } from '../../../../../../../redux/store'
import { contentTimeRanges } from '../../../../molecules/TimeSelector/data'

const StyledTileFormWrapper = styled(TileFormWrapper)(({ theme }) => {
  const { palette } = theme
  return css`
    background-color: ${palette.light.main};
  `
})

export const AccessRangeTile:
React.FC<{data: IAccessRangeTileData, isExtraPaid?: boolean, tileId: string}> = ({
  data, isExtraPaid, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    value, currentSubscriptionData, analyseId,
  } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const [val, setVal] = useState<ResourceWithId[]>([])
  const [accepted, setAccepted] = useState<boolean>(false)
  const [layers, setLayers] = useState<IMapTile['layers']>()
  const [canComparedLocationBeRequested, setCanComparedLocationBeRequested] = useState(false)
  const [comparedLayers, setComparedLayers] = useState<IMapTile['layers']>()
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })
  const authContext = useContext(AuthContext)

  const fetchCatchment = () => values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange

  const timeRanges = data?.value?.accessRange?.data?.features?.map(feature => ({
    label: feature?.properties?.id,
    area: feature?.properties?.catchmentArea,
  }))

  const comparedTimeRanges = data?.value?.comparedLocation?.accessRange?.data?.features?.map(feature => ({
    label: feature?.properties?.id,
    area: feature?.properties?.catchmentArea,
    areaDiff: feature?.properties?.catchmentAreaDiff,
  }))

  const getFirstNumber = (value: string) => value?.split('_')[1]
  const getSecondNumber = (value: string) => value?.split('_')[2]
  const getIcon = (value:string) => {
    const type = value?.split('_')[0]
    switch (type) {
      case 'walk':
        return (
          `<img alt='' src=${'assets/icons/rangeIcons/walkWhite.svg'} style="width:16px; height: 16px; "/>`
        )
      case 'car':
        return (
          `<img alt='' src=${'assets/icons/rangeIcons/carWhite.svg'} style="width:16px; height: 16px; "/>`
        )
      default:
        return (
          `<img alt='' src=${'assets/icons/rangeIcons/walkWhite.svg'} style="width:16px; height: 16px; "/>`
        )
    }
  }

  const handleOpacity = (amount: number, index: number) => (0.70 / amount) * (index + 1)

  const handleLayerClick = (coordiantes: number[], numbers: string[],
    e:IEventLayerClick, comparedLocation: boolean) => {
    const layers = comparedLocation
      ? getLayers(data?.value?.comparedLocation?.accessRange, true)
      : getLayers(data?.value?.accessRange, false)
    if (layers) {
      const newFeatures = layers[0]?.layer?.data?.features?.filter(feat =>
        feat?.properties?.id !== e?.target?.feature?.properties?.id)

      newFeatures.push({
        geometry: e?.target?.feature?.geometry,
        properties:{
          id: e?.target?.feature?.properties?.id,
          catchmentArea: e?.target?.feature?.properties?.catchmentArea,
          title: `<div style="display: flex; flex-direction: column;"><span>${contentTimeRanges?.find(range => range?.id === e?.target?.feature?.properties?.id)?.content}
            ${(e?.target?.feature?.properties?.id.split('_')[0] === 'walk'
    ? t('placeme.range_tile.minutes_by_foot')
    : t('placeme.range_tile.minutes_by_car'))}}</span><span>${t('placeme.parking_tile.row_with_data_1_span_1')}: ${e?.target?.feature?.properties?.catchmentArea} [km2]</span></div>`,

          onClick: (e) => {
            const coordinatesMulti = e?.target?.feature?.geometry?.coordinates as number[][][]
            const coordinatesPolygon = e?.target?.feature?.geometry?.coordinates as number[][]
            const c = (e?.target?.feature?.geometry?.type !== 'Polygon' ? coordinatesMulti[0][0][0] : coordinatesPolygon[0][0]) as unknown as number[]
            handleLayerClick(c, numbers, e, comparedLocation)
          },
          style:{
            color: '#348700',
            fillColor:'#348700',
            fillOpacity: 0.6,
            weight: 1,
          },
        },
        type: 'Feature',
      })

      newFeatures.push({
        geometry: {
          coordinates: coordiantes,
          type: 'Point',
        },
        properties:{
          pinnedItem: {
            class: 'poi-img',
            html: `<span style="display: flex; width: 75px; height: 27px; border-radius: 6px; color: white; background-color: #348700; align-items: center; justify-content: center;"><p style="font-size: 12px; text-align: center; display:flex; align-items: center; justify-content: center;">${getIcon(e?.target?.feature?.properties?.id)}${`${numbers[1]}-${numbers[0]}`} min</p></span>`,
          },
        },
        type: 'Feature',
      })

      newFeatures.sort((a, b) => {
        if (b?.properties?.catchmentArea && a?.properties?.catchmentArea) {
          return b.properties?.catchmentArea - a?.properties?.catchmentArea
        }
        return 0
      })

      if (!comparedLocation) {
        setLayers([{
          id: 'access_range_layer',
          layer: {
            data: {
              features: newFeatures,
              type: 'FeatureCollection',
            },
            options: {
              type: 'geojson',
              id: 'access_range',
            },
          },
        }])
      } else {
        setComparedLayers([{
          id: 'access_range_layer',
          layer: {
            data: {
              features: newFeatures,
              type: 'FeatureCollection',
            },
            options: {
              type: 'geojson',
              id: 'access_range',
            },
          },
        }])
      }
    }
  }

  const handleValueLabelClick = (id: string, comparedLocation: boolean) => {
    const val = !comparedLocation ? data?.value?.accessRange : data?.value?.comparedLocation?.accessRange
    const feature = val?.data?.features?.find(feat => feat?.properties?.id === id)
    if (feature) {
      const numbers = feature ? [getSecondNumber(feature?.properties?.id), getFirstNumber(feature?.properties?.id)] : []
      const coordinatesMulti = feature?.geometry?.coordinates as number[][][]
      const coordinatesPolygon = feature?.geometry?.coordinates as number[][][]
      const c = (feature?.geometry?.type !== 'Polygon' ? coordinatesMulti[0][0][0] : coordinatesPolygon[0][0]) as unknown as number[]
      const e = {
        target: {
          feature: {
            properties:{
              catchmentArea: feature?.properties?.catchmentArea,
              id: feature?.properties?.id,
            },
            geometry:{
              coordinates: feature?.geometry?.coordinates,
              type: feature?.geometry?.type,
            },
          },
        },
      }
      handleLayerClick(c, numbers, e, comparedLocation)
    }
  }

  const getLayers = (data: IAccessRangeLayers, comparedLocation: boolean) => {
    if (data) {
      const features : IFeatureCollection['data']['features'] = []

      data?.data?.features?.forEach((item, index) => {
        const numbers = [getSecondNumber(item?.properties?.id), getFirstNumber(item?.properties?.id)]
        features.push({
          geometry: item?.geometry,
          properties:{
            catchmentArea: item?.properties?.catchmentArea,
            title: `<div style="display: flex; flex-direction: column;"><span>${contentTimeRanges?.find(range => range?.id === item?.properties?.id)?.content}
            ${(item?.properties?.id.split('_')[0] === 'walk'
    ? t('placeme.range_tile.minutes_by_foot')
    : t('placeme.range_tile.minutes_by_car'))}</span><span>${t('placeme.parking_tile.row_with_data_1_span_1')}: ${item?.properties?.catchmentArea} [km2]</span></div>`,
            id: item?.properties?.id,
            onClick: (e) => {
              const coordinatesMulti = e?.target?.feature?.geometry?.coordinates as number[][][]
              const coordinatesPolygon = e?.target?.feature?.geometry?.coordinates as number[][]
              const c = (e?.target?.feature?.geometry?.type !== 'Polygon' ? coordinatesMulti[0][0][0] : coordinatesPolygon[0][0]) as unknown as number[]
              handleLayerClick(c, numbers, e, comparedLocation)
            },
            style:{
              color: '#423AB3',
              fillColor:'#423AB3',
              fillOpacity: handleOpacity(data?.data?.features?.length, index),
              weight: 1,
            },
          },
          type: 'Feature',
        })
      })

      features.sort((a, b) => {
        if (b?.properties?.catchmentArea && a?.properties?.catchmentArea) {
          return b?.properties?.catchmentArea - a?.properties?.catchmentArea
        }
        return 0
      })

      return [{
        id: 'access_range_layer',
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'access_range',
          },
        },
      }]
    }
    return undefined
  }

  const fetchData = useCallback(async () => {
    if (val.length && accepted && fetchCatchment()?.catchmentId) {
      const body = {
        catchmentId: fetchCatchment()?.catchmentId,
        ranges: val.map(v => {
          const sliced = v.id.split('_')
          return {
            id: v.id,
            rangeType: sliced[0],
            min: sliced[1],
            max: sliced[2],
          }
        }),
      }

      let saveData
      const axiosInstance = await getAxios({
        errCallbackFn: (e) => {
          saveData = {
            loading: false,
            error: e,
            value: null,
          }
        },
      })
      const response = await axiosInstance.post<ITileData>(ENDPOINTS.ACCESS_RANGE_TILE, body)
      if (response) {
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', fetchCatchment()?.catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      }
      setLoading(false)
      dispatch(saveTileData('access_logistics', tileId, saveData))
    }
  }, [val, token, accepted, fetchCatchment()?.catchmentId])

  const handleFetchComparedData = useCallback(async () => {
    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
    try {
      // post project - with compared location - create compared analyse
      if (!comparedAnalyseId) await dispatch(createNewAnalyse(analyseId, comparedLocation?.location))
    }
    finally {
      const accessRangeTile = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
        t.id === tileId)
      const catchmentId = accessRangeTile?.chosenRange?.catchmentId
      dispatch(compareLocationCatchmentAndDataAction(
        token,
        catchmentId || '',
        accessRangeTile?.chosenRange as IRange,
        accessRangeTile?.section || '',
        accessRangeTile?.id || '',
        currentSubscriptionData?.value?.subscriptionId || '',
        comparedLocation?.location as ILocation,
        {
          ranges: val.map(v => {
            const sliced = v.id.split('_')
            return {
              id: v.id,
              rangeType: sliced[0],
              min: sliced[1],
              max: sliced[2],
            }
          }),
        },
      ))
    }
  }, [canComparedLocationBeRequested, fetchCatchment()?.catchmentId])

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (fetchCatchment()?.catchmentId && !data?.value && accepted) {
      setLoading(true)
      if (comparedLocation?.generatedFromNow && fetchCatchment()?.type !== 'custom') {
        setCanComparedLocationBeRequested(true)
      } else {
        fetchData()
      }
    }
  }, [fetchData, fetchCatchment()?.catchmentId, !data?.value, accepted])

  useEffect(() => {
    if (!values?.find(c => c.id === 'access_logistics')?.tiles?.find(t => t.id === tileId)?.data?.value && token) {
      setAccepted(false)
      if (!fetchCatchment()?.catchmentId) {
        dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'access_logistics', tileId, {
          id: `${tileId}-250-line`,
          value: 250,
          type: 'line',
        })) }
    }
  }, [values, token])

  useEffect(() => {
    if (data?.value) {
      setLayers(getLayers(data?.value?.accessRange, false))
      if (data?.value?.comparedLocation?.accessRange) {
        setComparedLayers(getLayers(data?.value?.comparedLocation?.accessRange, true))
      }
    }
  }, [data?.value])

  // compared location
  useEffect(() => {
    const accessRangeTile = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
      t.id === tileId)
    if (canComparedLocationBeRequested
        && comparedLocation?.generatedFromNow
        && accessRangeTile
        && fetchCatchment()?.catchmentId
        && accessRangeTile.chosenRange?.type !== 'custom'
        && comparedLocation?.location) {
      handleFetchComparedData()
    }
  }, [canComparedLocationBeRequested, fetchCatchment()?.catchmentId])

  useEffect(() => {
    if (data?.value)setLoading(false)
  }, [data?.value])

  useEffect(() => {
    if (data?.value?.accessRange && mapLocation) {
      dispatch(saveTileData('access_logistics', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  return (
    <>
      {loading
        ? <Loader />
        : (
          <StyledTileFormWrapper>
            <TileFormSection>
              <TileFormRowWithData>
                <span>{t('placeme.access_range_tile.row_with_data_1_span_1')}</span>
              </TileFormRowWithData>
              <TileFormParagraph>
                {t('placeme.access_range_tile.paragraph_1', {
                  address: comparedLocation?.location?.address
                    ? `${value?.address}, ${comparedLocation?.location?.address}`
                    : `${value?.address},`,
                })}
              </TileFormParagraph>
              <TimeSelector
                comparedLayers={comparedLayers}
                comparedTimeRanges={comparedTimeRanges}
                handleValueLabelClick={handleValueLabelClick}
                isExtraPaid={isExtraPaid}
                layers={layers}
                onSubmit={setVal}
                ranges={val}
                setAccepted={setAccepted}
                setComparedMapLocation={setComparedMapLocation}
                setMapLocation={setMapLocation}
                tile={tileId}
                timeRanges={timeRanges}
              />
            </TileFormSection>
          </StyledTileFormWrapper>
        ) }
    </>
  )
}
