import React from 'react'
import { IStoreChainImpactTileData } from '../@types/IStoreChainImpact'
import { StoreChainImpactResult } from '../StoreChainImpactResult'
import { useDemoChainImpactTexts } from './hooks/useDemoChainImpactTexts'

export const DemoStoreChainImpact: React.FC<{ data: IStoreChainImpactTileData }> = ({ data }) => {
  const { demoChainImpact } = useDemoChainImpactTexts()

  return (
    <StoreChainImpactResult
      data={data}
      texts={demoChainImpact}
    />
  )
}
