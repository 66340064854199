import React, { Dispatch, ForwardedRef, forwardRef, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon, NoPrint } from '@dataplace.ai/ui-components/atoms'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import {
  AddressList,
  AddressListItem,
  AddressText,
  Content,
  ContentHeader,
  ContentHeaderText,
  Header,
  HeaderText,
  HeaderTextWrapper,
  LoaderWrapper,
  Wrapper,
} from '../RankingModal.styles'

interface RankingListObject {
  address: string
  id: number
  score: number
  locationType?: string
}

export interface RankingProps {
  rankingList: RankingListObject[]
  currentPosition: number
  setIsOpen: Dispatch<SetStateAction<boolean>>
  headerText?: string
  contentKeyText?: string
  contentValueText?: string
  trimFloat?: boolean
  additionalColumn?: boolean
}

const LOCATIONS_TO_DISPLAY = 10 // multiplied by two

const Ranking = forwardRef(({
  rankingList, currentPosition, setIsOpen, headerText,
  contentKeyText, contentValueText, trimFloat, additionalColumn,
}: RankingProps, ref: ForwardedRef<HTMLLIElement>) => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()

  const isAnalysedAddress = useCallback((index: number) => currentPosition === index + 1, [])

  const shouldDisplayLocationForPrint = useCallback((index: number) => {
    // display LOCATIONS_TO_DISPLAY number of locations above and below currentPosition in ranking
    const lowestIndex = currentPosition - LOCATIONS_TO_DISPLAY <= 0 ? 0 : currentPosition - LOCATIONS_TO_DISPLAY - 1
    const highestIndex = currentPosition + LOCATIONS_TO_DISPLAY >= rankingList.length
      ? rankingList.length - 1
      : currentPosition + LOCATIONS_TO_DISPLAY - 1
    return index >= lowestIndex && index <= highestIndex
  }, [currentPosition, rankingList])

  return (
    <Wrapper>
      {rankingList?.length
        ? (
          <>
            <Header>
              <HeaderTextWrapper>
                <HeaderText>{headerText ?? t('placeme.network_ranking.header')}</HeaderText>
              </HeaderTextWrapper>
              <NoPrint><CloseIcon onClick={() => setIsOpen(false)} /></NoPrint>
            </Header>
            <ContentHeader>
              <ContentHeaderText>{contentKeyText ?? t('placeme.network_ranking.point_address')}</ContentHeaderText>
              {additionalColumn
                ? <ContentHeaderText padding='0 0 0 12rem'>{contentKeyText ?? t('placeme.potential_within_chain.settings.location_type')}</ContentHeaderText>
                : null}
              <ContentHeaderText>{contentValueText ?? t('placeme.network_ranking.scoring')}</ContentHeaderText>
            </ContentHeader>
            <Content>
              <AddressList>
                {rankingList.map((location, index) => (
                  <AddressListItem
                    key={location.address}
                    ref={isAnalysedAddress(index) ? ref : undefined}
                    $isAnalysedAddress={isAnalysedAddress(index)}
                    $shouldDisplay={isPrinting ? shouldDisplayLocationForPrint(index) : true}
                  >
                    <AddressText>
                      {`${index + 1}. ${location?.address}`}
                    </AddressText>
                    {additionalColumn
                      ? <span>{t(`placeme.potential_within_chain.ranking.${location.locationType}`)}</span>
                      : null}
                    {trimFloat ? location.score.toFixed(2) : location.score}
                  </AddressListItem>
                ))}
              </AddressList>
            </Content>
          </>
        )
        : (
          <LoaderWrapper>
            <object
              data='assets/loaders/placeme/small.svg'
              type='image/svg+xml'
              width='130'
            >
              placeme loader
            </object>
          </LoaderWrapper>
        )}
    </Wrapper>
  )
})

export default Ranking
