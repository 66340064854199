import { saveNewRangeAction } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useContext, useEffect, useMemo } from 'react'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { IOverlappingRangesData } from '../@types/IOverlappingRanges'

interface TileDataHandlerProps {
  data: IOverlappingRangesData
  accepted: boolean
  fetchData: () => Promise<void | null>
  setCanComparedLocationBeRequested: React.Dispatch<React.SetStateAction<boolean>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setAccepted: React.Dispatch<React.SetStateAction<boolean>>
  tileId: string
  sectionId: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTileDataHandler = ({
  data,
  accepted,
  fetchData,
  setCanComparedLocationBeRequested,
  setLoading,
  setAccepted,
  tileId,
  sectionId,
}: TileDataHandlerProps) => {
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)

  const {
    values,
    comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const tileInfo = useMemo(() => getTileInfo(tileId, sectionId, values), [tileId, values])

  // checking whether data can be fetched
  useEffect(() => {
    if (!tileInfo?.chosenRange?.catchmentId || data?.value || !accepted) return
    setLoading(true)

    if (comparedLocation?.generatedFromNow && tileInfo?.chosenRange?.type !== 'custom') {
      setCanComparedLocationBeRequested(true)
    } else {
      fetchData()
    }
  }, [tileInfo?.chosenRange?.catchmentId, data?.value, accepted])

  // saving the new range
  useEffect(() => {
    if (tileInfo?.data?.value) return
    setAccepted(false)

    if (tileInfo?.chosenRange?.catchmentId) return
    dispatch(saveNewRangeAction('', authContext.userData.user?.uid || '', sectionId, tileId, {
      id: `${tileId}-250-line`,
      value: 250,
      type: 'line',
    }))
  }, [tileInfo, dispatch, authContext, tileId])
}
