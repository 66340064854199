import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { LocationTypeOptions } from '../../../@types/IPotentialWithinChain'

const pallete = {
  buildingsColors: [
    '#362FB6',
    '#4C44CF',
    '#736DD9',
    '#9A95E4',
    '#C1BEEE',
  ],
  trafficColors: [
    '#CF430C',
    '#F2591C',
    '#F57C4C',
    '#F89F7C',
    '#FAC3AC',
  ],
  poisColors: [
    '#54B2C9',
    '#7BC3D5',
    '#A5D7E3',
    '#C8E6EE',
    '#C4E5EC',
  ],
  populationColors: [
    '#F2C01C',
    '#F5CD4D',
    '#F7DB7D',
    '#F9E49F',
    '#FBEBB7',
  ],
  behavioralColors: [
    '#358500',
    '#49B800',
    '#5EEB00',
    '#97FF52',
    '#BFFF94',
  ],
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetFormFields = () => {
  const { t } = useTranslation()
  const [locationTypeId, setLocationTypeId] = useState<string | undefined>(undefined)

  const buildings = useMemo(() => {
    let description
    switch (locationTypeId) {
      case LocationTypeOptions.RETAIL_PARK:
        description = t('placeme.demo.potential_within_chain.desc.offices500m')
        break
      case LocationTypeOptions.HIGH_STREET:
        description = t('placeme.demo.potential_within_chain.desc.offices3km')
        break
      default:
        description = t('placeme.demo.potential_within_chain.desc.offices')
    }
    return {
      schools: {
        fieldId: 'schools',
        title: t('placeme.demo.potential_within_chain.form.buildings.schools'),
        description: t('placeme.demo.potential_within_chain.form.desc.schools'),
      },
      hotels: {
        fieldId: 'hotels',
        title: t('placeme.demo.potential_within_chain.form.buildings.hotels'),
        description: t('placeme.demo.potential_within_chain.form.desc.hotels'),
      },
      cultural: {
        fieldId: 'cultural',
        title: t('placeme.demo.potential_within_chain.form.buildings.cultular'),
        description: t('placeme.demo.potential_within_chain.form.desc.cultular'),
      },
      offices: {
        fieldId: 'offices',
        title: t('placeme.demo.potential_within_chain.form.buildings.offices'),
        description,
      },
      residential: {
        fieldId: 'residential',
        title: t('placeme.demo.potential_within_chain.form.buildings.residentials'),
        description: t('placeme.demo.potential_within_chain.form.desc.residentials'),
      },
    }
  }, [locationTypeId])

  const sociodemographic = {
    population: {
      fieldId: 'population',
      title: t('placeme.demo.potential_within_chain.form.population.inhabitantsNumber'),
      description: t('placeme.demo.potential_within_chain.form.desc.inhabitantsNumber'),
    },
    demography: {
      fieldId: 'demography',
      title: t('placeme.demo.potential_within_chain.form.population.demography'),
      description: t('placeme.demo.potential_within_chain.form.desc.demography'),
    },
    income: {
      fieldId: 'income',
      title: t('placeme.demo.potential_within_chain.form.population.earnings'),
      description: t('placeme.demo.potential_within_chain.form.desc.earnings'),
    },
  }

  const traffic = {
    pedestrian: {
      fieldId: 'pedestrian',
      title: t('placeme.demo.potential_within_chain.form.traffic.pedestrian'),
      description: t('placeme.demo.potential_within_chain.desc.pedestrian'),
    },
    car: {
      fieldId: 'car',
      title: t('placeme.demo.potential_within_chain.form.traffic.car'),
      description: t('placeme.demo.potential_within_chain.desc.car'),
    },
  }

  const pois = {
    chainStoreDistance: {
      fieldId: 'chainStoreDistance',
      title: t('placeme.demo.potential_within_chain.form.pois.chainStoreDistance'),
      description: t('placeme.demo.potential_within_chain.desc.chainStoreDistance'),
    },
    restaurants: {
      fieldId: 'restaurants',
      title: t('placeme.demo.potential_within_chain.form.pois.restaurants'),
      description: t('placeme.demo.potential_within_chain.desc.restaurants'),
    },
    gasStation: {
      fieldId: 'gasStation',
      title: t('placeme.demo.potential_within_chain.form.pois.petrolStations'),
      description: t('placeme.demo.potential_within_chain.desc.petrolStations'),
    },
    hardwareStore: {
      fieldId: 'hardwareStore',
      title: t('placeme.demo.potential_within_chain.form.pois.constructionStores'),
      description: t('placeme.demo.potential_within_chain.desc.constructionStores'),
    },
    discounts: {
      fieldId: 'discounts',
      title: t('placeme.demo.potential_within_chain.form.pois.discountStores'),
      description: t('placeme.demo.potential_within_chain.desc.discountStores'),
    },
  }

  const behavioral = useMemo(() => {
    let description
    switch (locationTypeId) {
      case LocationTypeOptions.RETAIL_PARK:
        description = t('placeme.demo.potential_within_chain.desc.heavyFastFoodUsers3km')
        break
      case LocationTypeOptions.HIGH_STREET:
        description = t('placeme.demo.potential_within_chain.desc.heavyFastFoodUsers250m')
        break
      case LocationTypeOptions.LOCAL:
        description = t('placeme.demo.potential_within_chain.desc.heavyFastFoodUsers3km')
        break
      default:
        description = t('placeme.demo.potential_within_chain.desc.heavyFastFoodUsers')
    }

    return {
      heavyFastFoodUsers: {
        fieldId: 'heavyFastFoodUsers',
        title: t('placeme.potential_within_chain.form.behavioral.heavyFastFoodUsers'),
        description,
      },
      partyAnimals: {
        fieldId: 'partyAnimals',
        title: t('placeme.demo.potential_within_chain.form.behavioral.partyAnimals'),
        description: t('placeme.demo.potential_within_chain.desc.partyAnimals'),
      },
    }
  }, [locationTypeId])

  const locationType: ResourceWithId | undefined = useMemo((): ResourceWithId | undefined => {
    switch (locationTypeId) {
      case LocationTypeOptions.HIGH_STREET:
        return ({
          id: LocationTypeOptions.HIGH_STREET,
          content: t('placeme.demo.potential_within_chain.dropdown.high_street'),
          initState: {
            pedestrian: 28,
            chainStoreDistance: 5,
            restaurants: 7,
            cultural: 15,
            schools: 6,
            hotels: 8,
            offices: 10,
            heavyFastFoodUsers: 10,
            partyAnimals: 11,
          },
          formsElements: [
            {
              category: 'traffic',
              catColor: pallete.trafficColors,
              title: t('placeme.demo.potential_within_chain.form.category.traffic'),
              elements: [traffic.pedestrian],
            },
            {
              category: 'pois',
              catColor: pallete.poisColors,
              title: t('placeme.demo.potential_within_chain.form.category.pois'),
              elements: [pois.chainStoreDistance, pois.restaurants],
            },
            {
              category: 'buildings',
              catColor: pallete.buildingsColors,
              title: t('placeme.demo.potential_within_chain.form.category.buildings'),
              elements: [
                buildings.cultural,
                buildings.schools,
                buildings.hotels,
                buildings.offices,
              ],
            },
            {
              category: 'behavioral',
              catColor: pallete.behavioralColors,
              title: t('placeme.demo.potential_within_chain.form.category.behavioral'),
              elements: [behavioral.heavyFastFoodUsers, behavioral.partyAnimals],
            },
          ],
        })
      case LocationTypeOptions.LOCAL:
        return ({
          id: LocationTypeOptions.LOCAL,
          content: t('placeme.demo.potential_within_chain.dropdown.local'),
          initState: {
            pedestrian: 18,
            schools: 6,
            chainStoreDistance: 9,
            restaurants: 11,
            discounts: 5,
            residential: 11,
            population: 16,
            demography: 9,
            income: 3,
            heavyFastFoodUsers: 12,
          },
          formsElements: [
            {
              category: 'traffic',
              catColor: pallete.trafficColors,
              title: t('placeme.demo.potential_within_chain.form.category.traffic'),
              elements: [traffic.pedestrian],
            },
            {
              category: 'pois',
              catColor: pallete.poisColors,
              title: t('placeme.potential_within_chain.form.category.pois'),
              elements: [pois.chainStoreDistance, pois.restaurants, pois.discounts],
            },
            {
              category: 'buildings',
              catColor: pallete.buildingsColors,
              title: t('placeme.demo.potential_within_chain.form.category.buildings'),
              elements: [buildings.schools, buildings.residential],
            },
            {
              category: 'sociodemographic',
              catColor: pallete.populationColors,
              title: t('placeme.demo.potential_within_chain.form.category.population'),
              elements: [sociodemographic.population, sociodemographic.income, sociodemographic.demography],
            },
            {
              category: 'behavioral',
              catColor: pallete.behavioralColors,
              title: t('placeme.demo.potential_within_chain.form.category.behavioral'),
              elements: [behavioral.heavyFastFoodUsers],
            },
          ],
        })
      case LocationTypeOptions.RETAIL_PARK:
        return (
          {
            id: LocationTypeOptions.RETAIL_PARK,
            content: t('placeme.demo.potential_within_chain.dropdown.retail_park'),
            initState: {
              pedestrian: 20,
              car: 4,
              chainStoreDistance: 12,
              restaurants: 6,
              hardwareStore: 7,
              residential: 13,
              offices: 7,
              population: 1,
              demography: 12,
              income: 8,
              heavyFastFoodUsers: 4,
              gasStation: 6,
            },
            formsElements: [
              {
                category: 'traffic',
                catColor: pallete.trafficColors,
                title: t('placeme.demo.potential_within_chain.form.category.traffic'),
                elements: [traffic.pedestrian, traffic.car],
              },
              {
                category: 'pois',
                catColor: pallete.poisColors,
                title: t('placeme.demo.potential_within_chain.form.category.pois'),
                elements: [
                  pois.chainStoreDistance,
                  pois.restaurants,
                  pois.hardwareStore,
                  pois.gasStation,
                ],
              },
              {
                category: 'buildings',
                catColor: pallete.buildingsColors,
                title: t('placeme.demo.potential_within_chain.form.category.buildings'),
                elements: [buildings.residential, buildings.offices],
              },
              {
                category: 'sociodemographic',
                catColor: pallete.populationColors,
                title: t('placeme.demo.potential_within_chain.form.category.population'),
                elements: [sociodemographic.population, sociodemographic.income, sociodemographic.demography],
              },
              {
                category: 'behavioral',
                catColor: pallete.behavioralColors,
                title: t('placeme.demo.potential_within_chain.form.category.behavioral'),
                elements: [behavioral.heavyFastFoodUsers],
              },
            ],
          }
        )
      default:
        return undefined
    }
  }, [locationTypeId])

  return {
    locationType,
    setLocationTypeId,
    locationTypeId,
  }
}
