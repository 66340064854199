import { useTranslation } from 'react-i18next'
import { FormFieldsDemo, InitialFormStateDemo } from '../../DemoScoringPrediction'
import { useGetDemoDropdownOptions } from './useGetDemoDropdownOptions'
import { FormElement } from '../../../@types/IAnwPotentialModelTileData'

export interface FormDataObjectDemo {
  formElementData: FormElement[];
  additionalFormElementData?: FormElement[] | null;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormFieldsData = (formState: InitialFormStateDemo, areAllFieldsFilled: boolean) => {
  const { t } = useTranslation()
  const {
    salesAreaOptions,
    plannedShopType,
    options,
    plannedSelfCheckoutsNumber,
  } = useGetDemoDropdownOptions()

  const formElementData: FormElement[] = [
    {
      formStateElement: formState.selfCheckoutsNumber,
      inputKey: FormFieldsDemo.selfCheckoutsNumber,
      options: plannedSelfCheckoutsNumber,
      title: t('placeme.demo.scoring_prediction.criteria.self_checkouts_number'),
    },
    {
      formStateElement: formState.salesArea,
      inputKey: FormFieldsDemo.salesArea,
      options: salesAreaOptions,
      title: t('placeme.demo.scoring_prediction.criteria.sales_area'),
      width: '10rem',
    },
    {
      formStateElement: formState.shopType,
      inputKey: FormFieldsDemo.shopType,
      options: plannedShopType,
      title: t('placeme.demo.scoring_prediction.criteria.shop_type'),
      width: '12rem',
    },
    {
      formStateElement: formState.availablePremiumStock,
      inputKey: FormFieldsDemo.availablePremiumStock,
      options,
      title: t('placeme.demo.scoring_prediction.criteria.available_premium_stock'),
    },
    {
      formStateElement: formState.pylon,
      inputKey: FormFieldsDemo.pylon,
      options,
      title: t('placeme.demo.scoring_prediction.criteria.pylon'),
    },
    {
      formStateElement: formState.openSaturdays,
      inputKey: FormFieldsDemo.openSaturdays,
      options,
      title: t('placeme.demo.scoring_prediction.criteria.open_saturdays'),
    },
    {
      formStateElement: formState.pickupPoint,
      inputKey: FormFieldsDemo.pickupPoint,
      options,
      title: t('placeme.demo.scoring_prediction.criteria.pickup_point'),
    },
  ]

  const additionalFormElementData: FormElement[] = [
    {
      formStateElement: formState.expressway,
      inputKey: FormFieldsDemo.expressway,
      options,
      title: t('placeme.demo.scoring_prediction.criteria.expressway'),
    },
    {
      formStateElement: formState.competitiveStore,
      inputKey: FormFieldsDemo.competitiveStore,
      options,
      title: t('placeme.demo.scoring_prediction.criteria.competitive_store'),
    },
    {
      formStateElement: formState.restaurant,
      inputKey: FormFieldsDemo.restaurant,
      options,
      title: t('placeme.demo.scoring_prediction.criteria.restaurant'),
    },
    {
      formStateElement: formState.discountStore,
      inputKey: FormFieldsDemo.discountStore,
      options,
      title: t('placeme.demo.scoring_prediction.criteria.discount_store'),
    },
  ]

  return {
    formElementData,
    additionalFormElementData: areAllFieldsFilled ? additionalFormElementData : null,
  }
}
