import { useTranslation } from 'react-i18next'
import { flatMap } from 'lodash'
import { useMemo } from 'react'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { LocationTypeOptionsType, Settings } from '../../@types/IPotentialWithinChain'

interface UseSelectedSettingsProps {
  settings: Settings
  locationType?: ResourceWithId
  dropdownElements: {
    id: LocationTypeOptionsType
    content: string
  }[]
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSelectedSettings({
  settings, locationType, dropdownElements,
}: UseSelectedSettingsProps) {
  const { t } = useTranslation()

  const foundObject = dropdownElements.find(el => el.id === settings?.locationType)

  const flattenedData = useMemo(() => {
    if (!locationType) return null
    return locationType?.formsElements?.map(category => category.elements).flat(2)
  }, [locationType])

  const flattenedParams = useMemo(() => flatMap(settings?.parameters), [settings])
  const matchedTitlesWithValues = useMemo(() => flattenedData?.filter((item: { fieldId: string }) => {
    const matchingParam = flattenedParams.find(param => param.hasOwnProperty(item.fieldId))
    return matchingParam !== undefined
  })
    .map(item => {
      const matchingParam = flattenedParams.find(param => param.hasOwnProperty(item.fieldId))
      const paramValue = matchingParam ? matchingParam[item.fieldId] : null
      return `${item.title} (${paramValue}%)`
    }), [flattenedData, flattenedParams])

  const settingsList = [
    {
      label: t('placeme.potential_within_chain.settings.location_type'),
      value: foundObject?.content,
      visible: true,
    },
    {
      label: t('placeme.potential_within_chain.settings.analyzed_variables'),
      value: matchedTitlesWithValues?.join(', '),
      visible: true,
      column: true,
    },
  ]

  return {
    settingsList,
  }
}
