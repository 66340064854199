import { DataType } from './@types/IPotentialTileData'

export type PotentialLabels = {key: DataType, value: string, unit?: string, step?: number,
  min?: number, max?: number}[];

export const labels: PotentialLabels = [
  {
    key: 'inhabitants',
    value: 'placeme.sales_potential_tile.radar.inhabitants',
    unit: 'placeme.sales_potential_tile.params.inhabitants',
    step: 100,
  },
  {
    key: 'carTraffic',
    value: 'placeme.sales_potential_tile.radar.car_traffic',
    unit: '%',
    step: 5,
  },
  {
    key: 'footTraffic',
    value: 'placeme.sales_potential_tile.radar.foot_traffic',
    unit: '%',
    step: 5,
  },
  {
    key: 'purchasing',
    value: 'placeme.sales_potential_tile.radar.purchasing',
    unit: 'placeme.sales_potential_tile.params.currency',
    step: 100,
  },
  {
    key: 'purchasingPower',
    value: 'placeme.sales_potential_tile.radar.purchasing',
    unit: 'placeme.sales_potential_tile.params.currency',
    step: 100,
  },
  {
    key: 'industrialBuildings',
    value: 'placeme.sales_potential_tile.radar.industrial_buildings',
    unit: 'placeme.sales_potential_tile.params.buildings',
    step: 5,
  },
  {
    key: 'residentialBuildings',
    value: 'placeme.sales_potential_tile.radar.residential_buildings',
    unit: 'placeme.sales_potential_tile.params.buildings',
    step: 5,
  },
  {
    key: 'officeBuildings',
    value: 'placeme.sales_potential_tile.radar.office_buildings',
    unit: 'placeme.sales_potential_tile.params.buildings',
    step: 5,
  },
  {
    key: 'shopArea',
    value: 'placeme.sales_potential_tile.radar.shop_area',
    unit: 'placeme.sales_potential_tile.params.area',
    step: 1,
  },
  {
    key: 'storeType',
    value: 'placeme.sales_potential_tile.radar.store_type',
  },
  {
    key: 'averageShoppingValue',
    value: 'placeme.sales_potential_tile.radar.average_shopping_value',
    unit: 'placeme.sales_potential_tile.params.currency',
    step: 0.5,
  },
  {
    key: 'preferences',
    value: 'placeme.sales_potential_tile.radar.preferences',
  },
  {
    key: 'greenery',
    value: 'placeme.sales_potential_tile.radar.greenery',
    unit: 'placeme.sales_potential_tile.params.area',
    step: 5,
  },
  {
    key: 'planningShopArea',
    value: 'placeme.sales_potential_tile.radar.shop_area',
    unit: 'placeme.sales_potential_tile.params.area',
    step: 1,
  },
  {
    key: 'spendingAlcohol',
    value: 'placeme.sales_potential_tile.radar.alcoholSpendings',
    unit: 'placeme.sales_potential_tile.params.currency',
    step: 10,
    min: 0,
  },
  {
    key: 'averageAge',
    value: 'placeme.sales_potential_tile.radar.averageAge',
    unit: 'placeme.sales_potential_tile.params.age',
    step: 1,
  },
  {
    key: 'averageIncome',
    value: 'placeme.sales_potential_tile.radar.averageIncome',
    unit: 'placeme.sales_potential_tile.params.currency',
    step: 5,
  },
  {
    key: 'averageShopArea',
    value: 'placeme.sales_potential_tile.radar.averageShopArea',
    unit: 'placeme.sales_potential_tile.params.area',
    step: 1,
  },
  {
    key: 'averageTrafficWorkingDays',
    value: 'placeme.sales_potential_tile.radar.averageTrafficWorkingDays',
    unit: 'placeme.sales_potential_tile.params.person',
    step: 10,
  },
  {
    key: 'customers',
    value: 'placeme.sales_potential_tile.radar.customers',
    unit: 'placeme.sales_potential_tile.params.people',
    step: 2,
  },
  {
    key: 'spendings',
    value: 'placeme.sales_potential_tile.radar.spendings',
    unit: '%',
    step: 0.01,
    max: 1.1,
    min: 0.9,
  },
  {
    key: 'age1924',
    value: 'placeme.sales_potential_tile.radar.age_19_24',
    unit: 'placeme.sales_potential_tile.params.inhabitants',
    step: 10,
    min: 0,
  },
  {
    key: 'beforeRetirementF5060M5065',
    value: 'placeme.sales_potential_tile.radar.before_retirement_f50_60_m50_65',
    unit: 'placeme.sales_potential_tile.params.inhabitants',
    step: 10,
    min: 0,
  },
  {
    key: 'distanceMConvenience',
    value: 'placeme.sales_potential_tile.radar.distance_m_convenience',
    unit: 'm',
    step: 10,
    min: 0,
  },
  {
    key: 'distanceMToOwnBrand',
    value: 'placeme.sales_potential_tile.radar.distance_m_to_own_brand',
    unit: 'm',
    step: 50,
    min: 0,
  },
  {
    key: 'residentialBlocks5000',
    value: 'placeme.sales_potential_tile.radar.residential_blocks_5000',
    unit: 'placeme.sales_potential_tile.params.buildings',
    step: 1,
    min: 0,
  },
  {
    key: 'monthlyCtd1000',
    value: 'placeme.sales_potential_tile.radar.monthly_ctd_1000',
    unit: '%',
    step: 5,
    min: 0,
  },
]
