/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import styled, { css } from 'styled-components'
import { AuthContext } from '@dataplace.ai/features'
import { formatNumber, getAxios } from '@dataplace.ai/functions/utils'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { RootState } from 'apps/placeme/src/redux/store'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { useSelector } from 'react-redux'
import { Loader, SelectCaret } from 'libs/shared/ui-components/src/atoms'
import { TileFormRowWithData, TileFormSpan } from '@dataplace.ai/ui-components/atoms'
import { fetchWorkspaceUsageValue, saveTileData } from '../../../../../slice/analysisSlice'
import { ISimilarLocations, ISimilarLocationTileData } from './@types/ISimilarLocationTileData'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const Section = styled.div(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
    margin-bottom: 30px;

    >ul {
      width: 100%;
      list-style-type: none;
      
      >li{
        display: flex;
        flex-direction: column;
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
        padding: 10px 0 10px 10px;
        width:100%;
        border-radius: 6px;

       >div {
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
          >div{
            >span{
                font-weight: ${typography.small.pt_13_semibold.fontWeight};
              }
        }

        >div:nth-child(2){
          display: flex;
          cursor: pointer;
          >div{
            margin-left: 43px;
          }
        }
      }
      }

      >li:nth-child(2n){
          background-color: ${palette.light.main};
        }

     
    }

    > h4 {
      font-size: ${typography.main.pt_15_medium_upper.fontSize};
      font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
      line-height: ${typography.main.pt_15_medium_upper.lineHeight};
      letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
      text-transform: ${typography.main.pt_15_medium_upper.textTransform};
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > h5 {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }

    > p {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      margin-bottom: 15px;

      > span {
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};
        font-family: 'Inter',sans-serif;
        display: inline;
        margin-left: 0.25rem;
      }
    }

    .p15{
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-bottom: 15px;
    
      > strong {
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
        display: block;
        margin-top: 10px;
      }
    }
  `
})

const AdditionalInfo = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    padding: 0.5rem;
    border-top: 1px solid ${palette.light.darker};
    flex-direction: row;
    flex-wrap: wrap;

    div {
      display: flex;
      margin: 0.5rem 0;

    }

    > div {
      width: 100%;
      >div{
        width:50%;
        display: flex;
        flex-direction: column;
      }
    }

    > div:last-child {
      width: 100%;
    }

    > div > span:first-child,
    > div > div > span:first-child {
      margin-bottom: 0.25rem;
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    > div > div > span {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      letter-spacing: ${typography.small.pt_13_semibold.letterSpacing};
      text-transform: ${typography.small.pt_13_semibold.textTransform};
    }

    > div > span {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      letter-spacing: ${typography.small.pt_13_regular.letterSpacing};
      text-transform: ${typography.small.pt_13_regular.textTransform};
    }
  `
})

interface IExpendableRow {
  location: ISimilarLocations
}

const ExpandableRow : React.FC<IExpendableRow> = ({ location }) => {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)
  const { isPrinting } = useCheckIfPrinting()

  useEffect(() => {
    if (isPrinting) setActive(true)
  }, [isPrinting])

  return (
    <li key={location?.pointId}>
      <div>
        <div>
          {t('placeme.big_ben_similar_locations.list1')}
          <span>
            {' '}
            {`${Math.round(location?.percent * 100)}%`}
            {' '}
          </span>

          {t('placeme.big_ben_similar_locations.list2')}
          ,
          <span>
            {' '}
            {location?.address}
          </span>
        </div>
        <div onClick={() => setActive(!active)}>
          {t('generic.details')}
          {' '}
          <SelectCaret
            active={active}
          />
        </div>
      </div>
      {active && (
        <AdditionalInfo>
          <div>
            <div>
              <span>{`${t('placeme.similar_locations_tile.average_traffic')}:`}</span>
              <span>
                {formatNumber(location?.averageTrafficPerDay)}
              </span>
            </div>

            <div>
              <span>{`${t('placeme.similar_locations_tile.residents_value')}:`}</span>
              <span>{formatNumber(location?.residentsValue)}</span>
            </div>

          </div>
          <div>
            <div>
              <span>{`${t('placeme.similar_locations_tile.alcohol_spending')}:`}</span>
              <span>{formatNumber(location?.alcoholSpendingPerCapita)}</span>
            </div>
          </div>
        </AdditionalInfo>
      )}
    </li>
  )
}

export const SimilarLocationTile: React.FC<{ data: ISimilarLocationTileData, tileId: string}> = ({
  tileId, data,
}) => {
  // variables
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const { values } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  const catchmentId = values?.find(c => c.id === 'big_ben')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

  // states
  const [token, setToken] = useState('')

  // functions
  const getTileType = (id: string) => id.split('-')[0]

  const fetchData = useCallback(async () => {
    if (token.length) {
      const body = {
        catchmentId,
      }

      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]
      let saveData
      const axiosInstance = await getAxios({
        errCallbackFn: (e) => {
          saveData = {
            loading: false,
            error: e.message,
            value: null,
          }
        },
      })
      const response = await axiosInstance.post(endpoint || '', body)
      if (response) {
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      }
      dispatch(saveTileData('big_ben', tileId, saveData))
    }
  }, [token, catchmentId])

  // hooks
  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (catchmentId && !data?.value) {
      fetchData()
    }
  }, [fetchData, catchmentId, !data?.value])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        { data?.value?.similarLocations?.length
          ? (
            <Section>

              <>
                {' '}
                <TileFormRowWithData>
                  <span>{t('placeme.big_ben_similar_locations.row_with_data_1_span_1')}</span>
                  <span>{(data?.value?.similarLocations?.length)}</span>
                </TileFormRowWithData>
                <TileFormSpan>
                  {t('placeme.big_ben_similar_locations.form_span_1_normal_1')}
                </TileFormSpan>
                <TileFormSpan
                  isBold
                  margin='20px 0 0 0'
                >
                  {t('placeme.big_ben_similar_locations.form_span_1_normal_2')}
                  {' '}
                  {value?.address}
                  {' '}
                  {t('placeme.big_ben_similar_locations.form_span_1_normal_3')}
                </TileFormSpan>
                <ul>
                  {data?.value?.similarLocations?.map(location => (
                    <ExpandableRow
                      key={location?.pointId}
                      location={location}
                    />
                  ))}
                </ul>
              </>
            </Section>
          )
          : (
            <>
              <TileFormSpan
                style={{
                  marginBottom: '1rem',
                }}
              >
                {t('placeme.big_ben_similar_locations.form_span_1_normal_1')}
              </TileFormSpan>
              <Section>
                <TileFormSpan>
                  {t('placeme.big_ben_similar_locations.no_locations_above_60')}
                </TileFormSpan>
              </Section>
            </>
          )}
      </Wrapper>
    )
}
