import React from 'react'
import styled, { css } from 'styled-components'
import { CoinsButton, TextButton } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { ITileFooterProps } from './@types/ITileFooter.props'

const Footer = styled.span(
  () => css`
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    > button:first-child {
      margin: 0.5rem 2rem;
    }
  `,
)

export const TileFooter = ({
  disabled,
  onAccept,
  onCancel,
  value,
  label,
  isExtraPaid,
  tile,
  isUnlimited,
}: ITileFooterProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Footer>
      <TextButton onClick={onCancel}>
        <p>{t('generic.cancel')}</p>
      </TextButton>
      <CoinsButton
        disabled={disabled}
        isExtraPaid={isExtraPaid}
        isUnlimited={isUnlimited}
        onClick={onAccept}
        text={label}
        tileId={tile ?? ''}
        value={value}
      />
    </Footer>
  )
}
