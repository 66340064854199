import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { ITileData } from 'apps/placeme/src/features/Analyse/slice/@types/ITileData'
import { mcdCategoryId } from '@dataplace.ai/constants'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { McdPotentialTile } from './McdPotentialModelTile'
import { useFormFieldsData } from './McdPotentialModelData/hooks/formData/useFormFieldData'
import { useSelectedSettings } from './McdPotentialModelData/hooks/resultData/useSelectedSettings'
import { useGetChartData } from './McdPotentialModelData/hooks/resultData/useGetChartData'
import { radarChartLabels } from './McdPotentialModelData/hooks/resultData/radarChartLabels'

export interface IPotentialModelSettings {
  locationType: string,
  locationAdditions: string[],
  plannedShoppingMall?: boolean,
  plannedShoppingMallSize?: string,
  plannedShoppingMallFoodCourtExists?: boolean,
}

export interface Influence {
  [key: string]: number
}

export interface IMcdPotentialTileData extends ITileData {
  value: {
    potentialModel: {
      transactions: number,
      averageChainTransactions: number,
      averageChainSales: number,
      sales: number,
      influence: Influence,
    }
    settings: IPotentialModelSettings
  }
}

export type FormFieldValue = number | string | boolean | null;

export enum FormFields {
  locationType = 'locationType',
  locationAdditions = 'locationAdditions',
  plannedShoppingMall = 'plannedShoppingMall',
  plannedShoppingMallSize = 'plannedShoppingMallSize',
  plannedShoppingMallFoodCourtExists = 'plannedShoppingMallFoodCourtExists',
}

export interface InitialFormState {
  locationType: string
  plannedShoppingMall: boolean
  plannedShoppingMallSize: string
  plannedShoppingMallFoodCourtExists: boolean
}

const initialState = {
  locationType: '',
  plannedShoppingMall: false,
  plannedShoppingMallSize: 'medium',
  plannedShoppingMallFoodCourtExists: false,
}

export const McdPotentialModel: React.FC<{
  data: IMcdPotentialTileData,
  tileId: string,
}> = ({
  data, tileId,
}) => {
  const { values } = useSelector((state: RootState) => state.analysis)

  const [locationAdditions, setLocationAdditions] = useState<string[]>([])
  const [formState, setFormState] = useState<InitialFormState>(initialState)

  const barData = useGetChartData(data)
  const settingList = useSelectedSettings(data)
  const formDataObject = useFormFieldsData(formState, formState.plannedShoppingMall)

  const setFormValue = useCallback((key: string, value: FormFieldValue) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }, [setFormState])

  const catchmentId = useMemo(() => values.find(cat =>
    cat.id === mcdCategoryId)?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId, [values, tileId])

  const body = useMemo(() => ({
    catchmentId,
    locationType: formState.locationType,
    locationAdditions,
    plannedShoppingMall: formState.plannedShoppingMall,
    plannedShoppingMallSize: formState.plannedShoppingMallSize,
    plannedShoppingMallFoodCourtExists: formState.plannedShoppingMallFoodCourtExists,
  }), [formState, catchmentId])

  return (
    <McdPotentialTile
      barData={barData}
      body={body}
      categoryId={mcdCategoryId}
      data={data}
      endpoint={ENDPOINTS.MCD_POTENTIAL_MODEL_TILE}
      formDataObject={formDataObject}
      formState={formState}
      labels={radarChartLabels}
      setFormValue={setFormValue}
      setLocationAdditions={setLocationAdditions}
      settingList={settingList}
      tileId={tileId}
    />
  )
}
