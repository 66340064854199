import { RootState } from 'apps/placeme/src/redux/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ComparedLocationBean } from '@dataplace.ai/ui-components/molecules'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { ReactComponent as EyeIcon } from 'libs/shared/assets/src/lib/icons/eyeIcon.svg'

const ValueTile = styled.div(({ theme: { palette } }) => css`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: ${palette.light.white};
  border: 1px solid ${palette.light.darker};
  box-sizing: border-box;
  border-radius: 6px;
  color: ${palette.black};

  &:hover{
    .icon {
      display: block;
    }
  }
`)

const SelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Content = styled.div(({ theme: { typography } }) => css`
  font-size: ${typography.main.pt_15_semibold.fontSize};
  font-weight: ${typography.main.pt_15_semibold.fontWeight};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
  display: flex;
  align-items: center;
`)

const StyledStrong = styled.strong(({ theme: { typography } }) => css`
  font-size: ${typography.big.pt_28_semibold.fontSize};
  font-weight: ${typography.big.pt_28_semibold.fontWeight};
  line-height: ${typography.big.pt_28_semibold.lineHeight};
  margin-left: 5px;
`)

const StyledEyeIcon = styled(EyeIcon)<{active: boolean }>(({ active }) => css`
  display: ${active ? 'block' : 'none'};
  cursor: pointer;
`)

const StyledSpan = styled.span`
  margin-left: 5px;
`

interface IClickableLabel {
  contentTimeRanges: ResourceWithId[]
  resource: {
    label: string
    population: number
    populationDiff?: number
    active?:boolean
  }
  handleValueLabelClick?: (id: string, compared: boolean) => void
  compared?: {
    label: string
    population: number
    populationDiff?: number
    active?:boolean
  }
}

const ClickableLabel: React.FC<IClickableLabel> = ({
  resource, handleValueLabelClick, compared, contentTimeRanges,
}) => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)

  const type = resource?.label?.split('_')[0]

  const handleEyeIconClick = () => {
    if (!handleValueLabelClick) return
    handleValueLabelClick(resource?.label, false)

    if (compared) {
      handleValueLabelClick(compared?.label, true)
    }
  }

  return (
    <ValueTile key={resource?.label}>
      <SelectedWrapper>
        <Content>
          {contentTimeRanges?.find(range => range?.id === resource?.label)?.content}
          <StyledSpan>{t(`placeme.overlapping_ranges.time_selector.by_${type}`)}</StyledSpan>
          <StyledStrong>{formatNumber(resource?.population)}</StyledStrong>
          {compared?.population
            ? (
              <ComparedLocationBean
                comparedAddress={comparedLocation?.location?.address}
                comparedValue={formatNumber(compared?.population)}
                difference={compared?.populationDiff}
                mainAddress={value?.address}
                mainValue={formatNumber(resource?.population)}
                style={{
                  marginLeft: '5px',
                }}
              />
            )
            : null}
        </Content>
      </SelectedWrapper>
      <StyledEyeIcon
        active={resource?.active || false}
        className='icon'
        onClick={handleEyeIconClick}
      />
    </ValueTile>
  )
}

export default ClickableLabel
