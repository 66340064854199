export const othersInvestmentsTypes = {
  residential: 'residential',
  administration: 'administration',
  infrastructural: 'infrastructural',
  others: 'others',
} as const

export const investmentTypes = {
  ...othersInvestmentsTypes,
  commercialAndServices: 'commercialAndServices',
} as const

export const investmentDeviationTypes = {
  housingInvestment: 'housingInvestment',
  apartmentsCount: 'apartmentsCount',
  commercialAndServicesInvestment: 'commercialAndServicesInvestment',
} as const

export type InvestmentDeviationTypes = (typeof investmentDeviationTypes)[keyof typeof investmentDeviationTypes]

export const TOOLTIP_MAX_WIDTH = '300px'
