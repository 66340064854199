/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  MapTile,
  CloseIcon,
  TileFormParagraph,
  NoPrint,
} from '@dataplace.ai/ui-components/atoms'
import { ResourcesSelector } from '@dataplace.ai/ui-components/organisms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { useTranslation } from 'react-i18next'
import { AddMapView, ComparedLocationBean, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { carTimeRanges, contentTimeRanges, footTimeRanges } from './data'
import { ComparedLocationHeader, TileFooter } from '../../atoms'
import { RootState } from '../../../../../redux/store'
import { ITimeSelectorProps } from './@types/ITimeSelector.props'
import { ReactComponent as EyeIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/eyeIcon.svg'
import { tilesWithoutComparedLocation } from '../AddAnotherLocationModal/constants'

const SelectorWrapper = styled.span(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: ${palette.light.white};
    border: 1px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};

    > span:first-child,
    > span > div > span {
      color: ${palette.dark.normal};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
    }

    > span:first-child {
      margin-bottom: 0.5rem;
    }
  `
})

const Wrapper = styled.span(
  () => css`
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
  `,
)

const ListSpan = styled.span(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    width: 50%;
    position: relative;
    padding: 0.375rem 0.5rem;
    flex-direction: row;
    margin: 0.25rem 0;
    align-items: center;
    background-color: ${palette.light.main};
    border: 1px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};

    > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    :hover > span {
      color: ${palette.blue};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    > span > span {
      margin-right: 0.25rem;
    }
  `
})

// const SelectedSection = styled.div(({ theme }) => {
//   const {
//     palette, typography, corners,
//   } = theme

//   return css`
//     display: flex;
//     flex-direction: column;
//     background: ${palette.light.white};
//     border: 1.5px dashed ${palette.light.darkest};
//     border-radius: ${corners.default.borderRadius};
//     padding: 1rem;

//     > span > svg {
//       cursor: pointer;
//     }

//     > span {
//       display: flex;
//     }

//     > span > span {
//       flex-grow: 1;
//       color: ${palette.black};
//       font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
//       font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
//       line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
//       letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
//       text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
//     }

//     > div {
//       margin: 0.5rem 0;
//       display: flex;
//       color: ${palette.dark.normal};
//       font-size: ${typography.tiny.pt_12_regular.fontSize};
//       font-weight: ${typography.tiny.pt_12_regular.fontWeight};
//       line-height: ${typography.tiny.pt_12_regular.lineHeight};
//     }

//     > div > span,
//     > div > span > span {
//       margin-right: 0.25rem;
//     }
//   `
// })

const MapWrapper = styled.div`
  margin: 1rem 0;
  width: 100%;
  height: 300px;

  @media print {
    margin: 0;
  }
`

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  margin-bottom: 0;
`

const ValuesWrapper = styled.div(() => css`
    display: flex;
    flex-direction: column;
    width: 100%;

  `)

const ValueTile = styled.div<{active: boolean, }>(({
  theme, active,
}) => {
  const {
    palette, typography,
  } = theme

  return css`
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background: white;
    border: 1px solid #EDF0F5;
    box-sizing: border-box;
    border-radius: 6px;
    color: ${palette.black};
    >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        >p{
          font-size: ${typography.main.pt_15_semibold.fontSize};
          font-weight: ${typography.main.pt_15_semibold.fontWeight};
          line-height: ${typography.main.pt_15_semibold.lineHeight};
          display: flex;
          align-items: center;

          >strong{
            font-size: ${typography.big.pt_28_semibold.fontSize};
            font-weight: ${typography.big.pt_28_semibold.fontWeight};
            line-height: ${typography.big.pt_28_semibold.lineHeight};
            margin-left: 5px;
          }
        }

        >span{
          font-size: ${typography.small.pt_13_regular.fontSize};
          font-weight: ${typography.small.pt_13_regular.fontWeight};
          line-height: ${typography.small.pt_13_regular.lineHeight};
          color: ${palette.dark.normal};
          margin-top: 5px;
        }
    }
      .icon{
        display: ${active ? 'block' : 'none'};
        cursor: pointer;
      }

      :hover{
        .icon{
          display: block;
        }
      }

  ` })

interface IClickableLabel {
  resource: {
    label: string
    area: number
    areaDiff?: number
    active?:boolean
  }
  handleValueLabelClick?: (id: string, compared: boolean) => void
  compared?: {
    label: string
    area: number
    areaDiff?: number
    active?:boolean
  }
}

export const ClickableLabel: React.FC<IClickableLabel> = ({
  resource, handleValueLabelClick, compared,
}) => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  return (
    <ValueTile
      key={resource?.label}
      active={resource?.active || false}

    >
      <div>
        <p>
          {contentTimeRanges?.find(range => range?.id === resource?.label)?.content}
          {resource?.label?.split('_')[0] === 'walk'
            ? t('placeme.range_tile.minutes_by_foot')
            : t('placeme.range_tile.minutes_by_car')}
          {' '}
          <strong>{formatNumber(resource?.area * 1000000)}</strong>
          {compared?.area && (
            <ComparedLocationBean
              comparedAddress={comparedLocation?.location?.address}
              comparedValue={formatNumber(compared?.area * 1000000)}
              difference={compared?.areaDiff}
              mainAddress={value?.address}
              mainValue={formatNumber(resource?.area * 1000000)}
              style={{
                marginLeft: '5px',
              }}
            />
          )}
        </p>
        <span>{t('placeme.range_tile.area_in_m2')}</span>
      </div>
      <EyeIcon
        className='icon'
        onClick={() => {
          if (handleValueLabelClick) {
            handleValueLabelClick(resource?.label, false)
            if (compared) {
              handleValueLabelClick(compared?.label, true)
            }
          }
        }}
      />
    </ValueTile>
  )
}

export const TimeSelector = ({
  onSubmit,
  layers,
  comparedLayers,
  tile,
  isExtraPaid,
  setAccepted,
  accepted,
  timeRanges,
  comparedTimeRanges,
  handleValueLabelClick,
  setMapLocation,
  setComparedMapLocation,
}: ITimeSelectorProps): JSX.Element => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const [carRanges, setCarRanges] = useState<ResourceWithId[]>([])
  const [footRanges, setFootRanges] = useState<ResourceWithId[]>([])
  const [timeRangesWithActive, setTimeRangesWithActive] = useState<ITimeSelectorProps['timeRanges']>()
  const [comparedTimeRangesWithActive, setComparedTimeRangesWithActive] = useState<ITimeSelectorProps['comparedTimeRanges']>()
  const {
    value, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)
  const {
    creditsAmount, values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const hasItems = !!carRanges.length || !!footRanges.length
  const [isMapVisible, setIsMapVisible] = useState(false)
  const [isComparedMapVisible, setIsComparedMapVisible] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)

  const isDataValue = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
    t.id === tile)?.data?.value

  const handleMapOpen = (isComparedLocation: boolean) => {
    if (!isComparedLocation) { setIsMapDisplayed(!isMapDisplayed) }
    else { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
  }

  const handleAddMap = (compared: boolean) => {
    if (compared) setIsComparedMapVisible(true)
    else { setIsMapVisible(true) }
  }

  const handleDelete = (resourceId: string) => {
    setCarRanges(carRanges.filter(range => range.id !== resourceId))
    setFootRanges(footRanges.filter(range => range.id !== resourceId))
  }

  const onAccept = () => {
    if (creditsAmount && setAccepted) {
      onSubmit([...carRanges, ...footRanges])
      setAccepted(true)
    }
  }

  const handleValueLabelClickWithActive = (label: string, compared: boolean) => {
    if (handleValueLabelClick) {
      const newActive : ITimeSelectorProps['timeRanges'] = []
      if (!compared) {
        timeRangesWithActive?.forEach(range => (range?.label === label
          ? newActive.push({
            ...range,
            active: true,
          })
          : newActive.push(range)))
        setTimeRangesWithActive(newActive)
      } else {
        comparedTimeRangesWithActive?.forEach(range => (range?.label === label
          ? newActive.push({
            ...range,
            active: true,
          })
          : newActive.push(range)))
        setComparedTimeRangesWithActive(newActive)
      }
    }
  }

  useEffect(() => {
    if (timeRanges) {
      setTimeRangesWithActive(timeRanges?.map(range => ({
        ...range,
        active: false,
      })))
    }
  }, [timeRanges])

  useEffect(() => {
    if (comparedTimeRanges) {
      setComparedTimeRangesWithActive(comparedTimeRanges?.map(range => ({
        ...range,
        active: false,
      })))
    }
  }, [comparedTimeRanges])

  useEffect(() => {
    const activeTimeRange = timeRangesWithActive?.find(range => range?.active === true)
    if (handleValueLabelClick && timeRangesWithActive && activeTimeRange) {
      handleValueLabelClick(activeTimeRange?.label, false)
    }
  }, [timeRangesWithActive])

  useEffect(() => {
    const activeTimeRange = comparedTimeRangesWithActive?.find(range => range?.active === true)
    if (handleValueLabelClick && comparedTimeRangesWithActive && activeTimeRange) {
      handleValueLabelClick(activeTimeRange?.label, true)
    }
  }, [comparedTimeRangesWithActive])

  useEffect(() => {
    if (!isPrinting) return
    handleAddMap(false)
    handleAddMap(true)
  }, [isPrinting])

  return (
    <>
      {!hasItems && !isDataValue && (
        <TitleFormSectionSubTitle>
          {t('placeme.time_selector.title')}
        </TitleFormSectionSubTitle>
      )}

      <Wrapper>
        <>
          {!accepted && !isDataValue && (
            <SelectorWrapper>
              <span>{t('placeme.time_selector.description')}</span>
              {hasItems && (
                <>
                  {footRanges.map((resource) => (
                    <ListSpan key={resource.id}>
                      <span>
                        {resource.content}
                        {t('placeme.time_selector.by_foot')}
                      </span>
                      <CloseIcon onClick={() => handleDelete(resource.id)} />
                    </ListSpan>
                  ))}
                  {carRanges.map((resource) => (
                    <ListSpan key={resource.id}>
                      <span>
                        {resource.content}
                        {t('placeme.time_selector.by_car')}
                      </span>
                      <CloseIcon onClick={() => handleDelete(resource.id)} />
                    </ListSpan>
                  ))}
                </>
              )}
              <>
                <TitleFormSectionSubTitle>
                  {t('placeme.time_selector.by_foot')}
                </TitleFormSectionSubTitle>
                <ResourcesSelector
                  multiSelect
                  onChange={(resources) =>
                    setFootRanges(
                      footTimeRanges.filter((ft) => resources.includes(ft.id)),
                    )}
                  resources={footTimeRanges}
                  value={footRanges.map((fr) => fr.id)}
                />
                <TitleFormSectionSubTitle>
                  {t('placeme.time_selector.by_car')}
                </TitleFormSectionSubTitle>
                <ResourcesSelector
                  multiSelect
                  onChange={(resources) =>
                    setCarRanges(
                      carTimeRanges.filter((ct) => resources.includes(ct.id)),
                    )}
                  resources={carTimeRanges}
                  value={carRanges.map((cr) => cr.id)}
                />
              </>
            </SelectorWrapper>
          )}
          {(isDataValue) && (
            <ValuesWrapper>
              {timeRangesWithActive?.map((resource) => (
                <ClickableLabel
                  key={resource?.label}
                  compared={comparedTimeRanges?.find(item => item?.label === resource?.label)}
                  handleValueLabelClick={handleValueLabelClickWithActive}
                  resource={resource}
                />
              ))}
            </ValuesWrapper>
          )}
          {(!isDataValue) && (
            <TileFooter
              disabled={!hasItems}
              isExtraPaid={isExtraPaid}
              isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
              label={isExtraPaid
                ? `${t('generic.apply_and_buy')}`
                : `${t('generic.apply')}`}
              onAccept={onAccept}
              tile='access_range'
              value={((carRanges.length + footRanges.length) * checkComparedCoinsValue(
                comparedLocation?.generatedFromNow,
                !!comparedLocation?.location,
                !tilesWithoutComparedLocation.includes(tile?.split('-')[0]),
              )).toString() || ''}
            />
          )}
          {isDataValue && (
            <div className={comparedLayers ? 'page-break-before' : ''}>
              <>
                {comparedLayers && (
                  <ComparedLocationHeader>
                    <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                    {' '}
                    <span>{value?.address}</span>
                  </ComparedLocationHeader>
                )}
                <StyledTitleFormSectionSubTitle>
                  <span>{t('placeme.time_selector.ranges_on_the_map')}</span>
                  <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
                </StyledTitleFormSectionSubTitle>
                <NoPrint>
                  <TileFormParagraph>
                    {t('placeme.access_range_tile.map_description.on_click')}
                  </TileFormParagraph>
                </NoPrint>
                {(!isMapVisible) && (
                  <AddMapView
                    buttonValue={0}
                    description='placeme.add_traffic_visualisation_map.description'
                    onChange={() => { handleAddMap(false) }}
                    title='placeme.add_access_range_map.title'
                  />
                )}
                {isMapVisible && (
                  <MapWrapper>
                    <MapTile
                      dragging
                      height='100%'
                      layers={layers || undefined}
                      location={value}
                      mapId='example-map-data-tile'
                      pinDisplayed
                      popupHeading={`${t('generic.chosen_location')}:`}
                      popupParagraph={value?.address}
                      setMapLocation={setMapLocation}
                      showScaleControl
                      width='100%'
                      zoom={12}
                      zoomControl
                    />
                  </MapWrapper>
                )}
              </>
              {comparedLayers
                  && (
                    <>
                      <ComparedLocationHeader second>
                        <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                        {' '}
                        <span>{comparedLocation?.location?.address}</span>
                      </ComparedLocationHeader>
                      <StyledTitleFormSectionSubTitle>
                        <span>{t('placeme.time_selector.ranges_on_the_map')}</span>
                        <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                      </StyledTitleFormSectionSubTitle>
                      <NoPrint>
                        <TileFormParagraph>
                          {t('placeme.access_range_tile.map_description.on_click')}
                        </TileFormParagraph>
                      </NoPrint>
                      {(!isComparedMapVisible) && (
                        <AddMapView
                          buttonValue={0}
                          description='placeme.add_traffic_visualisation_map.description'
                          onChange={() => handleAddMap(true)}
                          title='placeme.add_access_range_map.title'
                        />
                      )}
                      { isComparedMapVisible && (
                        <MapWrapper>
                          <MapTile
                            dragging
                            height='100%'
                            layers={comparedLayers || undefined}
                            location={comparedLocation?.location}
                            mapId='example-map-data-tile'
                            pinDisplayed
                            popupHeading={`${t('generic.chosen_location')}:`}
                            popupParagraph={comparedLocation?.location?.address}
                            setMapLocation={setComparedMapLocation}
                            showScaleControl
                            width='100%'
                            zoom={12}
                            zoomControl
                          />
                        </MapWrapper>
                      )}
                    </>
                  )}
            </div>
          )}

          {isMapDisplayed
          && (
            <MapVisualizationModal
              isDisplayed={isMapDisplayed}
              layers={layers || undefined}
              location={value}
              mapId={`time-select-access-map-${values?.find(c => c.id === 'access_logistic')?.tiles?.find(t => t.id === 'access_range')?.chosenRange?.catchmentId}`}
              setIsDisplay={setIsMapDisplayed}
              zoom={12}
            />
          )}
          {isComparedMapDisplayed
          && (
            <MapVisualizationModal
              isDisplayed={isComparedMapDisplayed}
              layers={comparedLayers || undefined}
              location={comparedLocation?.location}
              mapId={`time-select-access-map-${values?.find(c => c.id === 'access_logistic')?.tiles?.find(t => t.id === 'access_range')?.chosenRange?.catchmentId}_compared`}
              setIsDisplay={setIsComparedMapDisplayed}
              zoom={12}
            />
          )}
        </>
      </Wrapper>
    </>
  )
}
