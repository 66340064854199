import React, { useContext } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
/* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment */
// @ts-ignore
import { CustomRoutes, AuthProvider, I18nProvider } from '@dataplace.ai/features'
import { GlobalStyle, theme } from '@dataplace.ai/ui-components/themes'
import AppWrapper from '@dataplace.ai/features/components/AppWrapper'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { AuthContext } from '@dataplace.ai/features/components/AuthContext'
import { useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes } from 'react-router-dom'
import { FeatureFlagsContextProvider } from '@dataplace.ai/features/contexts/FeatureFlagsContext'
import { UserSettingsProvider } from 'apps/placeme/src/contexts'
import { RemoteConfigProvider } from './features/Analyse/utils/RemoteConfigProvider'
import { routes } from './routes'
import store from './redux/store'
import SentryErrorBoundary from '../../../libs/shared/features/src/components/SentryErrorBoundary'

const SentryComponent = () => {
  const authContext = useContext(AuthContext)
  Sentry.init({
    dsn: 'https://cb6b0b8dcfa546c2b6a3c1cce40c2d1a@o1017201.ingest.sentry.io/5982887',
    integrations: [
      new Integrations.BrowserTracing(),
      new Integrations.BrowserTracing({
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    initialScope: {
      user: {
        id:authContext.userData?.user?.uid,
        email: authContext?.userData?.user?.email || '',
      },
    },
    environment: process.env.NX_FIREBASE_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })

  return (<></>)
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <SentryErrorBoundary name='app'>
    <React.StrictMode>
      <AuthProvider>
        <UserSettingsProvider>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <SentryComponent />
              <FeatureFlagsContextProvider>
                <AppWrapper product='placeme'>
                  <CustomRoutes
                    product='placeme'
                    routes={routes}
                  >
                    <RemoteConfigProvider />
                  </CustomRoutes>
                </AppWrapper>
              </FeatureFlagsContextProvider>
            </ThemeProvider>
          </Provider>
        </UserSettingsProvider>
      </AuthProvider>
    </React.StrictMode>
  </SentryErrorBoundary>,
)
