import { Tooltip } from '@dataplace.ai/ui-components/atoms'
import { BigBenRangeChartModalTrigger } from '@placeme/components/atoms/BigBen'
import React from 'react'
import NumberFormat from 'react-number-format'
import { ReactComponent as HelpCircle } from 'libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import styled, { css } from 'styled-components'

const StyledHelpCircle = styled(HelpCircle)(({ theme }) => {
  const { palette } = theme
  return css`
    cursor: pointer;
    min-width: 1rem;
    width: 1rem;
    margin-left: 0.5rem;
    margin-bottom: -0.2rem;
  
    > path {
      fill: ${palette.blue};
    }`
})

interface IRangeTooltip {
  tooltipValue: number | undefined,
  percent: boolean | undefined,
  modal: {
    catchmentId: string | undefined
    parameter: string
    rangeTranslation: string
  } | undefined,
  modalOpened: boolean,
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>,
}

export function RangeTooltip({
  tooltipValue,
  percent,
  modal,
  modalOpened,
  setModalOpened,
}: IRangeTooltip): JSX.Element {
  return (
    <Tooltip
      content={(
        <>
          <strong>
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={tooltipValue ?? ''}
            />
            {percent ? '%' : ''}
          </strong>
          {modal
            ? <BigBenRangeChartModalTrigger onClick={() => setModalOpened(true)} />
            : null}
        </>
      )}
      display={modalOpened ? 'none' : 'inline-block'}
      maxWidth='200px'
      position='right center'
    >
      <StyledHelpCircle />
    </Tooltip>
  )
}
