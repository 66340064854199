import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@dataplace.ai/ui-components/atoms'
import { DownloadButton } from '../AnalysePageHeader.styles'
import { ReactComponent as Download } from '../../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/download.svg'
import { TOOLTIP_MAX_WIDTH } from '../../Tiles/Surroundings/Investments/utils/constants'

interface DownloadComponentProps {
  downloadDisabled: boolean
}

function DownloadComponent({ downloadDisabled }: DownloadComponentProps) {
  const { t } = useTranslation()

  return (
    <DownloadButton
      disabled={downloadDisabled}
      type='button'
    >
      <Download />
      <span>{t('generic.download_pdf')}</span>
    </DownloadButton>
  )
}

export interface DownloadPdfButtonProps extends DownloadComponentProps {
  isTrial: boolean
  comparedReportId?: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DownloadPdfButton({
  downloadDisabled, isTrial,
}: DownloadPdfButtonProps) {
  const { t } = useTranslation()

  return (downloadDisabled || isTrial
    ? (
      <Tooltip
        content={t(`placeme.download_button.disabled${isTrial ? '_for_trial' : ''}`)}
        maxWidth={TOOLTIP_MAX_WIDTH}
        position='left top'
      >
        <DownloadComponent downloadDisabled={downloadDisabled} />
      </Tooltip>
    )
    : <DownloadComponent downloadDisabled={downloadDisabled} />
  )
}

export default DownloadPdfButton
