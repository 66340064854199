import { useTranslation } from 'react-i18next'
import { ISegmentationData } from '../SegmentationTile'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetDoughnutData = (data: ISegmentationData, element: 'segment' | 'traffic') => {
  const { t } = useTranslation()
  const elementData = {
    segment: {
      title: t('placeme.segmentation_tile.section_title_1'),
      subtitle: t('placeme.segmentation_tile.form_span_1'),
      firstSegment: {
        span: t('placeme.segmentation_tile.premium_segment'),
        value: data?.value?.segmentation.segments.premium * 100,
      },
      secondSegment: {
        span: t('placeme.segmentation_tile.standard_segment'),
        value: data?.value?.segmentation.segments.standard * 100,
      },
    },
    traffic: {
      title: t('placeme.segmentation_tile.section_title_2'),
      subtitle: t('placeme.segmentation_tile.form_span_2'),
      firstSegment: {
        span: t('placeme.segmentation_tile.local_segment'),
        value: data?.value?.segmentation.traffic.segments.local * 100,
      },
      secondSegment: {
        span: t('placeme.segmentation_tile.transit_segment'),
        value: data?.value?.segmentation.traffic.segments.transit * 100,
      },
    },
  }

  return {
    elementData: elementData[element],
  }
}
